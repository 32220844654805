import { Dictionary, EntityId } from '@reduxjs/toolkit';
import {
  ButtonActionMapping,
  CanvasElement,
} from '@brame/builder/src/reducers/builder';
import { Breakpoint } from '@brame/builder/src/reducers/shared/types';
import { DraggableElementsType } from '@brame/builder/src/components/DragNDropWrapper/types';
import { ITheme } from '@brame/theme';
import CanvasComponent from './CanvasComponent';

export const renderTree = ({
  rootElementId,
  elements,
  wrapper,
  actions,
  gameData,
  breakpoints,
  activeBreakpoint,
  builderMode = false,
  theme,
}: {
  rootElementId: EntityId;
  elements: Dictionary<CanvasElement>;
  activeBreakpoint?: Breakpoint;
  theme: ITheme;
  wrapper?: (element: DraggableElementsType) => any;
  actions?: ButtonActionMapping;
  breakpoints?: Breakpoint[];
  builderMode?: boolean;
  gameData?: any;
}) => {
  const element = elements[rootElementId];
  if (!element) return null;

  return (
    <CanvasComponent
      elements={elements}
      element={element}
      index={0}
      breakpoints={breakpoints}
      activeBreakpoint={activeBreakpoint}
      theme={theme}
      builderMode={builderMode}
      actions={actions}
      wrapper={wrapper}
      gameData={gameData}
    />
  );
};
