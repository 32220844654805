export const primary = {
  light: '#94E6DC',
  regular: '#197B6F',
  hover: '#07584E',
};

export const monochrome = {
  black: '#000000',
  white: '#ffffff',
};

export const grey = {
  800: '#47484C',
  700: '#4D4D4D',
  600: '#666666',
  500: '#808080',
  400: '#9F9F9F',
  300: '#B3B3B3',
  200: '#EAEEEF',
  100: '#F2F6F7',
  0: '#FCFCFC',
};

export const red = {
  800: '#DF3838',
  600: '#FF5454',
  400: '#FF7E7E',
  200: '#FFBDBD',
  0: '#FFE9E9',
};

export const blue = {
  800: '#1328A0',
  600: '#2740CD',
  400: '#5067E9',
  200: '#98A6F9',
  0: '#F5F6FF',
};

export const petrol = {
  400: '#75798C',
};

export const green = {
  800: '#50A013',
  600: '#6FCD27',
  400: '#93E950',
  200: '#C2F998',
  0: '#F9FFF5',
};

export const yellow = {
  800: '#B8BB00',
  600: '#C9CC04',
  300: '#E2E618',
  200: '#FCFF61',
  0: '#FEFFCF',
};
