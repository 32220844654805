/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import React, { FC, useEffect, useState } from 'react';
import { TextInput } from '../TextInput';
import { useStyles } from './styles';
import { fallbackProps } from './mock';

interface IProps {
  onChange: (value: string) => void;
  value?: string;
  label?: string;
}

const ButtonValueInput: FC<IProps> = ({ value = '', onChange }) => {
  const [inputValue, setInputValue] = useState(value);
  const styles = useStyles();

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const handleChange = (value: string) => {
    setInputValue(value);
    onChange(value);
  };

  const handleBlur = () => {
    if (!inputValue) {
      onChange(fallbackProps.value);
      setInputValue(fallbackProps.value);
    }
  };

  return (
    <TextInput
      label="Text"
      value={inputValue}
      css={styles.input}
      onChange={handleChange}
      onBlur={handleBlur}
      fullWidth
    />
  );
};

export default ButtonValueInput;
