/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import React, { FC, Fragment, useEffect, useRef, useState } from 'react';
import { Trophy } from 'phosphor-react';
import { EntityId } from '@reduxjs/toolkit';
import { Popper, Tooltip, Typography, useTheme } from '@mui/material';
import { PageElement } from '../../../../reducers/builder';
import { useStyles } from '../Page/styles';
import { Page } from '../Page';
import { CustomIcon } from '../CustomIcon';
import Tab from './Tab';

interface IProps {
  group: PageElement[];
  title: string;
  currentPageId: EntityId | null;
  onSelectPage: (id: EntityId) => void;
}

const GroupedPages: FC<IProps> = ({
  group,
  title,
  onSelectPage,
  currentPageId,
}) => {
  const ref = useRef(null);
  const [selected, setSelected] = useState(false);
  const theme = useTheme();
  const styles = useStyles(selected);

  const handleSelect = () => {
    setSelected((prev) => !prev);
    onSelectPage(group[0].id);
  };

  useEffect(() => {
    if (!group.find((page) => page.id === currentPageId)) {
      setSelected(false);
    }
  }, [currentPageId]);

  return (
    <div ref={ref}>
      {selected ? (
        <Fragment>
          <Popper
            open={!!ref.current}
            anchorEl={ref.current}
            placement="top"
            keepMounted
            css={styles.groupedPagesLabel}
          >
            <Fragment>
              {group.map((page, index) => (
                <Tab
                  index={index}
                  key={page.id}
                  selected={selected}
                  // @ts-ignore
                  style={{
                    zIndex: selected && page.id === currentPageId ? 4 : 3,
                  }}
                  onClick={(index) => onSelectPage(group[index].id)}
                  switchProps={{
                    fill:
                      selected && page.id === currentPageId
                        ? theme.palette.primary.main
                        : selected
                        ? theme.palette.primary.light
                        : theme.palette.common.white,
                  }}
                  labelProps={{
                    style: {
                      color:
                        selected &&
                        page.id !== currentPageId &&
                        theme.palette.primary.main,
                    },
                  }}
                />
              ))}
            </Fragment>
          </Popper>
          <div css={styles.groupedPagesPageOuter}>
            {group.map((page, index) => (
              <div css={styles.groupedPagesPageInner} key={page.id}>
                <Page
                  page={page}
                  selected={page.id === currentPageId}
                  onSelectPage={onSelectPage}
                  hidden={page.id !== currentPageId}
                  index={index}
                />
              </div>
            ))}
          </div>
        </Fragment>
      ) : (
        <div
          css={styles.container}
          data-testid="outcome-group"
          onClick={handleSelect}
        >
          <div css={styles.page}>
            <Popper
              open={true}
              anchorEl={ref.current}
              placement="top"
              css={styles.groupedPagesLabel}
            >
              <Tab index={group.length - 1} selected={selected} />
            </Popper>

            <CustomIcon
              Icon={Trophy}
              color={
                selected
                  ? theme.palette.common.white
                  : theme.palette.text.primary
              }
            />
          </div>
          <Tooltip
            title={title}
            data-testid="page-tooltip"
            css={styles.title}
            disableInteractive
          >
            <Typography variant="h4" css={styles.title}>
              {title}
            </Typography>
          </Tooltip>
        </div>
      )}
    </div>
  );
};

export default GroupedPages;
