import Phaser from 'phaser';
import DropGameScene from './DropGameScene';

export type MovementFunction = (scene: DropGameScene) => void;

export function initializeMovement(scene: DropGameScene) {
  switch (DropGameScene.jsonLink['game.movementMode']) {
    case 'drag':
      scene.movementFunction = movementDrag;
      scene.input.on('pointerdown', () => {
        // Distance threshhold needs to be changed to depend on sprite displaySize
        if (
          Phaser.Math.Distance.Between(
            scene.player.x,
            scene.player.y,
            scene.input.activePointer.x,
            scene.input.activePointer.y
          ) < 30
        ) {
          scene.movementDragging = true;
        }
      });
      scene.input.on('pointerup', () => {
        scene.movementDragging = false;
      });
      break;
    case 'followVelocity':
      scene.movementFunction = movementFollowVel;
      break;
    case 'followPosition':
      scene.movementFunction = movementFollowPos;
      break;
    case 'laneBased':
      scene.input.on('pointerdown', () => {
        if (!scene.gameEnd && !scene.tutorialMode) {
          switch (DropGameScene.jsonLink['game.direction']) {
            case 'TopBot':
            case 'BotTop':
              if (scene.input.activePointer.x < scene.gameWidth / 2) {
                if (scene.playerLaneIndex > 0) scene.playerLaneIndex -= 1;
              } else {
                if (scene.playerLaneIndex < scene.laneArray.length - 1)
                  scene.playerLaneIndex += 1;
              }
              scene.tweens.add({
                targets: scene.player,
                x: scene.laneArray[scene.playerLaneIndex].x,
                duration: 200,
              });
              break;
            case 'LeftRight':
            case 'RightLeft':
              if (scene.input.activePointer.y > scene.gameHeight / 2) {
                if (scene.playerLaneIndex < scene.laneArray.length - 1)
                  scene.playerLaneIndex += 1;
              } else {
                if (scene.playerLaneIndex > 0) scene.playerLaneIndex -= 1;
              }
              scene.tweens.add({
                targets: scene.player,
                y: scene.laneArray[scene.playerLaneIndex].y,
                duration: 200,
              });
              break;
          }
        }
      });
      scene.playerLaneIndex = Math.floor(scene.laneArray.length / 2);
      switch (scene.gameDirection) {
        case 'TopBot':
        case 'BotTop':
          scene.playerStartX = scene.laneArray[scene.playerLaneIndex].x;
          break;
        case 'LeftRight':
        case 'RightLeft':
          scene.playerStartY = scene.laneArray[scene.playerLaneIndex].y;
          break;
      }
      break;
  }
}

export function movementDrag(scene: DropGameScene) {
  if (scene.movementDragging) {
    movementFollowPos(scene);
  }
}

export function movementFollowVel(scene: DropGameScene) {
  const speedVec = new Phaser.Math.Vector2(0, 0);
  switch (scene.gameDirection) {
    case 'TopBot':
    case 'BotTop':
      speedVec.x = scene.input.activePointer.x - scene.player.x;
      break;
    case 'LeftRight':
    case 'RightLeft':
      speedVec.y = scene.input.activePointer.y - scene.player.y;
      break;
  }

  // Hardcoded min distance for the player to stop
  if (speedVec.length() > 10 && speedVec.length() < scene.minFollowSpeed) {
    speedVec.setLength(scene.minFollowSpeed);
  }
  scene.player.setVelocity(speedVec.x / 50, speedVec.y / 50);
}

export function movementFollowPos(scene: DropGameScene) {
  switch (scene.gameDirection) {
    case 'TopBot':
      scene.player.setPosition(
        Phaser.Math.Clamp(
          scene.input.activePointer.x,
          scene.player.displayWidth / 2,
          scene.gameWidth - scene.player.displayWidth / 2
        ),
        scene.gameHeight +
          (scene.playerOffsetY * scene.player.displayHeight) / 2
      );
      break;
    case 'BotTop':
      scene.player.setPosition(
        Phaser.Math.Clamp(
          scene.input.activePointer.x,
          scene.player.displayWidth / 2,
          scene.gameWidth - scene.player.displayWidth / 2
        ),
        (scene.playerOffsetY * scene.player.displayHeight) / 2
      );
      break;
    case 'LeftRight':
      scene.player.setPosition(
        scene.gameWidth + (scene.playerOffsetX * scene.player.displayWidth) / 2,
        Phaser.Math.Clamp(
          scene.input.activePointer.y,
          scene.player.displayHeight / 2,
          scene.gameHeight - scene.player.displayHeight / 2
        )
      );
      break;
    case 'RightLeft':
      scene.player.setPosition(
        (scene.playerOffsetX * scene.player.displayWidth) / 2,
        Phaser.Math.Clamp(
          scene.input.activePointer.y,
          scene.player.displayHeight / 2,
          scene.gameHeight - scene.player.displayHeight / 2
        )
      );
      break;
  }
}
