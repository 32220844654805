/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { FC } from 'react';
import { TextAlignSwitcher } from '../TextAlignSwitcher';
import { JustifyContentSwitcher } from '../JustifyContentSwitcher';
import { AlignItemsSwitcher } from '../AlignItemsSwitcher';
import { DirectionSwitcher } from '../DirectionSwitcher';
import { IInternalAlignment } from '../../types';
import { useStyles } from './styles';

interface IProps {
  props: IInternalAlignment;
  onChange: (e: IInternalAlignment) => void;
}

const InternalAlignmentWidget: FC<IProps> = ({ props, onChange }) => {
  const { textAlign, flexDirection, justifyContent, alignItems } = props;
  const styles = useStyles();

  return (
    <div data-testid="internal-alignment-widget" css={styles.wrapper}>
      <TextAlignSwitcher
        align={textAlign}
        onChange={(value) => onChange({ ...props, textAlign: value })}
      />

      <DirectionSwitcher
        direction={flexDirection}
        onChange={(value) => onChange({ ...props, flexDirection: value })}
      />

      <JustifyContentSwitcher
        justify={justifyContent}
        onChange={(value) => onChange({ ...props, justifyContent: value })}
      />

      <AlignItemsSwitcher
        align={alignItems}
        onChange={(value) => onChange({ ...props, alignItems: value })}
      />
    </div>
  );
};

export default InternalAlignmentWidget;
