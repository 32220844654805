import { css } from '@emotion/react';
import { brameTheme } from '@brame/theme';
import globalColors from '@brame/theme/src/globalColors';

export const useStyles = () => ({
  dialogTitle: css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: ${brameTheme.palette.primary.main};
    font-size: 16px;
    font-weight: 500;
    color: ${brameTheme.palette.primary.contrastText};
    padding: 8px 20px;
    text-transform: uppercase;
    margin-bottom: 20px;
  `,
  dialogTitleIcon: css`
    color: ${brameTheme.palette.primary.contrastText};
    margin-left: 10px;
  `,
  content: css`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    box-shadow: 10px 10px 15px ${globalColors.opacity4dBlack};
    height: 100%;
    overflow: hidden;
    border-radius: 10px;
  `,
});
