/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { FC } from 'react';
import { Rectangle } from 'phosphor-react';
import { Typography, useTheme } from '@mui/material';
import { ICanvasDimensions } from '../../../reducers/shared/types';
import { useStyles } from './styles';

interface IProps {
  format: ICanvasDimensions;
  active: boolean;
  onClick: (format: ICanvasDimensions) => void;
}

const CanvasFormat: FC<IProps> = ({ format, active, onClick }) => {
  const { width, height } = format;
  const styles = useStyles(active);
  const theme = useTheme();

  return (
    <div
      css={styles.container}
      data-testid="canvas-format"
      onClick={() => onClick(format)}
    >
      <div css={styles.box}>
        <Rectangle weight="duotone" color={theme.palette.text.primary} />
      </div>

      <div css={styles.box}>
        <Typography>
          {width}x{height}
        </Typography>
      </div>
    </div>
  );
};

export default CanvasFormat;
