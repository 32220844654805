import {
  createEntityAdapter,
  createSlice,
  EntityId,
  EntityState,
  PayloadAction,
} from '@reduxjs/toolkit';
import merge from 'lodash/merge';
import { RootState } from '../store';
import {
  arrayMoveImmutable,
  createCanvasElement,
  createPage,
  createRootElement,
  getElementIds,
  getFormId,
  isBasicPage,
  getResponsiveHeightOverride,
  createContainerElement,
} from './utils';
import {
  BuilderComponent,
  ButtonActionType,
  CanvasElement,
  ContainerElement,
  IElement,
  IRawStyles,
  PageElement,
  PageType,
} from './types';
import { defaultTemplateState } from './defaultTemplate';
import { fetchInitialStateAsync } from './asyncThunks';
import { maxAmountOfElementsByType } from './limits';

export interface ElementsState {
  elements: EntityState<CanvasElement>;
  pages: EntityState<PageElement>;
  additionalPages: EntityState<PageElement>;
  selectedPageId: EntityId;
  previewMode: boolean;
  readiness: string;
  selectedTheme: string;
}

const elementsAdapter = createEntityAdapter<CanvasElement>();
const pagesAdapter = createEntityAdapter<PageElement>();
const initialState: ElementsState = {
  elements: elementsAdapter.getInitialState(defaultTemplateState.elements),
  pages: pagesAdapter.getInitialState(defaultTemplateState.pages),
  additionalPages: pagesAdapter.getInitialState(
    defaultTemplateState.additionalPages
  ),
  selectedPageId: defaultTemplateState.selectedPageId,
  previewMode: false,
  readiness: 'loading',
  selectedTheme: 'theme1',
};

const elementsSlice = createSlice({
  name: 'elements',
  initialState,
  reducers: {
    /* Element reducers => add, move, remove, rename */
    addElement: (state, action) => {
      const { parentId, type, index, option } = action.payload;

      const limit: number | undefined =
        maxAmountOfElementsByType[type as BuilderComponent];
      const elementsOfType: IElement[] = (
        Object.values(state?.elements?.entities) as IElement[]
      ).filter((el) => el.type === type);
      if (typeof limit === 'number' && elementsOfType.length >= limit) {
        return;
      }

      const parent = state.elements.entities[parentId];
      let element: CanvasElement[];
      if (type === BuilderComponent.CONTAINER) {
        option
          ? (element = Object.values(
              createContainerElement(parentId, type, option)
            ))
          : (element = [createCanvasElement(parentId, type, option)]);
      } else {
        element = [createCanvasElement(parentId, type, option)];
      }

      // when container receives its first child, its height is set to 'auto'
      if (parent?.type === BuilderComponent.CONTAINER) {
        if (parent?.childrenIds.length === 0) {
          merge(parent, getResponsiveHeightOverride(true));
        }
      }

      if (
        parent &&
        (parent.type === BuilderComponent.CONTAINER ||
          parent.type === BuilderComponent.ROOT_CONTAINER)
      ) {
        parent.childrenIds.splice(index, 0, element[0].id).push(element[0].id);
      }

      if (type === BuilderComponent.CONTAINER) {
        elementsAdapter.addMany(state.elements, element);
      } else {
        elementsAdapter.addOne(state.elements, element[0]);
      }

      if (
        type === BuilderComponent.FORM ||
        type === BuilderComponent.TERMS_AND_CONDITIONS
      ) {
        const submitButton = createCanvasElement(
          parentId,
          BuilderComponent.BUTTON,
          {
            formId: getFormId(element[0].id),
            value: 'Submit',
            action: {
              type: ButtonActionType.SUBMIT_FORM,
            },
          }
        );

        if (
          parent &&
          (parent.type === BuilderComponent.CONTAINER ||
            parent.type === BuilderComponent.ROOT_CONTAINER)
        ) {
          parent.childrenIds
            .splice(index + 1, 0, submitButton.id)
            .push(submitButton.id);
        }
        elementsAdapter.addOne(state.elements, submitButton);
      }
    },
    moveElement: (state, action) => {
      const { parentId, id, index, switchElementIndex } = action.payload;
      const element = state.elements.entities[id] as IElement;

      if (element.type === BuilderComponent.ROOT_CONTAINER) return;

      const newParent = state.elements.entities[parentId] as ContainerElement;

      // when container receives its first child, its height is set to 'auto'
      if (newParent?.type === BuilderComponent.CONTAINER) {
        if (newParent?.childrenIds.length === 0) {
          merge(newParent, getResponsiveHeightOverride(true));
        }
      }

      if (element.parentId === parentId) {
        newParent.childrenIds = arrayMoveImmutable(
          newParent.childrenIds,
          index,
          switchElementIndex
        );
        return;
      }

      const oldParent = state.elements.entities[
        element.parentId
      ] as ContainerElement;
      //add
      newParent.childrenIds
        ? newParent.childrenIds.splice(switchElementIndex, 0, id)
        : (newParent.childrenIds = [id]);
      element.parentId = parentId;

      //delete
      oldParent.childrenIds = oldParent.childrenIds?.filter((el) => el !== id);

      // emptied parent container gets height to prevent it from collapsing
      if (oldParent.type === BuilderComponent.CONTAINER) {
        if (oldParent.childrenIds.length === 0) {
          if (oldParent?.styles?.overrides?.size?.height?.unit === 'auto') {
            merge(oldParent, getResponsiveHeightOverride());
          }
        }
      }
    },
    renameElement: (state, action) => {
      const { id, name } = action.payload;
      const element = state.elements.entities[id];
      if (element) {
        element.name = name;
      }
    },
    updateElement: (state, action) => {
      elementsAdapter.updateOne(state.elements, action.payload);
    },
    removeElement: (state, action) => {
      const id = action.payload;
      const element = state.elements.entities[id] as IElement;
      const parent = state.elements.entities[
        element?.parentId
      ] as ContainerElement;

      if (parent) {
        const childIndex = parent.childrenIds?.indexOf(id);
        if (childIndex !== undefined && childIndex > -1) {
          parent.childrenIds?.splice(childIndex, 1);
        }
      }
      elementsAdapter.removeOne(state.elements, id);

      // emptied parent container gets height to prevent it from collapsing
      if (parent.type === BuilderComponent.CONTAINER) {
        if (parent.childrenIds.length === 0) {
          merge(parent, getResponsiveHeightOverride());
        }
      }
    },
    addPage: (state, action) => {
      const { pageType, dimensions } = action.payload;
      const rootContainer = createRootElement(dimensions);
      const { id: elementId } = rootContainer;
      const newPage = createPage(pageType, elementId);

      if (pageType === PageType.Game || pageType === PageType.Basic) {
        const pages = Object.values(state?.pages?.entities) as PageElement[];
        let newPages: PageElement[];
        const firstOutcomePageIndex = pages.findIndex(
          (page) => page?.pageType === PageType.Outcome
        );

        if (firstOutcomePageIndex > -1) {
          newPages = [...pages];
          newPages.splice(firstOutcomePageIndex, 0, newPage);
          pagesAdapter.setAll(state.pages, newPages);
        } else {
          pagesAdapter.addOne(state.pages, newPage);
        }
      } else {
        pagesAdapter.addOne(
          isBasicPage(pageType) ? state.pages : state.additionalPages,
          newPage
        );
      }

      elementsAdapter.addOne(state.elements, rootContainer);
    },
    movePage: (state, action) => {
      const { page, newIndex } = action.payload;
      const pages = isBasicPage(page.pageType)
        ? state.pages
        : state.additionalPages;

      const oldIndex = pages.ids.findIndex((id) => id === page.id);
      const newPages = Object.values(pages?.entities) as PageElement[];
      newPages.splice(newIndex, 0, page);
      newPages.splice(newIndex > oldIndex ? oldIndex : oldIndex + 1, 1);
      pagesAdapter.setAll(pages, newPages);
    },
    removePage: (state, action) => {
      const pageId = action.payload;
      const elementIdsToRemove = getElementIds(state, pageId);
      elementsAdapter.removeMany(state.elements, elementIdsToRemove);
      pagesAdapter.removeOne(state.pages, pageId);
      pagesAdapter.removeOne(state.additionalPages, pageId);
    },
    updatePage: (state, action) => {
      pagesAdapter.updateOne(state.pages, action.payload);
      pagesAdapter.updateOne(state.additionalPages, action.payload);
    },
    selectPage: (state, action) => {
      state.selectedPageId = action.payload;
    },
    /* Style reducers => update, reset */
    updateStyles: (state, action) => {
      const { id, styles, activeBreakpoint } = action.payload;
      const currentStyles = state.elements.entities[id]?.styles;
      if (currentStyles) {
        if (activeBreakpoint) {
          if (currentStyles.responsive) {
            currentStyles.responsive = {
              ...currentStyles.responsive,
              [activeBreakpoint]: merge(
                currentStyles.responsive[activeBreakpoint],
                styles
              ),
            };
          } else {
            currentStyles.responsive = {
              [activeBreakpoint]: styles,
            };
          }
        } else {
          if (styles.decoration) {
            currentStyles.overrides.decoration = styles.decoration;
          }
          merge(currentStyles?.overrides, styles);
        }
      }
    },
    resetStyles: (state, action) => {
      const { id, styles } = action.payload;
      elementsAdapter.updateOne(state.elements, {
        id,
        changes: {
          styles: {
            ...(state.elements.entities[id]?.styles as IRawStyles),
            overrides: styles || {},
            responsive: {},
          },
        },
      });
    },
    resetElementDimensions: (state, action) => {
      const id = action.payload;
      // @ts-ignore
      const { size, ...rest } = state.elements.entities[id].styles.overrides;
      elementsAdapter.updateOne(state.elements, {
        id,
        changes: {
          styles: {
            ...(state.elements.entities[id]?.styles as IRawStyles),
            overrides: rest,
          },
        },
      });
    },
    setPreviewMode: (state, action: PayloadAction<boolean>) => {
      state.previewMode = action.payload;
    },
    /* Theme reducers => set */
    setSelectedTheme: (state: ElementsState, action: PayloadAction<string>) => {
      state.selectedTheme = action.payload;
    },
    initHistory: (state) => state,
  },
  /* Async reducers => fetch */
  extraReducers: (builder) => {
    builder.addCase(fetchInitialStateAsync.fulfilled, (state, action) => {
      const data = action.payload.data.data;
      //@ts-ignore
      state.game = data.game;
      state.elements = data.elements;
      state.pages = data.pages;
      state.additionalPages =
        data.additionalPages || defaultTemplateState.additionalPages;
      state.selectedPageId = data.pages.ids[0];
      state.readiness = 'ready';
      state.selectedTheme = data.selectedTheme;
    });
    builder.addCase(fetchInitialStateAsync.pending, (state) => {
      state.readiness = 'loading';
    });
    builder.addCase(fetchInitialStateAsync.rejected, (state) => {
      state.readiness = 'failed';
    });
  },
});

export const {
  addElement,
  moveElement,
  updateElement,
  addPage,
  selectPage,
  updatePage,
  removePage,
  updateStyles,
  resetStyles,
  movePage,
  renameElement,
  removeElement,
  setPreviewMode,
  setSelectedTheme,
  resetElementDimensions,
  initHistory,
} = elementsSlice.actions;

export const elementsSelectors = elementsAdapter.getSelectors<RootState>(
  (state) => state.elements.present.elements
);
export const pagesSelectors = pagesAdapter.getSelectors<RootState>(
  (state) => state.elements.present.pages
);
export const additionalPagesSelectors = pagesAdapter.getSelectors<RootState>(
  (state) => state.elements.present.additionalPages
);

export const selectBuilderState = (state: RootState) => state.elements;
export const selectElements = (state: RootState) =>
  state.elements.present.elements;
export const selectPages = (state: RootState) => state.elements.present.pages;
export const selectRegularPages = (state: RootState) =>
  state.elements.present.pages.ids.map(
    (id) => state.elements.present.pages.entities[id]
  );
export const selectAdditionalPages = (state: RootState) =>
  state.elements.present.additionalPages.ids.map(
    (id) => state.elements.present.additionalPages.entities[id]
  );

export const selectedPageId = (state: RootState) =>
  state.elements.present.selectedPageId;
export const getSelectedPage = (state: RootState) =>
  state.elements.present.pages.entities[
    state.elements.present.selectedPageId
  ] ||
  state.elements.present.additionalPages.entities[
    state.elements.present.selectedPageId
  ];
export const selectedTheme = (state: RootState) =>
  state.elements.present.selectedTheme;

export const selectCurrentRootId = (state: RootState) => {
  const currentPage =
    state.elements.present.pages.entities[
      state.elements.present.selectedPageId
    ] ||
    state.elements.present.additionalPages.entities[
      state.elements.present.selectedPageId
    ];
  return currentPage?.elementRoot;
};

export const getSelectedElement = (state: RootState): CanvasElement | null => {
  if (state.shared.selectedElementId) {
    const element =
      state.elements.present.elements.entities[state.shared.selectedElementId];
    return element || null;
  }
  return null;
};

export const getReadinessOfBuilder = (state: RootState) =>
  state.elements.present.readiness;

export const getFutureStateLength = (state: RootState) =>
  state.elements.future.length;

export const getPastStateLength = (state: RootState) =>
  state.elements.past.length;

export default elementsSlice.reducer;
