/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import React, { FC, useEffect, useState } from 'react';
import { TextField, Typography } from '@mui/material';
import { fallbackProps } from '../TextEditor/mock';
import { useStyles } from './styles';

interface IProps {
  onChange: (value: string) => void;
  value?: string;
  label?: string;
}

const TextArea: FC<IProps> = ({ value = '', onChange, label }) => {
  const [inputValue, setInputValue] = useState(value);
  const styles = useStyles();

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInputValue(e.target.value);
    onChange(e.target.value);
  };

  const handleBlur = () => {
    if (!inputValue) {
      onChange(fallbackProps.value);
      setInputValue(fallbackProps.value);
    }
  };

  return (
    <div data-testid="text-area">
      {label && <Typography css={styles.label}>{label}</Typography>}
      <TextField
        value={inputValue}
        data-testid="text-area-input"
        onChange={handleChange}
        onBlur={handleBlur}
        multiline
        fullWidth
      />
    </div>
  );
};

export default TextArea;
