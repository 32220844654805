import globalColors from '@brame/theme/src/globalColors';
import { ReactComponent as Memory } from './assets/memory.svg';
import { ReactComponent as Spin } from './assets/spin.svg';
import { ReactComponent as Survey } from './assets/survey.svg';
import { ReactComponent as Picture } from './assets/picture.svg';
import { ReactComponent as Test } from './assets/test.svg';
import { ReactComponent as Quiz } from './assets/quiz.svg';
import { ReactComponent as Swipe } from './assets/swipe.svg';
import { ReactComponent as QuizSwipe } from './assets/quizSwipe.svg';
import { ReactComponent as Scratch } from './assets/scratch.svg';
import { ReactComponent as Easter } from './assets/easter.svg';
import { ReactComponent as Advent } from './assets/advent.svg';
import { ReactComponent as Weekly } from './assets/weekly.svg';
import { ReactComponent as Drop } from './assets/drop.svg';
import { ReactComponent as Fly } from './assets/fly.svg';
import { ReactComponent as Drive } from './assets/drive.svg';
import { ReactComponent as Swim } from './assets/swim.svg';
import { ReactComponent as Machine } from './assets/machine.svg';

export enum WizardGameType {
  MEMORY_GAME = 'Memory game',
  SPIN_THE_WHEEl = 'Spin the wheel',
  SURVEY = 'Survey',
  GUESS_THE_PICTURE = 'Guess the picture',
  PERSONALITY_TEST = 'Personality test',
  QUIZ = 'Quiz',
  PERSONALITY_TEST_SWIPE = 'Personality test swipe',
  QUIZ_SWIPE = 'Quiz swipe',
  SCRATCH_CARD = 'Scratch card',
  EASTER_CALENDAR = 'Easter calendar',
  ADVENT_CALENDAR = 'Advent calendar',
  WEEKLY_CALENDAR = 'Weekly calendar',
  DROP = 'Drop',
  FLY = 'Fly',
  DRIVE = 'Drive',
  SWIM = 'Swim',
  SLOT_MACHINE = 'Slot Machine',
}

export enum WizardType {
  CAMPAIGN_TYPE = 'Campaign Type',
  CAMPAIGN_DETAILS = 'Campaign Details',
  CAMPAIGN_THEME = 'Campaign Theme',
  CAMPAIGN_TEMPLATE = 'Campaign Template',
}

export const gameCardsTabOne = [
  {
    id: 1,
    name: WizardGameType.MEMORY_GAME,
    icon: <Memory />,
    color: globalColors.chetwodeBlue,
  },
  {
    id: 2,
    name: WizardGameType.SPIN_THE_WHEEl,
    icon: <Spin />,
    color: globalColors.viking,
  },
  {
    id: 3,
    name: WizardGameType.SURVEY,
    icon: <Survey />,
    color: globalColors.downy,
  },
  {
    id: 4,
    name: WizardGameType.GUESS_THE_PICTURE,
    icon: <Picture />,
    color: globalColors.tonysPink,
  },
  {
    id: 5,
    name: WizardGameType.PERSONALITY_TEST,
    icon: <Test />,
    color: globalColors.gulfStream,
  },
  {
    id: 6,
    name: WizardGameType.QUIZ,
    icon: <Quiz />,
    color: globalColors.chenin,
  },
  {
    id: 7,
    name: WizardGameType.PERSONALITY_TEST_SWIPE,
    icon: <Swipe />,
    color: globalColors.deYork,
  },
  {
    id: 8,
    name: WizardGameType.QUIZ_SWIPE,
    icon: <QuizSwipe />,
    color: globalColors.apache,
  },
  {
    id: 9,
    name: WizardGameType.SCRATCH_CARD,
    icon: <Scratch />,
    color: globalColors.tan,
  },
  {
    id: 10,
    name: WizardGameType.EASTER_CALENDAR,
    icon: <Easter />,
    color: globalColors.sunglo,
  },
  {
    id: 11,
    name: WizardGameType.ADVENT_CALENDAR,
    icon: <Advent />,
    color: globalColors.lavender,
  },
  {
    id: 12,
    name: WizardGameType.WEEKLY_CALENDAR,
    icon: <Weekly />,
    color: globalColors.poloBlue,
  },
];

export const gameCardsTabTwo = [
  {
    id: 13,
    name: WizardGameType.DROP,
    icon: <Drop />,
    color: globalColors.froly,
  },
  {
    id: 14,
    name: WizardGameType.FLY,
    icon: <Fly />,
    color: globalColors.portage,
  },
  {
    id: 15,
    name: WizardGameType.DRIVE,
    icon: <Drive />,
    color: globalColors.chetwodeBlueLight,
  },
  {
    id: 16,
    name: WizardGameType.SWIM,
    icon: <Swim />,
    color: globalColors.orchid,
  },
  {
    id: 17,
    name: WizardGameType.SLOT_MACHINE,
    icon: <Machine />,
    color: globalColors.mediumPurple,
  },
];
