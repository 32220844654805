import { createSlice } from '@reduxjs/toolkit';

const initialState: Record<string, any> = {};

const gameStateSlice = createSlice({
  name: 'gameState',
  initialState,
  reducers: {
    //@ts-ignore
    setValue: (state, action: { param: string; value: any }) => {
      //@ts-ignore
      state[action.payload.param] = action.payload.value;
    },
    //@ts-ignore
    resetState: (state, action: { storeState: any }) => {
      //@ts-ignore
      return action.payload.storeState;
    },
  },
});

export const { setValue, resetState } = gameStateSlice.actions;

export default gameStateSlice.reducer;
