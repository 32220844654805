/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { FC } from 'react';
import { TextField } from '@mui/material';
import { ICanvasDimensions } from '../../reducers/shared/types';
import { useStyles } from './styles';
import { fallbackProps } from './fixtures';

interface IProps {
  dimensions: ICanvasDimensions;
  onChange: (dimensions: ICanvasDimensions) => void;
}

const CanvasDimensions: FC<IProps> = ({ dimensions, onChange }) => {
  const { width, height } = dimensions;
  const styles = useStyles();

  return (
    <div css={styles.container} data-testid="canvas-dimensions">
      <TextField
        value={width || fallbackProps.width}
        label="Width"
        type="number"
        InputProps={{ endAdornment: 'px' }}
        onChange={(e) =>
          onChange({ ...dimensions, width: Number(e.target.value) })
        }
        css={styles.input}
      />
      <TextField
        value={height || fallbackProps.height}
        label="Height"
        type="number"
        InputProps={{ endAdornment: 'px' }}
        onChange={(e) =>
          onChange({ ...dimensions, height: Number(e.target.value) })
        }
        css={styles.input}
      />
    </div>
  );
};
export default CanvasDimensions;
