import { createSlice } from '@reduxjs/toolkit';
import { fetchCampaignMetaDataAsync } from '../builder/asyncThunks';
import { RootState } from '../store';
import { campaignStateIProps } from './types';

const initialState: campaignStateIProps = {
  selectedCampaign: '',
  status: 'loading',
};

const campaignSlice = createSlice({
  name: 'campaign',
  initialState,
  reducers: {
    setSelectedCampaign: (state: campaignStateIProps, action) => {
      state.selectedCampaign = action.payload.data;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCampaignMetaDataAsync.fulfilled, (state, action) => {
      if (action.payload.data.id !== undefined) {
        state.campaignMetaData = action.payload.data;
        state.selectedCampaign = action.payload.data.id;
        state.status = 'success';
      } else {
        state.status = 'failed';
      }
    });
    builder.addCase(fetchCampaignMetaDataAsync.pending, (state) => {
      state.status = 'loading';
    });
    builder.addCase(fetchCampaignMetaDataAsync.rejected, (state) => {
      state.status = 'failed';
    });
  },
});

export const { setSelectedCampaign } = campaignSlice.actions;

export const selectCampaign = (state: RootState) => state.campaign;

export default campaignSlice.reducer;
