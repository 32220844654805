/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import React, { FC } from 'react';
import { camelCase } from 'lodash';
import { TextField } from '@mui/material';
import { WizardType } from '../CampaignType/tabsData';
import { IWizard } from '../initialWizardState';
import { useStyles } from './styles';

export interface ICampaignDetails {
  wizardState: IWizard;
  handleWizardState: (stepName: string, payload: IWizard) => void;
  handleProgressBar: (activeType: string) => void;
}

const CampaignDetails: FC<ICampaignDetails> = ({
  wizardState,
  handleWizardState,
  handleProgressBar,
}) => {
  const styles = useStyles();

  const setProgressBarValue = (value: string) => {
    if (value.length) {
      handleProgressBar(WizardType.CAMPAIGN_DETAILS);
    } else {
      handleProgressBar(WizardType.CAMPAIGN_TYPE);
    }
  };

  return (
    <div css={styles.formContainer} data-testid="campaign-details-content">
      <form css={styles.form}>
        <div css={styles.title}>Campaign Name</div>
        <TextField
          fullWidth
          data-testid="company-name"
          inputProps={{ maxLength: 50, style: { fontSize: 'body2.fontSize' } }}
          placeholder="E.g. My Campaign"
          value={wizardState.campaignDetails.name}
          onChange={(e) => {
            handleWizardState(WizardType.CAMPAIGN_DETAILS, {
              ...wizardState,
              [camelCase(WizardType.CAMPAIGN_DETAILS)]: {
                name: e.target.value,
                description: wizardState.campaignDetails.description,
                isDisable: false,
              },
            });
            setProgressBarValue(e.target.value);
          }}
          sx={{ mb: 5 }}
        />
        <div css={styles.title}>Campaign description</div>
        <TextField
          fullWidth
          data-testid="company-description"
          multiline
          rows={9}
          inputProps={{ maxLength: 300, style: { fontSize: 'body2.fontSize' } }}
          placeholder="You can use it to destinguish it from other campaigns, and players will not be able to see this info."
          value={wizardState.campaignDetails.description}
          onChange={(e) =>
            handleWizardState(WizardType.CAMPAIGN_DETAILS, {
              ...wizardState,
              [camelCase(WizardType.CAMPAIGN_DETAILS)]: {
                name: wizardState.campaignDetails.name,
                description: e.target.value,
              },
            })
          }
        />
      </form>
    </div>
  );
};

export default CampaignDetails;
