/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { FC, useRef } from 'react';
import { Divider } from '@mui/material';
import { usePageDrop } from '../hooks/dndHooks';
import { useStyles } from './styles';

export interface IProperties {
  pageIndex: number;
  hidden?: boolean;
}

const PageDropArea: FC<IProperties> = ({ pageIndex, hidden }) => {
  const styles = useStyles();
  const dividerRef = useRef<HTMLHRElement>(null);
  const { canDrop } = usePageDrop(dividerRef, pageIndex);

  return (
    <Divider
      data-testid="page-drop-area"
      css={[styles.divider, canDrop ? styles.droppable : '']}
      style={{
        display: hidden ? 'none' : 'block',
      }}
      ref={dividerRef}
      orientation="vertical"
      flexItem
    />
  );
};

export default PageDropArea;
