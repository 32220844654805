/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import React, { FC } from 'react';
import { Typography } from '@mui/material';
import { IThemeMedia } from '@brame/theme/src/types';

import { useStyles } from '../styles';

interface IProps {
  media: IThemeMedia;
  onClick: (e: React.MouseEvent) => void;
}

const MediaPreview: FC<IProps> = ({ media, onClick }) => {
  const styles = useStyles();

  return (
    <div css={styles.block} data-testid={`media-preview-${media.name}`}>
      <Typography variant="caption" css={styles.subtitle}>
        {media.name}
      </Typography>
      <div css={styles.imageFallback}>
        <div onClick={onClick}>
          {media.type === 'video' ? (
            <video
              src={media.url}
              autoPlay
              loop
              muted
              css={[styles.galleryItem, styles.image]}
            >
              Your browser does not support this element
            </video>
          ) : (
            <img
              src={media.url}
              key={media.name}
              alt={media.name}
              css={[
                styles.galleryItem,
                styles.image,
                media.type === 'logo' && styles.logo,
              ]}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default MediaPreview;
