/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { FC } from 'react';
import { MenuItem, Select, Typography } from '@mui/material';
import { useStyles } from './styles';

export interface IOption {
  label: string;
  value: string | number;
}

interface IProps {
  value: string;
  options: Array<string | number | IOption>;
  label?: string;
  onChange: (value: string) => void;
}

const SelectInput: FC<IProps> = ({ value, label, options, onChange }) => {
  const styles = useStyles();

  return (
    <div>
      {!!options.length && (
        <div css={styles.container} data-testid="select-input-container">
          {label && <Typography css={styles.title}>{label}</Typography>}
          <Select
            value={value}
            css={styles.select}
            data-testid="select-input"
            fullWidth
            onChange={(e) => onChange(e.target.value)}
          >
            {options.map((option) =>
              typeof option === 'string' || typeof option === 'number' ? (
                <MenuItem value={option} key={option}>
                  {option}
                </MenuItem>
              ) : (
                <MenuItem value={option.value} key={option.value}>
                  {option.label}
                </MenuItem>
              )
            )}
          </Select>
        </div>
      )}
    </div>
  );
};

export default SelectInput;
