import { css } from '@emotion/react';
import { useTheme } from '@mui/material';
import image from '../../assets/switch-white.svg';

export const useStyles = (isOpen: boolean) => {
  const theme = useTheme();
  const headerHeight = '110px';

  return {
    rightPanel: css`
      position: relative;
      box-shadow: 0 10px 20px ${theme.palette.grey[700]}20;
    `,
    drawer: css`
      width: ${isOpen ? '344px' : 0};
      flex-shrink: 0;
      height: calc(100vh - ${headerHeight});

      & .MuiDrawer-paper {
        width: ${isOpen ? '344px' : 0};
        padding-top: ${headerHeight};
      }
    `,
    switch: css`
      width: 20px;
      height: 60px;
      background: url(${image}) center no-repeat;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      top: 50%;
      left: -20px;
      transform: translateY(-50%);
      cursor: pointer;
      filter: drop-shadow(0 10px 20px ${theme.palette.grey[700]}20);
      z-index: 3;
    `,
    breadcrumbsBox: css`
      padding: ${theme.spacing(2, 0.5, 0.5, 0.5)};
    `,
    tabPanel: css`
      margin-top: ${theme.spacing(1)};
    `,
    tabs: css`
      min-height: 35px;
      height: 35px;

      & .MuiTabs-indicator {
        height: 1px;
      }
    `,
    tab: css`
      color: ${theme.palette.text.primary};
      padding: ${theme.spacing(1, 0)};

      &.Mui-selected {
        color: ${theme.palette.text.primary};
      }
    `,
  };
};
