import { ElementsState } from './builderSlice';
import { BuilderComponent, ButtonActionType, PageType } from './types';
import { defaultElementStyles } from './fixtures';

export const defaultTemplateState: ElementsState = {
  elements: {
    ids: ['1', '2', '3', '4', '5', '6', '7'],
    entities: {
      '1': {
        id: '1',
        type: BuilderComponent.ROOT_CONTAINER,
        styles: { defaults: defaultElementStyles.rootContainer, overrides: {} },
        childrenIds: ['2', '3'],
      },
      '2': {
        id: '2',
        parentId: '1',
        type: BuilderComponent.CONTAINER,
        childrenIds: [],
        styles: { defaults: defaultElementStyles.container, overrides: {} },
      },
      '3': {
        id: '3',
        parentId: '1',
        type: BuilderComponent.BUTTON,
        color: 'primary',
        action: {
          type: ButtonActionType.CHANGE_PAGE,
          target: 'NEXT',
        },
        styles: { defaults: defaultElementStyles.button, overrides: {} },
        value: 'Submit',
      },
      '4': {
        id: '4',
        type: BuilderComponent.ROOT_CONTAINER,
        childrenIds: [],
        styles: { defaults: defaultElementStyles.rootContainer, overrides: {} },
      },
      '5': {
        id: '5',
        type: BuilderComponent.ROOT_CONTAINER,
        childrenIds: [],
        styles: { defaults: defaultElementStyles.rootContainer, overrides: {} },
      },
      '6': {
        id: '6',
        type: BuilderComponent.ROOT_CONTAINER,
        childrenIds: [],
        styles: { defaults: defaultElementStyles.rootContainer, overrides: {} },
      },
      '7': {
        id: '7',
        type: BuilderComponent.ROOT_CONTAINER,
        childrenIds: [],
        styles: { defaults: defaultElementStyles.rootContainer, overrides: {} },
      },
    },
  },
  pages: {
    ids: ['page-1', 'page-2', 'page-3', 'page-4', 'page-5'],
    entities: {
      'page-1': {
        id: 'page-1',
        name: 'Intro page',
        elementRoot: '1',
        pageType: PageType.Basic,
      },
      'page-2': {
        id: 'page-2',
        name: 'Game page',
        elementRoot: '4',
        pageType: PageType.Game,
      },
      'page-3': {
        id: 'page-3',
        name: 'Registration page',
        elementRoot: '5',
        pageType: PageType.Basic,
      },
      'page-4': {
        id: 'page-4',
        name: 'Win outcome page',
        elementRoot: '6',
        pageType: PageType.Outcome,
      },
      'page-5': {
        id: 'page-5',
        name: 'Loose outcome page',
        elementRoot: '7',
        pageType: PageType.Outcome,
      },
    },
  },
  additionalPages: {
    ids: [],
    entities: {},
  },
  selectedPageId: 'page-1',
  previewMode: false,
  readiness: 'loading',
  selectedTheme: 'theme1',
};
