import { css } from '@emotion/react';
import { useTheme } from '@mui/material';

interface IProps {
  value: string;
}

export const useStyles = ({ value }: IProps) => {
  const theme = useTheme();

  return {
    widget: css`
      display: flex;
      flex-direction: column;
      gap: ${theme.spacing(1)};
    `,
    swatch: css`
      width: 26px;
      height: 26px;
      border-radius: ${theme.shape.borderRadius}px;
      margin: ${theme.spacing(0, 1)};
      cursor: pointer;
      border: 1px dashed rgba(128, 128, 128, 0.5);
      background: ${value};
    `,
  };
};
