import {
  Camera,
  CheckSquareOffset,
  ClipboardText,
  Columns,
  FilmStrip,
  GameController,
  IconWeight,
  Image,
  Layout,
  LinkSimpleHorizontalBreak,
  Rectangle,
  Rows,
  TextAa,
  TextHOne,
  TextHThree,
  TextHTwo,
  TextT,
} from 'phosphor-react';
import {
  BuilderComponent,
  ButtonVariant,
  ContainerVariant,
  IElementsLibrary,
  TextVariant,
} from '../../reducers/builder';
import { iconSize } from '../../constants';

const tree: IElementsLibrary = [
  {
    title: 'Containers',
    icon: (weight: IconWeight) => <Layout weight={weight} size={iconSize} />,
    elements: [
      {
        title: 'Container',
        icon: <Rectangle size={iconSize} />,
        type: BuilderComponent.CONTAINER,
      },
      {
        title: '2 col',
        icon: <Columns size={iconSize} />,
        type: BuilderComponent.CONTAINER,
        option: { variant: ContainerVariant.TWO_COLUMNS },
      },
      {
        title: '2 row',
        icon: <Rows size={iconSize} />,
        type: BuilderComponent.CONTAINER,
        option: { variant: ContainerVariant.TWO_ROWS },
      },
      {
        title: '3 col',
        icon: <Columns size={iconSize} />,
        type: BuilderComponent.CONTAINER,
        option: { variant: ContainerVariant.THREE_COLUMNS },
      },
      {
        title: '3 row',
        icon: <Rows size={iconSize} />,
        type: BuilderComponent.CONTAINER,
        option: { variant: ContainerVariant.THREE_ROWS },
      },
      {
        title: '2 col & row',
        icon: <Layout size={iconSize} />,
        type: BuilderComponent.CONTAINER,
        option: { variant: ContainerVariant.TWO_COLUMNS_ROW },
      },
      {
        title: '2 row & col',
        icon: <Layout size={iconSize} />,
        type: BuilderComponent.CONTAINER,
        option: { variant: ContainerVariant.TWO_ROWS_COLUMN },
      },
      {
        title: 'Col & 2 row',
        icon: <Layout size={iconSize} />,
        type: BuilderComponent.CONTAINER,
        option: { variant: ContainerVariant.COLUMN_TWO_ROWS },
      },
      {
        title: 'Row & 2 col',
        icon: <Layout size={iconSize} />,
        type: BuilderComponent.CONTAINER,
        option: { variant: ContainerVariant.ROW_TWO_COLUMNS },
      },
    ],
  },
  {
    title: 'Text',
    icon: (weight: IconWeight) => <TextAa weight={weight} size={iconSize} />,
    elements: [
      {
        title: 'Heading 1',
        icon: <TextHOne size={iconSize} />,
        type: BuilderComponent.TEXT,
        option: {
          variant: TextVariant.H1,
        },
      },
      {
        title: 'Heading 2',
        icon: <TextHTwo size={iconSize} />,
        type: BuilderComponent.TEXT,
        option: {
          variant: TextVariant.H2,
        },
      },
      {
        title: 'Heading 3',
        icon: <TextHThree size={iconSize} />,
        type: BuilderComponent.TEXT,
        option: {
          variant: TextVariant.H3,
        },
      },
      {
        title: 'Body',
        icon: <TextAa size={iconSize} />,
        type: BuilderComponent.TEXT,
        option: {
          variant: TextVariant.BODY,
        },
      },
    ],
  },
  {
    title: 'Buttons',
    icon: (weight: IconWeight) => <Layout weight={weight} size={iconSize} />,
    elements: [
      {
        title: 'Rectangle',
        icon: <Rectangle size={iconSize} />,
        type: BuilderComponent.BUTTON,
        option: {
          variant: ButtonVariant.Contained,
        },
      },
      {
        title: 'Capsule',
        icon: <LinkSimpleHorizontalBreak size={iconSize} />,
        type: BuilderComponent.BUTTON,
        option: {
          variant: ButtonVariant.Outlined,
        },
      },
      {
        title: 'Textual',
        icon: <TextT size={iconSize} />,
        type: BuilderComponent.BUTTON,
        option: {
          variant: ButtonVariant.Text,
        },
      },
    ],
  },
  {
    title: 'Media',
    icon: (weight: IconWeight) => <Camera weight={weight} size={iconSize} />,
    elements: [
      {
        title: 'Image',
        icon: <Image size={iconSize} />,
        type: BuilderComponent.IMAGE,
      },
      {
        title: 'Video',
        icon: <FilmStrip size={iconSize} />,
        type: BuilderComponent.VIDEO,
      },
    ],
  },
  {
    title: 'Forms',
    icon: (weight: IconWeight) => (
      <ClipboardText weight={weight} size={iconSize} />
    ),
    elements: [
      {
        title: 'Registration form',
        icon: <ClipboardText size={iconSize} />,
        type: BuilderComponent.FORM,
      },
      {
        title: 'Terms & Conditions',
        icon: <CheckSquareOffset size={iconSize} />,
        type: BuilderComponent.TERMS_AND_CONDITIONS,
      },
    ],
  },
  {
    title: 'Games',
    icon: (weight: IconWeight) => (
      <GameController weight={weight} size={iconSize} />
    ),
    elements: [
      {
        title: 'Phaser Game',
        icon: <GameController size={iconSize} />,
        type: BuilderComponent.PHASER_GAME,
      },
      {
        title: 'Phaser Text',
        icon: <GameController size={iconSize} />,
        type: BuilderComponent.PHASER_TEXT,
      },
      {
        title: 'Phaser Button',
        icon: <GameController size={iconSize} />,
        type: BuilderComponent.PHASER_BUTTON,
      },
    ],
  },
];

export default tree;
