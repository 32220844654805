import { v4 } from 'uuid';
import { cloneDeep } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import { fetchInitialStateAsync } from '../builder/asyncThunks';
import { RootState } from '../store';
import fruitGameSettings from '../../components/GameEditors/DropGame/StateDefaults/FruitDropDefaults.json';
import slotmachineSettings from '../../components/GameEditors/SlotMachine/StateDefaults/SlotMachineDefaults.json';
import { PHASER_GAME_TYPE } from '../../components/GameEditors/GameToolbar/GameTypes';

const initialState: any = fruitGameSettings;

const gameSlice = createSlice({
  name: 'game',
  initialState,
  reducers: {
    //@ts-ignore
    setGameParam: (
      state,
      action: {
        param: string;
        value: any;
        index?: number;
        arrayCategory?: string;
      }
    ) => {
      //@ts-ignore
      if (action.payload.index === undefined) {
        //@ts-ignore
        state[action.payload.param] = action.payload.value;
      } else {
        //@ts-ignore
        state[action.payload.arrayCategory][action.payload.index][
          //@ts-ignore
          action.payload.param
        ] =
          //@ts-ignore
          action.payload.value;
      }
    },
    //@ts-ignore
    addGameArrayEntry: (
      state,
      action: {
        arrayCategory: string;
        uuid?: boolean;
      }
    ) => {
      //@ts-ignore
      state[action.payload.arrayCategory].push(
        //@ts-ignore
        cloneDeep(state[action.payload.arrayCategory][0])
      );
      //@ts-ignore
      if (action.payload.uuid) {
        //@ts-ignore
        const length = state[action.payload.arrayCategory].length;
        //@ts-ignore
        state[action.payload.arrayCategory][length - 1].uuid = v4();
      }
    },
    //@ts-ignore
    removeGameArrayEntry: (
      state,
      action: {
        arrayCategory: string;
        index: number;
      }
    ) => {
      //@ts-ignore
      if (state[action.payload.arrayCategory].length > 1) {
        //@ts-ignore
        state[action.payload.arrayCategory].splice(action.payload.index, 1);
      }
    },
    //@ts-ignore
    changeAsset: (
      state,
      action: {
        texName: string;
        url: string;
      }
    ) => {
      //@ts-ignore
      state['assets'][action.payload.texName] = action.payload.url;
    },
    //@ts-ignore;
    changeGameType: (
      state,
      action: {
        gameType: PHASER_GAME_TYPE;
      }
    ) => {
      //@ts-ignore
      if (state['gameType'] !== action.payload.gameType) {
        //@ts-ignore
        switch (action.payload.gameType) {
          case PHASER_GAME_TYPE.FruitDrop:
            return fruitGameSettings;
          case PHASER_GAME_TYPE.SlotMachine:
            return slotmachineSettings;
        }
      }
    },
    //@ts-ignore
    resetGameSettings: () => {
      return fruitGameSettings;
    },
  },
  /* Async reducers => fetch */
  extraReducers: (game) => {
    game.addCase(fetchInitialStateAsync.fulfilled, (state, action) => {
      const data = action.payload.data.data;
      if (data.game) {
        for (const [key, value] of Object.entries(data.game)) {
          //@ts-ignore
          state[key] = value;
        }
      }
    });
  },
});

export const {
  setGameParam,
  addGameArrayEntry,
  removeGameArrayEntry,
  changeAsset,
  changeGameType,
  resetGameSettings,
} = gameSlice.actions;

export const selectGameSettings = (state: RootState) => state.game;

export default gameSlice.reducer;
