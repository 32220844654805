import { css } from '@emotion/react';
import { useTheme } from '@mui/material';

export const useStyles = () => {
  const theme = useTheme();

  return {
    tabs: css`
      min-height: 40px;
      height: 40px;
      margin-bottom: ${theme.spacing(3)};

      & .MuiTabs-indicator {
        height: 1px;
      }
    `,
    tabPanel: css`
      padding: ${theme.spacing(0)};
    `,
    tab: css`
      color: ${theme.palette.text.primary};
      padding: ${theme.spacing(0.5, 2)};

      &.Mui-selected {
        color: ${theme.palette.text.primary};
      }
    `,
    themePreviewWrapper: css`
      max-height: 465px;
      padding: ${theme.spacing(1, 3)};
      overflow-y: scroll;
    `,
    buttonBlock: css`
      margin-top: ${theme.spacing(3)};
      display: flex;
      justify-content: center;
    `,
  };
};
