import * as yup from 'yup';
import { generateId } from '@brame/builder/src/reducers/builder/utils';
import { defaultTermsAndConditionsFields } from '@brame/builder/src/reducers/builder/fixtures';
import { FormFieldType, IFieldsDictionary, IFormField } from './IProps';

export const availableFormFields: IFieldsDictionary<IFormField> = {
  firstName: {
    name: 'firstName',
    label: 'First Name',
    value: '',
    type: FormFieldType.Text,
    validation: {
      errorMessage: '',
    },
  },
  lastName: {
    name: 'lastName',
    label: 'Last Name',
    value: '',
    type: FormFieldType.Text,
    validation: {
      errorMessage: '',
    },
  },
  email: {
    name: 'email',
    label: 'Email',
    value: '',
    type: FormFieldType.Email,
    validation: {
      errorMessage: '',
    },
  },
  address: {
    name: 'address',
    label: 'Address',
    value: '',
    type: FormFieldType.Text,
    validation: {
      errorMessage: '',
    },
  },
  phoneNumber: {
    name: 'phoneNumber',
    label: 'Phone Number',
    value: '',
    type: FormFieldType.PhoneNumber,
    validation: {
      errorMessage: '',
      mask: '+000000000000',
    },
  },
  postalCode: {
    name: 'postalCode',
    label: 'Postal Code',
    value: '',
    type: FormFieldType.Number,
    validation: {
      errorMessage: '',
      mask: '#00000',
    },
  },
  city: {
    name: 'city',
    label: 'City',
    value: '',
    type: FormFieldType.Text,
    validation: {
      errorMessage: '',
    },
  },
  jobTitle: {
    name: 'jobTitle',
    label: 'Job Title',
    value: '',
    type: FormFieldType.Text,
    validation: {
      errorMessage: '',
    },
  },
  company: {
    name: 'company',
    label: 'Company',
    value: '',
    type: FormFieldType.Text,
    validation: {
      errorMessage: '',
    },
  },
  title: {
    name: 'title',
    label: 'Title',
    value: '',
    type: FormFieldType.Text,
    validation: {
      errorMessage: '',
    },
  },
  dateOfBirth: {
    name: 'dateOfBirth',
    label: 'Date of Birth',
    value: '',
    type: FormFieldType.Date,
    validation: {
      errorMessage: '',
    },
  },
  newsletter: {
    name: 'newsletter',
    label: 'Newsletter',
    value: false,
    type: FormFieldType.Checkbox,
    validation: {
      errorMessage: '',
    },
  },
  custom: {
    name: 'custom',
    label: 'Custom Field',
    value: '',
    type: FormFieldType.Text,
    validation: {
      errorMessage: '',
    },
    isCustom: true,
  },
  termsAndConditions: defaultTermsAndConditionsFields[0],
  privacyPolicy: defaultTermsAndConditionsFields[1],
};

export const getCustomField = (): IFormField => {
  return { ...availableFormFields.custom, name: `custom-${generateId()}` };
};

export const defaultFormFields: IFormField[] = [
  availableFormFields.firstName,
  availableFormFields.lastName,
  availableFormFields.email,
];

export const defaultTermsAndConditionFields: IFormField[] = [
  availableFormFields.termsAndConditions,
  availableFormFields.privacyPolicy,
  availableFormFields.newsletter,
];

export const getErrorMessage = (field: IFormField): string => {
  return field.validation?.errorMessage || `${field.label} is a required field`;
};

const getSchema = (schema: any, field: IFormField): any => {
  return field.validation.required
    ? schema.required(getErrorMessage(field))
    : schema;
};

const getNumberSchema = (field: IFormField): any => {
  const { min, max, mask, minCharacters, maxCharacters } = field.validation;

  let schema = mask ? yup.string() : yup.number();
  const minValue = mask ? minCharacters : min;
  const maxValue = mask ? maxCharacters : max;

  if (typeof minValue === 'number') {
    schema = schema.min(minValue);
  }
  if (typeof maxValue === 'number') {
    schema = schema.max(maxValue);
  }

  return schema;
};

export const getValidationSchema = (field: IFormField) => {
  switch (field.type) {
    case FormFieldType.Number:
      return getSchema(getNumberSchema(field), field);
    case FormFieldType.Email:
      return getSchema(yup.string().email(), field);
    case FormFieldType.MultiSelect:
      return field.validation.required
        ? yup.array().of(yup.string()).min(1, getErrorMessage(field))
        : yup.array().of(yup.string());
    case FormFieldType.SingleSelect:
      return getSchema(
        yup.string().oneOf(field?.validation?.options || []),
        field
      );
    case FormFieldType.Checkbox:
      return field.validation.required
        ? yup.bool().oneOf([true]).required(getErrorMessage(field))
        : yup.bool();
    case FormFieldType.Textarea:
      return getSchema(
        yup.string().min(field.validation.minCharacters || 0),
        field
      );
    case FormFieldType.Text:
    case FormFieldType.Date:
    default:
      return getSchema(yup.string(), field);
  }
};

export const getValidationDictionary = (
  fieldDictionary: IFieldsDictionary<IFormField>
): {
  [key: string]: any;
} => {
  return Object.entries(fieldDictionary).reduce((schema: any, [key, field]) => {
    schema[key] = getValidationSchema(field);
    return schema;
  }, {});
};
