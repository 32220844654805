import { css } from '@emotion/react';
import { useTheme } from '@mui/material';

export const useStyles = () => {
  const theme = useTheme();

  return {
    tabTitle: css`
      position: sticky;
      z-index: 2;
      top: 0;
      padding: ${theme.spacing(1)};
      font-size: ${theme.typography.h3.fontSize};
      text-transform: uppercase;
      text-align: center;
      background: ${theme.palette.primary.main};
      color: ${theme.palette.primary.contrastText};
      width: 100%;
      box-shadow: 0 10px 20px ${theme.palette.grey[700]}20;
      border-radius: 0 0 5px 5px;
    `,
  };
};
