/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import React, { FC } from 'react';
import { camelCase } from 'lodash';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Tab,
  Typography,
} from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { IWizard } from '../initialWizardState';
import { useStyles } from './styles';
import { gameCardsTabOne, gameCardsTabTwo, WizardType } from './tabsData';
export interface ICampaignGames {
  wizardState: IWizard;
  handleWizardState: (stepName: string, payload: IWizard) => void;
  handleProgressBar: (activeType: string) => void;
}

const CampaignType: FC<ICampaignGames> = ({
  wizardState,
  handleWizardState,
  handleProgressBar,
}) => {
  const styles = useStyles();
  const [activeTab, setActiveTab] = React.useState(1);
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };
  const navigate = useNavigate();

  return (
    <div data-testid="campaign-type-content">
      <TabContext value={activeTab.toString()}>
        <Box css={styles.tabsContainer}>
          <TabList
            variant="fullWidth"
            onChange={handleTabChange}
            aria-label="game-tabs"
            css={styles.tabsList}
            sx={{
              '.MuiTabs-indicator': {
                display: 'none',
              },
            }}
          >
            <Tab label="GAMES 1.0" value="0" css={styles.tabsItem} />
            <Tab label="Games 2.0" value="1" css={styles.tabsItem} />
          </TabList>
        </Box>
        <div css={styles.panelContainer}>
          <TabPanel css={styles.panelItem} value="0">
            <Grid container spacing={2}>
              {gameCardsTabOne.map((card) => (
                <Grid key={card.id} item xs={12} sm={6} lg={4} xl={3}>
                  <Card
                    data-testid="card"
                    css={styles.card}
                    sx={{
                      border: `1px solid ${
                        wizardState.campaignType.gameId === card.id.toString()
                          ? card.color
                          : 'transparent'
                      }`,
                    }}
                    onClick={() => {
                      handleWizardState(WizardType.CAMPAIGN_TYPE, {
                        ...wizardState,
                        [camelCase(WizardType.CAMPAIGN_TYPE)]: {
                          gameId: card.id.toString(),
                          isDisable: false,
                        },
                      });
                      handleProgressBar(WizardType.CAMPAIGN_TYPE);
                    }}
                  >
                    <CardContent css={styles.cardContainer}>
                      <div
                        css={styles.iconContainer}
                        style={{ color: `${card.color}` }}
                      >
                        {card.icon}
                      </div>
                      <Typography
                        css={styles.cardName}
                        sx={{ color: card.color }}
                      >
                        {card.name}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </TabPanel>
          <TabPanel css={styles.panelItem} value="1">
            <Grid container spacing={2}>
              {gameCardsTabTwo.map((card) => (
                <Grid key={card.id} item xs={12} sm={6} lg={4} xl={3}>
                  <Card
                    css={styles.card}
                    sx={{
                      border: `1px solid ${
                        wizardState.campaignType.gameId === card.id.toString()
                          ? card.color
                          : 'transparent'
                      }`,
                    }}
                    onClick={() => {
                      handleWizardState(WizardType.CAMPAIGN_TYPE, {
                        ...wizardState,
                        [camelCase(WizardType.CAMPAIGN_TYPE)]: {
                          gameId: card.id.toString(),
                          isDisable: false,
                        },
                      });
                      handleProgressBar(WizardType.CAMPAIGN_TYPE);
                    }}
                  >
                    <CardContent css={styles.cardContainer}>
                      <div
                        css={styles.iconContainer}
                        style={{ color: `${card.color}` }}
                      >
                        {card.icon}
                      </div>
                      <Typography
                        css={styles.cardName}
                        sx={{ color: card.color }}
                      >
                        {card.name}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </TabPanel>
          <Button
            onClick={() => {
              navigate(`/error-page`);
            }}
          >
            Switch to error page
          </Button>
        </div>
      </TabContext>
    </div>
  );
};

export default CampaignType;
