import { css } from '@emotion/react';
import { useTheme } from '@mui/material';

export const useTabsStyles = () => {
  const theme = useTheme();

  return {
    tabs: css`
      min-height: 40px;
      height: 40px;

      & .MuiTabs-indicator {
        height: 1px;
      }
    `,
    tab: css`
      color: ${theme.palette.text.primary};
      padding: ${theme.spacing(0.5, 2)};

      &.Mui-selected {
        color: ${theme.palette.text.primary};
      }
    `,
  };
};
