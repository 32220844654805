import React, { FC, ReactElement } from 'react';
import { Box, Typography } from '@mui/material';

interface ITabProps {
  children: ReactElement;
  value: number;
  index: number;
  noPadding?: boolean;
}

const TabPanel: FC<ITabProps> = (props) => {
  const { children, value, index, noPadding } = props;

  return (
    <div role="tabpanel" hidden={value !== index} data-testid="tab-panel">
      {value === index && (
        <Box sx={{ p: noPadding ? 0 : 3 }}>
          <Typography component="span">{children}</Typography>
        </Box>
      )}
    </div>
  );
};

export default TabPanel;
