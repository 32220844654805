import { css } from '@emotion/react';
import { useTheme } from '@mui/material';

export const useStyles = (inDropArea: boolean) => {
  const theme = useTheme();

  return {
    dropArea: css`
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 80px;
      border: 2px dashed
        ${inDropArea ? theme.palette.primary.main : theme.palette.grey[200]};
      padding: ${theme.spacing(1, 3)};
      margin-top: ${theme.spacing(1)};
      text-align: center;
    `,
    label: css`
      align-self: center;
      font-weight: ${theme.typography.fontWeightBold};
      color: ${theme.palette.text.primary};
    `,
    spinner: css`
      align-self: center;
      margin-top: ${theme.spacing(1)};
    `,
  };
};
