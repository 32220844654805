/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { FC } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { useStyles } from './styles';

export interface IProps {
  isOpen: boolean;
  title?: string;
  description?: string;
  onConfirm: () => void;
  onCancel: () => void;
  confirmButtonLabel?: string;
  cancelButtonLabel?: string;
}

export const defaultConfirmButtonLabel = 'Confirm';
export const defaultCancelButtonLabel = 'Cancel';

const ConfirmationModal: FC<IProps> = ({
  isOpen,
  title,
  description,
  confirmButtonLabel,
  cancelButtonLabel,
  onConfirm,
  onCancel,
}) => {
  const styles = useStyles();

  return (
    <Dialog open={isOpen} onClose={onCancel} data-testid="confirmation-modal">
      <div css={styles.dialog}>
        {title && (
          <DialogTitle
            variant="h3"
            css={styles.dialogTitle}
            data-testid="confirmation-modal-title"
          >
            {title}
          </DialogTitle>
        )}
        <DialogContent css={styles.dialogContent}>
          {description && (
            <DialogContentText data-testid="confirmation-modal-description">
              {description}
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions css={styles.dialogButtons}>
          <Button variant="text" onClick={onCancel}>
            {cancelButtonLabel || defaultCancelButtonLabel}
          </Button>
          <Button onClick={onConfirm} color="primary" variant="contained">
            {confirmButtonLabel || defaultConfirmButtonLabel}
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default ConfirmationModal;
