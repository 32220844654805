import { css } from '@emotion/react';
import { useTheme } from '@mui/material';

export const useStyles = () => {
  const theme = useTheme();

  return {
    formContainer: css`
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: calc(100vh - (67px + 85px));
      overflow-y: auto;
    `,
    form: css`
      padding: ${theme.spacing(2)};
      max-width: 560px;
      width: 100%;
      box-sizing: border-box;
    `,
    title: css`
      text-transform: uppercase;
      color: ${theme.palette.text.primary};
      margin: ${theme.spacing(1, 0)};
    `,
  };
};
