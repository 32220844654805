import { ButtonVariants, IButtonShapes } from '../types';

export const buttonShapes: IButtonShapes = {
  rectangle: 0,
  rounded: 4,
  capsule: 16,
};

export const iconSize = 24;

export const borderWidthList: number[] = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

export const buttonVariants = Object.values(ButtonVariants);
