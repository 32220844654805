import findIndex from 'lodash/findIndex';
import { css } from '@emotion/react';
import {
  BuilderComponent,
  ButtonAction,
  ButtonActionMapping,
  ButtonActionType,
  CanvasElement,
  ContainerElement,
  ElementProps,
  IStyleProps,
  ParsedButtonAction,
  RootContainer,
} from '@brame/builder/src/reducers/builder';
import parseStyleProps from '@brame/builder/src/components/Canvas/utils';
import { Breakpoint } from '@brame/builder/src/reducers/shared/types';

export const getElementProps = (element: CanvasElement): ElementProps => {
  const props: any = { ...element };
  delete props?.childrenIds;
  delete props?.parentId;
  return props as ElementProps;
};

export const parseMediaQueryStyles = (responsiveStyles: {
  [key: string]: IStyleProps;
}) => {
  const mediaQueries = Object.entries(responsiveStyles).map(
    ([breakpoint, styles]) => {
      return css({
        [`@media (min-width: ${breakpoint}px)`]: parseStyleProps(styles),
      });
    }
  );

  return css(mediaQueries);
};

export const getMediaQueryStyles = ({
  breakpoints,
  activeBreakpoint,
  responsive,
}: {
  breakpoints: Breakpoint[];
  activeBreakpoint: Breakpoint;
  responsive?: { [key: string]: IStyleProps };
}) => {
  if (!responsive) return {};
  const breakpointIndex = findIndex(
    breakpoints,
    (b) => b.id === activeBreakpoint?.id
  );

  return responsive && activeBreakpoint && breakpoints
    ? breakpoints.slice(0, breakpointIndex + 1).reduce((prev, curr): any => {
        let result = {};
        if (responsive?.[curr.breakpoint.toString()]) {
          result = { ...responsive[curr.breakpoint.toString()] };
        }
        return { ...prev, ...result };
      }, {})
    : {};
};

export const parseAction = (
  action: ButtonAction,
  actions: ButtonActionMapping
) => {
  const result: ParsedButtonAction = {};

  if (
    action.type === ButtonActionType.CHANGE_PAGE ||
    action.type === ButtonActionType.ADDITIONAL_PAGE
  ) {
    result.onClick = () => actions[action?.type](action);
  } else if (action.type === ButtonActionType.LINK) {
    result.href = action.target as string;
    result.target = action.openNewTab ? '_blank' : '_self';
  }

  return result;
};

export const getAction = (
  element: CanvasElement,
  actions?: ButtonActionMapping
) => {
  if (!actions) return null;

  if (element.type === BuilderComponent.BUTTON) {
    return parseAction(element.action, actions);
  }

  if (element.type === BuilderComponent.FORM) {
    const { fields } = element;

    return {
      fields: fields?.map((field) => ({
        ...field,
        ...(field.action ? parseAction(field.action, actions) : {}),
      })),
    };
  }

  return null;
};

export const isContainer = (
  element: CanvasElement
): element is ContainerElement | RootContainer => {
  return (
    element.type === BuilderComponent.ROOT_CONTAINER ||
    element.type === BuilderComponent.CONTAINER
  );
};
