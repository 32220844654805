import merge from 'lodash/merge';
import { BuilderComponent, IBuilderTemplate } from '../reducers/builder';
import { ICanvasDimensions } from '../reducers/shared/types';

interface IProps {
  fixed?: boolean;
  dimensions?: ICanvasDimensions;
}

const useTemplate = ({ fixed, dimensions }: IProps) => {
  const prepareTemplate = (template: IBuilderTemplate) => {
    if (fixed && dimensions) {
      const roots = Object.values(template.template.elements.entities).filter(
        // @ts-ignore
        (element) => element?.type === BuilderComponent.ROOT_CONTAINER
      );
      roots.forEach((item) =>
        // @ts-ignore
        merge(item?.styles?.overrides, {
          size: {
            width: { value: dimensions.width, unit: 'px' },
            height: { value: dimensions.height, unit: 'px' },
          },
        })
      );
    }

    return template;
  };
  return { prepareTemplate };
};

export default useTemplate;
