import DropGameScene from './DropGameScene';

export function resetDifficulty(scene: DropGameScene) {
  scene.initSpawnTime = DropGameScene.jsonLink['game.initSpawnTime'];
  scene.initBombTime = DropGameScene.jsonLink['game.bombSpawnTime'];
  scene.initSpeed = scene.matterInitSpeed;
}

export function updateDifficulty(scene: DropGameScene) {
  let maxMod = 1;
  switch (DropGameScene.jsonLink['game.difficulty']) {
    case 'Easy':
      maxMod = 2;
      break;
    case 'Medium':
      maxMod = 4;
      break;
    case 'Hard':
      maxMod = 8;
      break;
  }
  scene.bombTime =
    ((scene.initBombTime * scene.avgPlayTime) /
      (scene.avgPlayTime +
        Math.min(maxMod * scene.getTime(), 9 * scene.avgPlayTime))) *
    1.5;
  scene.spawnTime =
    (scene.initSpawnTime * scene.avgPlayTime) /
    (scene.avgPlayTime +
      Math.min(maxMod * scene.getTime(), 9 * scene.avgPlayTime));
  scene.lifeTime =
    ((scene.initSpawnTime * scene.avgPlayTime) /
      (scene.avgPlayTime +
        Math.min(maxMod * scene.getTime(), 9 * scene.avgPlayTime))) *
    2.5;
  scene.currentSpeed =
    scene.initSpeed *
    (1 +
      (scene.avgPlayTime +
        Math.min(maxMod * scene.getTime(), 9 * scene.avgPlayTime)) /
        scene.avgPlayTime);
}
