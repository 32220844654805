import React, { useEffect, useState } from 'react';
import { UnitType, UnitValueType } from '../../types';

const useSizeProps = (
  sizeValue: UnitValueType,
  onChange: (value: UnitValueType) => void
) => {
  const [unit, setUnit] = useState<UnitType>(sizeValue?.unit);
  const [value, setValue] = useState<number | 'auto'>(sizeValue?.value);
  const [anchorEl, setAnchorEl] = useState(null);
  const [changes, setChanges] = useState<UnitValueType | null>(null);

  const isAuto = unit === 'auto';
  const open = Boolean(anchorEl);

  useEffect(() => {
    setValue(sizeValue?.value);
    setUnit(sizeValue?.unit);
  }, [sizeValue]);

  const openPropMenu = (event: React.BaseSyntheticEvent): void => {
    setAnchorEl(event.currentTarget);
  };

  const selectPropType = (u: UnitType) => {
    setAnchorEl(null);

    if (!u || typeof u !== 'string') return;

    setUnit(u);
    setChanges({ value, unit: u });
    onChange({
      value,
      unit: u,
    });
  };

  const handleChange = (value: number | string) => {
    if (!value) {
      setValue(0);
      setChanges({ value: 0, unit });
      onChange({
        value: 0,
        unit,
      });
    } else {
      const val = Number(value);
      setValue(val);
      setChanges({ value: val, unit });
      onChange({
        value: val,
        unit,
      });
    }
  };

  return {
    unit,
    value,
    anchorEl,
    isAuto,
    open,
    handleChange,
    openPropMenu,
    selectPropType,
    changes,
  };
};

export default useSizeProps;
