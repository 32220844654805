import { useEffect, useState } from 'react';
import { Breakpoint } from '../../reducers/shared/types';
import { useActiveResolution } from '../../pages/preview/hooks/useActiveResolution';

export const useBreakpoints = (activeBreakpoint: Breakpoint) => {
  const [builderWidth, setBuilderWidth] = useState(activeBreakpoint.breakpoint);
  const { activeResolution } = useActiveResolution(activeBreakpoint);

  useEffect(() => {
    setBuilderWidth(activeBreakpoint.breakpoint);
  }, [activeBreakpoint]);

  const handleBuilderWidthChange = (value: number) => {
    setBuilderWidth(value);
  };

  return {
    builderWidth,
    handleBuilderWidthChange,
    activeResolution,
  } as const;
};
