const mockUserData = {
  companyId: '0e52b89d-e635-11ec-994a-65fdbf41848e',
  campaignId: 'b1bea06e-c05a-49db-8415-ef54725b5d9a',
};

class MockCampaignResponses {
  getCampaignIdCompanyId = {
    status: 200,
    data: {
      createdAt: 1655454157672,
      companyId: '0e52b89d-e635-11ec-994a-65fdbf41848e',
      campaignId: 'b1bea06e-c05a-49db-8415-ef54725b5d9a',
      data: {
        selectedPageId: 'page-1',
        elements: {
          ids: ['1', '2', '3'],
          entities: {
            '1': {
              childrenIds: ['2', '3'],
              styles: {
                defaults: {
                  size: {
                    width: {
                      value: 100,
                      unit: '%',
                    },
                    height: {
                      value: 150,
                      unit: 'px',
                    },
                  },
                  flexWrap: 'wrap',
                  display: 'flex',
                },
                overrides: {},
              },
              id: '1',
              type: 'root-container',
            },
            '2': {
              childrenIds: [],
              styles: {
                defaults: {
                  size: {
                    width: {
                      value: 100,
                      unit: '%',
                    },
                    height: {
                      value: 150,
                      unit: 'px',
                    },
                  },
                  flexWrap: 'wrap',
                  display: 'flex',
                },
                overrides: {},
              },
              id: '2',
              type: 'container',
              parentId: '1',
            },
            '3': {
              styles: {
                defaults: {
                  alignSelf: 'baseline',
                  spacing: {
                    padding: {
                      right: {
                        value: 16,
                        unit: 'px',
                      },
                      top: {
                        value: 6,
                        unit: 'px',
                      },
                      left: {
                        value: 16,
                        unit: 'px',
                      },
                      bottom: {
                        value: 6,
                        unit: 'px',
                      },
                    },
                    margin: {
                      right: {
                        value: 10,
                        unit: 'px',
                      },
                      top: {
                        value: 10,
                        unit: 'px',
                      },
                      left: {
                        value: 10,
                        unit: 'px',
                      },
                      bottom: {
                        value: 10,
                        unit: 'px',
                      },
                    },
                  },
                },
                overrides: {},
              },
              id: '3',
              type: 'button',
              value: 'Submit',
              parentId: '1',
            },
          },
        },
        pages: {
          ids: ['page-1'],
          entities: {
            'page-1': {
              name: 'some page',
              id: 'page-1',
              pageType: 'basic',
              elementRoot: '1',
            },
          },
        },
      },
    },
    message: 'Campaign fetched',
  };
}

const mockCampaignConfig = {
  name: 'reallyRandomName',
  description: 'reallRandomDescription',
  logoUrl: '',
  startDate: null,
  endDate: null,
  companyId: '0e52b89d-e635-11ec-994a-65fdbf41848e',
  campaignType: 'spin-the-wheel',
  liveUrl:
    'http://dev-brame-campaign-demo.s3-website.eu-central-1.amazonaws.com/c2385a3b-f09c-11ec-8fc6-31bf15bef34a',
  demoUrl:
    'http://dev-brame-campaign-demo.s3-website.eu-central-1.amazonaws.com/c2385a3b-f09c-11ec-8fc6-31bf15bef34a',
  productionUrl:
    'http://dev-brame-campaign-demo.s3-website.eu-central-1.amazonaws.com/c2385a3b-f09c-11ec-8fc6-31bf15bef34a',
  languages: [
    {
      id: '117bf8dc-3f53-4b36-ad1a-8c040818bef1',
      name: 'German',
      shortName: 'de',
      flagImageUrl:
        'https://brame-static.s3.eu-central-1.amazonaws.com/flags/default+languages/German.png',
    },
  ],
  doubleOptIn: false,
  preciseGeolocation: false,
  isTailorMade: false,
  leadTableUrl: null,
  leadExportUrl: null,
  tailorMadeTableUrl: null,
  resetAnalyticsUrl: null,
  numberOfPlaysPerDay: null,
};

const mockCampaignResponses = new MockCampaignResponses();

export { mockUserData, mockCampaignResponses, mockCampaignConfig };
