import { css } from '@emotion/react';
import { useTheme } from '@mui/material';

export const useStyles = () => {
  const theme = useTheme();

  return {
    widget: css`
      display: flex;
      flex-direction: column;
      gap: ${theme.spacing(1)};
    `,
    itemsWrapper: css`
      display: flex;
      gap: ${theme.spacing(2)};
    `,
    button: css`
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
      padding: ${theme.spacing(0, 1)};
      cursor: pointer;
    `,
    contained: css`
      background: ${theme.palette.primary.main};
      color: ${theme.palette.primary.contrastText};
    `,
    outlined: css`
      border: 1px solid ${theme.palette.text.primary};
    `,
  };
};
