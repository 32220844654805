/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import React, { FC, useState } from 'react';
import { Button, TextField } from '@mui/material';
import { IThemeMediaCollection } from '@brame/theme';
import { AssetSourceType, IAssetData, IBackgroundProps } from '../../types';
import AssetPlaceholder from '../AssetPlaceholder/AssetPlaceholder';
import { AssetsModal, useAssetModal } from '../AssetsModal';
import { BuilderComponent } from '../../reducers/builder';
import { getCurrentAssetFromCollection } from '../ThemeMediaSelector/utils';
import { SelectInput } from '../SelectInput';
import { ThemeMediaSelector } from '../ThemeMediaSelector';
import { useAppSelector } from '../../reducers/app/hooks';
import { RootState } from '../../reducers/store';
import { ColorPickerWidget } from '../ColorPickerWidget';
import { useStyles } from './styles';

interface IProps {
  props: IBackgroundProps;
  onChange: (values: IBackgroundProps) => void;
}

const BackgroundWidget: FC<IProps> = ({ props, onChange }) => {
  const { color, image, videoUrl, asset, themedImage, themedVideo } = props;
  const styles = useStyles();
  const { assetsModalIsOpen, openAssetsModal, closeAssetsModal } =
    useAssetModal();
  const applyAsset = (asset: IAssetData) => {
    onChange({
      ...props,
      asset: asset,
      videoUrl: '',
      image: '',
      themedImage: '',
      themedVideo: '',
    });
    closeAssetsModal();
  };
  const isRemoveButtonDisabled =
    !videoUrl && !image && !asset && !themedVideo && !themedImage;

  const collectionType = () => {
    return themedImage ? 'images' : 'videos';
  };

  const collection = useAppSelector(
    (state: RootState): IThemeMediaCollection =>
      // @ts-ignore
      state.theme.currentTheme.assets.defaultCreatives[collectionType()]
  );

  const assetType = () => {
    if (asset) {
      return asset.type === 'video'
        ? BuilderComponent.VIDEO
        : BuilderComponent.IMAGE;
    }

    return videoUrl ? BuilderComponent.VIDEO : BuilderComponent.IMAGE;
  };

  const choseAssetSource = () => {
    if (themedImage && getCurrentAssetFromCollection(themedImage, collection)) {
      return AssetSourceType.THEME_IMAGE;
    }
    if (themedVideo && getCurrentAssetFromCollection(themedVideo, collection)) {
      return AssetSourceType.THEME_VIDEO;
    }

    return AssetSourceType.LIBRARY;
  };

  const [assetSource, setAssetSource] = useState<AssetSourceType>(
    choseAssetSource()
  );

  const sourceOptions: AssetSourceType[] = [
    AssetSourceType.LIBRARY,
    AssetSourceType.URL,
    AssetSourceType.THEME_IMAGE,
    AssetSourceType.THEME_VIDEO,
  ];

  return (
    <div data-testid="background-widget" css={styles.container}>
      <ColorPickerWidget
        title="Background Color"
        value={color}
        alpha
        onChange={(value) => onChange({ ...props, color: value })}
      />

      <SelectInput
        label="Image Source"
        value={assetSource}
        onChange={(value) => setAssetSource(value as AssetSourceType)}
        options={sourceOptions}
      />

      {assetSource === AssetSourceType.LIBRARY && (
        <AssetPlaceholder
          type={assetType()}
          src={image || videoUrl}
          asset={asset}
          onClick={openAssetsModal}
        />
      )}

      {assetSource === AssetSourceType.URL && (
        <TextField
          label="Image url"
          value={image}
          InputLabelProps={{ shrink: true }}
          hiddenLabel
          onChange={(e) =>
            onChange({
              ...props,
              asset: null,
              videoUrl: '',
              image: e.target.value,
              themedImage: '',
              themedVideo: '',
            })
          }
        />
      )}

      {assetSource === AssetSourceType.THEME_IMAGE && (
        <ThemeMediaSelector
          src={themedImage}
          onChange={(src) => {
            onChange({
              ...props,
              image: '',
              asset: null,
              videoUrl: '',
              themedImage: src,
              themedVideo: '',
            });
          }}
        />
      )}

      {assetSource === AssetSourceType.THEME_VIDEO && (
        <ThemeMediaSelector
          src={themedVideo}
          video
          onChange={(src) => {
            onChange({
              ...props,
              image: '',
              asset: null,
              videoUrl: '',
              themedImage: '',
              themedVideo: src,
            });
          }}
        />
      )}

      <Button
        variant="text"
        size="small"
        css={styles.removeButton}
        disabled={isRemoveButtonDisabled}
        onClick={() =>
          onChange({
            ...props,
            image: '',
            asset: null,
            videoUrl: '',
            themedImage: '',
            themedVideo: '',
          })
        }
      >
        Remove Image
      </Button>

      <AssetsModal
        isOpen={assetsModalIsOpen}
        onApplyAsset={applyAsset}
        onCancel={closeAssetsModal}
        elementType={BuilderComponent.CONTAINER}
      />
    </div>
  );
};

export default BackgroundWidget;
