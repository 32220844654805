import { IThemeMediaCollection, IThemeMediaType } from '@brame/theme';
import { getCurrentAssetFromCollection } from '../../ThemeMediaSelector/utils';

// protects new Asset object from taking already occupied key, thus preventing from unwanted overrides
export const findFreeIndexInCollection = (
  index: number,
  key: string,
  collection: IThemeMediaCollection
): number => {
  if (getCurrentAssetFromCollection(`default ${key} ${index}`, collection)) {
    index++;
    return findFreeIndexInCollection(index, key, collection);
  } else return index;
};

// unifying Photo and Image asset types to the Image
export const normalizeAssetType = (assetType: string): IThemeMediaType => {
  if (assetType === 'photo') {
    return 'image';
  } else return assetType as IThemeMediaType;
};
