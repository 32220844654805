// at some point of time this should become a service that will fetch available fonts
// from BE endpoint

export interface IFontDeclaration {
  fontFamily: string;
  fontWeight: number;
  source: string;
}

export const fonts = {
  TimesNewRoman: {
    fontFamily: 'Times New Roman',
    fontWeight: 400,
    source: '',
  },
  Iceberg: {
    fontFamily: 'Iceberg',
    fontWeight: 400,
    source:
      'https://brame-global-assets.s3.eu-central-1.amazonaws.com/fonts/Iceberg-Regular.ttf',
  },
  FrederickaTheGreat: {
    fontFamily: 'Fredericka the Great',
    fontWeight: 400,
    source:
      'https://brame-global-assets.s3.eu-central-1.amazonaws.com/fonts/FrederickatheGreat-Regular.ttf',
  },
  MarcellusSC: {
    fontFamily: 'MarcellusSC',
    fontWeight: 400,
    source:
      'https://brame-global-assets.s3.eu-central-1.amazonaws.com/fonts/MarcellusSC-Regular.ttf',
  },
  PressStart2P: {
    fontFamily: 'PressStart2P',
    fontWeight: 400,
    source:
      'https://brame-global-assets.s3.eu-central-1.amazonaws.com/fonts/PressStart2P-Regular.ttf',
  },
  SourceSansProRegular: {
    fontFamily: 'Source Sans Pro',
    fontWeight: 400,
    source:
      'https://brame-global-assets.s3.eu-central-1.amazonaws.com/fonts/SourceSansPro-Regular.ttf',
  },
  SourceSansProBold: {
    fontFamily: 'Source Sans Pro',
    fontWeight: 700,
    source:
      'https://brame-global-assets.s3.eu-central-1.amazonaws.com/fonts/SourceSansPro-Bold.ttf',
  },
  MontserratRegular: {
    fontFamily: 'Montserrat',
    fontWeight: 400,
    source:
      'https://brame-global-assets.s3.eu-central-1.amazonaws.com/fonts/Montserrat-Regular.ttf',
  },
  MontserratBold: {
    fontFamily: 'Montserrat',
    fontWeight: 700,
    source:
      'https://brame-global-assets.s3.eu-central-1.amazonaws.com/fonts/Montserrat-Bold.ttf',
  },
  RalewayRegular: {
    fontFamily: 'Raleway',
    fontWeight: 400,
    source:
      'https://brame-global-assets.s3.eu-central-1.amazonaws.com/fonts/Raleway-Regular.ttf',
  },
  RalewayBold: {
    fontFamily: 'Raleway',
    fontWeight: 700,
    source:
      'https://brame-global-assets.s3.eu-central-1.amazonaws.com/fonts/Raleway-Bold.ttf',
  },
  LatoRegular: {
    fontFamily: 'Lato',
    fontWeight: 400,
    source:
      'https://brame-global-assets.s3.eu-central-1.amazonaws.com/fonts/Lato-Regular.ttf',
  },
  LatoBold: {
    fontFamily: 'Lato',
    fontWeight: 700,
    source:
      'https://brame-global-assets.s3.eu-central-1.amazonaws.com/fonts/Lato-Bold.ttf',
  },
  BevanRegular: {
    fontFamily: 'Bevan',
    fontWeight: 400,
    source:
      'https://brame-global-assets.s3.eu-central-1.amazonaws.com/fonts/Bevan-Regular.ttf',
  },
  OpenSansRegular: {
    fontFamily: 'Open Sans',
    fontWeight: 400,
    source:
      'https://brame-global-assets.s3.eu-central-1.amazonaws.com/fonts/OpenSans-Regular.ttf',
  },
  OpenSansBold: {
    fontFamily: 'Open Sans',
    fontWeight: 700,
    source:
      'https://brame-global-assets.s3.eu-central-1.amazonaws.com/fonts/OpenSans-Bold.ttf',
  },
  OpenSansLight: {
    fontFamily: 'Open Sans',
    fontWeight: 300,
    source:
      'https://brame-global-assets.s3.eu-central-1.amazonaws.com/fonts/OpenSans-Light.ttf',
  },
  OswaldRegular: {
    fontFamily: 'Oswald',
    fontWeight: 400,
    source:
      'https://brame-global-assets.s3.eu-central-1.amazonaws.com/fonts/Oswald-Regular.ttf',
  },
  OswaldBold: {
    fontFamily: 'Oswald',
    fontWeight: 700,
    source:
      'https://brame-global-assets.s3.eu-central-1.amazonaws.com/fonts/Oswald-Bold.ttf',
  },
  RobotoRegular: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    source:
      'https://brame-global-assets.s3.eu-central-1.amazonaws.com/fonts/Roboto-Regular.ttf',
  },
  RobotoBold: {
    fontFamily: 'Roboto',
    fontWeight: 700,
    source:
      'https://brame-global-assets.s3.eu-central-1.amazonaws.com/fonts/Roboto-Bold.ttf',
  },
  RobotoLight: {
    fontFamily: 'Roboto',
    fontWeight: 300,
    source:
      'https://brame-global-assets.s3.eu-central-1.amazonaws.com/fonts/Roboto-Bold.ttf',
  },
  RobotoSlabRegular: {
    fontFamily: 'Roboto Slab',
    fontWeight: 400,
    source:
      'https://brame-global-assets.s3.eu-central-1.amazonaws.com/fonts/RobotoSlab-Regular.ttf',
  },
  RobotoSlabBold: {
    fontFamily: 'Roboto Slab',
    fontWeight: 700,
    source:
      'https://brame-global-assets.s3.eu-central-1.amazonaws.com/fonts/RobotoSlab-Bold.ttf',
  },
};

export const fontSizes = [
  10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28,
  29, 30, 31, 32,
];

const fontFamilyValues = Object.entries(fonts).map(
  (font) => font[1].fontFamily
);

export const uniqueFontFamilies = fontFamilyValues.filter((c, index) => {
  return fontFamilyValues.indexOf(c) === index;
});
