import { css } from '@emotion/react';

export const useStyles = (url: string) => ({
  container: css`
    position: static !important;
  `,
  image: css`
    position: absolute;
    inset: 0 0 0 0;
    pointer-events: none;
    object-fit: cover;
    width: 100%;
    height: 100%;
    background: url(${url}) center no-repeat;
    background-size: cover;
  `,
  video: css`
    pointer-events: none;
    position: absolute;
    inset: 0 0 0 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
  `,
});
