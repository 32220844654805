import { css } from '@emotion/react';
import { useTheme } from '@mui/material';

export const useStyles = () => {
  const theme = useTheme();

  return {
    tabs: css`
      min-height: 40px;
      height: 40px;
      margin-bottom: ${theme.spacing(3)};

      & .MuiTabs-indicator {
        height: 1px;
      }
    `,
    tabPanel: css`
      padding: ${theme.spacing(0)};
    `,
    tab: css`
      color: ${theme.palette.text.primary};
      padding: ${theme.spacing(0.5, 2)};

      &.Mui-selected {
        color: ${theme.palette.text.primary};
      }
    `,
    toolbarTitle: css`
      text-transform: capitalize;
      font-size: ${theme.typography.body1.fontSize};
      color: ${theme.palette.text.primary};
      font-weight: ${theme.typography.fontWeightBold};
    `,
    titleMargin: css`
      margin-bottom: ${theme.spacing(1)};
    `,
    settingsBlock: css`
      display: flex;
      gap: ${theme.spacing(1)};

      & > div {
        width: 100%;
      }
    `,
    column: css`
      flex-direction: column;
    `,
    checkableTitle: css`
      display: flex;
      justify-content: space-between;
      align-items: center;
    `,
  };
};
