/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { FC, useCallback, useEffect, useState } from 'react';
import { X } from 'phosphor-react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Tab,
  Tabs,
} from '@mui/material';
import { TabPanel, useTabs, useTabsStyles } from '../TabPanel';
import { AssetElements, AssetScope, AssetType, IAssetData } from '../../types';
import { useAppDispatch, useAppSelector } from '../../reducers/app/hooks';
import {
  globalImagesSelector,
  selectAsset,
  selectedAssetSelector,
  fetchBrameAssets,
  fetchGlobalAssets,
  fetchPersonalAssets,
} from '../../reducers/assets';
import { iconSize } from '../../constants';
import AssetsSearchInput from '../AssetsViewer/AssetsSearchInput';
import { selectAuth } from '../../reducers/auth';
import { useStyles } from './styles';
import AssetsTabPanel from './AssetsTabPanel/AssetsTabPanel';

export interface IProps {
  isOpen: boolean;
  assetData?: IAssetData;
  elementType: AssetElements;
  onApplyAsset: (asset: IAssetData) => void;
  onCancel: () => void;
}

const AssetsModal: FC<IProps> = ({
  isOpen,
  assetData,
  elementType,
  onApplyAsset,
  onCancel,
}) => {
  const { tabIndex, handleTabChange } = useTabs(0);
  const styles = useStyles();
  const tabsStyles = useTabsStyles();
  const dispatch = useAppDispatch();
  const assets = Object.values(useAppSelector(globalImagesSelector));
  const companyId = useAppSelector(selectAuth).parsedToken.company_id;
  const selectedAsset = useAppSelector(selectedAssetSelector);
  const [query, setQuery] = useState('');

  useEffect(() => {
    if (assetData) {
      dispatch(selectAsset(assetData));
    }
  }, [dispatch, assetData]);

  useEffect(() => {
    if (isOpen && !assets?.length) {
      dispatch(fetchGlobalAssets(AssetType.IMAGE));
      dispatch(fetchGlobalAssets(AssetType.VIDEO));
      dispatch(fetchBrameAssets(AssetType.IMAGE));
      dispatch(fetchBrameAssets(AssetType.VIDEO));
      dispatch(fetchBrameAssets(AssetType.LOGO));
      dispatch(fetchBrameAssets(AssetType.GAME));
    }
  }, [isOpen, assets, dispatch]);

  useEffect(() => {
    if (isOpen && companyId) {
      dispatch(fetchPersonalAssets({ companyId, assetType: AssetType.VIDEO }));
      dispatch(fetchPersonalAssets({ companyId, assetType: AssetType.IMAGE }));
    }
  }, [companyId, isOpen, dispatch]);

  const useAsset = () => {
    if (selectedAsset) {
      onApplyAsset(selectedAsset);
      dispatch(selectAsset(null));
    }
  };

  const onQueryChange = useCallback(
    (query: string) => {
      setQuery(query);
    },
    [setQuery]
  );

  return (
    <Dialog
      open={isOpen}
      onClose={onCancel}
      data-testid="asset-modal"
      PaperProps={{
        sx: { height: 'calc(100% - 64px)', width: '600px', maxWidth: '100%' },
      }}
    >
      <DialogTitle
        variant="body1"
        css={styles.dialogTitle}
        data-testid="asset-modal-title"
      >
        Select the gallery from which you want to use the asset
        <IconButton
          aria-label="close"
          onClick={onCancel}
          css={styles.dialogTitleIcon}
        >
          <X size={iconSize} />
        </IconButton>
      </DialogTitle>
      <DialogContent css={styles.dialogContent}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', paddingTop: 1 }}>
          <Tabs
            value={tabIndex}
            onChange={handleTabChange}
            aria-label="asset types tabs"
            indicatorColor="secondary"
            centered
            css={tabsStyles.tabs}
          >
            <Tab disableRipple label={AssetScope.BRAME} css={tabsStyles.tab} />
            <Tab disableRipple label={AssetScope.GLOBAL} css={tabsStyles.tab} />
            <Tab
              disableRipple
              label={AssetScope.PERSONAL}
              css={tabsStyles.tab}
            />
          </Tabs>
        </Box>
        <Box sx={{ marginTop: 3, marginBottom: 2 }}>
          <AssetsSearchInput onSearch={onQueryChange} />
        </Box>
        <TabPanel value={tabIndex} index={0} noPadding>
          <AssetsTabPanel
            library={AssetScope.BRAME}
            selectedAsset={selectedAsset}
            elementType={elementType}
            query={query}
          />
        </TabPanel>
        <TabPanel value={tabIndex} index={1} noPadding>
          <Box sx={{ paddingTop: 1 }}>
            <DialogContentText>
              Powered by Unsplash, Pexels, Pixabay. All images licensed under
              Creative Commons Zero.
            </DialogContentText>
            <AssetsTabPanel
              library={AssetScope.GLOBAL}
              selectedAsset={selectedAsset}
              elementType={elementType}
              query={query}
            />
          </Box>
        </TabPanel>
        <TabPanel value={tabIndex} index={2} noPadding>
          <AssetsTabPanel
            library={AssetScope.PERSONAL}
            selectedAsset={selectedAsset}
            elementType={elementType}
            query={query}
          />
        </TabPanel>
      </DialogContent>
      <DialogActions css={styles.dialogButtons}>
        <Button
          disabled={!selectedAsset}
          onClick={useAsset}
          color="primary"
          variant="text"
        >
          Use asset
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AssetsModal;
