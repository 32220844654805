import * as React from 'react';
import { FC } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import ScopedCssBaseline from '@mui/material/ScopedCssBaseline';
import useThemeData from '@brame/components/src/hooks/useThemeData';
import { expandThemeWithFonts } from './utility';

interface IProps {
  children: React.ReactNode;
}

const BuilderThemeConfigProvider: FC<IProps> = ({ children }) => {
  const { currentTheme } = useThemeData();

  const expandedTheme = createTheme(expandThemeWithFonts(currentTheme));

  return (
    <ThemeProvider theme={expandedTheme}>
      <ScopedCssBaseline hidden />
      {children}
    </ThemeProvider>
  );
};

export default BuilderThemeConfigProvider;
