import { createAsyncThunk } from '@reduxjs/toolkit';
import { AssetType } from '../../types';
import AssetsService from '../../api-client/assets.service';

export const fetchBrameAssets = createAsyncThunk(
  'assets/brame',
  (assetType: AssetType) => {
    return AssetsService.getBrameAssets(assetType);
  }
);

export const fetchGlobalAssets = createAsyncThunk(
  'assets/global',
  async (assetType: AssetType) => {
    return await AssetsService.getAssets(assetType);
  }
);

export const fetchPersonalAssets = createAsyncThunk(
  'assets/personal',
  async ({
    companyId,
    assetType,
  }: {
    companyId: string;
    assetType: AssetType;
  }) => {
    return await AssetsService.getPersonalAssets(companyId, assetType);
  }
);

export const searchGlobalAssets = createAsyncThunk(
  'assets/search',
  async ({ assetType, query }: { assetType: AssetType; query: string }) => {
    return await AssetsService.searchAssets(assetType, query);
  }
);
