import { css } from '@emotion/react';
import { useTheme } from '@mui/material';
import { brameTheme } from '@brame/theme';

export const useStyles = () => {
  const theme = useTheme();

  return {
    container: css`
      width: 96px;
      background: ${theme.palette.common.white};
      margin-bottom: 3px;
      box-shadow: 0px -10px 20px rgba(174, 174, 174, 0.3);
      border-radius: ${brameTheme.shape.borderRadius}px
        ${brameTheme.shape.borderRadius}px 0 0;
      overflow: hidden;
    `,
    item: css`
      width: 100%;
      display: flex;
      padding: ${theme.spacing(0.5, 0)};
      justify-content: center;
      align-items: center;
      flex-direction: column;
      cursor: pointer;
      gap: ${theme.spacing(1)};

      &:hover {
        background: ${theme.palette.primary.main};

        * {
          color: ${theme.palette.common.white};
        }
      }
    `,
    itemText: css`
      text-align: center;
    `,
    popItem: css`
      color: ${theme.palette.text.primary};
    `,
  };
};
