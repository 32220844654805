import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import tokenService from '../auth/token.service';

export const baseName = 'advanced-builder';

const axiosI = axios.create({
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${tokenService.getCookieAccessToken()}`,
  },
});

const handleSuccess = (response: AxiosResponse) => response;

const isAxiosError = (value: any): value is AxiosError =>
  typeof value?.response === 'object';
const handleError = (error: unknown) => {
  if (error instanceof Error) {
    if (isAxiosError(error)) {
      if (error.response) {
        switch (error.response.status) {
          case 401:
            window.location.replace('/login');
            break;
          case 403:
            console.log(error);
            //refresh token
            break;
          case 404:
            if (
              !['delete', 'put', 'patch'].includes(
                error.config.method as string
              )
            ) {
              window.location.assign(`/${baseName}/404`);
            }
            break;
          case 500:
            window.location.assign(`/${baseName}/500`);
            break;
          default:
            return error.response;
        }
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser
        console.log(error.request);
      }
    } else {
      console.log('Error', error.message);
    }
  }

  return Promise.reject(error);
};

axiosI.interceptors.response.use(handleSuccess, handleError);

axiosI.interceptors.request.use((config): AxiosRequestConfig => {
  const token = tokenService.getCookieAccessToken();

  const updatedConfig = {
    ...config,
  };

  if (!updatedConfig.headers) {
    updatedConfig.headers = {};
  }

  if (token) {
    updatedConfig.headers.Authorization = `Bearer ${token}`;
  } else {
    delete axiosI.defaults.headers.common.Authorization;
  }
  return updatedConfig;
});

export default axiosI;
