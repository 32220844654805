import { css } from '@emotion/react';
import { useTheme } from '@mui/material';

export const useStyles = () => {
  const theme = useTheme();

  return {
    title: css`
      text-transform: capitalize;
      margin-bottom: ${theme.spacing(1)};
    `,
    button: css`
      font-size: ${theme.typography.caption.fontSize};
    `,
  };
};
