import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { defaultThemes } from '@brame/theme';
import ThemeService from '../../../api-client/themes.service';
import CampaignService from '../../../api-client/campaigns.service';

export const useThemeData = () => {
  const { campaignId, companyId } = useParams();
  const [themeData, setThemeData] = useState(defaultThemes['theme-1']);

  useEffect(() => {
    const getThemeData = async () => {
      const campaignData =
        await CampaignService.getCampaignDataByCompanyAndCampaign(
          companyId,
          campaignId
        );
      const themeData = await ThemeService.fetchThemesData(companyId);
      const themeConfig = themeData.reduce(
        // eslint-disable-next-line
        (currentValue: any, theme: any) => ({
          ...currentValue,
          [theme.themeId]: theme.data,
        }),
        {}
      );
      const allAvailableThemes = {
        ...themeConfig,
        ...defaultThemes,
      };
      const selectedThemeData =
        allAvailableThemes[campaignData.data.data.selectedTheme];
      setThemeData(selectedThemeData);
    };

    getThemeData();
  }, [campaignId, companyId]);
  return {
    // @ts-ignore
    chosenTheme: themeData,
  };
};
