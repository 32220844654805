/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import React, { FC } from 'react';
import { EmotionJSX } from '@emotion/react/types/jsx-namespace';
import {
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useTheme,
} from '@mui/material';
import { IconProps, TextTransform } from '../../types';
import { CustomIcon } from '../Icon';
import { iconSize } from '../../constants';
import { useStyles } from './styles';

type TextTransformType = {
  name: string;
  icon: (props: IconProps) => EmotionJSX.Element;
};

const decorations: TextTransformType[] = [
  {
    name: 'uppercase',
    icon: (props) => (
      <CustomIcon
        name="uppercase"
        stroke={props.color}
        size={iconSize}
        fill="none"
      />
    ),
  },
  {
    name: 'capitalize',
    icon: (props) => (
      <CustomIcon
        name="capitalize"
        stroke={props.color}
        size={iconSize}
        fill="none"
      />
    ),
  },
  {
    name: 'lowercase',
    icon: (props) => (
      <CustomIcon
        name="lowercase"
        stroke={props.color}
        size={iconSize}
        fill="none"
      />
    ),
  },
];

interface IProps {
  textTransform: TextTransform;
  onChange: (values: TextTransform) => void;
  label?: string;
}

const TextTransformWidget: FC<IProps> = ({
  textTransform,
  onChange,
  label,
}) => {
  const styles = useStyles();
  const theme = useTheme();

  const isActive = (name: string) => {
    return textTransform === name;
  };

  return (
    <div data-testid="text-transform-widget" css={styles.wrapper}>
      {label && <Typography>{label}</Typography>}
      <div>
        <ToggleButtonGroup
          exclusive
          value={textTransform}
          sx={{ gap: '10px' }}
          onChange={(e, value) => onChange(value)}
        >
          {decorations.map(({ name, icon }) => (
            <ToggleButton
              key={name}
              value={name}
              size="small"
              data-testid={`text-transform-toggle-${name}`}
              css={styles.button}
            >
              {icon({
                color: isActive(name)
                  ? theme.palette.primary.contrastText
                  : theme.palette.text.primary,
              })}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      </div>
    </div>
  );
};

export default TextTransformWidget;
