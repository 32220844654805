import { css } from '@emotion/react';
import { useTheme } from '@mui/material';

export const useStyles = () => {
  const theme = useTheme();

  return {
    formWrapper: css`
      width: 100%;
      padding: ${theme.spacing(2)};

      .MuiGrid-container {
        background: none;
      }
    `,
    formItemWrapper: css`
      text-align: center;
      margin: ${theme.spacing(2, 0)};
    `,
  };
};
