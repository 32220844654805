import { useEffect, useState } from 'react';
import orderBy from 'lodash/orderBy';
import { useAppSelector } from '../../reducers/app/hooks';
import { campaignMetaDataIProps, selectAuth } from '../../reducers/auth';
import { CampaignMetaDataIProps } from '../../reducers/campaign/types';
import CampaignService from '../../api-client/campaigns.service';

export const useCampaigns = () => {
  const authState = useAppSelector(selectAuth);
  const companyId = authState.parsedToken.company_id;

  const [campaigns, setCampaigns] = useState<CampaignMetaDataIProps[]>([]);

  useEffect(() => {
    const loadCampaigns = async (companyId: string) => {
      const { data, message, error } =
        await CampaignService.getAllCampaignsByCompany(companyId);
      if (error) {
        return;
      }
      let filterCampaigns: CampaignMetaDataIProps[] = data.filter(
        (campaign: campaignMetaDataIProps) => campaign.status !== 'DELETED'
      );

      filterCampaigns = orderBy(filterCampaigns, 'createdDate', 'desc');
      setCampaigns(filterCampaigns);
    };

    if (companyId) {
      loadCampaigns(companyId);
    }
  }, [companyId]);

  return campaigns;
};
