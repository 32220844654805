import { FC, useEffect, useState } from 'react';
import { MenuItem, Select } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import { IThemeMediaCollection } from '@brame/theme';
import { RootState } from '../../reducers/store';
import { useAppSelector } from '../../reducers/app/hooks';

import { getCurrentAssetFromCollection } from './utils';
import ThemeMediaPreviewBox from './ThemeMediaPreviewBox/ThemeMediaPreviewBox';

interface IProps {
  src?: string;
  video?: boolean;
  onChange: (src: string) => void;
}

const ThemeMediaSelector: FC<IProps> = ({ src, onChange, video }) => {
  const collectionType = () => {
    return video ? 'videos' : 'images';
  };

  const collection = useAppSelector(
    (state: RootState): IThemeMediaCollection =>
      // @ts-ignore
      state.theme.currentTheme.assets.defaultCreatives[collectionType()]
  );

  const names = Object.keys(collection);
  const [selected, setSelected] = useState<string>('');

  const handleChange = (e: SelectChangeEvent) => {
    setSelected(e.target.value);
    onChange(e.target.value);
  };

  useEffect(() => {
    if (src && getCurrentAssetFromCollection(src, collection)) {
      setSelected(
        // @ts-ignore
        () => getCurrentAssetFromCollection(src, collection)[0]
      );
    } else setSelected('');
  }, [src, collection]);

  return (
    <div data-testid="theme-image-selector">
      <Select
        displayEmpty
        value={selected}
        onChange={handleChange}
        fullWidth
        sx={{ height: 42 }}
        renderValue={(selected) => {
          if (selected.length === 0) {
            return <span>Select from {collectionType()}</span>;
          }

          return (
            <ThemeMediaPreviewBox
              asset={collection[selected]}
              name={selected}
              video={video}
            />
          );
        }}
      >
        {names.map((name) => (
          <MenuItem value={name} key={name}>
            <ThemeMediaPreviewBox
              asset={collection[name]}
              name={name}
              video={video}
            />
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

export default ThemeMediaSelector;
