/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import React, { FC } from 'react';
import { Typography } from '@mui/material';
import { ReactComponent as Switch } from '../../../assets/switch.svg';
import { useStyles } from '../Page/styles';

interface IProps {
  index: number;
  selected: boolean;
  onClick?: (index: number) => void;
  switchProps?: { [key: string]: any };
  labelProps?: { [key: string]: any };
}

const Tab: FC<IProps> = ({
  index,
  selected,
  onClick,
  switchProps,
  labelProps,
  ...props
}) => {
  const styles = useStyles(selected);

  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (onClick) {
      onClick(index);
    }
  };

  return (
    <div css={styles.iconWrapper} onClick={handleClick} {...props}>
      <Typography css={styles.groupedPagesLabelText} {...labelProps}>
        {index + 1}
      </Typography>
      <Switch css={styles.tabButton} {...switchProps} />
    </div>
  );
};

export default Tab;
