import { createAsyncThunk } from '@reduxjs/toolkit';
import CampaignService from '../../api-client/campaigns.service';

interface campaignIProps {
  companyId: string | undefined;
  campaignId: string | undefined;
}

const fetchInitialStateAsync = createAsyncThunk(
  'campaigns/fetchByCompanyAndCampaign',
  async (payload: campaignIProps) => {
    const campaignData =
      await CampaignService.getCampaignDataByCompanyAndCampaign(
        payload.companyId,
        payload.campaignId
      );
    return campaignData;
  }
);

const fetchCampaignMetaDataAsync = createAsyncThunk(
  'campaign/fetchCampaignData',
  async (campaignId: string | undefined) => {
    return await CampaignService.getCampaignMetaData(campaignId);
  }
);

export { fetchInitialStateAsync, fetchCampaignMetaDataAsync };
