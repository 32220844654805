/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { FC } from 'react';
import useEmailEditor from '../../hooks/useEmailEditor/useEmailEditor';
import { useStyles } from './styles';
import { EmailTemplateTile } from './TemplateTile';

const EmailTemplates: FC = () => {
  const styles = useStyles();
  const { availableTemplates, applyTemplate } = useEmailEditor();

  return (
    <div css={styles.wrapper}>
      {availableTemplates.map((template) => (
        <EmailTemplateTile
          template={template}
          onClick={applyTemplate}
          key={template.id}
        />
      ))}
    </div>
  );
};

export default EmailTemplates;
