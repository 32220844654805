import { css } from '@emotion/react';
import { useTheme } from '@mui/material';

export const useStyles = () => {
  const theme = useTheme();

  return {
    titleBox: css`
      display: flex;
      justify-content: space-between;
      align-items: center;
    `,
    toolbarTitle: css`
      text-transform: capitalize;
      margin-bottom: ${theme.spacing(1)};
      font-size: ${theme.typography.body1.fontSize};
      color: ${theme.palette.text.primary};
      font-weight: ${theme.typography.fontWeightBold};
    `,
    itemsWrapper: css`
      display: flex;
      flex-direction: column;
      gap: ${theme.spacing(1)};
    `,
  };
};
