/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import React, { FC } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ScopedCssBaseline, Typography } from '@mui/material';
import { ButtonUnstyled } from '@mui/base';
import { expandThemeWithFonts, ITheme } from '@brame/theme';
import { useStyles } from './styles';

export interface IProps {
  name: string;
  theme: ITheme;
}

const ThemeBasicStructure: FC<IProps> = ({ name, theme }) => {
  const styles = useStyles(theme);
  return (
    <ThemeProvider theme={createTheme(expandThemeWithFonts(theme))}>
      <ScopedCssBaseline hidden />
      <Typography variant="h2" css={styles.heading} data-testid="theme-name">
        {name || 'Theme'}
      </Typography>
      <Typography variant="body1" css={styles.body}>
        Example of body text
      </Typography>
      <ButtonUnstyled css={styles.button}>Button</ButtonUnstyled>
    </ThemeProvider>
  );
};

export default ThemeBasicStructure;
