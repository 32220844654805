import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import { useState } from 'react';
import { uniqueId } from 'lodash';
import {
  Button,
  Box,
  MenuItem,
  FormControl,
  Select,
  SelectChangeEvent,
  Typography,
  TextField,
} from '@mui/material';
import CampaignService from '../../api-client/campaigns.service';
import { campaignMetaDataIProps } from '../../reducers/auth/types';
import { selectAuth } from '../../reducers/auth/authSlice';
import { useAppSelector } from '../../reducers/app/hooks';
import threePageTemplate from '../../templates/threePageTemplate';
import { CanvasDimensions } from '../CanvasDimensions';
import { ICanvasDimensions } from '../../reducers/shared/types';
import { defaultCanvasFormats } from '../CanvasFormats/fixtures';
import useTemplate from '../../templates/useTemplate';
import { useTemplatesData } from '../../hooks/useTemplatesData';
import { normaliseTemplateData } from '../../reducers/builder/utils';
import { ElementsState } from '../../reducers/builder/builderSlice';
import { CampaignMetaDataIProps } from '../../reducers/campaign/types';
import wizFailure from './wiz-failure.svg';

interface ICanvasType {
  name: string;
  responsiveness: boolean;
}

const ErrorCampaignNotFound: React.FC = () => {
  const [newCampaignName, setNewCampaignName] = React.useState('');
  const [availableCampaigns, setAvailableCampaigns] = React.useState<
    CampaignMetaDataIProps[]
  >([]);
  const [selectedCampaign, setSelectedCampaign] = React.useState('');
  const { addTemplate, getTemplatesData } = useTemplatesData();
  const [availableTemplates, setAvailableTemplates] = useState({});
  const [selectedTemplate, setSelectedTemplate] = useState(
    // @ts-ignore
    availableTemplates['1'] || ''
  );
  const [currentCompanyName, setCurrentCompanyName] = useState('');

  const handleAddTemplate = async () => {
    await addTemplate({
      name: `some new template number ${uniqueId()}`,
      template: threePageTemplate as unknown as ElementsState,
    });
    await loadTemplates();
  };

  const canvasTypes: ICanvasType[] = [
    { name: 'responsive', responsiveness: true },
    { name: 'fixed', responsiveness: false },
  ];
  const [canvasDimensions, setCanvasDimensions] = useState<ICanvasDimensions>({
    width: 600,
    height: 300,
  });
  const [canvasType, setCanvasType] = useState<string>('responsive');
  const authState = useAppSelector(selectAuth);
  const navigate = useNavigate();

  const loadTemplates = async () => {
    const templateData = await getTemplatesData();
    const templates = normaliseTemplateData(templateData);
    setAvailableTemplates(templates);
  };

  const loadCampaigns = async () => {
    const campaigns = await CampaignService.getAllCampaignsByCompany(
      authState.parsedToken.company_id
    );
    let filterCampaigns = campaigns.data.filter(
      (campaign: campaignMetaDataIProps) => campaign.status !== 'DELETED'
    );
    filterCampaigns = filterCampaigns.sort((a: any, b: any) =>
      a.createdDate > b.createdDate ? -1 : b.createdDate > a.createdDate ? 1 : 0
    );
    campaigns.message === 'Campaigns successfully fetched'
      ? setAvailableCampaigns(filterCampaigns)
      : null;
  };

  const responsiveness = canvasTypes.find(
    (item) => item.name === canvasType
  )?.responsiveness;

  const { prepareTemplate } = useTemplate({
    fixed: !responsiveness,
    dimensions: canvasDimensions,
  });

  React.useEffect(() => {
    loadCampaigns();
    loadTemplates();
  }, []);

  const handleChange = (event: SelectChangeEvent) => {
    setSelectedCampaign(event.target.value);
    setCurrentCompanyName(`${event.target.value.split('/')[0]} Copy`);
  };

  const handleTemplateChange = (event: SelectChangeEvent) => {
    // @ts-ignore
    setSelectedTemplate(availableTemplates[event.target.value]);
  };

  const createCampaign = async (newCampaignName: string, data: any) => {
    const res = await CampaignService.createCampaignByCompany(
      authState.parsedToken.company_id,
      newCampaignName
    );
    if (res.data?.id) {
      await CampaignService.postInitialCampaignDataByCompanyAndCampaign(
        authState.parsedToken.company_id,
        res.data.id,
        data
      );
    }
    return await res;
  };

  const copyCampaign = async () => {
    const campaignName = selectedCampaign.split('/')[0].trim();
    const campaignId = selectedCampaign.split('/')[1].trim();
    const currentCampaign =
      await CampaignService.getCampaignDataByCompanyAndCampaign(
        authState.parsedToken.company_id,
        campaignId
      );
    const currentCampaignData = currentCampaign.data.data;
    const copyCampaignData = {
      additionalPages: currentCampaignData.additionalPages,
      canvasDimensions: currentCampaignData.canvasDimensions,
      canvasResponsiveness: currentCampaignData.canvasResponsiveness,
      dimensions: currentCampaignData.dimensions,
      elements: currentCampaignData.elements,
      game: currentCampaignData.game,
      pages: currentCampaignData.pages,
      previewMode: currentCampaignData.previewMode,
      readiness: currentCampaignData.readiness,
      selectedElementId: currentCampaignData.selectedElementId,
      selectedPageId: currentCampaignData.selectedPageId,
      selectedTheme: currentCampaignData.selectedTheme,
    };

    if (campaignName && currentCampaign) {
      const newCampaignData = await createCampaign(
        `${campaignName}_COPY`,
        copyCampaignData
      );

      navigate(`/${newCampaignData?.data.id}`);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        flexDirection: 'column',
      }}
    >
      <img src={wizFailure} alt="wizard failure" />
      <Typography sx={{ margin: 3 }}>Campaign not found</Typography>
      <Typography variant="h2" style={{ paddingBottom: 10 }}>
        Create a new campaign
      </Typography>
      <Typography variant="h4">Select a template</Typography>
      {availableTemplates && (
        <FormControl sx={{ width: '20%', margin: 1 }}>
          <Select
            labelId="simple-select-label"
            id="simple-select-id"
            // @ts-ignore
            value={selectedTemplate?.id || ''}
            onChange={handleTemplateChange}
          >
            {Object.values(availableTemplates).map((template: any) => (
              <MenuItem
                key={template.id}
                value={`${template.id}`}
              >{`${template.name}`}</MenuItem>
            ))}
          </Select>
        </FormControl>
      )}

      <Typography variant="h4">Select canvas type</Typography>
      <FormControl sx={{ width: '20%', margin: 1 }}>
        <Select
          labelId="simple-select-label"
          id="simple-select-id"
          value={canvasType}
          onChange={(e) => setCanvasType(e.target.value)}
        >
          {canvasTypes.map((type: ICanvasType) => (
            <MenuItem key={type.name} value={type.name}>
              {type.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {canvasType === 'fixed' && (
        <FormControl sx={{ width: '20%', margin: 1 }}>
          <CanvasDimensions
            dimensions={canvasDimensions}
            onChange={setCanvasDimensions}
          />
        </FormControl>
      )}

      <Typography variant="h4">Choose a name</Typography>
      <TextField
        id="text-field-new-campaign"
        value={newCampaignName}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setNewCampaignName(event.target.value);
        }}
      />
      <Button
        variant="outlined"
        sx={{ margin: 2 }}
        onClick={async () => {
          await createCampaign(newCampaignName, {
            ...prepareTemplate(selectedTemplate).template,
            canvasResponsiveness: responsiveness,
            canvasDimensions,
            dimensions: defaultCanvasFormats,
          });
          await loadCampaigns();
        }}
      >
        Create campaign
      </Button>
      <Typography variant="h2" style={{ paddingBottom: 10, marginTop: 30 }}>
        Open existing campaign
      </Typography>
      <>
        <FormControl sx={{ width: '20%', margin: 1 }}>
          <Select
            labelId="simple-select-label"
            id="simple-select-id"
            value={selectedCampaign}
            onChange={handleChange}
          >
            {availableCampaigns.map((campaign: campaignMetaDataIProps) => (
              <MenuItem
                key={campaign.id}
                value={`${campaign.name} / ${campaign.id}`}
              >{`${campaign.name} / ${campaign.id}`}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </>
      <Button
        variant="outlined"
        sx={{ margin: 2 }}
        onClick={() => {
          navigate(`/${selectedCampaign.split('/ ')[1]}`);
        }}
      >
        Open selected campaign
      </Button>
      {selectedCampaign && (
        <Button variant="outlined" style={{ margin: 2 }} onClick={copyCampaign}>
          {currentCompanyName}
        </Button>
      )}
      <Button
        variant="outlined"
        sx={{ margin: 2 }}
        onClick={() => {
          navigate(`/wizard`);
        }}
      >
        Go to Wizard
      </Button>
      {/*For testing purpose*/}
      <Button onClick={() => handleAddTemplate()}>Add template</Button>
    </Box>
  );
};

export default ErrorCampaignNotFound;
