import { css } from '@emotion/react';
import { useTheme } from '@mui/material';

export const useStyles = (selected: boolean) => {
  const theme = useTheme();

  return {
    container: css`
      display: flex;
      align-items: center;
      flex-direction: column;
      padding: ${theme.spacing(2, 0)};
      background-color: ${selected
        ? theme.palette.primary.main
        : 'transparent'};
      max-width: 100px;
    `,
    pageContainer: css`
      display: block;
      height: 100px;
    `,
    page: css`
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: inherit;
      padding: ${theme.spacing(0, 1)};
      height: 100%;
      width: 100px;
      cursor: pointer;
      background-color: ${selected
        ? theme.palette.primary.main
        : 'transparent'};

      &:hover {
        background-color: ${selected
          ? theme.palette.primary.main
          : 'transparent'};
      }
    `,
    title: css`
      font-size: ${theme.typography.body1.fontSize};
      max-width: 100px;
      box-sizing: border-box;
      padding: ${theme.spacing(0, 0.5)};
      font-weight: ${selected
        ? theme.typography.fontWeightBold
        : theme.typography.fontWeightRegular};
      color: ${selected
        ? theme.palette.primary.contrastText
        : theme.palette.text.primary};
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      cursor: pointer;
    `,

    groupedPagesLabel: css`
      height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      inset: auto auto 1px 0 !important;
    `,

    tabButton: css`
      transform: rotate(90deg) scaleY(0.66) scaleX(0.8);
      fill: ${!selected && theme.palette.common.white};
    `,

    iconWrapper: css`
      position: relative;
    `,

    groupedPagesLabelText: css`
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 3;
      color: ${selected
        ? theme.palette.common.white
        : theme.palette.text.primary};
    `,

    groupedPagesPageOuter: css`
      position: relative;
      min-width: 100px;
      height: 100%;
      display: flex;
      justify-content: center;
    `,
    groupedPagesPageInner: css`
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    `,
  };
};
