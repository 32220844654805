/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import React, { FC, useState } from 'react';
import { ArrowRight, ArrowUp } from 'phosphor-react';
import { ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { IFlip } from '../../types';
import { iconSize } from '../../constants';
import { useStyles } from './styles';

interface IProps {
  flip: IFlip;
  onChange: (flip: IFlip) => void;
}

const FlipWidget: FC<IProps> = ({ flip, onChange }) => {
  const initialFlip = [];

  if (flip.horizontal) {
    initialFlip.push('horizontal');
  }
  if (flip.vertical) {
    initialFlip.push('vertical');
  }

  const [flipValue, setFlipValue] = useState<string[]>(initialFlip);
  const styles = useStyles();

  const onChangeFlip = (
    event: React.MouseEvent<HTMLElement>,
    newFlip: string[]
  ): void => {
    setFlipValue(newFlip);
    onChange({
      horizontal: newFlip.includes('horizontal'),
      vertical: newFlip.includes('vertical'),
    });
  };

  return (
    <div data-testid="flip-widget" css={styles.container}>
      <Typography css={styles.title} data-testid="title">
        Flip
      </Typography>
      <ToggleButtonGroup value={flipValue} onChange={onChangeFlip}>
        <ToggleButton
          color="primary"
          value="horizontal"
          size="small"
          data-testid="flip-horizontal-toggle"
        >
          <ArrowUp size={iconSize} />
        </ToggleButton>
        <ToggleButton
          color="primary"
          value="vertical"
          size="small"
          data-testid="flip-vertical-toggle"
        >
          <ArrowRight size={iconSize} />
        </ToggleButton>
      </ToggleButtonGroup>
    </div>
  );
};

export default FlipWidget;
