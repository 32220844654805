/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import React, { FC } from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../reducers/app/hooks';
import {
  selectGameSettings,
  setGameParam,
} from '../../../reducers/game/gameSlice';
import { IGameBooleanEditorProps } from './IProps';

const GameBooleanEditor: FC<IGameBooleanEditorProps> = (props) => {
  const dispatch = useAppDispatch();
  const gameState = useAppSelector(selectGameSettings);

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    dispatch(
      setGameParam({
        param: props.name,
        value: checked,
        index: props.index,
        arrayCategory: props.arrayCategory,
      })
    );
  };
  let value;
  if (props.arrayCategory) {
    //@ts-ignore
    value = gameState[props.arrayCategory][props.index][props.name];
  } else {
    //@ts-ignore
    value = gameState[props.name];
  }

  return (
    <div css={props.styles} data-testid="game-boolean-editor">
      <FormControlLabel
        control={
          <Checkbox
            //@ts-ignore
            checked={value}
            onChange={handleChange}
          />
        }
        label={props.label}
      />
    </div>
  );
};

export default GameBooleanEditor;
