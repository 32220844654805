/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import React, { FC } from 'react';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Typography } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../reducers/app/hooks';
import {
  selectGameSettings,
  setGameParam,
} from '../../../reducers/game/gameSlice';
import { IGameEnumEditorProps } from './IProps';
import { useStyles } from './styles';

const GameEnumEditor: FC<IGameEnumEditorProps> = (props) => {
  const dispatch = useAppDispatch();
  const gameState = useAppSelector(selectGameSettings);
  const styles = useStyles();
  const handleChange = (event: SelectChangeEvent) => {
    dispatch(
      setGameParam({
        param: props.name,
        value: event.target.value,
        index: props.index,
        arrayCategory: props.arrayCategory,
      })
    );
  };

  let value;
  if (props.arrayCategory) {
    //@ts-ignore
    value = gameState[props.arrayCategory][props.index][props.name];
  } else {
    //@ts-ignore
    value = gameState[props.name];
  }

  return (
    <div data-testid="game-enum-editor" css={styles.container}>
      {props.label && <Typography css={styles.title}>{props.label}</Typography>}
      <Select
        value={value}
        onChange={handleChange}
        fullWidth
        data-testid="drop-direction"
      >
        {props.enums.map((key, index) => (
          <MenuItem value={key} key={key}>
            {props.descs ? props.descs[index] : key}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

export default GameEnumEditor;
