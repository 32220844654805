import { css } from '@emotion/react';
import { useTheme } from '@mui/material';

export const useStyles = () => {
  const theme = useTheme();

  return {
    menuIcon: css`
      position: absolute;
      top: ${theme.spacing(1)};
      right: ${theme.spacing(1)};
      padding: ${theme.spacing(0)};
      cursor: pointer;
      color: ${theme.palette.grey[200]};

      &:hover {
        color: ${theme.palette.primary.main};
      }
    `,
    menuItem: css`
      text-transform: capitalize;
    `,
  };
};
