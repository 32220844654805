import globalColors from '@brame/theme/src/globalColors';

const emptyTemplate = {
  elements: {
    ids: ['1'],
    entities: {
      '1': {
        id: '1',
        type: 'root-container',
        styles: {
          defaults: {
            size: {
              width: { value: 100, unit: '%' },
            },
            background: {
              color: globalColors.transparent,
              image: '',
              videoUrl: '',
            },
            internalAlignment: {
              textAlign: 'left',
              flexDirection: 'column',
              justifyContent: 'start',
              alignItems: 'center',
            },
            display: 'flex',
            flexWrap: 'wrap',
            position: 'relative',
          },
          overrides: {},
        },
        childrenIds: [],
      },
    },
  },
  pages: {
    ids: ['page-1'],
    entities: {
      'page-1': {
        id: 'page-1',
        name: 'page',
        elementRoot: '1',
        pageType: 'basic',
      },
    },
  },
  selectedPageId: 'page-1',
  selectedElementId: null,
  previewMode: false,
  selectedTheme: 'theme1',
};

export default emptyTemplate;
