/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { FC } from 'react';
import { TextField, Typography } from '@mui/material';
import { useStyles } from './styles';

interface IProps {
  value: number;
  title?: string;
  onChange: (value: number) => void;
}

const ZIndexWidget: FC<IProps> = ({ value, title, onChange }) => {
  const styles = useStyles();
  return (
    <div data-testid="z-index-widget">
      {title && <Typography css={styles.title}>{title}</Typography>}
      <TextField
        value={value}
        fullWidth
        type="number"
        onChange={(e) => onChange(Number(e.target.value))}
      />
    </div>
  );
};

export default ZIndexWidget;
