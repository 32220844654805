import { css } from '@emotion/react';
import { useTheme } from '@mui/material';

export const useStyles = () => {
  const theme = useTheme();

  return {
    toolBar: css`
      background: ${theme.palette.common.white};
      border-bottom: 1px solid ${theme.palette.common.black}26;

      &.MuiToolbar-root {
        min-height: 40px;
        padding: ${theme.spacing(0, 2)};
      }
    `,
    logo: css`
      margin-right: ${theme.spacing(5)};
      fill: ${theme.palette.text.primary};
      cursor: pointer;

      &:hover {
        fill: ${theme.palette.primary.main};
      }
    `,
    routeContainer: css`
      display: flex;
      gap: ${theme.spacing(3)};
      flex-grow: 1;
    `,
    route: css`
      font-weight: ${theme.typography.fontWeightBold};
      color: ${theme.palette.text.primary};
      cursor: pointer;

      &:hover {
        color: ${theme.palette.grey[500]};
      }
    `,
  };
};
