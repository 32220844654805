import dispatchConditionalPageChange from '../../../components/GameOutcomeDispatcher';
import { Collectible } from './Collectibles';
import { resetDifficulty } from './DifficultyManager';
import DropGameScene from './DropGameScene';

export function invulFrame(scene: DropGameScene) {
  // Make player "invulnerable"
  scene.playerInvul = true;
  // Animation
  scene.add.tween({
    targets: scene.player,
    alpha: 0,
    yoyo: true,
    duration: 100,
    repeat: 4,
    onComplete: () => {
      scene.playerInvul = false;
    },
  });
}

export function takeDamage(scene: DropGameScene) {
  if (scene.playerInvul) return;

  scene.updateValue('lives', scene.getLives() - 1);
  if (scene.getLives() > 0) {
    invulFrame(scene);
    scene.cameras.main.shake(200, 0.005);
  } else {
    endGame(scene);
  }
}

export function grantLife(scene: DropGameScene) {
  if (scene.getLives() < scene.maxLives) {
    scene.updateValue('lives', scene.getLives() + 1);
  }
}

export function endGame(scene: DropGameScene) {
  scene.gameEnd = true;
  for (const index in scene.collectiblePool) {
    scene.collectiblePool[index].children.iterate((child: Collectible) => {
      if (child.active) scene.collectiblePool[index].despawn(child);
    });
  }
  // Turn off player
  scene.player.setVisible(false);

  if (DropGameScene.jsonLink['game.outcomes']) {
    dispatchConditionalPageChange(DropGameScene.jsonLink['outcomes']);
  }
}

export function resetGame(scene: DropGameScene) {
  if (scene.gameEnd) {
    scene.gameEnd = false;
    // Reset Values
    scene.spawnTimer = 0;
    scene.updateValue('score', 0);
    scene.updateValue('time', 0);
    scene.updateValue('lives', DropGameScene.jsonLink['game.lifeCount']);
    scene.player.setPosition(
      scene.playerStartX +
        (scene.playerOffsetX * scene.player.displayWidth) / 2,
      scene.playerStartY +
        (scene.playerOffsetY * scene.player.displayHeight) / 2
    );
    resetDifficulty(scene);

    // Turn player back on
    scene.player.setVisible(true);
  }
}
