import { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../reducers/app/hooks';
import { selectAuth, selectCampaign } from '../../reducers/auth/authSlice';
import AssetsService from '../../api-client/assets.service';
import {
  removeBrameAsset,
  removePersonalAsset,
  updateAsset as updateAssetAction,
} from '../../reducers/assets/assetSlice';
import { AssetScope, AssetType, IAsset, IAssetUpdate } from '../../types';

const useAssets = () => {
  const dispatch = useAppDispatch();
  const companyId = useAppSelector(selectAuth).parsedToken.company_id;
  const campaignId = useAppSelector(selectCampaign).id;
  const [assetIsRemoving, setAssetIsRemoving] = useState<boolean>(false);

  const removeAsset = async (
    asset: IAsset,
    scope: AssetScope.BRAME | AssetScope.PERSONAL,
    assetType: AssetType
  ) => {
    if (asset && companyId) {
      setAssetIsRemoving(true);
      await AssetsService.removeAsset(scope, asset.assetId, companyId)
        .then(() => {
          dispatch(
            scope === AssetScope.PERSONAL
              ? removePersonalAsset({ id: asset.assetId, assetType })
              : removeBrameAsset({ id: asset.assetId, assetType })
          );
        })
        .finally(() => {
          setAssetIsRemoving(false);
        });
    }
  };

  const updateAsset = async (
    asset: IAssetUpdate,
    scope: AssetScope.BRAME | AssetScope.PERSONAL,
    assetType: AssetType
  ) => {
    if (asset && companyId) {
      await AssetsService.updateAsset(scope, asset, companyId).then(() => {
        dispatch(updateAssetAction({ asset, scope, assetType }));
      });
    }
  };

  const getDefaultLabels = (assetType: AssetType): string[] => {
    const labels = [`origin/${assetType}`];
    if (companyId) {
      labels.push(companyId);
    }
    if (campaignId) {
      labels.push(campaignId);
    }
    return labels;
  };

  return {
    removeAsset,
    assetIsRemoving,
    updateAsset,
    getDefaultLabels,
  };
};

export default useAssets;
