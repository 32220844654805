import { css } from '@emotion/react';
import { useTheme } from '@mui/material';

export const useStyles = () => {
  const theme = useTheme();

  return {
    category: css`
      margin: ${theme.spacing(2)};
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
    `,
    title: css`
      text-transform: capitalize;
      margin-bottom: ${theme.spacing(1)};
      font-size: ${theme.typography.subtitle1.fontSize};
      color: ${theme.palette.text.primary};
      font-weight: ${theme.typography.fontWeightBold};
    `,
  };
};
