// @ts-nocheck
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { FC, useState } from 'react';
import { Check } from 'phosphor-react';
import { Typography, useTheme } from '@mui/material';
import { ButtonUnstyled } from '@mui/base';
import { ITheme } from '@brame/theme/src/types';
import { deleteTheme } from '@brame/components/src/services';
import { ConfirmationModal } from '@brame/components';
import { iconSize } from '../../../constants';
import { useStyles } from './styles';
interface IProps {
  theme: ITheme;
  onSelect: (themeName: string) => void;
  isCurrent: boolean;
}

const ThemePreviewBox: FC<IProps> = ({ theme, onSelect, isCurrent }) => {
  const brameTheme = useTheme();
  const { name } = theme;
  const [removalModalIsOpen, setRemovalModalIsOpen] = useState(false);
  const styles = useStyles({ theme, isCurrent });

  const confirmRemove = () => {
    setRemovalModalIsOpen(false);
    deleteTheme();
  };

  return (
    <div
      data-testid="theme-preview-box"
      css={styles.previewBox}
      onClick={() => onSelect(theme.id)}
    >
      <Typography variant="h2" css={styles.heading}>
        {name || 'Theme'}
      </Typography>

      <Typography variant="body1" css={styles.body}>
        Example of body text
      </Typography>

      <ButtonUnstyled css={styles.button}>Button text</ButtonUnstyled>
      {isCurrent && (
        <div css={styles.checkMark}>
          <Check
            size={iconSize}
            color={brameTheme.palette.primary.main}
            weight="bold"
          />
        </div>
      )}
      <ConfirmationModal
        isOpen={removalModalIsOpen}
        title="Remove Theme Confirmation"
        description="Do you really want to remove this theme?"
        onConfirm={confirmRemove}
        onCancel={() => setRemovalModalIsOpen(false)}
      />
    </div>
  );
};

export default ThemePreviewBox;
