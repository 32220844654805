/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import React, { FC } from 'react';
import { capitalize } from 'lodash';
import { Button } from '@mui/material';
import useThemeData from '@brame/components/src/hooks/useThemeData';
import { fontSizes } from '@brame/components/src/assets/fonts';
import { SelectInput } from '../SelectInput';
import { ToolbarCategory } from '../ToolbarCategory';
import {
  IElementProps,
  ITextStyleProps,
  ITextStylePropsChange,
} from '../../types';
import { SpacingWidget } from '../SpacingWidget';
import { TextAlignSwitcher } from '../TextAlignSwitcher';
import { TextArea } from '../TextArea';
import ColorPicker from '../ColorPicker/ColorPicker';
import { FontDecorationWidget } from '../FontDecorationWidget';
import { TextTransformWidget } from '../TextTransformWidget';
import SizingWidget from '../SizingWidget/SizingWidget';
import { BuilderComponent, TextVariant } from '../../reducers/builder';
import { updateElement } from '../../reducers/builder/builderSlice';
import { useAppDispatch } from '../../reducers/app/hooks';
import { ZIndexWidget } from '../ZIndexWidget';
import { defaultElementsSizes } from '../../reducers/builder/fixtures';
import { fallbackProps } from './mock';
import { useStyles } from './styles';

interface IProps {
  styleProps: ITextStyleProps;
  elementProps: IElementProps;
  onChange: (props: ITextStylePropsChange | IElementProps) => void;
  onReset: () => void;
  isPhaser?: boolean;
}

const TextEditor: FC<IProps> = ({
  styleProps,
  elementProps,
  onChange,
  onReset,
  isPhaser,
}) => {
  const {
    size,
    textAlign,
    font: { fontFamily, fontSize, fontColor, textTransform },
    decoration,
    spacing: { margin, padding },
    zIndex,
  } = styleProps;
  const dispatch = useAppDispatch();
  const { value, variant, id } = elementProps;
  const styles = useStyles();
  const { fontFamilies, getThemedStylingValues } = useThemeData();

  const handleElementChange = (e: string) => {
    const formattedValue = e === 'Text' ? 'body1' : e.toLowerCase();
    dispatch(
      updateElement({
        id: id,
        changes: {
          variant: formattedValue,
        },
      })
    );
  };

  return (
    <div data-testid="text-editor">
      <ToolbarCategory>
        {!isPhaser && (
          <TextArea
            label="text"
            value={value}
            onChange={(value) => onChange({ ...elementProps, value })}
          />
        )}
      </ToolbarCategory>

      <ToolbarCategory title="Font styles">
        <div css={styles.fontStylesBlock}>
          <div>
            <SelectInput
              value={
                variant === TextVariant.BODY ? 'Text' : capitalize(variant)
              }
              options={['H1', 'H2', 'H3', 'Text']}
              onChange={handleElementChange}
            />
          </div>
          <div>
            <ColorPicker
              value={fontColor || fallbackProps.font.fontColor}
              onChange={(value) => onChange({ font: { fontColor: value } })}
            />
          </div>
          <div>
            <SelectInput
              value={fontFamily || fallbackProps.font.fontFamily}
              options={fontFamilies}
              onChange={(value) =>
                onChange({
                  font: { fontFamily: value },
                })
              }
            />
          </div>
          <div>
            <SelectInput
              value={
                fontSize ||
                getThemedStylingValues('MuiTypography').fontSize ||
                fallbackProps.font.fontSize
              }
              options={fontSizes}
              onChange={(value) => onChange({ font: { fontSize: +value } })}
            />
          </div>
        </div>
        <SizingWidget
          size={size || defaultElementsSizes[BuilderComponent.TEXT]}
          useAuto
          onChange={(size) => onChange({ size: size })}
        />
      </ToolbarCategory>

      <ToolbarCategory title="Alignment">
        <TextAlignSwitcher
          align={textAlign || fallbackProps.textAlign}
          onChange={(value) => onChange({ textAlign: value })}
        />
      </ToolbarCategory>

      <ToolbarCategory title="Decoration">
        <FontDecorationWidget
          textDecoration={decoration || fallbackProps.decoration}
          onChange={(value) => onChange({ decoration: value })}
        />
      </ToolbarCategory>

      <ToolbarCategory title="Letters">
        <TextTransformWidget
          textTransform={
            textTransform ||
            getThemedStylingValues('MuiTypography').textTransform ||
            fallbackProps.font.textTransform
          }
          onChange={(value) => onChange({ font: { textTransform: value } })}
        />
      </ToolbarCategory>

      <ToolbarCategory title="Plane Index">
        <ZIndexWidget
          value={zIndex || fallbackProps.zIndex}
          onChange={(value) => onChange({ zIndex: value })}
        />
      </ToolbarCategory>

      <ToolbarCategory title="Margin">
        <SpacingWidget
          spacing={margin || fallbackProps.spacing.margin}
          onChange={(values) => onChange({ spacing: { margin: values } })}
        />
      </ToolbarCategory>

      <ToolbarCategory title="Padding">
        <SpacingWidget
          spacing={padding || fallbackProps.spacing.padding}
          onChange={(values) =>
            onChange({
              spacing: { padding: values },
            })
          }
        />
      </ToolbarCategory>

      <ToolbarCategory>
        <Button data-testid="reset-text-styles" onClick={onReset} size="small">
          Reset
        </Button>
      </ToolbarCategory>
    </div>
  );
};

export default TextEditor;
