import { FC } from 'react';
import { Dictionary, EntityId } from '@reduxjs/toolkit';
import {
  ButtonActionMapping,
  CanvasElement,
} from '@brame/builder/src/reducers/builder';
import { ITheme } from '@brame/theme';
import { renderTree } from '../../utils/renderComponentsTree';

const StructureLayout: FC<{
  rootEntityId: EntityId;
  elements: Dictionary<CanvasElement>;
  actions: ButtonActionMapping;
  gameData: any;
  theme: ITheme;
}> = ({ rootEntityId, elements, actions, theme, gameData }) => {
  return renderTree({
    rootElementId: rootEntityId,
    elements,
    actions,
    theme,
    gameData: gameData,
    builderMode: false,
  });
};

export default StructureLayout;
