import Phaser from 'phaser';
import SlotMachineScene from './SlotMachineScene';

export default class SlotSprite {
  private internalSprites: Phaser.GameObjects.Sprite[];
  private internalTex: Phaser.Textures.Texture;

  // Parent Scene
  private scene: Phaser.Scene;

  // Ordering
  private orderArray = [0, 1, 2];

  private yPos!: number;
  private originalYPos: number[] = [];
  private xPos!: number;
  private iconCount!: number;
  private iconSize!: number;
  private texHeight!: number;
  private spinCount!: number;
  private spinDuration!: number;
  // eslint-disable-next-line @typescript-eslint/ban-types
  private onCompleteCallback: Function | undefined;

  constructor(
    scene: Phaser.Scene,
    texture: Phaser.Textures.Texture,
    iconCount: number,
    iconSize: number,
    frameAnchor: number,
    spinCount: number,
    spinDuration: number,
    framePos: Phaser.Math.Vector2,
    frameSize: Phaser.Math.Vector2,
    // eslint-disable-next-line @typescript-eslint/ban-types
    onCompleteCallBack?: Function
  ) {
    this.internalTex = texture;
    this.iconCount = iconCount;
    this.iconSize = iconSize;
    this.yPos = framePos.y;
    this.xPos = framePos.x + (frameAnchor - 0.5) * frameSize.x;
    this.texHeight = iconCount * iconSize;
    this.spinCount = spinCount;
    this.spinDuration = spinDuration * 1000;
    this.internalSprites = [];
    this.internalSprites.push(
      scene.add
        .sprite(this.xPos, this.yPos - this.texHeight, this.internalTex)
        .setOrigin(0.5, 0.5)
    );
    this.internalSprites.push(
      scene.add
        .sprite(this.xPos, this.yPos, this.internalTex)
        .setOrigin(0.5, 0.5)
    );
    this.internalSprites.push(
      scene.add
        .sprite(this.xPos, this.yPos + this.texHeight, this.internalTex)
        .setOrigin(0.5, 0.5)
    );
    this.originalYPos.push(this.yPos - this.texHeight);
    this.originalYPos.push(this.yPos);
    this.originalYPos.push(this.yPos + this.texHeight);
    this.scene = scene;
    this.setDepth(2);
    this.onCompleteCallback = onCompleteCallBack;
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  update() {}

  setMask(mask: Phaser.Display.Masks.GeometryMask) {
    this.internalSprites.forEach((sprite) => {
      sprite.setMask(mask);
    });
  }

  setDepth(depth: number) {
    this.internalSprites.forEach((sprite) => {
      sprite.setDepth(depth);
    });
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  setOnCompleteCallback(onCompleteCallBack: Function) {
    this.onCompleteCallback = onCompleteCallBack;
  }

  spin(iconIndex: number) {
    let resultIndex = iconIndex;
    if (!(this.iconCount % 2)) {
      // Even iconCount
      resultIndex = -resultIndex - 0.5;
    } else {
      resultIndex = -resultIndex;
    }
    this.scene.add.tween({
      targets: this.internalSprites[this.orderArray[0]],
      y: this.yPos,
      duration: (this.spinDuration * 2) / 5,
      loop: this.spinCount,
      onLoop: () => {
        const temp0 = this.orderArray[0];
        const temp1 = this.orderArray[1];
        this.internalSprites[this.orderArray[2]].y = this.yPos - this.texHeight;
        this.orderArray[0] = this.orderArray[2];
        this.orderArray[1] = temp0;
        this.orderArray[2] = temp1;
      },
    });
    this.scene.add.tween({
      targets: this.internalSprites[this.orderArray[1]],
      y: this.yPos + this.texHeight,
      duration: (this.spinDuration * 2) / 5,
      loop: this.spinCount,
      onComplete: () => {
        endTween.play();
      },
    });
    const endTween = this.scene.add
      .tween({
        targets: this.internalSprites,
        y:
          '+=' + (Math.floor(this.iconCount / 2) + resultIndex) * this.iconSize,
        duration: (this.spinDuration / 5) * (iconIndex + 1),
        ease: 'Back.easeOut',
        onComplete: () => {
          if (this.onCompleteCallback) {
            this.onCompleteCallback();
          }
        },
      })
      .pause();
  }

  reset() {
    for (const entry in this.internalSprites) {
      this.internalSprites[entry].setY(this.originalYPos[entry]);
    }
    this.orderArray = [0, 1, 2];
  }
}
