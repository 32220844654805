import { useState } from 'react';
import { useAppSelector } from '../reducers/app/hooks';
import CampaignsService from '../api-client/campaigns.service';
import { selectCampaign } from '../reducers/auth/authSlice';

const useAnalytics = () => {
  const campaignMetaData = useAppSelector(selectCampaign);
  const [trackingEnabled, setTrackingEnabled] = useState(
    campaignMetaData?.collectAnalytics
  );

  const setTracking = async (collectAnalytics: boolean) => {
    const requestBody = {
      ...campaignMetaData,
      collectAnalytics,
    };

    const res = await CampaignsService.putCampaignMetaData(requestBody);
    if (res) {
      setTrackingEnabled(collectAnalytics);
    }
  };

  return { trackingEnabled, setTracking };
};

export default useAnalytics;
