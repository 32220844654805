/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import React, { FC } from 'react';
import { ArrowDown, ArrowRight } from 'phosphor-react';
import { ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { FlexDirection } from '../../types';
import { iconSize } from '../../constants';
import { useStyles } from './styles';

interface IProps {
  direction: FlexDirection;
  onChange: (direction: FlexDirection) => void;
}

const DirectionSwitcher: FC<IProps> = ({ direction, onChange }) => {
  const styles = useStyles();
  return (
    <div data-testid="direction-switcher">
      <div>
        <Typography css={styles.title}>Content direction</Typography>

        <ToggleButtonGroup
          value={direction}
          exclusive
          onChange={(e, value) => !!value && onChange(value)}
        >
          <ToggleButton
            value="row"
            data-testid="flex-direction-toggle-row"
            size="small"
          >
            <ArrowRight size={iconSize} />
          </ToggleButton>
          <ToggleButton
            value="column"
            data-testid="flex-direction-toggle-column"
            size="small"
          >
            <ArrowDown size={iconSize} />
          </ToggleButton>
        </ToggleButtonGroup>
      </div>
    </div>
  );
};

export default DirectionSwitcher;
