/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import React, { FC } from 'react';
import { LinkSimpleHorizontal } from 'phosphor-react';
import { ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { SizeInput } from '../SizeInput';
import { ISizing } from '../../types';
import { iconSize } from '../../constants';
import { useStyles } from './styles';
import useSizingWidget from './useSizingWidget';
import useResize from './useResize';

export interface ISizingWidgetProps {
  title?: string;
  size: ISizing & Required<Pick<ISizing, 'width' | 'height'>>;
  actualSize?: {
    width: number;
    height: number;
  };
  useAuto?: boolean;
  showAspectRatioToggle?: boolean;
  onChange: (sizing: ISizing) => void;
}

const SizingWidget: FC<ISizingWidgetProps> = ({
  title,
  size,
  actualSize,
  useAuto,
  showAspectRatioToggle,
  onChange,
}) => {
  const { width, height } = size;
  const styles = useStyles();
  const {
    preservedAspectRatio,
    onChangeAspectRatioToggle,
    onChangeWidth,
    onChangeHeight,
  } = useSizingWidget({ showAspectRatioToggle, size, actualSize, onChange });
  const { maxWidth } = useResize();

  const maxWidthValue =
    width.unit === '%' ? 100 : width.unit === 'px' ? maxWidth : undefined;
  const widthInputProps = { min: 0, max: maxWidthValue };

  return (
    <div data-testid="sizing-widget">
      <Typography css={styles.title} data-testid="title">
        {title}
      </Typography>
      <div css={styles.container}>
        <SizeInput
          title="width"
          sizeValue={width}
          onChange={onChangeWidth}
          inputProps={widthInputProps}
          useAuto={useAuto}
        />
        <SizeInput
          title="height"
          sizeValue={height}
          onChange={onChangeHeight}
          useAuto={useAuto}
        />
        {showAspectRatioToggle && (
          <ToggleButtonGroup
            value={preservedAspectRatio}
            exclusive
            onChange={onChangeAspectRatioToggle}
          >
            <ToggleButton
              css={styles.toggleButton}
              color="primary"
              value="preserved"
              size="small"
              data-testid="preserved-aspect-ratio-toggle"
            >
              <LinkSimpleHorizontal
                css={styles.icon}
                size={iconSize}
                weight="bold"
              />
            </ToggleButton>
          </ToggleButtonGroup>
        )}
      </div>
    </div>
  );
};

export default SizingWidget;
