import { css } from '@emotion/react';
import { useTheme } from '@mui/material';
import { brameTheme } from '@brame/theme';

export const useStyles = () => {
  const theme = useTheme();

  return {
    subtitle: css`
      color: ${theme.palette.text.primary};
    `,
    wrapper: css`
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: ${theme.spacing(1)};
    `,
    galleryItem: css`
      border-radius: 5px;
      cursor: pointer;
    `,
    logo: css`
      object-fit: contain;
    `,
    video: css`
      width: 100%;
    `,
    image: css`
      object-fit: cover;
      position: absolute;
      inset: 0 0 0 0;
      width: 100%;
      height: 100%;
    `,
    block: css`
      display: flex;
      flex-direction: column;
      gap: ${theme.spacing(0.5)};
      margin: ${theme.spacing(1, 0)};
      border-radius: 4px;
    `,
    imageFallback: css`
      width: 100%;
      height: 75px;
      position: relative;
      background: ${theme.palette.grey[200]};
      background-size: cover;
      border-radius: ${brameTheme.shape.borderRadius}px;
    `,
    mediaPreviewGroup: css`
      padding: ${theme.spacing(1, 0)};
    `,
    iconButtonContainer: css`
      margin-top: ${theme.spacing(3)};
      display: flex;
      justify-content: center;
      align-items: center;
    `,
    mediaContainer: css`
      display: flex;
      gap: ${theme.spacing(1)};
    `,
    mediaName: css`
      position: absolute;
      top: 0;
      right: 0;
      display: block;
      width: 100%;
      text-align: center;
      background: ${brameTheme.palette.primary.main};
      color: ${brameTheme.palette.primary.contrastText};
      font-size: 12px;
      z-index: 1;
      pointer-events: none;
    `,
    deleteIcon: css`
      position: absolute;
      bottom: 5px;
      right: 5px;
      z-index: 1;
    `,
    assetTiles: css`
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: ${theme.spacing(1)};
    `,
    gameAssetTile: css`
      object-fit: contain;
    `,
  };
};
