/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { Check } from 'phosphor-react';
import React, { useState } from 'react';
import ConfirmationModal from '@brame/components/src/components/ConfirmationModal/ConfirmationModal';
import { AssetType, IAsset, IAssetUpdate } from '../../../types';
import useModal from '../../../hooks/useModal';
import UpdateAssetModal from '../UpdateAssetModal/UpdateAssetModal';
import useAssets from '../useAssets';
import { AssetAction, MenuAction, MenuButton } from '../MenuButton';
import { iconSize } from '../../../constants';
import { useStyles } from './styles';

const MasonryCard = ({
  asset,
  assetType,
  selected,
  isEditable,
  onSelectAsset,
  onRemoveAsset,
  onUpdateAsset,
}: {
  asset: IAsset;
  assetType: AssetType;
  selected: boolean;
  isEditable: boolean;
  onSelectAsset: (asset: IAsset) => void;
  onRemoveAsset?: (asset: IAsset) => void;
  onUpdateAsset?: (asset: IAssetUpdate) => void;
}) => {
  const styles = useStyles(isEditable);
  const [assetIsLoaded, setAssetIsLoaded] = useState<boolean>(false);
  const {
    isModalOpen: isRemovalConfirmationModalOpen,
    openModal: openRemovalConfirmationModal,
    closeModal: closeRemovalConfirmationModal,
  } = useModal();
  const {
    isModalOpen: isUpdateModalOpen,
    openModal: openUpdateModal,
    closeModal: closeUpdateModal,
  } = useModal();
  const { getDefaultLabels } = useAssets();
  const options: MenuAction[] = [
    {
      name: AssetAction.UPDATE,
      action: openUpdateModal,
    },
    {
      name: AssetAction.REMOVE,
      action: openRemovalConfirmationModal,
    },
  ];

  const handleAssetUpdate = (assetUpdate: IAssetUpdate) => {
    closeRemovalConfirmationModal();
    onUpdateAsset?.({ ...assetUpdate, assetId: asset.assetId });
  };

  const handleAssetRemove = () => {
    closeRemovalConfirmationModal();
    onRemoveAsset?.(asset);
  };

  const handleAssetLoaded = () => {
    setAssetIsLoaded(true);
  };

  return (
    <React.Fragment>
      <div
        key={asset.assetId}
        css={styles.assetContainer}
        onClick={() => onSelectAsset(asset)}
      >
        {assetType === AssetType.VIDEO ? (
          <video
            src={asset.urlSmall || asset.url}
            autoPlay
            loop
            muted
            onLoadedData={handleAssetLoaded}
            onError={handleAssetLoaded}
            css={styles.asset}
          />
        ) : (
          <img
            src={asset.urlSmall || asset.url}
            decoding="async"
            alt={asset.name}
            loading="lazy"
            onLoad={handleAssetLoaded}
            onError={handleAssetLoaded}
            css={styles.asset}
          />
        )}
        {selected && (
          <Check size={iconSize} weight="bold" css={styles.selectedIcon} />
        )}
        {isEditable && assetIsLoaded && <MenuButton options={options} />}
      </div>
      <ConfirmationModal
        isOpen={isRemovalConfirmationModalOpen}
        title="Removal confirmation"
        description="Do you really want to remove this asset?"
        onConfirm={handleAssetRemove}
        onCancel={closeRemovalConfirmationModal}
      />
      <UpdateAssetModal
        isOpen={isUpdateModalOpen}
        asset={asset}
        defaultLabels={getDefaultLabels(assetType)}
        onCancel={closeUpdateModal}
        onSaveChanges={handleAssetUpdate}
      />
    </React.Fragment>
  );
};

export default MasonryCard;
