/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import React, { FC } from 'react';
import { Formik, Form } from 'formik';
import { X } from 'phosphor-react';
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import { useStyles as useDialogStyles } from '../styles';
import { IAsset, IAssetUpdate } from '../../../types';
import useAlert from '../../Alert/useAlert';
import { iconSize } from '../../../constants';
import { useStyles } from './styles';
import { updateAssetValidationSchema } from './validation';
import useLabels from './useLabels';

export interface IProps {
  isOpen: boolean;
  asset: IAsset;
  defaultLabels: string[];
  onCancel: () => void;
  onSaveChanges: (asset: IAssetUpdate) => void;
}

export interface IFormProps {
  name: string;
  newLabel: string;
  labels: string[];
}

const UpdateAssetModal: FC<IProps> = ({
  isOpen,
  asset,
  defaultLabels,
  onCancel,
  onSaveChanges,
}) => {
  const dialogStyles = useDialogStyles();
  const styles = useStyles();
  const { toast, openAlert } = useAlert();
  const { addLabel, deleteLabel } = useLabels(openAlert);
  const initialValues = {
    name: asset.name || '',
    labels: [...(asset.labels || [])],
    newLabel: '',
  };

  const onSubmit = (values: IFormProps) => {
    onSaveChanges({
      assetId: asset.assetId,
      name: values.name,
      labels: values.labels,
    });
    onCancel();
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onCancel}
      data-testid="update-asset-modal"
      PaperProps={{
        sx: {
          height: 'auto',
          maxHeight: 'calc(100% - 64px)',
          width: '450px',
          maxWidth: '100%',
        },
      }}
    >
      <Formik
        onSubmit={onSubmit}
        initialValues={initialValues}
        validateOnChange={true}
        enableReinitialize
        validationSchema={updateAssetValidationSchema}
      >
        {(props) => (
          <Form>
            <DialogTitle
              variant="body1"
              css={dialogStyles.dialogTitle}
              data-testid="update-asset-modal-title"
            >
              Update asset
              <IconButton
                aria-label="close"
                onClick={onCancel}
                css={dialogStyles.dialogTitleIcon}
              >
                <X size={iconSize} />
              </IconButton>
            </DialogTitle>
            <DialogContent css={dialogStyles.dialogContent}>
              <FormControl fullWidth>
                <Typography css={styles.label}>Name</Typography>
                <TextField
                  error={!!props.errors.name}
                  helperText={props.errors.name}
                  InputLabelProps={{ shrink: true }}
                  name="name"
                  css={styles.nameInput}
                  value={props.values.name}
                  onChange={props.handleChange}
                />
              </FormControl>
              <FormControl fullWidth>
                <Typography css={styles.label}>Labels</Typography>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    startAdornment: props.values.labels.map((item) => (
                      <Chip
                        key={item}
                        tabIndex={-1}
                        label={item}
                        onDelete={
                          defaultLabels.includes(item)
                            ? undefined
                            : () => deleteLabel(props, item)
                        }
                      />
                    )),
                  }}
                  css={styles.labelInput}
                  name="newLabel"
                  value={props.values.newLabel}
                  onChange={props.handleChange}
                  onKeyDown={(e) => {
                    addLabel(e, props);
                  }}
                />
              </FormControl>
            </DialogContent>
            <DialogActions css={dialogStyles.dialogButtons}>
              <Button variant="text" onClick={onCancel}>
                Cancel
              </Button>
              <Button type="submit" color="primary" variant="contained">
                Save
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
      {toast}
    </Dialog>
  );
};

export default UpdateAssetModal;
