import Phaser from 'phaser';
import { calculateScale, convertISizingToPX } from '../../ScalingManager';
import DropGameScene from './DropGameScene';

export function initTutorial(scene: DropGameScene) {
  const cursorW = scene.textures.get('cursor').get(0).width;
  const cursorH = scene.textures.get('cursor').get(0).height;
  const cursorPixelValues = convertISizingToPX(
    {
      width: { value: 5, unit: '%' },
      height: { value: 5, unit: '%' },
    },
    scene.gameWidth,
    scene.gameHeight
  );
  const scale = calculateScale(
    cursorW,
    cursorH,
    cursorPixelValues.x,
    cursorPixelValues.y
  );
  scene.cursor = scene.matter.add
    .sprite(scene.gameWidth / 2, scene.gameHeight / 2, 'cursor')
    .setScale(scale.x, scale.y)
    .setDepth(10);
  scene.cursor.setName('Cursor');
  switch (scene.gameDirection) {
    case 'TopBot':
    case 'BotTop':
      scene.tutorialDir = new Phaser.Math.Vector2(1, 0);
      break;
    case 'RightLeft':
    case 'LeftRight':
      scene.tutorialDir = new Phaser.Math.Vector2(0, 1);
      break;
  }
  switch (DropGameScene.jsonLink['game.movementMode']) {
    case 'drag':
      // 0 Tween, move cursor to player
      scene.tutorialTweens.push(
        scene.add.tween({
          targets: scene.cursor,
          x: scene.player.x,
          y: scene.player.y,
          duration: 1000,
          onComplete: () => {
            scene.tutorialTweens[1].play();
          },
        })
      );
      // 1 Tween, cursor "click" animation
      scene.tutorialTweens.push(
        scene.add.tween({
          targets: scene.cursor,
          scaleX: scene.cursor.scaleX * 0.7,
          scaleY: scene.cursor.scaleY * 0.7,
          duration: 500,
          onComplete: () => {
            scene.tutorialTweens[2].play();
          },
        })
      );
      scene.tutorialTweens[1].pause();
      // 2 Tween, move cursor with player one direction
      scene.tutorialTweens.push(
        scene.add.tween({
          targets: [scene.cursor, scene.player],
          x: '+=' + scene.tutorialDir.x * scene.gameWidth * 0.4,
          y: '+=' + scene.tutorialDir.y * scene.gameHeight * 0.4,
          duration: 1000,
          onComplete: () => {
            scene.tutorialTweens[3].play();
          },
        })
      );
      scene.tutorialTweens[2].pause();
      // 3 Tween, move cursor with player other direction
      scene.tutorialTweens.push(
        scene.add.tween({
          targets: [scene.cursor, scene.player],
          x: '+=' + scene.tutorialDir.x * scene.gameWidth * -0.8,
          y: '+=' + scene.tutorialDir.y * scene.gameHeight * -0.8,
          duration: 1500,
          onComplete: () => {
            scene.tutorialTweens[4].play();
          },
        })
      );
      scene.tutorialTweens[3].pause();
      scene.tutorialTweens.push(
        scene.add.tween({
          targets: [scene.cursor, scene.player],
          x: '+=' + scene.tutorialDir.x * scene.gameWidth * 0.8,
          y: '+=' + scene.tutorialDir.y * scene.gameHeight * 0.8,
          duration: 1500,
          onComplete: () => {
            scene.tutorialTweens[3].play();
          },
        })
      );
      scene.tutorialTweens[4].pause();
      break;
    case 'followVelocity':
      // 0 Tween, initial cursor move
      scene.tutorialTweens.push(
        scene.add.tween({
          targets: scene.cursor,
          x: '+=' + scene.tutorialDir.x * scene.gameWidth * 0.4,
          y: '+=' + scene.tutorialDir.y * scene.gameHeight * 0.4,
          duration: 1000,
          onComplete: () => {
            scene.tutorialTweens[2].play();
          },
        })
      );
      // 1 Tween, initial player move
      scene.tutorialTweens.push(
        scene.add.tween({
          targets: scene.player,
          x: '+=' + scene.tutorialDir.x * scene.gameWidth * 0.4,
          y: '+=' + scene.tutorialDir.y * scene.gameHeight * 0.4,
          duration: 1000,
          delay: 500,
          onComplete: () => {
            scene.tutorialTweens[3].play();
          },
        })
      );
      // 2 Tween, move cursor other direction
      scene.tutorialTweens.push(
        scene.add.tween({
          targets: scene.cursor,
          x: '+=' + scene.tutorialDir.x * scene.gameWidth * -0.8,
          y: '+=' + scene.tutorialDir.y * scene.gameHeight * -0.8,
          duration: 1500,
          onComplete: () => {
            scene.tutorialTweens[4].play();
          },
        })
      );
      scene.tutorialTweens[2].pause();
      // 3 Tween, move player other direction
      scene.tutorialTweens.push(
        scene.add.tween({
          targets: scene.player,
          x: '+=' + scene.tutorialDir.x * scene.gameWidth * -0.8,
          y: '+=' + scene.tutorialDir.y * scene.gameHeight * -0.8,
          duration: 1500,
          onComplete: () => {
            scene.tutorialTweens[5].play();
          },
        })
      );
      scene.tutorialTweens[3].pause();
      // 4 Tween, move cursor last direction
      scene.tutorialTweens.push(
        scene.add.tween({
          targets: scene.cursor,
          x: '+=' + scene.tutorialDir.x * scene.gameWidth * 0.8,
          y: '+=' + scene.tutorialDir.y * scene.gameHeight * 0.8,
          duration: 1500,
          onComplete: () => {
            scene.tutorialTweens[2].play();
          },
        })
      );
      scene.tutorialTweens[4].pause();
      // 5 Tween, move cursor last direction
      scene.tutorialTweens.push(
        scene.add.tween({
          targets: scene.player,
          x: '+=' + scene.tutorialDir.x * scene.gameWidth * 0.8,
          y: '+=' + scene.tutorialDir.y * scene.gameHeight * 0.8,
          duration: 1500,
          onComplete: () => {
            scene.tutorialTweens[3].play();
          },
        })
      );
      scene.tutorialTweens[5].pause();
      break;
    case 'followPosition':
      // 0 Tween, move cursor with player initial direction
      scene.tutorialTweens.push(
        scene.add.tween({
          targets: [scene.cursor, scene.player],
          x: '+=' + scene.tutorialDir.x * scene.gameWidth * 0.4,
          y: '+=' + scene.tutorialDir.y * scene.gameHeight * 0.4,
          duration: 1000,
          onComplete: () => {
            scene.tutorialTweens[1].play();
          },
        })
      );
      // 1 Tween, move cursor with player back direction
      scene.tutorialTweens.push(
        scene.add.tween({
          targets: [scene.cursor, scene.player],
          x: '+=' + scene.tutorialDir.x * scene.gameWidth * -0.8,
          y: '+=' + scene.tutorialDir.y * scene.gameHeight * -0.8,
          duration: 1500,
          onComplete: () => {
            scene.tutorialTweens[2].play();
          },
        })
      );
      scene.tutorialTweens[1].pause();
      // 2 Tween, move cursor with player forward direction
      scene.tutorialTweens.push(
        scene.add.tween({
          targets: [scene.cursor, scene.player],
          x: '+=' + scene.tutorialDir.x * scene.gameWidth * 0.8,
          y: '+=' + scene.tutorialDir.y * scene.gameHeight * 0.8,
          duration: 1500,
          onComplete: () => {
            scene.tutorialTweens[1].play();
          },
        })
      );
      scene.tutorialTweens[2].pause();
      break;
    case 'laneBased':
      // 0 Tween, move cursor with player initial direction
      scene.tutorialTweens.push(
        scene.add.tween({
          targets: scene.cursor,
          x: '+=' + scene.tutorialDir.x * scene.gameWidth * 0.4,
          y: '+=' + scene.tutorialDir.y * scene.gameHeight * 0.4,
          duration: 1000,
          onComplete: () => {
            scene.tutorialTweens[1].play();
          },
        })
      );
      // 1 Tween, cursor "click" animation
      scene.tutorialTweens.push(
        scene.add.tween({
          targets: scene.cursor,
          scaleX: scene.cursor.scaleX * 0.7,
          scaleY: scene.cursor.scaleY * 0.7,
          duration: 500,
          onComplete: () => {
            scene.tutorialTweens[2].play();
          },
          yoyo: 1,
        })
      );
      scene.tutorialTweens[1].pause();
      // 2 Tween, move player 1 lane
      scene.tutorialTweens.push(
        scene.add.tween({
          targets: scene.player,
          x: '+=' + scene.tutorialDir.x * scene.gameWidth * 0.4,
          y: '+=' + scene.tutorialDir.y * scene.gameHeight * 0.4,
          duration: 1500,
          onComplete: () => {
            scene.tutorialTweens[3].play();
          },
        })
      );
      scene.tutorialTweens[2].pause();
      // 3 Tween, move cursor with player back direction
      scene.tutorialTweens.push(
        scene.add.tween({
          targets: scene.cursor,
          x: '+=' + scene.tutorialDir.x * scene.gameWidth * -0.8,
          y: '+=' + scene.tutorialDir.y * scene.gameHeight * -0.8,
          duration: 1500,
          onComplete: () => {
            scene.tutorialTweens[4].play();
          },
        })
      );
      scene.tutorialTweens[3].pause();
      // 4 Tween, cursor "click" animation
      scene.tutorialTweens.push(
        scene.add.tween({
          targets: scene.cursor,
          scaleX: scene.cursor.scaleX * 0.7,
          scaleY: scene.cursor.scaleY * 0.7,
          duration: 500,
          onComplete: () => {
            scene.tutorialTweens[5].play();
          },
          yoyo: 1,
        })
      );
      scene.tutorialTweens[4].pause();
      // 5 Tween, move player 1 lane
      scene.tutorialTweens.push(
        scene.add.tween({
          targets: scene.player,
          x: '+=' + scene.tutorialDir.x * scene.gameWidth * -0.4,
          y: '+=' + scene.tutorialDir.y * scene.gameHeight * -0.4,
          duration: 1500,
          onComplete: () => {
            scene.tutorialTweens[6].play();
          },
        })
      );
      scene.tutorialTweens[5].pause();
      // 6 Tween, cursor "click" animation
      scene.tutorialTweens.push(
        scene.add.tween({
          targets: scene.cursor,
          scaleX: scene.cursor.scaleX * 0.7,
          scaleY: scene.cursor.scaleY * 0.7,
          duration: 500,
          onComplete: () => {
            scene.tutorialTweens[7].play();
          },
          yoyo: 1,
        })
      );
      scene.tutorialTweens[6].pause();
      // 7 Tween, move player 1 lane
      scene.tutorialTweens.push(
        scene.add.tween({
          targets: scene.player,
          x: '+=' + scene.tutorialDir.x * scene.gameWidth * -0.4,
          y: '+=' + scene.tutorialDir.y * scene.gameHeight * -0.4,
          duration: 1500,
          onComplete: () => {
            scene.tutorialTweens[8].play();
          },
        })
      );
      scene.tutorialTweens[7].pause();
      // 8 Tween, move cursor with player initial direction
      scene.tutorialTweens.push(
        scene.add.tween({
          targets: scene.cursor,
          x: '+=' + scene.tutorialDir.x * scene.gameWidth * 0.8,
          y: '+=' + scene.tutorialDir.y * scene.gameHeight * 0.8,
          duration: 1000,
          onComplete: () => {
            scene.tutorialTweens[9].play();
          },
        })
      );
      scene.tutorialTweens[8].pause();
      // 9 Tween, cursor "click" animation
      scene.tutorialTweens.push(
        scene.add.tween({
          targets: scene.cursor,
          scaleX: scene.cursor.scaleX * 0.7,
          scaleY: scene.cursor.scaleY * 0.7,
          duration: 500,
          onComplete: () => {
            scene.tutorialTweens[10].play();
          },
          yoyo: 1,
        })
      );
      scene.tutorialTweens[9].pause();
      // 10 Tween, move player 1 lane
      scene.tutorialTweens.push(
        scene.add.tween({
          targets: scene.player,
          x: '+=' + scene.tutorialDir.x * scene.gameWidth * 0.4,
          y: '+=' + scene.tutorialDir.y * scene.gameHeight * 0.4,
          duration: 1500,
          onComplete: () => {
            scene.tutorialTweens[1].play();
          },
        })
      );
      scene.tutorialTweens[10].pause();
      break;
  }
  scene.input.on('pointerdown', () => {
    if (scene.tutorialMode) {
      for (const entry in scene.tutorialTweens) {
        scene.tutorialTweens[entry].pause();
      }
      scene.tutorialMode = false;
      scene.cursor.setVisible(false);
    }
  });
}
