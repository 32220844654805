import React, { RefObject, useState } from 'react';
import { EntityId } from '@reduxjs/toolkit';
import { useAppDispatch } from '@brame/builder/src/reducers/app/hooks';
import { updateElement } from '@brame/builder/src/reducers/builder/builderSlice';

const useEditable = (ref: RefObject<HTMLElement>, id: EntityId) => {
  const [isEditable, setIsEditable] = useState(false);
  const dispatch = useAppDispatch();

  const handleTextChange = (e: React.KeyboardEvent<HTMLElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSubmitValue();
    }
  };

  const startEditing = () => {
    setIsEditable(true);
    // @ts-ignore
    window.getSelection().selectAllChildren(ref?.current);
  };

  const handleSubmitValue = () => {
    if (ref?.current) {
      dispatch(
        updateElement({
          id: id,
          changes: {
            value: ref?.current.innerText,
          },
        })
      );

      setIsEditable(false);
    }
  };

  return {
    isEditable,
    handleTextChange,
    startEditing,
    handleSubmitValue,
  };
};

export default useEditable;
