import { useEffect, useState } from 'react';
import { Breakpoint } from '../../../reducers/shared/types';

export const useActiveResolution = (activeBreakpoint: Breakpoint) => {
  const [activeResolution, setActiveResolution] = useState<string>('');

  useEffect(() => {
    if (
      activeBreakpoint.breakpoint > 301 &&
      activeBreakpoint.breakpoint <= 768
    ) {
      setActiveResolution('Mobile');
    } else if (
      activeBreakpoint.breakpoint > 769 &&
      activeBreakpoint.breakpoint < 1024
    ) {
      setActiveResolution('Tablet');
    } else if (activeBreakpoint.breakpoint >= 1024) {
      setActiveResolution('Desktop');
    }
  }, [activeBreakpoint]);

  return { activeResolution };
};
