import { css } from '@emotion/react';
import { useTheme } from '@mui/material';

export const useStyles = () => {
  const theme = useTheme();

  return {
    dialog: css`
      width: 460px;
      max-width: 100%;
    `,
    dialogTitle: css`
      font-weight: ${theme.typography.fontWeightBold};
    `,
    dialogContent: css`
      font-size: ${theme.typography.body1.fontSize};
      display: flex;
      flex-direction: column;
      gap: ${theme.spacing(2)};
    `,
    dialogButtons: css`
      padding: ${theme.spacing(0, 3, 3)};

      & > :not(:first-of-type) {
        margin-left: ${theme.spacing(2)};
      }
    `,
  };
};
