import ConfirmationModal from '@brame/components/src/components/ConfirmationModal/ConfirmationModal';
import { CampaignsList } from '../../components/CampaignsList';
import { useCampaigns } from './useCampaigns';
import { useDeleteCampaign } from './useDeleteCampaign';
import { useCopyCampaign } from './useCopyCampaign';

const CampaignsPage = () => {
  const campaigns = useCampaigns();
  const { openConfirmationModal, onConfirm, onCancel, open, toast } =
    useDeleteCampaign();
  const { copyCampaign } = useCopyCampaign();

  if (!campaigns.length) return null;
  return (
    <>
      <CampaignsList
        campaigns={campaigns}
        onDelete={openConfirmationModal}
        onCopyCampaign={copyCampaign}
      />
      <ConfirmationModal
        isOpen={open}
        title="Removal confirmation"
        description="Do you really want to remove this campaign?"
        onConfirm={onConfirm}
        onCancel={onCancel}
      />
      {toast}
    </>
  );
};

export default CampaignsPage;
