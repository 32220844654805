import { IThemeMedia, IThemeMediaCollection } from '@brame/theme';

type IThemeMediaObject = [string, IThemeMedia];

// checks whether asset collection contains the Media under 'src' key and returns it if so
export const getCurrentAssetFromCollection = (
  src: string,
  collection: IThemeMediaCollection
): IThemeMediaObject | undefined => {
  const assetInCollection = Object.entries(collection).find(
    (item) => item[0] === src
  );
  if (assetInCollection) {
    return assetInCollection;
  }
  return;
};
