import { useCallback, useEffect } from 'react';
import { AssetScope, AssetType } from '../../../types';
import { useAppDispatch } from '../../../reducers/app/hooks';
import {
  fetchGlobalAssets,
  searchGlobalAssets,
} from '../../../reducers/assets/asyncThunks';

const useGlobalSearch = (
  query: string,
  library: AssetScope,
  tabIndex: number
) => {
  const dispatch = useAppDispatch();
  const searchAssets = useCallback(
    (query: string) => {
      const assetType = tabIndex === 0 ? AssetType.IMAGE : AssetType.VIDEO;
      if (query) {
        dispatch(
          searchGlobalAssets({
            assetType,
            query,
          })
        );
      } else {
        dispatch(fetchGlobalAssets(assetType));
      }
    },
    [dispatch, tabIndex]
  );

  useEffect(() => {
    if (library === AssetScope.GLOBAL) {
      searchAssets(query);
    }
  }, [query, searchAssets, library]);
};

export default useGlobalSearch;
