/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import React, { FC, Fragment, useRef, useState } from 'react';
import { EntityId } from '@reduxjs/toolkit';
import { PageElement, PageType } from '../../../reducers/builder';
import { Page } from './Page';
import { useStyles } from './styles';
import { GroupedPages } from './GroupedPages/';

export interface IProperties {
  pages: PageElement[];
  currentPageId: EntityId | null;
  onSelectPage: (id: EntityId) => void;
}

const PageNavigator: FC<IProperties> = ({
  pages,
  currentPageId,
  onSelectPage,
}) => {
  const styles = useStyles();

  const ref = useRef<HTMLDivElement>(null);
  const [isScrollable, setIsScrollable] = useState<boolean>(false);
  const outcomes = pages.filter((page) => page.pageType === PageType.Outcome);
  const basics = pages.filter((page) => page.pageType !== PageType.Outcome);

  const handleScroll = (e: React.WheelEvent<HTMLElement>) => {
    if (ref.current && isScrollable) {
      // @ts-ignore
      ref.current.scrollLeft += e.deltaY;
    }
  };

  return (
    <div css={styles.pageNavigator} data-testid="preview-page-navigator">
      <Fragment>
        <div
          css={styles.pageScroller}
          ref={ref}
          onMouseEnter={() => setIsScrollable(true)}
          onMouseLeave={() => setIsScrollable(false)}
          onWheel={handleScroll}
        >
          <div css={styles.pageScrollerContent}>
            {basics.map((page, index) => (
              <div key={page.id} style={{ position: 'relative' }}>
                <Page
                  key={page.id}
                  page={page}
                  index={index}
                  selected={page.id === currentPageId}
                  onSelectPage={onSelectPage}
                />
              </div>
            ))}
            {!!outcomes.length && (
              <GroupedPages
                group={outcomes}
                title="Results"
                currentPageId={currentPageId as EntityId}
                onSelectPage={onSelectPage}
              />
            )}
          </div>
        </div>
      </Fragment>
    </div>
  );
};

export default PageNavigator;
