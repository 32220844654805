import { css } from '@emotion/react';
import { useTheme } from '@mui/material';

export const useStyles = () => {
  const theme = useTheme();

  return {
    widget: css`
      margin-bottom: ${theme.spacing(1)};
      display: flex;
      flex-wrap: wrap;
      gap: ${theme.spacing(1)};
    `,
    select: css`
      text-transform: capitalize;
    `,
    menuItem: css`
      text-transform: capitalize;
    `,
    title: css`
      display: block;
      margin-bottom: ${theme.spacing(1)};
      text-transform: capitalize;
    `,
    styleControl: css`
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    `,
    widthSelect: css`
      width: 80px;
    `,
    widgetItem: css`
      width: 100%;
    `,
  };
};
