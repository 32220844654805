/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import * as React from 'react';
import { useStyles } from './style';

export const ErrorFallback = () => {
  const styles = useStyles();
  return <p css={styles.error}>Something went wrong!</p>;
};
