import { css } from '@emotion/react';
import { useTheme } from '@mui/material';

export const useStyles = (isEditable?: boolean) => {
  const theme = useTheme();
  return {
    assetContainer: css`
      position: relative;
    `,
    asset: css`
      display: block;
      width: 100%;
      cursor: pointer;
    `,
    selectedIcon: css`
      position: absolute;
      top: 8px;
      right: ${isEditable ? '36px' : '8px'};
      border-radius: 50%;
      background-color: ${theme.palette.common.white};
      color: ${theme.palette.primary.main};
    `,
  };
};
