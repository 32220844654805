import globalColors from '@brame/theme/src/globalColors';

const threePageTemplate = {
  elements: {
    ids: [
      '1',
      'page-3014f85e-79eb-46a5-a8df-c09703cfe062',
      'phasergame-f1605cc4-5583-40e8-8097-9300e36c7511',
      'page-273a648b-ba91-4d7b-8d21-680f73788757',
      'image-b511904e-dc71-4fdd-a70f-ced59a7fc938',
      'button-675100ae-5d54-49fd-8636-55f6e0ee0abc',
      'image-8a6add0e-359f-4f4b-b343-faad1583568f',
      'button-4bdbf9d6-72e2-45c0-ba89-1bef6e9bedae',
      'text-b77c3c2c-3686-44da-84fa-76277128b2ae',
      'text-67eecdb8-fd5c-482f-b384-3f4b705d8cbd',
      'button-b10aecf8-3d9f-482e-bc71-117cf7f4727d',
      'page-6a095123-2d3d-4216-b60f-eaf8c84cce72',
      'form-db802cfe-a920-424a-894d-4fce7f1df98d',
      'button-185ba474-8295-47b5-8c25-18e6e6579e7c',
    ],
    entities: {
      '1': {
        childrenIds: [
          'image-b511904e-dc71-4fdd-a70f-ced59a7fc938',
          'text-b77c3c2c-3686-44da-84fa-76277128b2ae',
          'button-675100ae-5d54-49fd-8636-55f6e0ee0abc',
        ],
        styles: {
          defaults: {
            position: 'relative',
            size: {
              width: {
                value: 100,
                unit: '%',
              },
              height: {
                value: 800,
                unit: 'px',
              },
            },
            flexWrap: 'wrap',
            background: {
              color: globalColors.transparent,
              videoUrl: '',
              image: '',
            },
            internalAlignment: {
              alignItems: 'center',
              textAlign: 'left',
              flexDirection: 'column',
              justifyContent: 'start',
            },
            display: 'flex',
          },
          overrides: {
            position: 'relative',
            size: {
              width: {
                value: 100,
                unit: '%',
              },
            },
            flexWrap: 'wrap',
            background: {
              themedImage: 'default background',
              image: '',
              color: globalColors.transparent,
              videoUrl: '',
              asset: null,
              themedVideo: '',
            },
            internalAlignment: {
              alignItems: 'center',
              textAlign: 'left',
              flexDirection: 'column',
              justifyContent: 'start',
            },
            display: 'flex',
          },
        },
        id: '1',
        type: 'root-container',
      },
      'image-8a6add0e-359f-4f4b-b343-faad1583568f': {
        src: '',
        themedImage: '',
        styles: {
          defaults: {
            border: {
              borderRadius: {
                value: 0,
                unit: 'px',
              },
            },
            rotate: 0,
            alignSelf: 'center',
            size: {
              width: {
                value: 400,
                unit: 'px',
              },
              height: {
                value: 400,
                unit: 'px',
              },
            },
            objectFit: 'contain',
            opacity: 100,
            flip: {
              horizontal: false,
              vertical: false,
            },
          },
          overrides: {},
        },
        id: 'image-8a6add0e-359f-4f4b-b343-faad1583568f',
        type: 'image',
        asset: {
          name: 'asset-35',
          type: 'photo',
          uuid: 'asset-photo-8b955b97-7aa3-424f-b237-f34f702a4284',
          url: 'https://brame-global-assets.s3.eu-central-1.amazonaws.com/images/image-35.jpg',
          scope: 'brame',
        },
        parentId: 'page-273a648b-ba91-4d7b-8d21-680f73788757',
      },
      'form-db802cfe-a920-424a-894d-4fce7f1df98d': {
        styles: {
          defaults: {
            input: {
              variant: 'outlined',
              border: {
                borderRadius: {
                  value: 0,
                  unit: 'px',
                },
              },
              boxShadow: null,
              size: {
                width: {
                  value: 100,
                  unit: '%',
                },
                height: {
                  value: 100,
                  unit: '%',
                },
              },
              background: globalColors.transparent,
            },
            spacing: {
              padding: {
                right: {
                  value: 10,
                  unit: 'px',
                },
                top: {
                  value: 10,
                  unit: 'px',
                },
                left: {
                  value: 10,
                  unit: 'px',
                },
                bottom: {
                  value: 10,
                  unit: 'px',
                },
              },
              margin: {
                right: {
                  value: 0,
                  unit: 'px',
                },
                top: {
                  value: 0,
                  unit: 'px',
                },
                left: {
                  value: 0,
                  unit: 'px',
                },
                bottom: {
                  value: 0,
                  unit: 'px',
                },
              },
            },
            size: {
              width: {
                value: 300,
                unit: 'px',
              },
              height: {
                value: 300,
                unit: 'auto',
              },
            },
            flexWrap: 'nowrap',
            background: {
              color: globalColors.transparent,
              videoUrl: '',
              image: '',
            },
            grid: {
              gridTemplateColumns: '1fr',
            },
            display: 'flex',
            position: 'relative',
            label: {
              checkbox: 'end',
              input: 'left',
            },
            internalAlignment: {
              alignItems: 'center',
              textAlign: 'left',
              flexDirection: 'column',
              justifyContent: 'start',
            },
          },
          overrides: {
            size: {
              width: {
                value: 364,
                unit: 'px',
              },
              height: {
                value: 300,
                unit: 'auto',
              },
            },
          },
        },
        id: 'form-db802cfe-a920-424a-894d-4fce7f1df98d',
        type: 'form',
        fields: [
          {
            name: 'firstName',
            label: 'First Name',
            type: 'text',
            value: '',
            validation: {
              errorMessage: '',
            },
          },
          {
            name: 'lastName',
            label: 'Last Name',
            type: 'text',
            value: '',
            validation: {
              errorMessage: '',
            },
          },
          {
            name: 'email',
            label: 'Email',
            type: 'email',
            value: '',
            validation: {
              errorMessage: '',
            },
          },
        ],
        parentId: 'page-6a095123-2d3d-4216-b60f-eaf8c84cce72',
      },
      'button-185ba474-8295-47b5-8c25-18e6e6579e7c': {
        formId: 'submit-form-db802cfe-a920-424a-894d-4fce7f1df98d',
        color: 'primary',
        variant: 'contained',
        action: {
          type: 'SUBMIT_FORM',
        },
        styles: {
          defaults: {
            size: {
              width: {
                value: 100,
                unit: 'px',
              },
            },
            spacing: {
              padding: {
                right: {
                  value: 16,
                  unit: 'px',
                },
                top: {
                  value: 6,
                  unit: 'px',
                },
                left: {
                  value: 16,
                  unit: 'px',
                },
                bottom: {
                  value: 6,
                  unit: 'px',
                },
              },
              margin: {
                right: {
                  value: 10,
                  unit: 'px',
                },
                top: {
                  value: 10,
                  unit: 'px',
                },
                left: {
                  value: 10,
                  unit: 'px',
                },
                bottom: {
                  value: 10,
                  unit: 'px',
                },
              },
            },
          },
          overrides: {},
        },
        id: 'button-185ba474-8295-47b5-8c25-18e6e6579e7c',
        type: 'button',
        value: 'Submit',
        parentId: 'page-6a095123-2d3d-4216-b60f-eaf8c84cce72',
      },
      'page-3014f85e-79eb-46a5-a8df-c09703cfe062': {
        childrenIds: [
          'phasergame-f1605cc4-5583-40e8-8097-9300e36c7511',
          'button-b10aecf8-3d9f-482e-bc71-117cf7f4727d',
        ],
        styles: {
          defaults: {
            position: 'relative',
            size: {
              width: {
                value: 100,
                unit: '%',
              },
            },
            flexWrap: 'wrap',
            background: {
              color: globalColors.transparent,
              videoUrl: '',
              image: '',
            },
            internalAlignment: {
              alignItems: 'center',
              textAlign: 'left',
              flexDirection: 'column',
              justifyContent: 'start',
            },
            display: 'flex',
          },
          overrides: {
            position: 'relative',
            size: {
              width: {
                value: 100,
                unit: '%',
              },
            },
            flexWrap: 'wrap',
            background: {
              themedImage: 'default background 1',
              image: '',
              color: globalColors.transparent,
              videoUrl: '',
              asset: null,
              themedVideo: '',
            },
            internalAlignment: {
              alignItems: 'center',
              textAlign: 'left',
              flexDirection: 'column',
              justifyContent: 'start',
            },
            display: 'flex',
          },
        },
        id: 'page-3014f85e-79eb-46a5-a8df-c09703cfe062',
        type: 'root-container',
      },
      'text-67eecdb8-fd5c-482f-b384-3f4b705d8cbd': {
        styles: {
          defaults: {
            decoration: [],
            spacing: {
              padding: {
                right: {
                  value: 5,
                  unit: 'px',
                },
                top: {
                  value: 5,
                  unit: 'px',
                },
                left: {
                  value: 5,
                  unit: 'px',
                },
                bottom: {
                  value: 5,
                  unit: 'px',
                },
              },
              margin: {
                right: {
                  value: 0,
                  unit: 'px',
                },
                top: {
                  value: 0,
                  unit: 'px',
                },
                left: {
                  value: 0,
                  unit: 'px',
                },
                bottom: {
                  value: 0,
                  unit: 'px',
                },
              },
            },
            position: 'relative',
          },
          overrides: {},
        },
        id: 'text-67eecdb8-fd5c-482f-b384-3f4b705d8cbd',
        type: 'text',
        value: 'Congratulations',
        parentId: 'page-273a648b-ba91-4d7b-8d21-680f73788757',
      },
      'button-b10aecf8-3d9f-482e-bc71-117cf7f4727d': {
        color: 'primary',
        variant: 'contained',
        action: {
          type: 'CHANGE_PAGE',
          target: 'NEXT',
        },
        styles: {
          defaults: {
            size: {
              width: {
                value: 100,
                unit: 'px',
              },
            },
            spacing: {
              padding: {
                right: {
                  value: 16,
                  unit: 'px',
                },
                top: {
                  value: 6,
                  unit: 'px',
                },
                left: {
                  value: 16,
                  unit: 'px',
                },
                bottom: {
                  value: 6,
                  unit: 'px',
                },
              },
              margin: {
                right: {
                  value: 10,
                  unit: 'px',
                },
                top: {
                  value: 10,
                  unit: 'px',
                },
                left: {
                  value: 10,
                  unit: 'px',
                },
                bottom: {
                  value: 10,
                  unit: 'px',
                },
              },
            },
          },
          overrides: {},
        },
        id: 'button-b10aecf8-3d9f-482e-bc71-117cf7f4727d',
        type: 'button',
        value: 'Finish the game',
        parentId: 'page-3014f85e-79eb-46a5-a8df-c09703cfe062',
      },
      'text-b77c3c2c-3686-44da-84fa-76277128b2ae': {
        styles: {
          defaults: {
            decoration: [],
            spacing: {
              padding: {
                right: {
                  value: 5,
                  unit: 'px',
                },
                top: {
                  value: 5,
                  unit: 'px',
                },
                left: {
                  value: 5,
                  unit: 'px',
                },
                bottom: {
                  value: 5,
                  unit: 'px',
                },
              },
              margin: {
                right: {
                  value: 0,
                  unit: 'px',
                },
                top: {
                  value: 0,
                  unit: 'px',
                },
                left: {
                  value: 0,
                  unit: 'px',
                },
                bottom: {
                  value: 0,
                  unit: 'px',
                },
              },
            },
            position: 'relative',
          },
          overrides: {},
        },
        id: 'text-b77c3c2c-3686-44da-84fa-76277128b2ae',
        type: 'text',
        value: 'Welcome to your first campaign\n',
        parentId: '1',
      },
      'button-675100ae-5d54-49fd-8636-55f6e0ee0abc': {
        color: 'primary',
        variant: 'contained',
        action: {
          type: 'CHANGE_PAGE',
          target: 'NEXT',
        },
        styles: {
          defaults: {
            size: {
              width: {
                value: 100,
                unit: 'px',
              },
            },
            spacing: {
              padding: {
                right: {
                  value: 16,
                  unit: 'px',
                },
                top: {
                  value: 6,
                  unit: 'px',
                },
                left: {
                  value: 16,
                  unit: 'px',
                },
                bottom: {
                  value: 6,
                  unit: 'px',
                },
              },
              margin: {
                right: {
                  value: 10,
                  unit: 'px',
                },
                top: {
                  value: 10,
                  unit: 'px',
                },
                left: {
                  value: 10,
                  unit: 'px',
                },
                bottom: {
                  value: 10,
                  unit: 'px',
                },
              },
            },
          },
          overrides: {},
        },
        id: 'button-675100ae-5d54-49fd-8636-55f6e0ee0abc',
        type: 'button',
        value: 'Play The Game',
        parentId: '1',
      },
      'page-273a648b-ba91-4d7b-8d21-680f73788757': {
        childrenIds: [
          'image-8a6add0e-359f-4f4b-b343-faad1583568f',
          'text-67eecdb8-fd5c-482f-b384-3f4b705d8cbd',
          'button-4bdbf9d6-72e2-45c0-ba89-1bef6e9bedae',
        ],
        styles: {
          defaults: {
            position: 'relative',
            size: {
              width: {
                value: 100,
                unit: '%',
              },
            },
            flexWrap: 'wrap',
            background: {
              color: globalColors.transparent,
              videoUrl: '',
              image: '',
            },
            internalAlignment: {
              alignItems: 'center',
              textAlign: 'left',
              flexDirection: 'column',
              justifyContent: 'start',
            },
            display: 'flex',
          },
          overrides: {
            position: 'relative',
            size: {
              width: {
                value: 100,
                unit: '%',
              },
            },
            flexWrap: 'wrap',
            background: {
              themedImage: 'default background 1',
              image: '',
              color: globalColors.transparent,
              videoUrl: '',
              asset: null,
              themedVideo: '',
            },
            internalAlignment: {
              alignItems: 'center',
              textAlign: 'left',
              flexDirection: 'column',
              justifyContent: 'start',
            },
            display: 'flex',
          },
        },
        id: 'page-273a648b-ba91-4d7b-8d21-680f73788757',
        type: 'root-container',
      },
      'button-4bdbf9d6-72e2-45c0-ba89-1bef6e9bedae': {
        color: 'primary',
        variant: 'contained',
        action: {
          type: 'LINK',
          openNewTab: true,
          target: 'https://brame.ch/',
        },
        styles: {
          defaults: {
            size: {
              width: {
                value: 100,
                unit: 'px',
              },
            },
            spacing: {
              padding: {
                right: {
                  value: 16,
                  unit: 'px',
                },
                top: {
                  value: 6,
                  unit: 'px',
                },
                left: {
                  value: 16,
                  unit: 'px',
                },
                bottom: {
                  value: 6,
                  unit: 'px',
                },
              },
              margin: {
                right: {
                  value: 10,
                  unit: 'px',
                },
                top: {
                  value: 10,
                  unit: 'px',
                },
                left: {
                  value: 10,
                  unit: 'px',
                },
                bottom: {
                  value: 10,
                  unit: 'px',
                },
              },
            },
          },
          overrides: {},
        },
        id: 'button-4bdbf9d6-72e2-45c0-ba89-1bef6e9bedae',
        type: 'button',
        value: 'Check out your prize',
        parentId: 'page-273a648b-ba91-4d7b-8d21-680f73788757',
      },
      'page-6a095123-2d3d-4216-b60f-eaf8c84cce72': {
        childrenIds: [
          'form-db802cfe-a920-424a-894d-4fce7f1df98d',
          'button-185ba474-8295-47b5-8c25-18e6e6579e7c',
        ],
        styles: {
          defaults: {
            position: 'relative',
            size: {
              width: {
                value: 100,
                unit: '%',
              },
            },
            flexWrap: 'wrap',
            background: {
              color: globalColors.transparent,
              videoUrl: '',
              image: '',
            },
            internalAlignment: {
              alignItems: 'center',
              textAlign: 'left',
              flexDirection: 'column',
              justifyContent: 'start',
            },
            display: 'flex',
          },
          overrides: {
            position: 'relative',
            size: {
              width: {
                value: 100,
                unit: '%',
              },
            },
            flexWrap: 'wrap',
            background: {
              themedImage: 'default background 1',
              image: '',
              color: globalColors.transparent,
              videoUrl: '',
              asset: null,
              themedVideo: '',
            },
            internalAlignment: {
              alignItems: 'center',
              textAlign: 'left',
              flexDirection: 'column',
              justifyContent: 'start',
            },
            display: 'flex',
          },
        },
        id: 'page-6a095123-2d3d-4216-b60f-eaf8c84cce72',
        type: 'root-container',
      },
      'image-b511904e-dc71-4fdd-a70f-ced59a7fc938': {
        src: '',
        themedImage: 'default logo 1',
        styles: {
          defaults: {
            border: {
              borderRadius: {
                value: 0,
                unit: 'px',
              },
            },
            rotate: 0,
            alignSelf: 'center',
            size: {
              width: {
                value: 400,
                unit: 'px',
              },
              height: {
                value: 400,
                unit: 'px',
              },
            },
            objectFit: 'contain',
            opacity: 100,
            flip: {
              horizontal: false,
              vertical: false,
            },
          },
          overrides: {
            size: {
              width: {
                value: 291,
                unit: 'px',
              },
              height: {
                value: 222,
                unit: 'px',
              },
            },
          },
        },
        id: 'image-b511904e-dc71-4fdd-a70f-ced59a7fc938',
        type: 'image',
        asset: null,
        parentId: '1',
      },
      'phasergame-f1605cc4-5583-40e8-8097-9300e36c7511': {
        styles: {
          defaults: {
            size: {
              width: {
                value: 400,
                unit: 'px',
              },
              height: {
                value: 400,
                unit: 'px',
              },
            },
          },
          overrides: {
            size: {
              width: {
                value: 449,
                unit: 'px',
              },
              height: {
                value: 454,
                unit: 'px',
              },
            },
          },
        },
        id: 'phasergame-f1605cc4-5583-40e8-8097-9300e36c7511',
        type: 'phasergame',
        parentId: 'page-3014f85e-79eb-46a5-a8df-c09703cfe062',
      },
    },
  },
  pages: {
    ids: [
      'page-1',
      'page-dde87a9b-78d2-470c-9304-d1f98ea48a58',
      'page-5876baad-aa03-403b-8218-f390970e1af1',
      'page-b4d375e7-d0f6-49e0-9efa-15f1ef1ee904',
    ],
    entities: {
      'page-5876baad-aa03-403b-8218-f390970e1af1': {
        name: 'result',
        id: 'page-5876baad-aa03-403b-8218-f390970e1af1',
        pageType: 'outcome',
        elementRoot: 'page-273a648b-ba91-4d7b-8d21-680f73788757',
      },
      'page-b4d375e7-d0f6-49e0-9efa-15f1ef1ee904': {
        name: 'lead',
        id: 'page-b4d375e7-d0f6-49e0-9efa-15f1ef1ee904',
        pageType: 'basic',
        elementRoot: 'page-6a095123-2d3d-4216-b60f-eaf8c84cce72',
      },
      'page-1': {
        name: 'intro',
        id: 'page-1',
        pageType: 'basic',
        elementRoot: '1',
      },
      'page-dde87a9b-78d2-470c-9304-d1f98ea48a58': {
        name: 'game',
        id: 'page-dde87a9b-78d2-470c-9304-d1f98ea48a58',
        pageType: 'game',
        elementRoot: 'page-3014f85e-79eb-46a5-a8df-c09703cfe062',
      },
    },
  },
  additionalPages: {
    ids: [],
    entities: {},
  },
  selectedPageId: 'page-1',
  selectedElementId: 'text-b77c3c2c-3686-44da-84fa-76277128b2ae',
  previewMode: false,
  readiness: 'ready',
  selectedTheme: 'theme1',
  game: {
    gameType: 0,
    'game.width': 400,
    'game.height': 400,
    'game.placeHolderImage':
      'https://brame-global-assets.s3.eu-central-1.amazonaws.com/placeholders/game-drop.png',
    'game.outcomes': true,
    'game.movementMode': 'followPosition',
    'game.debugSprites': false,
    'game.lifeCount': 3,
    'game.maxTime': 0,
    'game.laneCount': 5,
    'game.laneOffsetTop': 0,
    'game.laneOffsetBot': 0,
    'game.difficulty': 'Easy',
    'game.direction': 'TopBot',
    'game.initSpeed': 3,
    'game.initSpawnTime': 2000,
    'game.bombSpawnTime': 2500,
    'game.avgPlaytime': 30,
    'game.backgroundColor': globalColors.white,
    'game.collectibles': [
      {
        name: 'Cherry',
        width: 0.05,
        itemType: 'Points1',
        textureKey: 'Cherry',
        height: -1,
      },
      {
        name: 'Strawberry',
        width: 0.05,
        itemType: 'Points1',
        textureKey: 'Strawberry',
        height: -1,
      },
      {
        name: 'Orange',
        width: 0.05,
        itemType: 'Points1',
        textureKey: 'Orange',
        height: -1,
      },
      {
        name: 'Heart',
        width: 0.05,
        itemType: 'Life',
        textureKey: 'Heart',
        height: -1,
      },
      {
        name: 'Cucumber',
        width: 0.05,
        itemType: 'Bomb',
        textureKey: 'Cucumber',
        height: -1,
      },
      {
        name: 'Carrot',
        width: 0.05,
        itemType: 'Bomb',
        textureKey: 'Carrot',
        height: -1,
      },
      {
        name: 'Tomato',
        width: 0.05,
        itemType: 'Bomb',
        textureKey: 'Tomato',
        height: -1,
      },
      {
        name: 'Bomb',
        width: 0.05,
        itemType: 'Bomb',
        textureKey: 'Bomb',
        height: -1,
      },
    ],
    'visual.playerwidth': 354,
    'visual.playerheight': 305,
    'visual.playerTexture': 'Player',
    'visual.parallax': true,
    'visual.parallaxSettings': [
      {
        scrollSpeedX: 0,
        scrollSpeedY: 0,
        textureKey: 'parallax1',
      },
      {
        scrollSpeedX: 0.05,
        scrollSpeedY: 0,
        textureKey: 'parallax2',
      },
      {
        scrollSpeedX: 0.02,
        scrollSpeedY: 0,
        textureKey: 'parallax3',
      },
      {
        scrollSpeedX: 0.05,
        scrollSpeedY: 0,
        textureKey: 'parallax4',
      },
    ],
    assets: {
      Heart:
        'https://brame-global-assets.s3.eu-central-1.amazonaws.com/gameAssets/heart1.png',
      Carrot:
        'https://brame-global-assets.s3.eu-central-1.amazonaws.com/gameAssets/carrot1.png',
      Player:
        'https://brame-global-assets.s3.eu-central-1.amazonaws.com/gameAssets/basket.png',
      Cherry:
        'https://brame-global-assets.s3.eu-central-1.amazonaws.com/gameAssets/cherry1.png',
      parallax2:
        'https://brame-global-assets.s3.eu-central-1.amazonaws.com/gameAssets/grass2.png',
      parallax1:
        'https://brame-global-assets.s3.eu-central-1.amazonaws.com/gameAssets/background.png',
      Tomato:
        'https://brame-global-assets.s3.eu-central-1.amazonaws.com/gameAssets/tomato1.png',
      Orange:
        'https://brame-global-assets.s3.eu-central-1.amazonaws.com/gameAssets/orange.png',
      parallax4:
        'https://brame-global-assets.s3.eu-central-1.amazonaws.com/gameAssets/trees1.png',
      parallax3:
        'https://brame-global-assets.s3.eu-central-1.amazonaws.com/gameAssets/clouds2.png',
      Strawberry:
        'https://brame-global-assets.s3.eu-central-1.amazonaws.com/gameAssets/strawberry.png',
      Bomb: 'https://brame-global-assets.s3.eu-central-1.amazonaws.com/gameAssets/bomb1.png',
      Cucumber:
        'https://brame-global-assets.s3.eu-central-1.amazonaws.com/gameAssets/cucumber.png',
    },
    outcomes: [
      {
        'condition.value': 10,
        comparator: '>',
        page: 'page-9e16a6a8-9372-4fe1-b87c-39daa1d4f234',
        'value.name': 'score',
      },
    ],
    'visual.playerWidth': 0.1,
    'visual.playerHeight': -1,
  },
};

export default threePageTemplate;
