import { ToolbarCategory } from '../ToolbarCategory';
import { TextInput } from '../TextInput';
import { HtmlTextArea } from '../HtmlTextArea';
import useUserRoles from '../../hooks/useUserRoles';
import { UserRole } from '../../types';
import { IEmailTemplate } from '../../reducers/email';
import useEmailTemplateData from './useEmailTemplateData';

const EmailEditor = () => {
  const { currentTemplate, changeCurrentTemplate } = useEmailTemplateData();

  const { hasRole } = useUserRoles();

  return (
    <div data-testid="email-editor">
      {hasRole(UserRole.CREATOR) && (
        <ToolbarCategory title="Edit email">
          <HtmlTextArea
            label="HTML"
            value={currentTemplate?.html || ''}
            onChange={(html) =>
              changeCurrentTemplate({
                ...(currentTemplate as IEmailTemplate),
                html,
              })
            }
          />
        </ToolbarCategory>
      )}

      <ToolbarCategory>
        <TextInput
          label="Subject"
          value={currentTemplate?.subject || ''}
          onChange={(subject) =>
            changeCurrentTemplate({
              ...(currentTemplate as IEmailTemplate),
              subject,
            })
          }
        />
      </ToolbarCategory>
      <ToolbarCategory>
        <TextInput
          label="Sender"
          value={currentTemplate?.sender || ''}
          onChange={(sender) =>
            changeCurrentTemplate({
              ...(currentTemplate as IEmailTemplate),
              sender,
            })
          }
        />
      </ToolbarCategory>
    </div>
  );
};

export default EmailEditor;
