export const customIcons: ICustomIcons = {
  uppercase: [
    'm1.36072,9.26554l5.15751,0c0.62175,0 1.21804,0.24699 1.65769,0.68664c0.43964,0.43965 0.68663,1.03593 0.68663,1.65769c0,0.62175 -0.24699,1.21804 -0.68663,1.65768c-0.43965,0.43965 -1.03594,0.68668 -1.65769,0.68668l-5.15751,0l0,-8.90847l4.45421,0c0.55958,0 1.09624,0.22229 1.49192,0.61797c0.39568,0.39569 0.61797,0.93234 0.61797,1.49192c0,0.55958 -0.22229,1.09624 -0.61797,1.49192c-0.39568,0.39568 -0.93234,0.61797 -1.49192,0.61797',
    'm10.7966,9.26554l4.2784,0c0,0 2.0513,1.3025 2.0513,1.93407c0,0.63157 0,2.13283 0,2.75462m-6.3297,0l0,-8.90847l4.4542,0c0.5596,0 1.0963,0.22229 1.492,0.61797c0.3956,0.39569 0.6179,0.93234 0.6179,1.49192c0,0.55958 -0.2223,1.09624 -0.6179,1.49192c-0.3957,0.39568 -0.9324,0.61797 -1.492,0.61797',
  ],
  capitalize: [
    'm2.24901,9.26554l5.15751,0c0.62175,0 1.21804,0.24699 1.65769,0.68664c0.43964,0.43965 0.68663,1.03593 0.68663,1.65769c0,0.62175 -0.24699,1.21804 -0.68663,1.65768c-0.43965,0.43965 -1.03594,0.68668 -1.65769,0.68668l-5.15751,0l0,-8.90847l4.45421,0c0.55958,0 1.09624,0.22229 1.49192,0.61797c0.39568,0.39569 0.61797,0.93234 0.61797,1.49192c0,0.55958 -0.22229,1.09624 -0.61797,1.49192c-0.39568,0.39568 -0.93234,0.61797 -1.49192,0.61797',
    'm11.75099,13.66418l0,-7.11805',
    'm11.75099,13.82347l0,-4.27778c0,-0.66304 0.2634,-1.29892 0.7322,-1.76776c0.4688,-0.46884 1.1047,-0.73223 1.7678,-0.73223c0.663,0 1.2989,0.26339 1.7677,0.73223c0.4689,0.46884 0.7323,1.10472 0.7323,1.76776',
  ],
  lowercase: [
    'm10.50001,13.22953l0,-7.11805',
    'm10.50001,13.38882l0,-4.27778c0,-0.66304 0.26339,-1.29892 0.73223,-1.76776c0.46884,-0.46884 1.10475,-0.73223 1.76775,-0.73223c0.6631,0 1.2989,0.26339 1.7678,0.73223c0.4688,0.46884 0.7322,1.10472 0.7322,1.76776',
    'm3.50001,5.7704l0,7.11806',
    'm3.50001,5.61111l0,5.27778m0,0c0,0.66304 0.26339,1.29892 0.73223,1.76776c0.46884,0.46884 1.10473,0.73224 1.76777,0.73224c0.66304,0 1.29892,-0.2634 1.76776,-0.73224c0.46884,-0.46884 0.73224,-1.10472 0.73224,-1.76776c0,-0.66304 -0.2634,-1.29893 -0.73224,-1.76777c-0.46884,-0.46884 -1.10472,-0.73223 -1.76776,-0.73223c-0.66304,0 -1.29893,0.26339 -1.76777,0.73223c-0.46884,0.46884 -0.73223,1.10473 -0.73223,1.76777z',
  ],
};

export interface ICustomIcons {
  [key: string]: string[];
}
