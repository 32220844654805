import { useAppDispatch, useAppSelector } from '../../reducers/app/hooks';
import { getSelectedPage } from '../../reducers/builder/builderSlice';
import { PageType } from '../../reducers/builder';
import {
  IEmailTemplate,
  getEmailTemplates,
  setCurrentTemplate,
} from '../../reducers/email';

const useEmailEditor = () => {
  const dispatch = useAppDispatch();
  const currentPage = useAppSelector(getSelectedPage);
  const isEmailPage = currentPage?.pageType === PageType.Email;

  const templates = useAppSelector(getEmailTemplates);
  const availableTemplates: IEmailTemplate[] = Object.values(templates);

  const applyTemplate = (template: IEmailTemplate) => {
    dispatch(setCurrentTemplate(template));
  };

  return {
    isEmailPage,
    availableTemplates,
    applyTemplate,
  };
};

export default useEmailEditor;
