import { css, SerializedStyles } from '@emotion/react';
import { IStyleProps, RequireAtLeastOne } from '../../reducers/builder';
import { IBorder, IBorderCss, IBoxShadow, UnitValueType } from '../../types';

export const parseSize = (size: UnitValueType): string => {
  if (size.value !== 'auto' && size.unit !== 'auto') {
    return `${size.value}${size.unit}`;
  } else {
    return 'auto';
  }
};

export const parseBoxShadow = (bsh: IBoxShadow): string => {
  return `${bsh?.offsetX}px ${bsh?.offsetY}px ${bsh?.blurRadius}px ${bsh?.spreadRadius}px ${bsh?.color}`;
};

export const parseBorder = (border: RequireAtLeastOne<IBorder>): IBorderCss => {
  let borderStyles = {};

  if (border?.borderColor) {
    borderStyles = { ...borderStyles, borderColor: border?.borderColor };
  }

  if (border?.borderRadius) {
    borderStyles = {
      ...borderStyles,
      borderRadius: `${border?.borderRadius.value}${border?.borderRadius.unit}`,
    };
  }

  if (border?.borderStyle) {
    borderStyles = { ...borderStyles, borderStyle: border?.borderStyle };
  }

  if (border?.borderWidth) {
    borderStyles = {
      ...borderStyles,
      borderWidth: `${border?.borderWidth.value}${border?.borderWidth.unit}`,
    };
  }

  return borderStyles;
};

const addTransformStyle = (styles: any, newTransform: string) => {
  if (styles?.transform) {
    return { ...styles, transform: `${styles?.transform} ${newTransform}` };
  }
  return { ...styles, transform: newTransform };
};

const parseStyleProps = (rawStyles: IStyleProps): SerializedStyles => {
  const jointStyles = rawStyles;
  let styles = {};

  // POSITION
  if (jointStyles?.position) {
    styles = { ...styles, position: jointStyles?.position };
  }

  // SIZE (WIDGET)
  if (jointStyles?.size) {
    const { size } = jointStyles;

    // WIDTH
    if (size?.width) {
      styles = {
        ...styles,
        width: parseSize(size.width),
      };
    }

    // MAX WIDTH
    if (size?.maxWidth) {
      styles = { ...styles, maxWidth: parseSize(size.maxWidth) };
    }

    // HEIGHT
    if (size?.height) {
      styles = {
        ...styles,
        height: parseSize(size.height),
      };
    }

    // MIN WIDTH
    if (size?.minHeight) {
      styles = {
        ...styles,
        minHeight: parseSize(size.minHeight),
      };
    }
  }

  // SPACING (WIDGET)
  if (jointStyles?.spacing) {
    const { spacing } = jointStyles;

    // MARGIN
    if (spacing?.margin) {
      const { margin } = spacing;

      // MARGIN-TOP
      styles = {
        ...styles,
        marginTop: parseSize(margin.top),
      };

      //MARGIN-RIGHT
      styles = {
        ...styles,
        marginRight: parseSize(margin.right),
      };

      //MARGIN-BOTTOM
      styles = {
        ...styles,
        marginBottom: parseSize(margin.bottom),
      };

      //MARGIN-LEFT

      styles = {
        ...styles,
        marginLeft: parseSize(margin.left),
      };
    }

    // PADDING
    if (spacing?.padding) {
      const { padding } = spacing;

      // PADDING-TOP
      styles = {
        ...styles,
        paddingTop: parseSize(padding.top),
      };

      // PADDING-RIGHT
      styles = {
        ...styles,
        paddingRight: parseSize(padding.right),
      };

      // PADDING-BOTTOM
      styles = {
        ...styles,
        paddingBottom: parseSize(padding.bottom),
      };

      // PADDING-LEFT
      styles = {
        ...styles,
        paddingLeft: parseSize(padding.left),
      };
    }
  }

  // INTERNAL ALIGNMENT (WIDGET)
  if (jointStyles?.internalAlignment) {
    const { internalAlignment } = jointStyles;

    // TEXT-ALIGN
    if (internalAlignment?.textAlign) {
      styles = { ...styles, textAlign: internalAlignment.textAlign };
    }

    // FLEX-DIRECTION
    if (internalAlignment?.flexDirection) {
      styles = { ...styles, flexDirection: internalAlignment.flexDirection };
    }

    // JUSTIFY-CONTENT
    if (internalAlignment?.justifyContent) {
      styles = {
        ...styles,
        justifyContent: internalAlignment.justifyContent,
      };
    }

    // ALIGN-ITEMS
    if (internalAlignment?.alignItems) {
      styles = {
        ...styles,
        alignItems: internalAlignment.alignItems,
      };
    }
  }

  // ALIGN-SELF (WIDGET)
  if (jointStyles?.alignSelf) {
    styles = {
      ...styles,
      alignSelf: jointStyles.alignSelf,
    };
  }

  // OBJECT-FIT
  if (jointStyles?.objectFit) {
    styles = {
      ...styles,
      objectFit: jointStyles.objectFit,
    };
  }

  // BACKGROUND (WIDGET)
  if (jointStyles?.background) {
    const { background } = jointStyles;

    // BACKGROUND-COLOR
    if (background?.color) {
      styles = { ...styles, backgroundColor: background.color };
    }
  }

  // FONT PROPS (WIDGET)
  if (jointStyles?.fontProps) {
    const { fontProps } = jointStyles;

    // FONT-FAMILY
    if (fontProps?.font) {
      styles = { ...styles, fontFamily: fontProps.font.currentFont };
    }

    // FONT-SIZE
    if (fontProps?.size) {
      styles = { ...styles, fontSize: fontProps.size.currentSize };
    }

    // COLOR
    if (fontProps?.color) {
      styles = { ...styles, color: fontProps.color };
    }
  }

  //TEXT EDITOR: FONT
  if (jointStyles?.font) {
    const { font } = jointStyles;

    if (font?.fontColor) {
      styles = { ...styles, color: font.fontColor };
    }

    if (font?.fontFamily) {
      styles = { ...styles, fontFamily: font.fontFamily };
    }

    if (font?.fontSize) {
      styles = { ...styles, fontSize: font.fontSize };
    }

    if (font?.fontWeight) {
      styles = { ...styles, fontWeight: font.fontWeight };
    }

    if (font?.textTransform) {
      styles = { ...styles, textTransform: font.textTransform };
    }
  }

  // TEXT EDITOR: TEXT ALIGN
  if (jointStyles?.textAlign) {
    styles = { ...styles, textAlign: jointStyles?.textAlign };
  }

  // BORDER
  if (jointStyles?.border) {
    styles = { ...styles, ...parseBorder(jointStyles.border) };
  }

  // FLEX WRAP
  if (jointStyles?.flexWrap) {
    styles = { ...styles, flexWrap: jointStyles?.flexWrap };
  }

  // ALIGN SELF
  if (jointStyles?.alignSelf) {
    styles = { ...styles, alignSelf: jointStyles?.alignSelf };
  }

  // DISPLAY
  if (jointStyles?.display) {
    styles = { ...styles, display: jointStyles.display };
  }

  // BACKGROUND COLOR
  if (jointStyles?.backgroundColor) {
    const { backgroundColor } = jointStyles;

    styles = { ...styles, backgroundColor: backgroundColor };
  }

  //HOVER
  if (jointStyles?.hover?.backgroundColor) {
    styles = {
      ...styles,
      '&:hover': { backgroundColor: jointStyles.hover.backgroundColor },
    };
  }

  // COLOR
  if (jointStyles?.color) {
    styles = { ...styles, color: jointStyles.color };
  }

  // BORDER RADIUS
  if (jointStyles?.borderRadius) {
    styles = { ...styles, borderRadius: jointStyles?.borderRadius };
  }

  // FONT DECORATION (TEXT EDITOR)
  // BOLD
  if (
    jointStyles?.decoration?.find((decoration: string) => decoration === 'bold')
  ) {
    styles = { ...styles, fontWeight: 700 };
  }

  // ITALIC
  if (
    jointStyles?.decoration?.find(
      (decoration: string) => decoration === 'italic'
    )
  ) {
    styles = { ...styles, fontStyle: 'italic' };
  }

  // LINE THROUGH
  if (
    jointStyles?.decoration?.find(
      (decoration: string) => decoration === 'line-through'
    )
  ) {
    styles = { ...styles, textDecoration: 'line-through' };
  }

  // UNDERLINE
  if (
    jointStyles?.decoration?.find(
      (decoration: string) => decoration === 'underline'
    )
  ) {
    styles = { ...styles, textDecoration: 'underline' };
  }

  // OPACITY
  if (jointStyles?.opacity || jointStyles?.opacity === 0) {
    styles = { ...styles, filter: `opacity(${jointStyles?.opacity / 100})` };
  }

  // ROTATION
  if (jointStyles?.rotate) {
    styles = addTransformStyle(styles, `rotate(${jointStyles?.rotate}deg)`);
  }

  // FLIP
  if (jointStyles?.flip) {
    const { flip } = jointStyles;

    if (flip?.horizontal) {
      styles = addTransformStyle(styles, 'scaleX(-1)');
    }

    if (flip?.vertical) {
      styles = addTransformStyle(styles, 'scaleY(-1)');
    }
  }

  // BOX SHADOW
  if (jointStyles?.boxShadow) {
    styles = {
      ...styles,
      boxShadow: parseBoxShadow(jointStyles.boxShadow),
    };
  }

  // WORD WRAP
  if (jointStyles?.wordWrap) {
    styles = {
      ...styles,
      wordWrap: jointStyles.wordWrap,
    };
  }

  // OVERFLOW
  if (jointStyles?.overflowX) {
    styles = { ...styles, overflowX: jointStyles?.overflowX };
  }

  if (jointStyles?.zIndex) {
    styles = { ...styles, zIndex: jointStyles.zIndex };
  }

  return css(styles);
};

export default parseStyleProps;
