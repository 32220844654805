import Phaser from 'phaser';
import { ISizing } from '@brame/builder/src/types';

export function calculateScale(
  spriteW: number,
  spriteH: number,
  wScale: number,
  hScale: number
) {
  return new Phaser.Math.Vector2(wScale / spriteW, hScale / spriteH);
}

export function convertISizingToPX(
  size: ISizing | undefined,
  canvasWidth: number,
  canvasHeight: number
): Phaser.Math.Vector2 {
  const scale = new Phaser.Math.Vector2(0, 0);

  if (size?.width?.unit === 'px') {
    scale.x = size.width.value as number;
  } else if (size?.width?.unit === '%') {
    scale.x = ((size.width.value as number) / 100) * canvasWidth;
  }

  if (size?.height?.unit === 'px') {
    scale.y = size.height.value as number;
  } else if (size?.height?.unit === '%') {
    scale.y = ((size.height.value as number) / 100) * canvasHeight;
  }

  return scale;
}
