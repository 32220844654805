// Please use https://colorbase.app/colors to name colors
const globalColors = {
  white: '#ffffff',
  alabaster: '#fafafa',
  wildSand: '#f5f5f5',
  alto: '#dadada', //gray[200]
  darkAlto: '#dedede', //gray[200]
  silver: '#bcbcbc',
  lightGrey: '#d3d3d3',
  monsoon: '#898989',
  gray: '#808080', //gray[500]
  stormDust: '#666666', //gray[600]
  abbey: '#4e5252',
  emperor: '#4d4d4d',
  tundora: '#454545',
  mineShaft: '#333333',
  logCabin: '#202020',
  rangoonGreen: '#1a1a1a',
  codGray: '#0d080e',
  black: '#000000',
  tana: '#d2e4c4',
  sprout: '#c4e2ac',
  feijoa: '#a6d382',
  asparagus: '#80A168',
  deYork: '#8ccb7a',
  mantis: '#88c559',
  sushi: '#75bb3f',
  turtleGreen: '#2d3b13',
  bajaWhite: '#fff9cf',
  chenin: '#ddd26a',
  apache: '#ddbb6a',
  tan: '#cbaf86',
  downy: '#71c7c7',
  keppel: '#2db2a6',
  gulfStream: '#7acbaa',
  seaGreen: '#32985f',
  paleRose: '#ffe6ec',
  lavender: '#c685cc',
  orchid: '#d87ae0',
  poloBlue: '#859fcc',
  portage: '#82a3e2',
  viking: '#67b3dc',
  chetwodeBlue: '#8686cb',
  chetwodeBlueLight: '#8f82e2',
  mediumPurple: '#9367dc',
  mulledWine: '#4c4c66',
  azureRadiance: '#0085ff',
  lochmara: '#0071bc',
  royalBlue: '#3851dd',
  cosmos: '#f8d7da', //red[200]
  tonysPink: '#eeaa8e',
  froly: '#EC768B',
  sunglo: '#e46b6e',
  red: '#e10000', //red[800]
  crownOfThorns: '#721c24', //red[800]

  transparent: '#ffffff00',
  opacity7fGray: '#8080807f',
  opacity30Silver: '#aeaeae30',
  opacity0aStormDust: '#6666660a',
  opacity20GreenHouse: '#23470720', //grey[700]20
  opacity26Black: '#00000026',
  opacity4dBlack: '#0000004d',
  opacity60tBlack: '#00000060',
  opacity99Black: '#00000099',
};
Object.freeze(globalColors);
export default globalColors;
