import { css } from '@emotion/react';
import { useTheme } from '@mui/material';

export const useStyles = () => {
  const theme = useTheme();

  return {
    footer: css`
      display: flex;
      justify-content: center;
      align-items: flex-end;
      position: relative;
      max-width: calc(100vw - 344px);
      overflow-x: hidden;
      overflow-y: hidden;
      background: ${theme.palette.grey[100]};

      &::-webkit-scrollbar {
        display: none;
      }
    `,
    tabsContainer: css`
      width: 100%;
      height: 100px;
      background: ${theme.palette.common.white};
      border-top: 3px solid ${theme.palette.primary.main};
      box-shadow: 0px -10px 20px rgba(174, 174, 174, 0.3);
      gap: ${theme.spacing(2)};
      outline: none;
      position: relative;
    `,
    tabs: css`
      border: none;
      background: none;
      display: flex;
      position: absolute;
      top: -34px;
      left: 20px;
      height: fit-content;
      gap: ${theme.spacing(0.5)};
    `,
    tab: css`
      font-size: ${theme.typography.body1.fontSize};
      height: 31px;
      border: none;
      background: ${theme.palette.common.white};
      color: ${theme.palette.text.primary};
      border-radius: ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px
        0 0;
      padding: ${theme.spacing(1)};
      cursor: pointer;

      &.Mui-selected {
        background: ${theme.palette.primary.main};
        color: ${theme.palette.primary.contrastText};
      }
    `,
  };
};
