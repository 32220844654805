/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import React, { FC } from 'react';
import { Desktop, DeviceMobile, DeviceTablet } from 'phosphor-react';
import { Icon } from 'phosphor-react/src/lib';
import {
  InputAdornment,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  useTheme,
} from '@mui/material';
import { Breakpoint } from '../../reducers/shared/types';
import { iconSize } from '../../constants';
import { useBreakpoints } from './hooks';
import { useStyles } from './styles';

const icons: { [key: string]: Icon } = {
  Desktop: Desktop,
  Tablet: DeviceTablet,
  Mobile: DeviceMobile,
};

const Breakpoints: FC<{
  breakpoints: Breakpoint[];
  activeBreakpoint: Breakpoint;
  onSelect: (id: string) => void;
}> = ({ breakpoints, activeBreakpoint, onSelect }) => {
  const { builderWidth, handleBuilderWidthChange, activeResolution } =
    useBreakpoints(activeBreakpoint);
  const theme = useTheme();
  const styles = useStyles();

  const isActive = (id: string) => {
    return activeBreakpoint.id === id;
  };

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <ToggleButtonGroup
        className={
          activeBreakpoint.id === activeResolution ? 'Mui-selected' : ''
        }
        value={activeBreakpoint.id}
        exclusive
        onChange={(e, value) => !!value && onSelect(value)}
      >
        {breakpoints.map(({ breakpoint, id }) => {
          // @ts-ignore
          const Icon = icons[id];
          const color = isActive(id)
            ? theme.palette.primary.contrastText
            : theme.palette.text.primary;

          return (
            <ToggleButton key={id} value={id} title={id} css={styles.button}>
              {Icon ? (
                <Icon color={color} weight="light" size={iconSize} />
              ) : (
                <span style={{ color }}>{breakpoint}</span>
              )}
            </ToggleButton>
          );
        })}
      </ToggleButtonGroup>
      <TextField
        variant="outlined"
        value={builderWidth}
        onChange={(e) => handleBuilderWidthChange(Number(e.target.value))}
        size="small"
        sx={{
          width: 100,
          marginLeft: 2,
        }}
        InputProps={{
          endAdornment: <InputAdornment position="end">px</InputAdornment>,
        }}
      />
    </div>
  );
};

export default Breakpoints;
