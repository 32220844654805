/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { ActionCreators } from 'redux-undo';
import { ArrowArcLeft, ArrowArcRight } from 'phosphor-react';
import { IconButton } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../reducers/app/hooks';
import {
  getFutureStateLength,
  getPastStateLength,
} from '../../reducers/builder/builderSlice';
import { iconSize } from '../../constants';
import { useStyles } from './styles';

const HistoryButtons = () => {
  const dispatch = useAppDispatch();
  const styles = useStyles();
  const noPastChanges = !useAppSelector(getPastStateLength);
  const noFutureChanges = !useAppSelector(getFutureStateLength);

  return (
    <div css={styles.historyButtons}>
      <IconButton
        title="Undo"
        onClick={() => {
          dispatch(ActionCreators.undo());
        }}
        disabled={noPastChanges}
      >
        <ArrowArcLeft size={iconSize} />
      </IconButton>
      <IconButton
        title="Redo"
        onClick={() => dispatch(ActionCreators.redo())}
        disabled={noFutureChanges}
      >
        <ArrowArcRight size={iconSize} />
      </IconButton>
    </div>
  );
};

export default HistoryButtons;
