import Phaser from 'phaser';
import { DropGameSettingsInterface } from '@brame/builder/src/components/GameEditors/DropGame/StateDefaults/DropGameInterface';
import { calculateScale, convertISizingToPX } from '../../ScalingManager';
import {
  getValue,
  initState,
  registerPhaserFunction,
  stateParams,
  updateStoreValue,
} from '../PhaserGameHooks';
import {
  collectCollectible,
  CollectiblePool,
  initCollectiblePools,
  initProbabilityArray,
  missCollectible,
  spawnBomb,
  spawnLife,
  spawnPoints,
} from './Collectibles';
import { resetDifficulty, updateDifficulty } from './DifficultyManager';
import {
  drawDebugLanes,
  initBoardSpawnPosition,
  initLaneSpawnPositions,
} from './GameBoard';
import { endGame, resetGame } from './GameStateManager';
import { MovementFunction, initializeMovement } from './MovementFunctions';
import { initParallax, updateParallax } from './ParallaxManager';
import { initTutorial } from './Tutorial';

export type CallbackFunction = (value: number) => void;

Phaser.GameObjects.GameObjectFactory.register('collectiblePool', function () {
  // @ts-ignore
  return this.updateList.add(new CollectiblePool(this.scene));
});

export default class DropGameScene extends Phaser.Scene {
  // Singleton ref
  private static instance: DropGameScene;
  // JSON settings
  public static jsonLink: DropGameSettingsInterface;
  // Game Settings
  public gameWidth!: number;
  public gameHeight!: number;
  public movementFunction!: MovementFunction;
  public movementDragging!: boolean;
  public laneArray = [] as Phaser.Math.Vector2[];
  public maxLives!: number;
  public matterInitSpeed!: number;
  public previewMode!: boolean;
  // Tutorial Settings
  public tutorialMode!: boolean;
  public tutorialDir!: Phaser.Math.Vector2;
  public tutorialTweens = [] as Phaser.Tweens.Tween[];
  // Direction settings
  public gameDirection!: string;
  public playerOffsetX!: number;
  public playerOffsetY!: number;
  public playerStartX!: number;
  public playerStartY!: number;
  public deathBoxWidth!: number;
  public deathBoxHeight!: number;
  public deathBoxX!: number;
  public deathBoxY!: number;
  public laneSize!: number;
  // Collectible Settings
  public collectiblePool = Object();
  public spawnChances = Object();
  // Texture arrays
  public points1Keys = [] as string[];
  public points2Keys = [] as string[];
  public points3Keys = [] as string[];
  public bombKeys = [] as string[];
  public lifeKeys = [] as string[];
  // Collision groups
  public collectibleGroup!: number;
  public playerGroup!: number;
  // Hardcoded velocity on cursor follow
  public minFollowSpeed = 250;
  // Difficulty Settings
  public avgPlayTime = 30;
  public initSpeed!: number;
  public currentSpeed!: number;
  // Points
  public spawnTimer = 32000;
  public spawnTime!: number;
  public initSpawnTime!: number;
  // Bombs
  public bombTimer = 0;
  public bombTime!: number;
  public initBombTime!: number;
  public bombSpawning = false;
  // Life
  public lifeTimer = 0;
  public lifeTime!: number;
  public lifeSpawning = false;
  // Game State
  private score = 0;
  private lifeCount!: number;
  private timerValue = 0;
  public gameEnd = false;
  public playerLaneIndex = 0;
  public playerInvul = false;
  // Game Objects
  public player!: Phaser.Physics.Matter.Sprite;
  public cursor!: Phaser.Physics.Matter.Sprite;
  public collectibleHitBox!: Phaser.Physics.Matter.Image;
  public playerParticlesManager!: Phaser.GameObjects.Particles.ParticleEmitterManager;
  public playerParticlesEmitter!: Phaser.GameObjects.Particles.ParticleEmitter;
  // Parallax
  public parallaxOn = false;
  public parallaxBackgrounds = [] as Phaser.GameObjects.TileSprite[];
  public parallaxSpeeds = [] as Phaser.Math.Vector2[];

  constructor() {
    super('DropGameScene');
  }

  public static getInstance(): DropGameScene {
    return DropGameScene.instance;
  }

  public static setInstance(scene: DropGameScene) {
    DropGameScene.instance = scene;
  }

  preload() {
    // Game Settings
    this.gameWidth = this.game.canvas.width;
    this.gameHeight = this.game.canvas.height;
    this.previewMode = DropGameScene.jsonLink['game.preview'];
    if (this.previewMode) {
      this.tutorialMode = false;
    } else {
      //@ts-ignore
      this.tutorialMode = DropGameScene.jsonLink['game.tutorial'];
    }
    // Direction Settings
    this.gameDirection = DropGameScene.jsonLink['game.direction'];
    // Game Settings
    this.maxLives = DropGameScene.jsonLink['game.lifeCount'];
    this.lifeCount = DropGameScene.jsonLink['game.lifeCount'];
    // Difficulty
    this.avgPlayTime = DropGameScene.jsonLink['game.avgPlaytime'];
    initBoardSpawnPosition(this);
    initLaneSpawnPositions(this);

    const startState: stateParams[] = [
      { storeKey: 'time', value: 0 },
      { storeKey: 'score', value: 0 },
      { storeKey: 'lives', value: this.maxLives },
    ];

    // Init store state
    initState(startState);
    registerPhaserFunction({
      id: 'playagain',
      args: [this],
      function: resetGame,
    });
  }

  getScalingValues(key: string) {
    const scale = convertISizingToPX(
      DropGameScene.jsonLink['game.collectibles'].find(
        (element) => element.textureKey === key
      )?.size,
      this.gameWidth,
      this.gameHeight
    );

    const spriteW = this.textures.get(key).get(0).width;
    const spriteH = this.textures.get(key).get(0).height;
    return calculateScale(spriteW, spriteH, scale.x, scale.y);
  }

  create() {
    // Movement Mode
    initializeMovement(this);
    // Setup Physics / Collision
    this.matter.world.disableGravity();
    this.matter.world.on(
      'collisionactive',
      (event: Phaser.Physics.Matter.Events.CollisionActiveEvent) => {
        for (const pair in event.pairs) {
          const bodyA = event.pairs[pair].bodyA;
          const bodyB = event.pairs[pair].bodyB;
          if (
            bodyA.gameObject.name === 'Cursor' ||
            bodyB.gameObject.name === 'Cursor'
          ) {
            return;
          }
          if (bodyA.gameObject.name === 'Player') {
            collectCollectible(bodyB.gameObject, this);
          } else if (bodyB.gameObject.name === 'Player') {
            collectCollectible(bodyA.gameObject, this);
          } else if (bodyA.gameObject.name === 'Deathbox') {
            missCollectible(bodyB.gameObject, this);
          } else if (bodyB.gameObject.name === 'Deathbox') {
            missCollectible(bodyA.gameObject, this);
          }
        }
      }
    );
    // Collision groups
    this.playerGroup = this.matter.world.nextGroup(true);
    this.collectibleGroup = this.matter.world.nextGroup(true);
    // Setup constant objects
    // Player
    const playerW = this.textures
      .get(DropGameScene.jsonLink['visual.playerTexture'])
      .get(0).width;
    const playerH = this.textures
      .get(DropGameScene.jsonLink['visual.playerTexture'])
      .get(0).height;
    const playerPixelValues = convertISizingToPX(
      DropGameScene.jsonLink['visual.playerSize'],
      this.gameWidth,
      this.gameHeight
    );
    const scale = calculateScale(
      playerW,
      playerH,
      playerPixelValues.x,
      playerPixelValues.y
    );
    this.player = this.matter.add
      .sprite(0, 0, DropGameScene.jsonLink['visual.playerTexture'], undefined, {
        isSensor: true,
      })
      .setScale(scale.x, scale.y)
      .setName('Player');
    this.player.setPosition(
      this.playerStartX + (this.playerOffsetX * this.player.displayWidth) / 2,
      this.playerStartY + (this.playerOffsetY * this.player.displayHeight) / 2
    );
    this.player.setCollisionGroup(this.playerGroup);
    this.player.setDepth(1);
    // Player particles
    this.playerParticlesManager = this.add.particles('');
    // Collectible Hitbox
    this.collectibleHitBox = this.matter.add.image(
      this.deathBoxX,
      this.deathBoxY,
      ''
    );
    this.collectibleHitBox
      .setVisible(false)
      .setBody({
        type: 'rectangle',
        width: this.deathBoxWidth,
        height: this.deathBoxHeight,
      })
      .setName('Deathbox');
    this.collectibleHitBox.setSensor(true);
    this.collectibleHitBox.setCollisionGroup(this.playerGroup);
    // Setup Difficulty
    resetDifficulty(this);
    // Setup Parallax
    initParallax(this);
    // Setup collectible pools
    initCollectiblePools(this);
    // Setup game board (Lanes)
    initProbabilityArray(this);
    if (this.previewMode) drawDebugLanes(this);
    if (this.tutorialMode) initTutorial(this);
    updateDifficulty(this);
  }

  update(time: number, delta: number): void {
    if (!this.gameEnd && !this.tutorialMode) {
      if (this.movementFunction) {
        this.movementFunction(this);
      }
      // Preview?
      if (!this.previewMode) {
        updateDifficulty(this);
        this.spawnTimer += delta;
        this.bombTimer += delta;
        this.lifeTimer += delta;
        this.updateValue('time', this.timerValue + delta);

        if (
          DropGameScene.jsonLink['game.maxTime'] &&
          DropGameScene.jsonLink['game.maxTime'] - this.timerValue / 1000 < 0
        ) {
          // Gameover by time
          endGame(this);
        }
        // Collectible spawning
        if (this.spawnTimer > this.spawnTime) {
          this.spawnTimer = 0;
          spawnPoints(this);
        }
        if (this.bombTimer > this.bombTime) {
          this.bombTimer = 0;
          spawnBomb(this);
        }
        if (this.lifeTimer > this.lifeTime) {
          this.lifeTimer = 0;
          spawnLife(this);
        }
      }
      // If no points use time as score
      if (!Object.keys(this.spawnChances).length) {
        this.updateValue('score', Math.floor(this.timerValue / 1000));
      }
      // Parallax Scrolling
      updateParallax(delta, this);
    }
  }

  public getLives() {
    return this.lifeCount;
  }

  public getScore() {
    return this.score;
  }

  public getTime() {
    return this.timerValue / 1000;
  }

  public updateValue(valueID: string, value: number) {
    switch (valueID) {
      case 'score':
        this.score = value;
        break;
      case 'lives':
        this.lifeCount = value;
        break;
      case 'time':
        this.timerValue = value;
        value = Math.floor(value / 1000);
        if (getValue(valueID) === value) return;
        break;
    }
    updateStoreValue(valueID, value);
  }
}
