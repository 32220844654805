/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { FC } from 'react';
import { Box, Tab, Tabs } from '@mui/material';
import { IFormProps } from '@brame/components/src/components/Form';
import { IContainerStyleProps, IFormStyleProps } from '../../types';
import { TabPanel, useTabs, useTabsStyles } from '../TabPanel';
import { FormControlsEditor } from './FormControlsEditor';
import { FormStylesEditor } from './FormStylesEditor';

interface IProps {
  props: IFormStyleProps;
  elementProps: IFormProps;
  onChange: (
    props: IFormStyleProps | IContainerStyleProps | IFormProps
  ) => void;
  onReset: () => void;
}

const FormEditor: FC<IProps> = ({ props, elementProps, onChange, onReset }) => {
  const { tabIndex, handleTabChange } = useTabs(0);
  const tabsStyles = useTabsStyles();

  return (
    <Box data-testid="form-editor" sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          aria-label="form editor tabs"
          indicatorColor="secondary"
          css={tabsStyles.tabs}
        >
          <Tab label="Settings" disableRipple css={tabsStyles.tab} />
          <Tab label="Styles" disableRipple css={tabsStyles.tab} />
        </Tabs>
      </Box>
      <TabPanel value={tabIndex} index={0} noPadding>
        <FormControlsEditor elementProps={elementProps} onChange={onChange} />
      </TabPanel>
      <TabPanel value={tabIndex} index={1} noPadding>
        <FormStylesEditor props={props} onChange={onChange} onReset={onReset} />
      </TabPanel>
    </Box>
  );
};

export default FormEditor;
