/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { FC, useState } from 'react';
import * as React from 'react';
import { EntityId } from '@reduxjs/toolkit';
import { Check, PencilSimple, TrashSimple, X } from 'phosphor-react';
import { IconButton, TextField, useTheme } from '@mui/material';
import MuiTreeItem from '@mui/lab/TreeItem';
import ConfirmationModal from '@brame/components/src/components/ConfirmationModal/ConfirmationModal';
import { RenderTree } from '../../types';
import { useFooter } from '../Footer/useFooter.hook';
import useModal from '../../hooks/useModal';
import { useAppSelector } from '../../reducers/app/hooks';
import { selectedPageId } from '../../reducers/builder/builderSlice';
import { iconSize } from '../../constants';
import { useStyles } from './styles';
import useElementTree from './useElementTree';

interface IProps {
  tree: RenderTree;
}

const TreeItem: FC<IProps> = ({ tree, ...props }) => {
  const theme = useTheme();
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [value, setValue] = useState<EntityId>(tree.name as EntityId);
  const styles = useStyles();
  const { renameTreeElement, removeTreeElement } = useElementTree();
  const { handleRenamePage } = useFooter();
  const { isModalOpen, openModal, closeModal } = useModal();
  const selectedId = useAppSelector(selectedPageId);
  const canRemoveTree = tree.type !== 'root';
  const canRemovePage = selectedId !== tree.id;

  const cancelEditing = () => {
    setIsEditing(false);
    setValue(tree.name as EntityId);
  };

  const startEditing = () => {
    setIsEditing(true);
  };

  const saveValue = () => {
    if (value) {
      if (tree.type === 'page' && tree.id) {
        handleRenamePage({ id: tree.id, name: value as string });
      } else {
        // @ts-ignore
        renameTreeElement({ id: tree.id, name: value });
      }
      setIsEditing(false);
    }
  };

  const deleteItem = () => {
    closeModal();
    removeTreeElement(tree);
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      saveValue();
    }
    if (e.key === 'Escape') {
      cancelEditing();
    }
  };

  if (!tree.id) return null;

  const treeDescription = tree.type === 'page' ? 'page' : 'element';

  return (
    <div css={styles.block} key={tree.id}>
      <div css={styles.iconsContainer}>
        <div>
          {isEditing ? (
            <IconButton onClick={saveValue} disabled={!value}>
              <Check size={iconSize} />
            </IconButton>
          ) : (
            <IconButton onClick={startEditing}>
              <PencilSimple size={iconSize} />
            </IconButton>
          )}
        </div>
        <div>
          {isEditing ? (
            <IconButton onClick={cancelEditing}>
              <X size={iconSize} />
            </IconButton>
          ) : (
            <IconButton
              disabled={!canRemoveTree || !canRemovePage}
              onClick={openModal}
            >
              <TrashSimple size={iconSize} />
            </IconButton>
          )}
        </div>
      </div>

      <MuiTreeItem
        nodeId={tree.id as string}
        label={
          isEditing ? (
            <TextField
              inputProps={{ sx: { fontSize: 'body1.fontSize' } }}
              autoFocus
              variant="standard"
              value={value}
              onKeyUp={handleKeyPress}
              onChange={(e) => setValue(e.target.value)}
            />
          ) : (
            tree.name
          )
        }
        sx={{
          '& .Mui-selected': {
            backgroundColor: `${theme.palette.primary.light} !important`,
            border: `1px solid ${theme.palette.primary.main} !important`,
          },
        }}
        ContentProps={{
          className: tree.type,
        }}
        {...props}
      />

      <ConfirmationModal
        isOpen={isModalOpen}
        title="Removal confirmation"
        description={`Do you really want to remove this ${treeDescription}?`}
        onConfirm={deleteItem}
        onCancel={closeModal}
      />
    </div>
  );
};
export default TreeItem;
