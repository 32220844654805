/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import React, { FC } from 'react';
import { TextField, Typography } from '@mui/material';
import { useStyles } from './styles';

interface IProps {
  value: string;
  onChange: (html: string) => void;
  label?: string;
}

const HtmlTextArea: FC<IProps> = ({ value, label, onChange }) => {
  const styles = useStyles();

  return (
    <div data-testid="html-text-area">
      {label && <Typography css={styles.label}>{label}</Typography>}
      <TextField
        value={value}
        minRows={5}
        data-testid="text-area-input"
        onChange={(e) => onChange(e.target.value)}
        multiline
        fullWidth
      />
    </div>
  );
};
export default HtmlTextArea;
