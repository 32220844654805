import { css } from '@emotion/react';
import { useTheme } from '@mui/material';
import { brameTheme } from '@brame/theme';

export const useFormEditorStyles = () => {
  const theme = useTheme();

  return {
    orderButton: css`
      &.MuiListItemButton-root {
        min-width: 30px;
        max-width: 30px;
        justify-content: center;
        margin: ${theme.spacing(0, 1, 0, 0)};
        padding: ${theme.spacing(1)};
        border-radius: ${brameTheme.shape.borderRadius}px;

        &:hover {
          color: ${theme.palette.primary.contrastText};
          background: ${theme.palette.primary.main};
        }
      }
    `,
    actionButton: css`
      width: 34px;
      min-width: 34px;
      height: 34px;
      background: ${theme.palette.common.white};
      color: ${theme.palette.secondary.main};
      border: none;
      border-radius: ${brameTheme.shape.borderRadius}px;
      cursor: pointer;
      padding: ${theme.spacing(0)};
      margin: ${theme.spacing(0, 0, 0, 1)};

      &:hover {
        background: ${theme.palette.primary.main};
        color: ${theme.palette.primary.contrastText};
      }
    `,
    label: css`
      text-transform: capitalize;
      margin-bottom: ${theme.spacing(1)};
    `,
    container: css`
      display: flex;
      margin-top: ${theme.spacing(1)};
    `,
    select: css`
      text-transform: capitalize;
    `,
    menuItem: css`
      text-transform: capitalize;
    `,
  };
};
