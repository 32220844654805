import { css } from '@emotion/react';
import globalColors from '@brame/theme/src/globalColors';

export const useStyles = (width: number) => ({
  wrapper: css`
    width: ${width}px;
    min-height: 920px;
    margin: 0 auto;
    overflow-y: visible;
    display: grid;
    background: ${globalColors.white};
  `,
});
