import { EntityId } from '@reduxjs/toolkit';
import { useCallback } from 'react';
import { IPageRenameModel, PageType } from '../../reducers/builder';
import {
  addPage,
  removePage,
  selectPage,
  updatePage,
} from '../../reducers/builder/builderSlice';
import { useAppDispatch } from '../../reducers/app/hooks';
import useCanvasResize from '../Toolbar/useCanvasResize';

export const useFooter = () => {
  const dispatch = useAppDispatch();
  const { canvasResponsiveness, canvasDimensions } = useCanvasResize();

  const handleRemovePage = useCallback(
    (id: EntityId) => {
      dispatch(removePage(id));
    },
    [dispatch]
  );

  const handleAddPage = useCallback(
    (type: PageType) => {
      dispatch(
        addPage({
          pageType: type,
          ...(!canvasResponsiveness && { dimensions: canvasDimensions }),
        })
      );
    },
    [dispatch, canvasResponsiveness, canvasDimensions]
  );

  const handleSelectPage = useCallback(
    (id: EntityId) => {
      dispatch(selectPage(id));
    },
    [dispatch]
  );

  const handleRenamePage = useCallback(
    (data: IPageRenameModel) => {
      dispatch(updatePage({ id: data.id, changes: { name: data.name } }));
    },
    [dispatch]
  );

  return {
    handleRemovePage,
    handleAddPage,
    handleSelectPage,
    handleRenamePage,
  } as const;
};
