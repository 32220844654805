import React, { FC } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { callPhaserFunction } from '../../phaser/games/PhaserGameHooks';
import { Button } from '../Button';
import { IPhaserButtonProps } from './IProps';

const PhaserButton: FC<IPhaserButtonProps> = React.forwardRef(
  ({ styles, value, elementStyles, builderMode, functionID, ...rest }, ref) => {
    const { formId, currentTheme, ...props } = rest;
    const handleClick = () => {
      //@ts-ignore
      callPhaserFunction(functionID);
    };
    return <Button onClick={handleClick} ref={ref} {...props} value={value} />;
  }
);

export default PhaserButton;
