import TemplateService from '../api-client/template.service';
import { useAppDispatch, useAppSelector } from '../reducers/app/hooks';
import { selectAuth } from '../reducers/auth/authSlice';
import { IBuilderTemplateData } from '../reducers/builder';
import { fetchTemplatesData } from '../reducers/templates/asyncThunks';

export const useTemplatesData = () => {
  const companyId = useAppSelector(selectAuth).parsedToken.company_id;
  const dispatch = useAppDispatch();

  const getTemplatesData = async () => {
    return await TemplateService.getTemplatesData(companyId);
  };

  const addTemplate = async (templateData: IBuilderTemplateData) => {
    await TemplateService.addTemplate(companyId as string, templateData);
    dispatch(fetchTemplatesData(companyId));
  };

  return { addTemplate, getTemplatesData };
};
