import { css } from '@emotion/react';
import { useTheme } from '@mui/material';

export const useStyles = (active: boolean) => {
  const theme = useTheme();

  return {
    templateContainer: css`
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: calc(100vh - (67px + 63px + 100px));
      overflow-y: auto;
      padding: ${theme.spacing(2)};
      box-sizing: border-box;
    `,
    successText: css`
      text-align: center;
      text-transform: capitalize;
      color: ${theme.palette.primary.main};
      font-size: ${theme.typography.h4.fontSize};
      margin: ${theme.spacing(3, 0)};
      visibility: ${active ? `visible` : `hidden`};
      transition: 0.3s all ease-in-out;
    `,
    gridContainer: css`
      height: calc(100vh - (67px + 63px + 100px));
    `,
  };
};
