/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { FC } from 'react';
import { MenuItem, Select, Typography } from '@mui/material';
import { useStyles } from './styles';

interface IProps {
  value: number;
  options: any;
  label?: string;
  onChange: (value: number) => void;
}

const FontWeightInput: FC<IProps> = ({ value, label, options, onChange }) => {
  const styles = useStyles();

  return (
    <div>
      {!!options.length && (
        <div css={styles.container} data-testid="select-input-container">
          {label && <Typography css={styles.title}>{label}</Typography>}
          <Select
            value={value}
            css={styles.select}
            data-testid="select-input"
            fullWidth
            onChange={(e) => onChange(+e.target.value)}
          >
            {options.map((option: any) => (
              <MenuItem value={option.value} key={option.name}>
                {option.name}
              </MenuItem>
            ))}
          </Select>
        </div>
      )}
    </div>
  );
};

export default FontWeightInput;
