/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import React, { FC } from 'react';
import { Plus } from 'phosphor-react';
import { Box, Typography } from '@mui/material';
import CustomIcon from '../Page/CustomIcon';
import { useStyles } from './styles';

export interface IProperties {
  onAddPage: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  active: boolean;
}

const AddPageButton: FC<IProperties> = ({ onAddPage, active }) => {
  const styles = useStyles(active);

  const handleAddPage = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ): void => {
    onAddPage(e);
  };

  return (
    <Box
      css={styles.container}
      data-testid="add-page-button"
      onClick={(e) => handleAddPage(e)}
    >
      <CustomIcon Icon={Plus} />
      <Typography color="primary" variant="h4" css={styles.addButtonLabel}>
        Add page
      </Typography>
    </Box>
  );
};

export default AddPageButton;
