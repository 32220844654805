import { Interpolation } from '@emotion/styled';
import { Theme } from '@emotion/react';
import { DraggableElement } from '@brame/builder/src/components/DragNDropWrapper/types';
import {
  ButtonAction,
  ParsedButtonAction,
} from '@brame/builder/src/reducers/builder';

export enum FormFieldType {
  Text = 'text',
  Email = 'email',
  Date = 'date',
  Number = 'number',
  PhoneNumber = 'phone number',
  Checkbox = 'checkbox',
  MultiSelect = 'multi select',
  SingleSelect = 'single select',
  Textarea = 'textarea',
}

export interface IFormFieldValidation {
  errorMessage?: string;
  mask?: string | RegExp;
  min?: number;
  max?: number;
  minCharacters?: number;
  maxCharacters?: number;
  rows?: number;
  required?: boolean;
  options?: string[];
}

export interface IFormField extends ParsedButtonAction {
  label: string;
  name: string;
  value: string | boolean;
  type: FormFieldType;
  isCustom?: boolean;
  isTermsAndConditions?: boolean;
  action?: ButtonAction;
  validation: IFormFieldValidation;
}

export interface IFormProps {
  fields?: IFormField[];
}

export interface IFormComponentProps extends IFormProps, DraggableElement {
  styles?: Interpolation<Theme>;
  id?: string;
}

export interface IFieldsDictionary<T> {
  [key: string]: T;
}
