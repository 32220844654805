import { css } from '@emotion/react';
import { useTheme } from '@mui/material';

export const useStyles = () => {
  const theme = useTheme();

  return {
    label: css`
      margin: ${theme.spacing(1, 0)};
    `,
    labelInput: css`
      & .MuiInputBase-root {
        flex-wrap: wrap;
        gap: ${theme.spacing(0.5)};
        padding: ${theme.spacing(1)};
      }

      & .MuiInputBase-input {
        flex: 1 0 auto;
      }
    `,
    nameInput: css`
      & .MuiFormHelperText-root {
        margin: ${theme.spacing(0.5, 0, 0)};
      }
    `,
  };
};
