import { css } from '@emotion/react';
import { brameTheme } from '@brame/theme';
import { IElementStyleProps } from '../../types';

export const useStyles = (
  selected: boolean,
  styleProps: IElementStyleProps,
  position: { top: number; left: number }
) => ({
  resizable: css`
    position: absolute !important;
    top: ${position.top}px;
    left: ${position.left}px;
    pointer-events: none;

    &::before {
      position: absolute;
      display: ${selected ? 'block' : 'none'};
      content: '';
      width: 100%;
      height: 100%;
      z-index: 3;
      inset: 0 0 0 0;
      outline: 2px solid ${brameTheme.palette.primary.main};
      pointer-events: none;
      user-select: none;
    }
  `,
});
