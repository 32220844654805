export const images = {
  ability_logo:
    'https://brame-global-assets.s3.eu-central-1.amazonaws.com/templateDefaults/ability-logo.png',
  ability_1:
    'https://brame-global-assets.s3.eu-central-1.amazonaws.com/templateDefaults/ability.jpg',
  youthful_logo:
    'https://brame-global-assets.s3.eu-central-1.amazonaws.com/templateDefaults/youthful-logo.png',
  youthful_1:
    'https://brame-global-assets.s3.eu-central-1.amazonaws.com/templateDefaults/youthful.jpg',
  consulting_1:
    'https://brame-global-assets.s3.eu-central-1.amazonaws.com/templateDefaults/consulting_1.jpg',
  consulting_2:
    'https://brame-global-assets.s3.eu-central-1.amazonaws.com/templateDefaults/consulting_2.jpg',
  consulting_logo:
    'https://brame-global-assets.s3.eu-central-1.amazonaws.com/templateDefaults/consulting_logo.png',
  fallback:
    'https://brame-global-assets.s3.eu-central-1.amazonaws.com/templateDefaults/fallback.svg',
  fallback_clear:
    'https://brame-global-assets.s3.eu-central-1.amazonaws.com/templateDefaults/fallback-clear.svg',
  concept_1:
    'https://brame-global-assets.s3.eu-central-1.amazonaws.com/templateDefaults/concept.jpg',
  concept_logo:
    'https://brame-global-assets.s3.eu-central-1.amazonaws.com/templateDefaults/conceptual_logo.svg',
  fresh_1:
    'https://brame-global-assets.s3.eu-central-1.amazonaws.com/templateDefaults/fresh.jpg',
  halloween_1:
    'https://brame-global-assets.s3.eu-central-1.amazonaws.com/templateDefaults/halloween.jpg',
  ice_1:
    'https://brame-global-assets.s3.eu-central-1.amazonaws.com/templateDefaults/ice.jpg',
  journey_1:
    'https://brame-global-assets.s3.eu-central-1.amazonaws.com/templateDefaults/jurney.jpg',
  simple_1:
    'https://brame-global-assets.s3.eu-central-1.amazonaws.com/templateDefaults/simple.jpg',
  sky_1:
    'https://brame-global-assets.s3.eu-central-1.amazonaws.com/templateDefaults/sky.jpg',
  snow_1:
    'https://brame-global-assets.s3.eu-central-1.amazonaws.com/templateDefaults/snow.jpg',
  wild_west_1:
    'https://brame-global-assets.s3.eu-central-1.amazonaws.com/templateDefaults/wild_west.jpg',
  winter_1:
    'https://brame-global-assets.s3.eu-central-1.amazonaws.com/templateDefaults/winter.jpg',
  wood_1:
    'https://brame-global-assets.s3.eu-central-1.amazonaws.com/templateDefaults/wood.jpg',
  xmas_1:
    'https://brame-global-assets.s3.eu-central-1.amazonaws.com/templateDefaults/xmas.jpg',
};
