import { useDrag, useDrop } from 'react-dnd';
import {
  RefObject,
  SyntheticEvent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { EntityId } from '@reduxjs/toolkit';
import {
  availableElementTypes,
  BuilderComponent,
  CanvasElement,
} from '../../reducers/builder';
import { useAppDispatch, useAppSelector } from '../../reducers/app/hooks';
import { addElement, moveElement } from '../../reducers/builder/builderSlice';
import {
  getSelectedElementId,
  selectElement,
  setDropIndicatorPosition,
  setDropIndicatorVisibility,
} from '../../reducers/shared/sharedSlice';
import { getDOMInfo, getRenderIndicatorPosition } from './dndHelpers';
import { DroppableElementsType } from './types';

const isContainer = (
  type: BuilderComponent
): type is BuilderComponent.ROOT_CONTAINER | BuilderComponent.CONTAINER => {
  return (
    type === BuilderComponent.ROOT_CONTAINER ||
    type === BuilderComponent.CONTAINER
  );
};

type DraggableElement = CanvasElement & {
  index?: number;
  targetId?: EntityId;
  targetIndex?: number;
  option?: { variant: string };
};

export const useDraggableElement = (
  element: CanvasElement & { index?: number }
) => {
  const dispatch = useAppDispatch();
  const [{ isDragging }, drag, dragPreview] = useDrag<
    DraggableElement,
    void,
    { isDragging: boolean }
  >(() => ({
    item: element,
    type: element.type,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    canDrag: () => element.type !== BuilderComponent.ROOT_CONTAINER,
    end: (item) => {
      dispatch(setDropIndicatorVisibility(false));
      if (!item.id) {
        dispatch(
          addElement({
            type: item.type,
            parentId: item.targetId,
            index: item.targetIndex,
            option: item.option,
          })
        );
        return;
      }
      dispatch(
        moveElement({
          id: item.id,
          parentId: item.targetId,
          switchElementIndex: item.targetIndex,
          index: item.index,
        })
      );
    },
  }));

  return { isDragging, drag, dragPreview };
};

export const useDroppableContainer = (
  parent: CanvasElement & { index: number },
  ref: RefObject<DroppableElementsType>
) => {
  const dispatch = useAppDispatch();
  const [{ canDrop, isOver }, drop] = useDrop<
    DraggableElement,
    void,
    { canDrop: boolean; isOver: boolean }
  >({
    accept: availableElementTypes,
    collect: (monitor) => ({
      isOver: monitor.isOver({ shallow: true }),
      canDrop: monitor.canDrop(),
    }),
    canDrop: (item, monitor) => {
      if (item.type === BuilderComponent.ROOT_CONTAINER) return false;

      if (!parent.id) return false;
      if (!isContainer(parent.type)) return false;

      return false;
    },
    hover: (item, monitor) => {
      if (!ref.current) return;
      //Check if hover only on current element
      if (!monitor.isOver({ shallow: true })) return;

      const hoveredItem = parent;
      const draggedItem = item;
      const dragIndex = draggedItem.index;
      const hoverIndex = hoveredItem.index;

      if (draggedItem.id === hoveredItem.id) return;

      //Container of a hovered node
      const hoveredParentNode: HTMLElement | null =
        hoveredItem.type === BuilderComponent.ROOT_CONTAINER
          ? null
          : document.getElementById(hoveredItem.parentId as string);
      const hoveredNode = document.getElementById(hoveredItem.id as string);
      const draggedNode = document.getElementById(
        draggedItem.id as string
      ) as HTMLElement;

      if (!hoveredNode) return;

      if (draggedNode) {
        //To prevent dropping a container into a child container
        draggedNode.style.pointerEvents = 'none';
      }

      // Determine rectangle on screen
      const hoverBoundingRect = hoveredNode.getBoundingClientRect();
      // Determine mouse position
      const clientOffset = monitor.getClientOffset() || { x: 0, y: 0 };

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Get horizontal middle
      const hoverMiddleX =
        (hoverBoundingRect.right - hoverBoundingRect.left) / 2;

      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      // Get pixels to the left
      const hoverClientX = clientOffset.x - hoverBoundingRect.left;

      //Flags to get new placement before or after the hovered element
      const hoveredRightSideOfTheElement = hoverClientX > hoverMiddleX;
      const hoveredLeftSideOfTheElement = hoverClientX < hoverMiddleX;

      const hoveredTopSideOfTheElement = hoverClientY < hoverMiddleY;
      const hoveredBottomSideOfTheElement = hoverClientY > hoverMiddleY;

      //Limits for a container to determine whether item should be dropped
      // into the container or order out of container
      const isCloseToTheTopBorder =
        clientOffset.y - hoverBoundingRect.top <= 10;
      const isCloseToTheBottomBorder =
        hoverBoundingRect.bottom - clientOffset.y <= 10;
      const isCloseToTheLeftBorder =
        clientOffset.x - hoverBoundingRect.left <= 10;
      const isCloseToTheRightBorder =
        hoverBoundingRect.right - clientOffset.x <= 10;

      const isTargetContainer = isContainer(hoveredItem.type);

      let targetLastElement: Element = hoveredNode;

      const inDroppableContainerArea =
        isTargetContainer &&
        ![
          isCloseToTheTopBorder,
          isCloseToTheBottomBorder,
          isCloseToTheLeftBorder,
          isCloseToTheRightBorder,
        ].some(Boolean);

      const hoveredFilteredChildren = Array.from(hoveredNode.children).filter(
        //@ts-ignore
        (node) => node.dataset.testid !== 're-resizable'
      );

      if (inDroppableContainerArea) {
        const { inFlow } = getDOMInfo(hoveredNode, hoveredNode);

        targetLastElement =
          ((inFlow && hoveredTopSideOfTheElement) ||
          (!inFlow && hoveredLeftSideOfTheElement)
            ? hoveredFilteredChildren[0]
            : hoveredFilteredChildren[hoveredFilteredChildren.length - 1]) ||
          hoveredNode;
      }

      //Get Dom Element info, whether it is in flow or not
      //In flow - vertically, not in flow - horizontally
      let parentElementOfTarget = hoveredParentNode;
      if (inDroppableContainerArea) {
        parentElementOfTarget = hoveredNode;
      } else if (isTargetContainer) {
        parentElementOfTarget = hoveredParentNode || hoveredNode;
      }
      //HoveredElement flow info
      const targetElDomInfo = getDOMInfo(
        targetLastElement as HTMLElement,
        parentElementOfTarget as HTMLElement
      ) as { inFlow: boolean };

      const inFlow = targetElDomInfo.inFlow;
      const indicatorPosition = getRenderIndicatorPosition(
        hoveredParentNode ? getDOMInfo(hoveredParentNode) : null,
        targetElDomInfo,
        hoveredTopSideOfTheElement,
        hoveredLeftSideOfTheElement
      );

      let dropTargetId = hoveredItem.id;

      if (isContainer(hoveredItem.type)) {
        if (
          [
            isCloseToTheTopBorder,
            isCloseToTheBottomBorder,
            isCloseToTheLeftBorder,
            isCloseToTheRightBorder,
          ].some(Boolean)
        ) {
          //@ts-ignore
          dropTargetId = hoveredItem.parentId;
        }
      } else {
        //@ts-ignore
        dropTargetId = hoveredItem.parentId;
      }

      const before =
        (inFlow && hoveredTopSideOfTheElement) ||
        (!inFlow && hoveredLeftSideOfTheElement);
      const after =
        (inFlow && hoveredBottomSideOfTheElement) ||
        (!inFlow && hoveredRightSideOfTheElement);

      const getTargetIndex = () => {
        const isSameParent =
          //@ts-ignore
          isTargetContainer
            ? //@ts-ignore
              hoveredItem.id === draggedItem.parentId
            : //@ts-ignore
              hoveredItem.parentId === item.parentId;

        let newIndex = hoverIndex;

        //Same parent case, just reorder elements or drop into the sibling container
        if (hoveredNode && isSameParent) {
          //Hovered node is a container
          if (isTargetContainer) {
            //By default dropping on the last position
            newIndex = before ? 0 : hoveredFilteredChildren.length - 1;

            //Hovering edge of a container to place element after or before the container
            if (
              (inFlow && (isCloseToTheTopBorder || isCloseToTheBottomBorder)) ||
              (!inFlow && (isCloseToTheLeftBorder || isCloseToTheRightBorder))
            ) {
              newIndex =
                (inFlow && isCloseToTheTopBorder) ||
                (!inFlow && isCloseToTheLeftBorder)
                  ? hoverIndex
                  : hoverIndex + 1;

              //Reordering - drag forward case - to the right or bottom
              if (typeof dragIndex !== 'undefined' && dragIndex < hoverIndex) {
                newIndex =
                  (inFlow && isCloseToTheTopBorder) ||
                  (!inFlow && isCloseToTheLeftBorder)
                    ? hoverIndex - 1
                    : hoverIndex;
              }
            }
          } else {
            //Move element out of its original container
            newIndex =
              (inFlow && hoveredBottomSideOfTheElement) ||
              (!inFlow && hoveredRightSideOfTheElement)
                ? hoverIndex + 1
                : hoverIndex;

            if (typeof dragIndex !== 'undefined' && dragIndex < hoverIndex) {
              newIndex =
                (!inFlow && hoveredLeftSideOfTheElement) ||
                (inFlow && hoveredTopSideOfTheElement)
                  ? hoverIndex - 1
                  : hoverIndex;
            }
          }
        }

        //Move element out of its original container
        //Drop into container or near the container
        if (hoveredNode && !isSameParent && isTargetContainer) {
          //By default dropping on the last position
          newIndex = before ? 0 : hoveredFilteredChildren.length;

          if (
            (inFlow && (isCloseToTheTopBorder || isCloseToTheBottomBorder)) ||
            (!inFlow && (isCloseToTheLeftBorder || isCloseToTheRightBorder))
          ) {
            newIndex =
              (inFlow && isCloseToTheTopBorder) ||
              (!inFlow && isCloseToTheLeftBorder)
                ? hoverIndex
                : hoverIndex + 1;
          }
        }

        //Move out of original container and place on some position
        if (!isSameParent && !isTargetContainer) {
          newIndex =
            (!inFlow && hoveredLeftSideOfTheElement) ||
            (inFlow && hoveredTopSideOfTheElement)
              ? hoverIndex
              : hoverIndex + 1;
        }

        return newIndex;
      };

      const targetIndex = getTargetIndex();

      //Set and show green indicator position
      dispatch(
        setDropIndicatorPosition({
          ...indicatorPosition,
          show: true,
        })
      );
      item.targetId = dropTargetId;
      item.targetIndex = targetIndex;
    },
  });

  drop(ref);

  return { canDrop, isOver };
};

export const useSelectElement = (
  element: CanvasElement
): { selected: boolean; onSelect: (e: SyntheticEvent) => void } => {
  const dispatch = useAppDispatch();
  const selectedElementId = useAppSelector(getSelectedElementId);
  const [selected, setSelected] = useState(false);

  useEffect(() => {
    setSelected(selectedElementId === element.id);
  }, [selectedElementId, element]);

  const onSelect = useCallback(
    (e: SyntheticEvent) => {
      e.stopPropagation();
      if (selected) return;
      dispatch(selectElement(element.id));
    },
    [dispatch, element, selected]
  );

  return {
    selected,
    onSelect,
  };
};
