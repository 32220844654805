//@ts-nocheck
import { ThemeOptions } from '@mui/material/styles';
import { fonts, IFontDeclaration } from '@brame/components/src/assets/fonts';
import { IElementStyleProps } from '@brame/builder/src/types';
import { ITheme } from '../types';

interface IAggProps {
  currentTheme: ITheme;
  elementStyles: IElementStyleProps;
}

const fontFaceDeclaration = (font: IFontDeclaration): string => {
  return `
    @font-face {
      font-family: ${font.fontFamily};
      font-display: auto;
      font-weight: ${font.fontWeight};
      src: url(${font.source}) format('truetype');
    }
  `;
};

export const expandThemeWithFonts = (currentTheme: ITheme): ThemeOptions => {
  const { theme } = currentTheme;
  let jointCssBaseLine = ``;

  // iterates through all fonts available to the user and aggregates @font-face declarations
  // so that theme could access all the fonts available to the user

  Object.entries(fonts).forEach((font) => {
    jointCssBaseLine += fontFaceDeclaration(font[1]);
  });

  return {
    ...theme,
    components: {
      ...theme.components,
      MuiScopedCssBaseline: { styleOverrides: { root: { jointCssBaseLine } } },
    },
  };
};

// accepts and updates specific properties of the theme

export const aggregateThemeOptions = ({
  currentTheme,
  elementStyles,
}: IAggProps): ITheme => {
  const {
    theme,
    theme: {
      palette: { primary, secondary },
      components,
      components: {
        MuiTypography,
        MuiTypography: {
          styleOverrides: typographyOverrides,
          styleOverrides: { root: typographyRoot, h2 },
        },
        MuiButton,
        MuiButton: {
          defaultProps: buttonDefaultProps,
          styleOverrides: buttonOverrides,
          styleOverrides: { root: buttonRoot },
        },
        MuiContainer,
        MuiContainer: {
          styleOverrides: containerOverrides,
          styleOverrides: { root: containerRoot },
        },
      },
    },
  } = currentTheme;

  if (elementStyles.assets) {
    currentTheme = {
      ...currentTheme,
      assets: elementStyles.assets,
    };
  }

  if (elementStyles.container) {
    if (elementStyles.container.background) {
      currentTheme = {
        ...currentTheme,
        theme: {
          ...currentTheme.theme,
          components: {
            ...currentTheme.theme.components,
            MuiContainer: {
              ...MuiContainer,
              styleOverrides: {
                ...containerOverrides,
                root: {
                  ...containerRoot,
                  background: elementStyles.container.background,
                },
              },
            },
          },
        },
      };
    }
  }

  if (elementStyles.primary) {
    currentTheme = {
      ...currentTheme,
      theme: {
        ...currentTheme.theme,
        palette: {
          ...currentTheme.theme.palette,
          primary: {
            ...primary,
            main: elementStyles.primary,
          },
        },
      },
    };
  }

  if (elementStyles.secondary) {
    currentTheme = {
      ...currentTheme,
      theme: {
        ...currentTheme.theme,
        palette: {
          ...currentTheme.theme.palette,
          secondary: {
            ...secondary,
            main: elementStyles.secondary,
          },
        },
      },
    };
  }

  if (elementStyles.h2) {
    currentTheme = {
      ...currentTheme,
      theme: {
        ...theme,
        components: {
          ...components,
          MuiTypography: {
            ...MuiTypography,
            styleOverrides: {
              ...typographyOverrides,
              h2: {
                ...h2,
                ...elementStyles.h2,
              },
            },
          },
        },
      },
    };
  }

  if (elementStyles.typographyRoot) {
    currentTheme = {
      ...currentTheme,
      theme: {
        ...theme,
        components: {
          ...components,
          MuiTypography: {
            ...MuiTypography,
            styleOverrides: {
              ...typographyOverrides,
              root: {
                ...typographyRoot,
                ...elementStyles.typographyRoot,
              },
            },
          },
        },
      },
    };
  }

  if (elementStyles.buttonRoot) {
    currentTheme = {
      ...currentTheme,
      theme: {
        ...theme,
        components: {
          ...components,
          MuiButton: {
            ...MuiButton,
            styleOverrides: {
              ...buttonOverrides,
              root: {
                ...buttonRoot,
                ...elementStyles.buttonRoot,
              },
            },
          },
        },
      },
    };
  }

  if (elementStyles.buttonProps) {
    let buttonProps = {};
    let hoverProps = {};

    if (
      elementStyles.buttonProps?.borderRadius ||
      elementStyles.buttonProps?.borderRadius === 0
    ) {
      buttonProps = {
        ...buttonProps,
        borderRadius: elementStyles.buttonProps?.borderRadius,
      };
    }

    if (elementStyles.buttonProps?.borderWidth) {
      buttonProps = {
        ...buttonProps,
        borderWidth: elementStyles.buttonProps?.borderWidth,
      };
    }

    if (elementStyles.buttonProps?.borderColor) {
      buttonProps = {
        ...buttonProps,
        borderColor: elementStyles.buttonProps?.borderColor,
      };
    }

    if (elementStyles.buttonProps?.borderStyle) {
      buttonProps = {
        ...buttonProps,
        borderStyle: elementStyles.buttonProps?.borderStyle,
      };
    }

    if (elementStyles.buttonProps?.background) {
      buttonProps = {
        ...buttonProps,
        background: elementStyles.buttonProps?.background,
      };
    }

    if (elementStyles.buttonProps?.color) {
      buttonProps = {
        ...buttonProps,
        color: elementStyles.buttonProps?.color,
      };
    }

    if (elementStyles.buttonProps?.boxShadow) {
      buttonProps = {
        ...buttonProps,
        boxShadow: elementStyles.buttonProps?.boxShadow,
      };
    }

    if (elementStyles.buttonProps?.hover) {
      if (elementStyles.buttonProps?.hover?.background) {
        hoverProps = {
          ...hoverProps,
          background: elementStyles.buttonProps?.hover?.background,
        };
      }
      if (elementStyles.buttonProps?.hover?.boxShadow) {
        hoverProps = {
          ...hoverProps,
          boxShadow: elementStyles.buttonProps?.hover?.boxShadow,
        };
      }
    }

    currentTheme = {
      ...currentTheme,
      theme: {
        ...theme,
        components: {
          ...components,
          MuiButton: {
            ...MuiButton,
            defaultProps: {
              ...buttonDefaultProps,
              variant: elementStyles.buttonProps.variant,
            },
            styleOverrides: {
              ...buttonOverrides,
              root: {
                ...buttonRoot,
                ...buttonProps,

                '&:hover': { ...buttonRoot['&:hover'], ...hoverProps },
              },
            },
          },
        },
      },
    };
  }

  return currentTheme;
};
