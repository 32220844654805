import { css } from '@emotion/react';
import { useTheme } from '@mui/material';

export const useStyles = () => {
  const theme = useTheme();
  return {
    divider: css`
      height: 100%;
      &.MuiDivider-root {
        border-right-width: 8px;
        border-style: solid;
        border-color: transparent;
      }
    `,
    droppable: css`
      &.MuiDivider-root {
        border-image: linear-gradient(
            to bottom,
            ${theme.palette.primary.main},
            rgba(0, 0, 0, 0)
          )
          1 100%;
      }
    `,
  };
};
