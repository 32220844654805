/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import React from 'react';
import { images } from '../../assets/images';
import { IImageComponentProps } from './IProps';

const Image = React.forwardRef<HTMLImageElement, IImageComponentProps>(
  (
    {
      styles,
      src,
      asset,
      alt,
      themedImage,
      currentTheme,
      elementStyles,
      ...props
    },
    ref
  ) => {
    // @ts-ignore
    const themedMedia = props?.element?.themedMedia;

    return (
      <img
        {...props}
        ref={ref}
        css={styles}
        src={src || asset?.url || themedMedia?.url || images.fallback}
        alt={alt}
      />
    );
  }
);

export default Image;
