import { css } from '@emotion/react';
import { useTheme } from '@mui/material';

export const useStyles = () => {
  const theme = useTheme();

  return {
    textarea: css`
      max-width: 100% !important;
      max-height: 246px;
      width: 100%;
      font-family: 'Lato';
    `,
    fontStylesBlock: css`
      display: grid;
      grid-template-columns: 60% 40%;
      gap: ${theme.spacing(1)};
    `,
    fontStylesBlockCell: css`
      display: flex;
      align-items: center;
      width: 100%;
    `,
  };
};
