import { FC } from 'react';
import { TextField } from '@mui/material';
import { TextFieldProps } from '@mui/material/TextField/TextField';

interface IProps {
  value: string;
  label?: string;
  onChange: (value: string) => void;
}

const TextInput: FC<IProps & Omit<TextFieldProps, 'onChange'>> = ({
  value,
  label,
  onChange,
  ...props
}) => {
  return (
    <TextField
      value={value}
      onChange={(e) => onChange(e.target.value)}
      label={label}
      type="text"
      title="title"
      variant="outlined"
      data-testid="text-input"
      InputLabelProps={{ shrink: true }}
      {...props}
    />
  );
};

export default TextInput;
