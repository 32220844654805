import { grey, monochrome, primary, red } from './colors';

export const brameTheme = {
  palette: {
    primary: {
      light: primary.light,
      main: primary.regular,
      dark: primary.hover,
      contrastText: monochrome.white,
    },
    secondary: {
      light: grey[400],
      main: grey[600],
      dark: grey[800],
      contrastText: monochrome.white,
    },
    common: {
      white: monochrome.white,
      black: monochrome.black,
    },
    text: {
      primary: grey[600],
      secondary: grey[400],
    },
    divider: grey[500],
    error: {
      light: '#FFBDBD',
      main: red[600],
      dark: red[800],
    },
    grey: {
      100: grey[100],
      200: grey[200],
      300: grey[300],
      500: grey[500],
      600: grey[600],
      700: grey[700],
      800: grey[800],
    },
  },
  shape: {
    borderRadius: 4,
  },
  typography: {
    fontWeightRegular: 400,
    fontWeightBold: 600,
    fontFamily: ['Inter', 'sans-serif'].join(','),
    fontColor: grey[700],
    h1: {
      fontWeight: 400,
      fontSize: '34px',
      lineHeight: '48px',
    },
    h2: {
      fontWeight: 400,
      fontSize: '29px',
      lineHeight: '40px',
    },
    h3: {
      fontWeight: 400,
      fontSize: '24px',
      lineHeight: '32px',
    },
    h4: {
      fontWeight: 400,
      fontSize: '19px',
      lineHeight: '24px',
    },
    h5: {
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '24px',
    },
    body1: {
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '24px',
    },
    body2: {
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '16px',
    },
    subtitle1: {
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '16px',
    },
    caption: {
      fontWeight: 400,
      fontSize: '9.72px',
      lineHeight: '16px',
    },
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          color: grey[800],
          fontWeight: 400,
          fontSize: 13,
          fontFamily: ['Roboto', 'sans-serif'].join(','),
        },
        h1: {
          fontWeight: 400,
          fontSize: 24,
        },
        h2: {
          fontWeight: 400,
          fontSize: 22,
        },
        h3: {
          fontWeight: 400,
          fontSize: 18,
        },
        h4: {
          fontWeight: 400,
          fontSize: 16,
        },
        h5: {
          fontWeight: 400,
          fontSize: 14,
        },
        h6: {
          fontWeight: 400,
          fontSize: 13,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          fontSize: 12,
          color: grey[600],
          borderRadius: 4,
        },
        notchedOutline: {
          borderColor: grey[400],
        },
        input: {
          padding: 8,
        },
        multiline: {
          padding: 0,
          borderRadius: 0,
          width: '100%',
          color: grey[600],
          backgroundColor: grey[100],
          fieldset: {
            borderColor: 'transparent',
          },
          textarea: {
            resize: 'vertical',
            maxHeight: 246,
            padding: '8px 16px',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: 14,
          fontWeight: 400,
        },
        outlined: {
          color: grey[400],
        },
        sizeMedium: {
          padding: '11px 8px',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          fieldset: {
            borderColor: `${monochrome.black}26`,
          },
        },
        select: {
          paddingTop: 8,
          paddingBottom: 8,
          paddingLeft: 8,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: 12,
          color: grey[400],
        },
      },
    },
    MuiTreeItem: {
      styleOverrides: {
        content: {
          padding: '8px 28px',
          borderRadius: 4,
          marginBottom: 3,
        },
      },
    },
  },
};
