import { css } from '@emotion/react';
import { useTheme } from '@mui/material';

export const useStyles = () => {
  const theme = useTheme();

  return {
    toggleButton: css`
      padding: ${theme.spacing(0.5)};
      transform: rotate(90deg);
      border: none;

      &.Mui-selected {
        background: none;
      }
    `,
    inputs: css`
      display: flex;
      gap: ${theme.spacing(1)};
    `,
  };
};
