/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import React, { FC, useEffect, useState } from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../reducers/app/hooks';
import {
  selectGameSettings,
  setGameParam,
} from '../../../reducers/game/gameSlice';
import { IGameBooleanWrapperProps } from './IProps';
import { useStyles } from './styles';

const GameBooleanWrapper: FC<IGameBooleanWrapperProps> = (props) => {
  const [isChecked, setIsChecked] = useState(false);
  const dispatch = useAppDispatch();
  const gameState = useAppSelector(selectGameSettings);
  const styles = useStyles();

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    let value;
    if (props.trueDefault === undefined && props.falseDefault === undefined) {
      value = checked;
    } else if (checked) {
      value = props.trueDefault;
    } else {
      value = props.falseDefault;
    }
    if (value !== undefined) {
      dispatch(
        setGameParam({
          param: props.name,
          value: value,
          index: props.index,
          arrayCategory: props.arrayCategory,
        })
      );
    }
    setIsChecked(checked);
  };
  useEffect(() => {
    if (props.arrayCategory) {
      //@ts-ignore
      setIsChecked(gameState[props.arrayCategory][props.index][props.name]);
    } else {
      //@ts-ignore
      setIsChecked(Boolean(gameState[props.name]));
    }
  }, []);

  return (
    <div data-testid="game-boolean-wrapper" css={styles.wrapper}>
      <div css={styles.header}>
        {props.headerChildren}
        <FormControlLabel
          css={styles.checkbox}
          control={<Checkbox checked={isChecked} onChange={handleChange} />}
          label={props.label}
        />
      </div>
      <div>{isChecked ? props.trueChildren : props.falseChildren}</div>
    </div>
  );
};

export default GameBooleanWrapper;
