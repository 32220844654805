import { FormikValues } from 'formik';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import LeadsService from '../../src/services/leads.service';

export const useFormSubmit = () => {
  const [sessionId, setSessionId] = useState(null);
  const { campaignId } = useParams();

  useEffect(() => {
    const getCampaignData = async () => {
      const sessionData = await LeadsService.getSessionId(campaignId).catch(
        (error) => console.error(error)
      );
      setSessionId(sessionData);
    };

    getCampaignData();
  }, [campaignId]);

  const submitForm = async (value: FormikValues) => {
    await LeadsService.submitForm(campaignId, sessionId, value);
  };

  return submitForm;
};
