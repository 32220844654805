/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import React, { FC } from 'react';
import { InputAdornment, styled, TextField } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../reducers/app/hooks';
import {
  selectGameSettings,
  setGameParam,
} from '../../../reducers/game/gameSlice';
import { IGameNumberEditorProps } from './IProps';

const StyledTextField = styled(TextField)`
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    display: none;
  }
`;

const GameNumberEditor: FC<IGameNumberEditorProps> = (props) => {
  const dispatch = useAppDispatch();
  const gameState = useAppSelector(selectGameSettings);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value =
      parseFloat(event.target.value) *
      (props.denominator ? props.denominator : 1);
    dispatch(
      setGameParam({
        param: props.name,
        value: value,
        index: props.index,
        arrayCategory: props.arrayCategory,
      })
    );
    if (props.styleProp) {
      const propertyObject = { ...props.styleProps.size };
      //@ts-ignore
      propertyObject[props.styleProp] = { value: value, unit: 'px' };
      props.onChange({
        ...props.styleProps,
        size: propertyObject,
      });
    }
  };
  let value;
  if (props.arrayCategory) {
    //@ts-ignore
    value = gameState[props.arrayCategory][props.index][props.name];
  } else {
    //@ts-ignore
    value = gameState[props.name];
  }
  value /= props.denominator ? props.denominator : 1;

  return (
    <div css={props.styles} data-testid="game-number-editor">
      {!props.unit ? (
        <TextField
          label={props.label}
          type="number"
          //@ts-ignore
          value={value}
          onChange={handleChange}
          fullWidth
        />
      ) : (
        <StyledTextField
          label={props.label}
          value={value}
          type="number"
          onChange={handleChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">{props.unit}</InputAdornment>
            ),
          }}
          fullWidth
        />
      )}
    </div>
  );
};

export default GameNumberEditor;
