import { useEffect, useMemo, useState } from 'react';
import { debounce } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../reducers/app/hooks';
import {
  getCurrentTemplate,
  IEmailTemplate,
  setCurrentTemplate,
} from '../../reducers/email';

const useEmailTemplateData = () => {
  const dispatch = useAppDispatch();
  const template = useAppSelector(getCurrentTemplate);
  const [currentTemplate, changeCurrentTemplate] =
    useState<IEmailTemplate | null>(template);

  const applyTemplate = (template: IEmailTemplate) => {
    dispatch(setCurrentTemplate(template));
  };

  const onTemplateChange = (change: IEmailTemplate) => {
    if (template) {
      const updatedTemplate = { ...template, ...change };
      applyTemplate(updatedTemplate);
    }
  };

  const saveTemplate = useMemo(() => debounce(onTemplateChange, 300), []);

  useEffect(() => {
    if (template) {
      changeCurrentTemplate(template);
    }
  }, [template]);

  useEffect(() => {
    if (currentTemplate && currentTemplate !== template) {
      saveTemplate(currentTemplate);
    }
  }, [currentTemplate]);

  return { applyTemplate, currentTemplate, changeCurrentTemplate };
};

export default useEmailTemplateData;
