/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import React, { FC, useState } from 'react';
import { Typography } from '@mui/material';
import { IDirection, ISpacing, UnitValueType } from '../../types';
import { SizeInput } from '../SizeInput';
import { LinkPropsButton } from '../LinkPropsButton';
import { useStyles } from './styles';

interface IProps {
  title?: string;
  spacing: ISpacing;
  onChange: (spacing: ISpacing) => void;
  limitMinValue?: boolean;
}

const SpacingWidget: FC<IProps> = ({
  spacing,
  onChange,
  limitMinValue,
  title,
}) => {
  const styles = useStyles();
  const [isLinked, setIsLinked] = useState({ topBot: false, leftRight: false });

  const handleChange = (direction: IDirection, value: UnitValueType) => {
    if ((direction === 'left' || direction === 'right') && isLinked.leftRight) {
      onChange({ ...spacing, left: value, right: value });
    } else if (
      (direction === 'top' || direction === 'bottom') &&
      isLinked.topBot
    ) {
      onChange({ ...spacing, top: value, bottom: value });
    } else onChange({ ...spacing, [direction]: value });
  };

  const inputProps = { min: limitMinValue ? 0 : undefined };

  return (
    <div data-testid="spacing-widget">
      <Typography css={styles.title} data-testid="title">
        {title}
      </Typography>
      <div css={styles.parent}>
        <div css={styles.container}>
          <div css={styles.inputs}>
            <SizeInput
              sizeValue={spacing.left}
              title="left"
              inputProps={inputProps}
              onChange={(value) => handleChange('left', value)}
            />
            <SizeInput
              sizeValue={spacing.right}
              title="right"
              inputProps={inputProps}
              onChange={(value) => handleChange('right', value)}
            />
          </div>
          <div>
            <LinkPropsButton
              selected={isLinked.leftRight}
              onChange={() =>
                setIsLinked((prev) => ({
                  ...isLinked,
                  leftRight: !prev.leftRight,
                }))
              }
            />
          </div>
        </div>
        <div css={styles.container}>
          <div css={styles.inputs}>
            <SizeInput
              sizeValue={spacing.top}
              title="top"
              inputProps={inputProps}
              onChange={(value) => handleChange('top', value)}
            />
            <SizeInput
              sizeValue={spacing.bottom}
              title="bottom"
              inputProps={inputProps}
              onChange={(value) => handleChange('bottom', value)}
            />
          </div>
          <div>
            <LinkPropsButton
              selected={isLinked.topBot}
              onChange={() =>
                setIsLinked((prev) => ({
                  ...isLinked,
                  topBot: !prev.topBot,
                }))
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SpacingWidget;
