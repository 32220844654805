import { useDrag, useDrop } from 'react-dnd';
import { RefObject } from 'react';
import { EntityId } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { PageElement, PageType } from '../../../reducers/builder';
import { useAppDispatch } from '../../../reducers/app/hooks';
import {
  additionalPagesSelectors,
  movePage,
  pagesSelectors,
} from '../../../reducers/builder/builderSlice';
import { isBasicPage } from '../../../reducers/builder/utils';

export type DragItem = {
  type?: PageType;
  id?: EntityId;
  index?: number;
};

const availableToDrop = (
  page: PageElement,
  newIndex: number,
  pages: PageElement[]
): boolean => {
  const oldIndex = pages.findIndex((p) => p.id === page.id);
  const isMovedAroundItself =
    oldIndex === newIndex || oldIndex + 1 === newIndex;

  return !isMovedAroundItself;
};

export const usePageDrop = (
  ref: RefObject<HTMLHRElement>,
  newIndex: number
) => {
  const dispatch = useAppDispatch();
  const basicPages = useSelector(pagesSelectors.selectAll);
  const additionalPages = useSelector(additionalPagesSelectors.selectAll);

  const pages = (pageType: PageType) => {
    return isBasicPage(pageType) ? basicPages : additionalPages;
  };
  const previewMode = false;

  const [{ canDrop, isOver }, drop] = useDrop<
    DragItem,
    void,
    { canDrop: boolean; isOver: boolean }
  >({
    accept: Object.values(PageType),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
    drop: (item) => {
      if (item.id) {
        dispatch(
          movePage({
            page: item,
            newIndex: newIndex,
          })
        );
      }
    },
    canDrop: (item) => {
      const page = item as PageElement;
      return (
        !previewMode && availableToDrop(page, newIndex, pages(page.pageType))
      );
    },
  });

  if (ref) {
    drop(ref);
  }

  return { drop: ref || drop, canDrop, isOver };
};

export const usePageDrag = (page: PageElement) => {
  const previewMode = false;

  const [{ isDragging }, drag, dragPreview] = useDrag<
    DragItem,
    void,
    { isDragging: boolean }
  >(
    () => ({
      item: page,
      type: page.pageType,
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
      canDrag: () => {
        return !previewMode;
      },
    }),
    [page]
  );

  return { isDragging, drag, dragPreview };
};
