import { AxiosError } from 'axios';
import axiosI from './axios.service';

class ThemeService {
  themesUrl = `${process.env.REACT_APP_API_URL}/campaign-configurations/api/themes`;

  fetchThemesData = async (companyId: string | undefined) => {
    const url = `${this.themesUrl}/all/${companyId}`;

    try {
      const res = await axiosI.get(url);

      if (typeof res.data === 'undefined') {
        return 'undefined';
      }
      return res.data.data;
    } catch (error) {
      const err = error as AxiosError;
      return err.response;
    }
  };
}

export default new ThemeService();
