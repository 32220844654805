/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { useFormikContext } from 'formik';
import React, { FC } from 'react';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Link,
} from '@mui/material';
import {
  IBorderCss,
  ICheckboxLabelPosition,
  IFormInputStyles,
} from '@brame/builder/src/types';
import { IFormField } from '../IProps';
import { useStyles } from './styles';

interface IProps {
  name: string;
  value: boolean;
  field: IFormField;
  inputStyles: IFormInputStyles;
  labelPosition?: ICheckboxLabelPosition;
  borderStyles: IBorderCss;
  boxShadow: string | null;
  width?: string;
  height?: string;
  fontFamily?: string;
  color?: string;
}

const CheckboxField: FC<IProps> = ({
  name,
  value,
  field,
  inputStyles,
  labelPosition,
  borderStyles,
  boxShadow,
  fontFamily,
  color,
  width,
  height,
}) => {
  const { handleChange, errors: formikErrors } = useFormikContext();
  const errors = formikErrors as any;
  const { variant, background } = inputStyles;
  const styles = useStyles(borderStyles);

  const label = () => {
    if (field.onClick) {
      return (
        <label>
          <Link onClick={field.onClick}>{field.label}</Link>
        </label>
      );
    }
    if (field.href) {
      return (
        <label>
          <Link href={field.href} target={field.target}>
            {field.label}
          </Link>
        </label>
      );
    } else return <label>{field.label}</label>;
  };

  return (
    <FormControl
      data-testid="terms-and-conditions-field"
      component="fieldset"
      variant={variant}
      required={field.validation.required}
      sx={{
        width,
        height,
        background,
        fontFamily,
      }}
    >
      <FormControlLabel
        label={label()}
        labelPlacement={labelPosition || 'end'}
        sx={{ color, fontFamily }}
        control={
          <Checkbox
            value={value}
            onChange={handleChange}
            name={name}
            size="small"
            css={styles.checkbox}
            disableRipple
          />
        }
      />
      {errors[name] && (
        <FormHelperText error>
          {field?.validation?.errorMessage || errors[name]}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default CheckboxField;
