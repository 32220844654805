import { css } from '@emotion/react';
import { useTheme } from '@mui/material';

export const useStyles = () => {
  const theme = useTheme();

  return {
    container: css`
      gap: ${theme.spacing(1)};
      display: grid;
      grid-template-columns: 1fr ${theme.spacing(4)};
      align-items: end;
    `,
    title: css`
      text-transform: capitalize;
      margin-bottom: ${theme.spacing(1)};
    `,
    parent: css`
      display: flex;
      flex-direction: column;
      gap: ${theme.spacing(1)};
    `,
    toggleButton: css`
      padding: ${theme.spacing(0.5)};
      transform: rotate(90deg);
      border: none;

      &.Mui-selected {
        background: none;
      }
    `,
    inputs: css`
      display: flex;
      gap: ${theme.spacing(1)};
    `,
  };
};
