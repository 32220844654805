import React, { FC } from 'react';
import { customIcons } from '../../assets/customIconsSvg/customIcons';
import { generateId } from '../../reducers/builder/utils';

interface IProps {
  name: string;
  fill?: string;
  stroke?: string;
  size: number;
}

const CustomIcon: FC<IProps> = ({ name, stroke, fill, size }) => {
  if (!customIcons[name]) return null;

  return (
    <svg
      data-testid={`custom-icon-${name}`}
      stroke={stroke}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 19 19"
      width={size}
      height={size}
    >
      {customIcons[name].map((path) => (
        <path
          d={path}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
          key={generateId()}
        />
      ))}
    </svg>
  );
};

export default CustomIcon;
