import { css } from '@emotion/react';

export const useStyles = () => ({
  box: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    position: relative;
    z-index: 3;
  `,
});
