/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import React, { FC } from 'react';
import { colord } from 'colord';
import {
  Typography,
  ToggleButton,
  ToggleButtonGroup,
  TextField,
  Tooltip,
} from '@mui/material';
import {
  IBorderStyleVariant,
  IButtonEditorProps,
  IElementStyleProps,
} from '../../types';
import { buttonShapes } from '../../constants';
import ColorPicker from '../ColorPicker/ColorPicker';
import { BorderStyleSwitcher } from '../BorderStyleSwitcher';
import { ColorPickerWidget } from '../ColorPickerWidget';
import { useStyles } from './styles';
import { ButtonVariantSwitcher } from './ButtonVariantSwitcher';

interface IProps {
  props: IButtonEditorProps;
  onChange: (props: IElementStyleProps) => void;
}

const ButtonPropsWidget: FC<IProps> = ({ props, onChange }) => {
  const { borderRadius, borderWidth, borderColor, borderStyle, background } =
    props;
  const styles = useStyles();

  return (
    <div css={styles.widget} data-testid="button-props-widget">
      <div css={styles.itemWrapper}>
        <div css={styles.widgetItem}>
          <Typography>Shape</Typography>
          <div css={styles.styleControl}>
            <ToggleButtonGroup
              exclusive
              value={borderRadius}
              css={styles.gap10}
              onChange={(e, value) =>
                value !== null && onChange({ borderRadius: value })
              }
            >
              {Object.entries(buttonShapes).map((shape) => (
                <ToggleButton
                  value={shape[1]}
                  css={styles.button}
                  key={shape[1]}
                  data-testid={`button-shape-${shape[0]}`}
                >
                  <Tooltip title={shape[0]}>
                    <div
                      css={styles.buttonShape}
                      style={{ borderRadius: shape[1] }}
                    />
                  </Tooltip>
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          </div>
        </div>
        <div>
          <ColorPicker
            value={background}
            alpha
            label="Color"
            onChange={(value) =>
              onChange({
                background: value,
                hover: { background: colord(value).darken(0.1).toHex() },
              })
            }
          />
        </div>
      </div>
      <div css={styles.itemWrapper}>
        <div>
          <div css={styles.widgetItem}>
            <Typography>Border Width</Typography>
            <TextField
              type="number"
              value={borderWidth}
              InputProps={{ endAdornment: 'px' }}
              onChange={(e) =>
                onChange({ borderWidth: Number(e.target.value) })
              }
            />
          </div>
        </div>
        <div>
          <div css={styles.widgetItem}>
            <Typography>Border Radius</Typography>
            <TextField
              type="number"
              value={borderRadius}
              InputProps={{ endAdornment: 'px' }}
              onChange={(e) =>
                onChange({ borderRadius: Number(e.target.value) })
              }
            />
          </div>
        </div>
      </div>
      <div css={styles.itemWrapper}>
        <div css={styles.widgetItem}>
          <ColorPickerWidget
            value={borderColor}
            title="Border Color"
            onChange={(value) => onChange({ borderColor: value })}
          />
        </div>
      </div>
      <div>
        <BorderStyleSwitcher
          value={borderStyle as IBorderStyleVariant}
          label="Border style"
          onChange={(value) => onChange({ borderStyle: value })}
        />
      </div>
      <div>
        <ButtonVariantSwitcher title="Button styles" onChange={onChange} />
      </div>
    </div>
  );
};

export default ButtonPropsWidget;
