/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import React, { FC } from 'react';
import { ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { flexAlign, FlexAlign } from '../../types';
import { useStyles } from './styles';

interface IProps {
  align: FlexAlign;
  onChange: (align: FlexAlign) => void;
}

const AlignItemsSwitcher: FC<IProps> = ({ align, onChange }) => {
  const styles = useStyles();
  return (
    <div data-testid="align-items-switcher">
      <div>
        <Typography css={styles.title}>Align items</Typography>

        <ToggleButtonGroup
          value={align}
          exclusive
          onChange={(e, value) => !!value && onChange(value)}
        >
          {flexAlign.map((item) => (
            <ToggleButton
              key={item}
              value={item}
              size="small"
              data-testid={`align-items-toggle-${item}`}
              css={styles.button}
            >
              {item}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      </div>
    </div>
  );
};

export default AlignItemsSwitcher;
