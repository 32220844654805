import { css } from '@emotion/react';

export const useStyles = () => ({
  container: css`
    display: flex;
    flex-direction: column;
    gap: 8px;
  `,
  removeIcon: css`
    width: 50px;
    height: 50px;
  `,
});
