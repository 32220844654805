import React, { FC, useState, ReactNode } from 'react';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

interface IProps {
  alertText: ReactNode;
  onChange: () => void;
}

const CollapsableAlert: FC<IProps> = ({ alertText, onChange }) => {
  const [isOpen, setIsOpen] = useState(true);
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    onChange();
    setIsOpen(false);
  };

  return (
    <Snackbar
      open={isOpen}
      autoHideDuration={12000}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      data-testid="url-snackbar"
    >
      <Alert
        onClose={handleClose}
        severity="success"
        data-testid="collapsable-alert"
      >
        {alertText}
      </Alert>
    </Snackbar>
  );
};

export default CollapsableAlert;
