import { useEffect } from 'react';

// Added empty redirect Route to be compatible with React Router
type RedirectType = {
  authenticationPath: string;
};

function Redirect({ authenticationPath }: RedirectType) {
  useEffect(() => {
    window.location.replace(authenticationPath);
  }, [authenticationPath]);

  return <div />;
}

export default Redirect;
