/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import React, { FC } from 'react';
import {
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from '@mui/material';
import { borderStyleVariants, IBorderStyleVariant } from '../../types';
import { useStyles } from './styles';

interface IProps {
  value: IBorderStyleVariant;
  onChange: (style: IBorderStyleVariant) => void;
  label?: string;
}

const BorderStyleSwitcher: FC<IProps> = ({ value, onChange, label }) => {
  const styles = useStyles();

  return (
    <div data-testid="border-style-switcher" css={styles.widget}>
      {label && <Typography>{label}</Typography>}
      <ToggleButtonGroup
        value={value}
        css={styles.buttonGroup}
        exclusive
        onChange={(e, value) => onChange(value || 'none')}
      >
        {borderStyleVariants.map((item) => (
          <ToggleButton
            value={item}
            size="small"
            key={item}
            data-testid={`text-align-toggle-${item}`}
            css={styles.button}
          >
            <Tooltip title={item}>
              <div
                css={[
                  styles.preview,
                  css`
                    border-style: ${item};
                  `,
                ]}
              />
            </Tooltip>
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </div>
  );
};

export default BorderStyleSwitcher;
