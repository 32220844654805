import { useMemo } from 'react';
import { IStyleProps } from '@brame/builder/src/reducers/builder';
import { Breakpoint } from '@brame/builder/src/reducers/shared/types';
import { getMediaQueryStyles, parseMediaQueryStyles } from './utils';

export const useMediaQueries = ({
  breakpoints,
  activeBreakpoint,
  styles,
}: {
  breakpoints?: Breakpoint[];
  activeBreakpoint?: Breakpoint;
  styles?: Record<string, IStyleProps>;
}) => {
  const mediaQueries = useMemo(
    () => (!!styles && parseMediaQueryStyles(styles)) || {},
    [styles]
  );

  const mediaQueryStyles = useMemo(
    () =>
      breakpoints && activeBreakpoint
        ? getMediaQueryStyles({
            breakpoints,
            activeBreakpoint,
            responsive: styles,
          })
        : {},
    [breakpoints, activeBreakpoint, styles]
  );

  return { mediaQueryStyles, mediaQueries } as const;
};
