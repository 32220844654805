import axios, { AxiosError, AxiosResponse } from 'axios';
import { PhotosWithTotalResults, Videos } from 'pexels';
import {
  AssetScope,
  AssetType,
  CustomError,
  IAsset,
  IAssetUpdate,
} from '../types';
import { getCustomError } from '../utils/utils';
import axiosI from './axios.service';

type GetAssetsResponse = PhotosWithTotalResults | Videos;

class AssetsService {
  baseUrl = 'https://api.pexels.com';
  brameBaseUrl = `${process.env.REACT_APP_API_URL}/asset-library/assets/global`;
  personalBaseUrl = `${process.env.REACT_APP_API_URL}/asset-library/assets/company`;
  apiKey = '563492ad6f91700001000001dfbaca34e3cc41c0998cd621e48f645e';
  config = {
    headers: {
      Authorization: this.apiKey,
    },
  };

  getUrl = (type: AssetType): string => {
    if (type === AssetType.VIDEO) {
      return `${this.baseUrl}/videos/popular`;
    } else {
      return `${this.baseUrl}/v1/curated`;
    }
  };

  getSearchUrl = (type: AssetType): string => {
    if (type === AssetType.VIDEO) {
      return `${this.baseUrl}/videos/search`;
    } else {
      return `${this.baseUrl}/v1/search`;
    }
  };

  serializeData = (data: PhotosWithTotalResults | Videos): IAsset[] => {
    if ('photos' in data) {
      return data.photos.map((asset) => ({
        assetId: asset.id,
        url: asset.src.original,
        urlSmall: asset.src.medium,
        name: `pexel-image-${asset.id}`,
      }));
    } else {
      return data.videos.map((asset) => {
        const originalVideo = asset.video_files.find(
          (file) => file.quality === 'hd'
        );
        const smallVideo = asset.video_files.find(
          (file) => file.quality === 'sd'
        );

        return {
          assetId: asset.id,
          url: originalVideo?.link || '',
          urlSmall: smallVideo?.link || '',
          name: `pexel-video-${asset.id}`,
        };
      });
    }
  };

  async getAssets(
    type: AssetType
  ): Promise<IAsset[] | AxiosResponse<unknown, any> | undefined> {
    try {
      const res = await axios.get<GetAssetsResponse>(
        this.getUrl(type),
        this.config
      );
      if (!res.data) {
        return undefined;
      }
      return this.serializeData(res.data);
    } catch (error) {
      const err = error as AxiosError;
      return err.response;
    }
  }

  async searchAssets(type: AssetType, query: string) {
    try {
      const res = await axios.get<GetAssetsResponse>(
        `${this.getSearchUrl(type)}`,
        {
          ...this.config,
          params: {
            query,
          },
        }
      );
      if (!res.data) {
        return res;
      }
      return this.serializeData(res.data);
    } catch (error) {
      const err = error as AxiosError;
      return err.response;
    }
  }

  async getBrameAssets(
    type: AssetType
  ): Promise<IAsset[] | AxiosResponse<unknown, any> | undefined> {
    try {
      const res = await axiosI.get(this.brameBaseUrl, {
        params: { type: type },
      });
      if (!res.data) {
        return res;
      }
      return res.data;
    } catch (error) {
      const err = error as AxiosError;
      return Promise.reject(err.response?.data);
    }
  }

  async getPersonalAssets(
    companyId: string,
    type: AssetType
  ): Promise<IAsset[] | AxiosResponse<unknown, any> | undefined> {
    try {
      const res = await axiosI.get(`${this.personalBaseUrl}/${companyId}`, {
        params: { type },
      });
      if (!res.data) {
        return res;
      }
      return res.data;
    } catch (error) {
      const err = error as AxiosError;
      return Promise.reject(err.response?.data);
    }
  }

  async addAsset(
    scope: AssetScope.BRAME | AssetScope.PERSONAL,
    file: File,
    base64: string | ArrayBuffer | null,
    assetType: AssetType,
    companyId: string,
    labels: string[]
  ): Promise<IAsset | AxiosResponse<unknown, any> | CustomError> {
    try {
      const res = await axiosI.post(
        scope === AssetScope.PERSONAL
          ? this.personalBaseUrl
          : this.brameBaseUrl,
        {
          companyId,
          labels: labels,
          assetType: assetType,
          data: base64,
          mimeType: file.type,
          name: file.name,
        }
      );
      if (!res.data) {
        return res;
      }
      return res.data;
    } catch (error) {
      return Promise.reject(getCustomError(error as AxiosError));
    }
  }

  async updateAsset(
    scope: AssetScope.BRAME | AssetScope.PERSONAL,
    asset: IAssetUpdate,
    companyId: string
  ): Promise<IAsset | AxiosResponse<unknown, any> | CustomError> {
    try {
      const res = await axiosI.put(
        scope === AssetScope.PERSONAL
          ? this.personalBaseUrl
          : this.brameBaseUrl,
        {
          ...asset,
          companyId,
        }
      );
      if (!res.data) {
        return res;
      }
      return res.data;
    } catch (error) {
      return Promise.reject(getCustomError(error as AxiosError));
    }
  }

  async removeAsset(
    scope: AssetScope.BRAME | AssetScope.PERSONAL,
    assetId: string | number,
    companyId: string
  ): Promise<IAsset | AxiosResponse<unknown, any> | CustomError> {
    try {
      const res = await axiosI.delete(
        scope === AssetScope.PERSONAL
          ? `${this.personalBaseUrl}/${companyId}/${assetId}`
          : `${this.brameBaseUrl}/${assetId}`
      );
      if (!res.data) {
        return res;
      }
      return res.data;
    } catch (error) {
      return Promise.reject(getCustomError(error as AxiosError));
    }
  }
}

export default new AssetsService();
