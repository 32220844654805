import { css } from '@emotion/react';
import { useTheme } from '@mui/material';

export const useStyles = () => {
  const theme = useTheme();

  return {
    tabsContainer: css`
      display: flex;
      justify-content: center;
      width: 100%;
      box-shadow: 0 5px 10px rgba(35, 71, 7, 0.15);
      margin-bottom: ${theme.spacing(2)};
    `,
    tabsList: css`
      width: 50%;
      @media (max-width: 768px) {
        width: 100%;
      }
    `,
    tabsItem: css`
      height: 63px;
    `,
    panelContainer: css`
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: calc(100vh - (67px + 63px + 100px));
    `,
    panelItem: css`
      padding: ${theme.spacing(1)};
      overflow-y: auto;
    `,
    card: css`
      height: 135px;
      box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
      cursor: pointer;

      &:hover {
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
      }
    `,
    cardContainer: css`
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      &:last-child {
        padding-bottom: ${theme.spacing(2)};
      }
    `,
    iconContainer: css`
      height: 68px;
      width: 134px;
      display: flex;
      justify-content: center;
      align-items: center;
    `,
    cardName: css`
      font-size: ${theme.typography.body1.fontSize};
      text-transform: uppercase;
      margin-top: ${theme.spacing(2)};
      text-decoration: none;
    `,
  };
};
