/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import React from 'react';
import { selectGameSettings } from '@brame/builder/src/reducers/game/gameSlice';
import { useAppSelector } from '@brame/builder/src/reducers/app/hooks';
import { Image } from '../../../components/Image';
import { PhaserComponent } from '../PhaserComponent';
import { IPhaserPlaceholderProps } from './IProps';
import { useStyles } from './styles';

const PhaserPlaceHolder = React.forwardRef<
  HTMLImageElement,
  IPhaserPlaceholderProps
>(({ styles, currentTheme, elementStyles, id, ...props }, ref) => {
  const gameState = useAppSelector(selectGameSettings);
  const localStyles = useStyles();
  if (!gameState['game.preview']) {
    return (
      <Image
        {...props}
        //@ts-ignore
        id={id}
        asset={null}
        //@ts-ignore
        styles={localStyles.box}
        ref={ref}
        //@ts-ignore
        src={gameState['game.placeHolderImage']}
      />
    );
  } else {
    return (
      <PhaserComponent
        styles={styles}
        //@ts-ignore
        ref={ref}
        id={id}
        {...props}
        json={gameState}
        gametype={gameState['gameType']}
      />
    );
  }
});

export default PhaserPlaceHolder;
