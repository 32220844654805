import { SyntheticEvent, useCallback, useState } from 'react';

const useTabs = (initialTabIndex: number) => {
  const [tabIndex, setTabIndex] = useState(initialTabIndex);

  const handleTabChange = useCallback(
    (event: SyntheticEvent, newValue: number) => {
      setTabIndex(newValue);
    },
    [setTabIndex]
  );

  return {
    tabIndex,
    setTabIndex,
    handleTabChange,
  };
};

export default useTabs;
