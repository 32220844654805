import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import undoable, { includeAction } from 'redux-undo';
import { builderSlice } from './builder';
import { activeTabSlice } from './activeTab';
import { authSlice } from './auth';
import { themeSlice } from './themes';
import listenerMiddleware from './builder/middleware';
import { sharedSlice } from './shared';
import { campaignSlice } from './campaign';
import { assetSlice } from './assets';
import { gameSlice } from './game';
import { templateSlice } from './templates';
import { analyticsSlice } from './analytics';
import { emailSlice } from './email';
import { fetchInitialStateAsync } from './builder/asyncThunks';
import {
  addElement,
  addPage,
  initHistory,
  moveElement,
  removeElement,
  removePage,
  renameElement,
  resetStyles,
  setSelectedTheme,
  updateElement,
  updatePage,
  updateStyles,
} from './builder/builderSlice';

const undoableBuilderSlice = undoable(builderSlice, {
  limit: 10,
  neverSkipReducer: true,
  ignoreInitialState: true,
  filter: includeAction([
    addElement.type,
    updateElement.type,
    moveElement.type,
    removeElement.type,
    updateStyles.type,
    resetStyles.type,
    renameElement.type,
    addPage.type,
    updatePage.type,
    removePage.type,
    setSelectedTheme.type,
    fetchInitialStateAsync.fulfilled.type,
  ]),
  initTypes: [initHistory.type],
  clearHistoryType: fetchInitialStateAsync.pending.type,
});

export const store = configureStore({
  reducer: {
    elements: undoableBuilderSlice,
    assets: assetSlice,
    activeTab: activeTabSlice,
    theme: themeSlice,
    auth: authSlice,
    shared: sharedSlice,
    campaign: campaignSlice,
    game: gameSlice,
    templates: templateSlice,
    analytics: analyticsSlice,
    email: emailSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().prepend(listenerMiddleware.middleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
