import { css } from '@emotion/react';
import { useTheme } from '@mui/material';

export const useStyles = (active: boolean) => {
  const theme = useTheme();

  return {
    container: css`
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      align-self: center;
      height: 100%;
      width: 96px;
      cursor: pointer;
      padding: ${theme.spacing(1)};
      background: ${active && theme.palette.primary.main};

      * {
        color: ${active ? theme.palette.common.white : theme.palette.primary.main};
      }

      &:hover {
        background: ${theme.palette.primary.main};

        * {
          color: ${theme.palette.common.white} !important;
        }
      }
    `,
    addButton: css`
      width: 28px;
      height: 28px;
      border-width: 1px;
      border-style: solid;
      border-radius: 5px;
      margin: ${theme.spacing(2)};
    `,
    addButtonLabel: css`
      font-size: ${theme.typography.body1.fontSize};
      text-align: center;
      color: ${active && theme.palette.common.white};
      margin: ${theme.spacing(0, 1)};
      white-space: nowrap;
    `,
  };
};
