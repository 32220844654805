import { css } from '@emotion/react';
import { useTheme } from '@mui/material';

export const useStyles = () => {
  const theme = useTheme();

  return {
    container: css`
      display: flex;
      align-items: flex-end;
      padding-top: ${theme.spacing(1)};
    `,
    requiredInput: css`
      margin: ${theme.spacing(0, -1, -1, 0)};
    `,
    select: css`
      text-transform: capitalize;
    `,
  };
};
