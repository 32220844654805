import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import pagesSlice from '../pageSlice/pagesSlice';
import gameStateSlice from '../gameStateSlice/gameStateSlice';

export const store = configureStore({
  reducer: {
    pages: pagesSlice,
    gameState: gameStateSlice,
  },
});

export type RunnerDispatch = typeof store.dispatch;
export type RunnerRootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RunnerRootState,
  unknown,
  Action<string>
>;

export const useRunnerDispatch = () => useDispatch<RunnerDispatch>();
export const useRunnerSelector: TypedUseSelectorHook<RunnerRootState> =
  useSelector;
