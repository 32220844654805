/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import React, { useEffect } from 'react';
import Phaser from 'phaser';
import { PHASER_GAME_TYPE } from '@brame/builder/src/components/GameEditors/GameToolbar/GameTypes';
import DropGameScene from '../../games/DropGame/DropGameScene';
import SlotMachineScene from '../../games/SlotMachine/SlotMachineScene';
import LoadingScene from '../../games/LoadingScene/LoadingScene';
import { IPhaserElementProps } from './IProps';

const PhaserComponent = React.forwardRef<
  HTMLCanvasElement,
  IPhaserElementProps
>(({ styles, id, ...props }, ref) => {
  useEffect(() => {
    let scene: Phaser.Scene;
    const loadScene = new LoadingScene();
    LoadingScene.assetLink = props.json.assets;
    switch (props.gametype) {
      case PHASER_GAME_TYPE.FruitDrop:
        scene = new DropGameScene();
        DropGameScene.setInstance(scene as DropGameScene);
        DropGameScene.jsonLink = props.json;
        LoadingScene.nextScene = 'DropGameScene';
        break;
      default:
        scene = new SlotMachineScene();
        SlotMachineScene.setInstance(scene as SlotMachineScene);
        SlotMachineScene.jsonLink = props.json;
        LoadingScene.nextScene = 'SlotMachineScene';
        break;
    }
    const iframe = document.getElementById(
      'preview-iframe'
    ) as HTMLIFrameElement;
    let canvas = document.getElementById(id.toString()) as HTMLCanvasElement;
    if (iframe) {
      //@ts-ignore
      canvas = iframe?.contentWindow?.document.getElementById(
        id.toString()
      ) as HTMLCanvasElement;
    }

    let width, height;
    if (props.json['game.size'].width.unit === '%') {
      width =
        (props.json['game.size'].width.value / 100) *
        //@ts-ignore
        canvas.parentElement?.offsetWidth;
    } else {
      width = props.json['game.size'].width.value;
    }

    if (props.json['game.size'].height.unit === '%') {
      height =
        (props.json['game.size'].height.value / 100) *
        //@ts-ignore
        canvas.parentElement?.offsetHeight;
    } else {
      height = props.json['game.size'].height.value;
    }

    const gameInstance = new Phaser.Game({
      type: Phaser.CANVAS,
      canvas: canvas,
      width: width,
      height: height,
      transparent: true,
      physics: {
        default: 'matter',
        matter: {
          debug: props.json['game.debugSprites'] || false,
        },
      },
      fps: {
        smoothStep: true,
      },
      scene: [loadScene, scene],
      banner: false,
    });
    return () => {
      if (gameInstance) gameInstance.destroy(false);
    };
  }, [props.json]);

  return (
    <canvas
      {...props}
      ref={ref}
      style={{ zIndex: 3 }}
      css={styles}
      id={id.toString()}
    />
  );
});

export default PhaserComponent;
