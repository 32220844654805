export const dropGame = {
  directions: ['TopBot', 'BotTop', 'LeftRight', 'RightLeft'],
  directionDescriptions: ['From top', 'From bottom', 'From left', 'From right'],
  movementModes: ['followPosition', 'followVelocity', 'laneBased', 'drag'],
  movementModeDescriptions: [
    'Follows the cursor exactly',
    'Follows the cursor with a delay',
    'Moves on Click/Tap',
    'First click then follows the cursor',
  ],
  difficulties: ['Easy', 'Medium', 'Hard'],
  comparators: ['<', '>', '=', '<=', '>='],
  comparatorDescriptions: [
    'Less than',
    'Greater than',
    'Equals',
    'Less than / Equals',
    'Greater than / Equals',
  ],
  values: ['score', 'lives', 'time'],
  valueDescriptions: ['Score', 'Lives', 'Time'],
};
