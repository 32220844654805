import { css } from '@emotion/react';

export const useStyles = () => ({
  widget: css`
    margin-bottom: 8px;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  `,
  title: css`
    margin-bottom: 8px;
    display: block;
    text-transform: capitalize;
  `,
  styleControl: css`
    display: inline-flex;
    align-items: center;
  `,
  fontSelect: css`
    width: 170px;
  `,
  sizeSelect: css`
    width: 80px;
    margin-left: 8px;
  `,
});
