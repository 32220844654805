/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import React, { FC } from 'react';
import merge from 'lodash/merge';
import useThemeData from '@brame/components/src/hooks/useThemeData';
import { IImageProps } from '@brame/components/src/components/Image';
import { IVideoProps } from '@brame/components/src/components/Video';
import { IFormProps } from '@brame/components/src/components/Form';
import { getMediaQueryStyles } from '@brame/components/src/utils/renderComponentsTree/utils';
import ContainerEditor from '../../ContainerEditor/ContainerEditor';
import ButtonEditor from '../../ButtonEditor/ButtonEditor';
import TextEditor from '../../TextEditor/TextEditor';
import FormEditor from '../../FormEditor/FormEditor';
import { ImageEditor } from '../../ImageEditor';
import VideoEditor from '../../VideoEditor/VideoEditor';
import { GameToolbar } from '../../GameEditors';
import PhaserTextEditor from '../../GameEditors/PhaserTextEditor/PhaserTextEditor';
import PhaserButtonEditor from '../../GameEditors/PhaserButtonEditor/PhaserButtonEditor';
import {
  ButtonElement,
  CanvasElement,
  IStyleProps,
} from '../../../reducers/builder';
import {
  IButtonStyleProps,
  IContainerStyleProps,
  IElementProps,
  IFormStyleProps,
  IImageStyleProps,
  ITextStyleProps,
  IVideoStyleProps,
} from '../../../types';
import { useAppSelector } from '../../../reducers/app/hooks';
import {
  getBreakpoints,
  getSelectedBreakpoint,
} from '../../../reducers/shared';
import { selectGameSettings } from '../../../reducers/game/gameSlice';
import { useStyles } from './styles';
import { buildDefaultStylesFromTheme } from './utils';

interface IProps {
  selectedElement: CanvasElement | null;
  onChange: (props: IStyleProps | IElementProps) => void;
  onReset: () => void;
}

const Editors: FC<IProps> = ({ selectedElement, onChange, onReset }) => {
  const { currentTheme } = useThemeData();
  const styles = useStyles();
  const activeBreakpoint = useAppSelector(getSelectedBreakpoint);
  const breakpoints = useAppSelector(getBreakpoints);
  const gameState = useAppSelector(selectGameSettings);

  const mediaQueryStyles = getMediaQueryStyles({
    breakpoints,
    activeBreakpoint,
    responsive: selectedElement?.styles?.responsive,
  });

  const styleProps = merge(
    buildDefaultStylesFromTheme(selectedElement, currentTheme),
    selectedElement?.styles?.defaults,
    selectedElement?.styles?.overrides,
    mediaQueryStyles
  );

  // TODO: fix it
  const elementProps = {
    // @ts-ignore
    value: selectedElement?.value,
    variant: selectedElement?.variant,
    // @ts-ignore
    src: selectedElement?.src,
    // @ts-ignore
    asset: selectedElement?.asset,
    // @ts-ignore
    autoplay: selectedElement?.autoplay,
    // @ts-ignore
    controls: selectedElement?.controls,
    // @ts-ignore
    color: selectedElement?.color,
    // @ts-ignore
    themedImage: selectedElement?.themedImage,
    // @ts-ignore
    themedVideo: selectedElement?.themedVideo,
    // @ts-ignore
    fields: selectedElement?.fields,
    // @ts-ignore
    variant: selectedElement?.variant,
    // @ts-ignore
    id: selectedElement?.id,
    // @ts-ignore
    option: selectedElement?.option,
  };

  return (
    <div data-testid="editor-panel">
      {!!Object.keys(styleProps).length && (
        <div>
          {selectedElement?.type === 'container' && (
            <ContainerEditor
              props={styleProps as IContainerStyleProps}
              onChange={onChange}
              onReset={onReset}
            />
          )}

          {selectedElement?.type === 'root-container' && (
            <ContainerEditor
              props={styleProps as IContainerStyleProps}
              onChange={onChange}
              onReset={onReset}
              root
            />
          )}

          {selectedElement?.type === 'button' && (
            <ButtonEditor
              styleProps={styleProps as IButtonStyleProps}
              elementProps={
                //@ts-ignore
                {
                  ...elementProps,
                  action: selectedElement.action,
                } as ButtonElement
              }
              onChange={onChange}
              onReset={onReset}
            />
          )}

          {selectedElement?.type === 'phaserbutton' && (
            <PhaserButtonEditor
              styleProps={styleProps as IButtonStyleProps}
              elementProps={
                //@ts-ignore
                {
                  ...elementProps,
                } as ButtonElement
              }
              gameType={gameState['gameType']}
              onChange={onChange}
              onReset={onReset}
              props={selectedElement}
            />
          )}

          {selectedElement?.type === 'text' && (
            <TextEditor
              styleProps={styleProps as ITextStyleProps}
              elementProps={elementProps as IElementProps}
              onChange={onChange}
              onReset={onReset}
            />
          )}
          {selectedElement?.type === 'phasertext' && (
            <PhaserTextEditor
              styleProps={styleProps as ITextStyleProps}
              elementProps={elementProps as IElementProps}
              gameType={gameState['gameType']}
              onChange={onChange}
              onReset={onReset}
              props={selectedElement}
            />
          )}
          {selectedElement?.type === 'image' && (
            <ImageEditor
              styleProps={styleProps as IImageStyleProps}
              elementProps={elementProps as IImageProps}
              onChange={onChange}
              onReset={onReset}
            />
          )}

          {selectedElement?.type === 'video' && (
            <VideoEditor
              styleProps={styleProps as IVideoStyleProps}
              elementProps={elementProps as IVideoProps}
              onChange={onChange}
              onReset={onReset}
            />
          )}

          {selectedElement?.type === 'phasergame' && (
            <GameToolbar
              styleProps={styleProps as IImageStyleProps}
              onChange={onChange}
              gameType={gameState['gameType']}
              selectedElement={selectedElement}
              onReset={onReset}
            />
          )}

          {(selectedElement?.type === 'form' ||
            selectedElement?.type === 'terms-and-conditions') && (
            <FormEditor
              props={styleProps as IFormStyleProps}
              elementProps={elementProps as IFormProps}
              //@ts-ignore
              onChange={onChange}
              onReset={onReset}
            />
          )}
        </div>
      )}
      {!selectedElement && (
        <div css={styles.messageBox}>
          Select the element on the page to see its properties
        </div>
      )}
    </div>
  );
};

export default Editors;
