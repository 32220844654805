import { BuilderComponent } from '@brame/builder/src/reducers/builder';
import { Text } from '../../components/Text';
import { Button } from '../../components/Button';
import { Image } from '../../components/Image';
import { PhaserPlaceHolder } from '../../phaser/components/PhaserPlaceholder';
import { PhaserComponent } from '../../phaser/components/PhaserComponent';
import { PhaserText } from '../../components/PhaserText';
import PhaserButton from '../../components/PhaserButton/PhaserButton';
import { Video } from '../../components/Video';
import { FormComponent } from '../../components/Form';
import { Container } from '../../components/Container';

export const getComponent = (type: BuilderComponent) => {
  switch (type) {
    case BuilderComponent.BUTTON:
      return Button;
    case BuilderComponent.IMAGE:
      return Image;
    case BuilderComponent.TEXT:
      return Text;
    case BuilderComponent.VIDEO:
      return Video;
    case BuilderComponent.CONTAINER:
      return Container;
    case BuilderComponent.FORM:
      return FormComponent;
    case BuilderComponent.PHASER_BUTTON:
      return PhaserButton;
    case BuilderComponent.PHASER_TEXT:
      return PhaserText;
    case BuilderComponent.PHASER_GAME:
      return PhaserPlaceHolder;
    case BuilderComponent.PHASER_PUBLISH:
      return PhaserComponent;
    case BuilderComponent.ROOT_CONTAINER:
      return Container;
    case BuilderComponent.TERMS_AND_CONDITIONS:
      return FormComponent;
    default:
      return Container;
  }
};
export const components = {
  [BuilderComponent.TEXT]: Text,
  [BuilderComponent.BUTTON]: Button,
  [BuilderComponent.IMAGE]: Image,
  [BuilderComponent.PHASER_GAME]: PhaserPlaceHolder,
  [BuilderComponent.PHASER_PUBLISH]: PhaserComponent,
  [BuilderComponent.PHASER_TEXT]: PhaserText,
  [BuilderComponent.PHASER_BUTTON]: PhaserButton,
  [BuilderComponent.VIDEO]: Video,
  [BuilderComponent.FORM]: FormComponent,
  [BuilderComponent.TERMS_AND_CONDITIONS]: FormComponent,
  [BuilderComponent.CONTAINER]: Container,
  [BuilderComponent.ROOT_CONTAINER]: Container,
};

export const haveNoValueElements = [
  BuilderComponent.IMAGE,
  BuilderComponent.VIDEO,
  BuilderComponent.FORM,
];
