import { createSlice } from '@reduxjs/toolkit';
import merge from 'lodash/merge';
import { RootState } from '../store';
import { fetchInitialStateAsync } from '../builder/asyncThunks';
import { IEmailState, IEmailTemplate, IEmailTemplateCollection } from './types';
import { defaultEmailTemplates } from './fixtures';

const initialState: IEmailState = {
  templates: defaultEmailTemplates,
  currentTemplate: null,
};

const emailSlice = createSlice({
  name: 'email',
  initialState,
  reducers: {
    setCurrentTemplate: (state, action) => {
      state.currentTemplate = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchInitialStateAsync.fulfilled, (state, action) => {
      const data = action.payload.data.data;
      if (data.email) {
        state.currentTemplate = data.email.currentTemplate;
        merge(state.templates, data.email.templates);
      }
    });
  },
});

export const getEmailTemplates = (
  state: RootState
): IEmailTemplateCollection => {
  return state.email.templates;
};

export const getCurrentTemplate = (state: RootState): IEmailTemplate | null => {
  return state.email.currentTemplate;
};

export const { setCurrentTemplate } = emailSlice.actions;

export default emailSlice.reducer;
