import { EntityId } from '@reduxjs/toolkit';
import { useEffect, useMemo } from 'react';
import {
  ButtonAction,
  ButtonActionMapping,
  ButtonActionType,
} from '@brame/builder/src/reducers/builder';
import { useRunnerDispatch, useRunnerSelector } from '../slices/store/store';
import { goToNextPage, goToPage, setPages } from '../slices/pageSlice';
import { goToPreviousPage } from '../slices/pageSlice/pagesSlice';

export const useRunnerNavigation = (
  pagesIds: EntityId[],
  additionalPagesIds?: EntityId[]
) => {
  const dispatch = useRunnerDispatch();
  const currentPage = useRunnerSelector((state) => state.pages.currentPage);
  const currentPageIsAdditional = useRunnerSelector(
    (state) => state.pages.currentPageIsAdditional
  );

  useEffect(() => {
    if (!pagesIds?.length) return;
    dispatch(
      setPages({ pages: pagesIds, additionalPages: additionalPagesIds || [] })
    );
  }, [dispatch, pagesIds, additionalPagesIds]);

  const actionsMap: ButtonActionMapping = useMemo(() => {
    return {
      [ButtonActionType.CHANGE_PAGE]: ({ target }: ButtonAction) => {
        if (target === 'NEXT') {
          dispatch(goToNextPage());
        } else if (target === 'PREVIOUS') {
          dispatch(goToPreviousPage());
        } else {
          dispatch(goToPage(target));
        }
      },
      [ButtonActionType.ADDITIONAL_PAGE]: ({ target }: ButtonAction) => {
        dispatch(goToPage(target));
      },
      [ButtonActionType.SUBMIT_FORM]: (action: ButtonAction) => {},
      [ButtonActionType.LINK]: (action: ButtonAction) => {},
    };
  }, [dispatch]);

  return {
    currentPage,
    currentPageIsAdditional,
    actionsMap,
  } as const;
};
