import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Redirect from './components/Redirect';
import { authStateType } from './reducers/auth/types';

interface ProtectedRouteProps {
  authenticationPath?: string;
  outlet: JSX.Element;
  authState: authStateType;
}

export default function ProtectedRoute({
  authenticationPath,
  outlet,
  authState,
}: ProtectedRouteProps) {
  if (authState.status === 'loggedIn') {
    return outlet;
  }
  if (authState.status === 'loading') {
    return <Loading />;
  } else {
    return (
      <Redirect
        authenticationPath={authenticationPath || 'https://brame.ch/'}
      />
    );
  }
}

function Loading() {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'primary.main',
      }}
      style={{ minHeight: '100vh' }}
      data-testid="loading-page"
    >
      <CircularProgress color="inherit" />
    </Box>
  );
}
