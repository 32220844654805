import { css } from '@emotion/react';
import { useTheme } from '@mui/material';

interface IProps {
  isHovered?: boolean;
  isExpanded?: boolean;
}

export const useStyles = ({ isHovered, isExpanded }: IProps) => {
  const theme = useTheme();

  return {
    title: css`
      text-transform: capitalize;
      color: ${theme.palette.text.primary};
      font-weight: ${isHovered || isExpanded
        ? theme.typography.fontWeightBold
        : theme.typography.fontWeightRegular};
    `,
    titleBlock: css`
      display: flex;
      align-items: center;
      gap: ${theme.spacing(1)};
      width: 100%;
    `,
  };
};
