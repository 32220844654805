import { css } from '@emotion/react';
import { useTheme } from '@mui/material';

export const useStyles = () => {
  const theme = useTheme();
  return {
    placeholderContainer: css`
      width: 100%;
      cursor: pointer;
    `,
    placeholderLabel: css`
      text-align: center;
      height: 24px;
      background-color: ${theme.palette.primary.main};
      color: ${theme.palette.primary.contrastText};
      border-radius: 5px 5px 0 0;
    `,
    placeholder: css`
      width: inherit;
      border-radius: 0 0 5px 5px;
    `,
  };
};
