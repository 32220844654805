import React from 'react';
import { EntityId } from '@reduxjs/toolkit';
import { EmotionJSX } from '@emotion/react/types/jsx-namespace';

import { IconWeight } from 'phosphor-react';

import { IStyleProps, RequireAtLeastOne } from '../reducers/builder';
import { IAssetData } from './assets';

export interface IFontProps {
  font: {
    fontList: string[];
    currentFont: string;
  };
  size: {
    sizeList: number[];
    currentSize: number;
  };
  color?: string;
}

export interface IBackgroundProps {
  color: string;
  image: string;
  videoUrl: string;
  themedImage?: string;
  themedVideo?: string;
  asset: IAssetData | null;
}

export interface IContainerStyleProps {
  internalAlignment: IInternalAlignment;
  size?: ISizing & Required<Pick<ISizing, 'width' | 'height'>>;
  spacing?: ISpacingProps;
  background: IBackgroundProps;
  zIndex: number;
}

export type IContainerStylePropsChange = {
  [Name in keyof IContainerStyleProps as string]: Partial<
    IContainerStyleProps[Name]
  >;
};

export const textTransformVariants = [
  'none',
  'capitalize',
  'uppercase',
  'lowercase',
];
export type TextTransform = typeof textTransformVariants[number];

export const fontWeights = [
  { name: 'Normal', value: 400 },
  { name: 'Bold', value: 700 },
];

export type FontWeight = number;

export const borderStyles = [
  'solid',
  'dotted',
  'dashed',
  'double',
  'groove',
  'ridge',
  'inset',
  'outset',
];
export type BorderStyle = typeof borderStyles[number];

export const themeColors = ['primary', 'secondary'];
export type ThemeColor = typeof themeColors[number];

export const objectFits = ['fill', 'contain', 'cover', 'none', 'scale-down'];
export type ObjectFit = typeof objectFits[number];

export interface ITextStyleProps {
  spacing: ISpacingProps;
  backgroundColor: string;
  textAlign: TextAlign;
  size: ISizing & Required<Pick<ISizing, 'width' | 'height'>>;
  font: {
    fontFamily: string;
    fontWeight: FontWeight;
    fontSize: number;
    textTransform: TextTransform;
    fontColor: string;
  };
  decoration: TextDecoration;
  zIndex: number;
}

export type ITextStylePropsChange = {
  [Name in keyof ITextStyleProps as string]: Partial<ITextStyleProps[Name]>;
};

export interface IPhaserStyleProps {
  size: ISizing;
}

export interface IButtonStyleProps {
  spacing: ISpacingProps;
  size: ISizing & Required<Pick<ISizing, 'width' | 'height'>>;
  variant: IButtonVariant;
  backgroundColor?: string;
  font: {
    fontFamily: string;
    fontWeight: FontWeight;
    fontSize: number;
    textTransform: TextTransform;
    fontColor: string;
  };
  border: IBorder;
  hover?: IStyleProps;
  zIndex: number;
}

export type IButtonStylePropsChange = {
  [Name in keyof IButtonStyleProps as string]: Partial<IButtonStyleProps[Name]>;
};

export enum FieldShape {
  Outlined = 'outlined',
  Filled = 'filled',
  Standard = 'standard',
}

export interface IFormInputStyles {
  size: ISizing;
  background: string;
  variant: FieldShape;
  border: RequireAtLeastOne<IBorder>;
  boxShadow: IBoxShadow | null;
}

export const checkboxLabelPositions = [
  'top',
  'start',
  'bottom',
  'end',
] as const;
export type ICheckboxLabelPosition = typeof checkboxLabelPositions[number];

export const labelPositions = ['left', 'top', 'right', 'bottom'] as const;
export type ILabelPosition = typeof labelPositions[number];

export const horizontalLabelAlignment = ['left', 'center', 'right'] as const;
export const defaultHorizontalLabelAlignment = 'left';
export type IHorizontalLabelAlignment = typeof horizontalLabelAlignment[number];

export const verticalLabelAlignment = ['top', 'center', 'bottom'] as const;
export const defaultVerticalLabelAlignment = 'center';
export type IVerticalLabelAlignment = typeof verticalLabelAlignment[number];

export interface IFormLabelInputStyles {
  position: ILabelPosition;
  alignment: IHorizontalLabelAlignment | IVerticalLabelAlignment;
}

export interface IFormLabelStyles {
  input: IFormLabelInputStyles;
  checkbox: ICheckboxLabelPosition;
}

export interface IFormStyleProps extends IContainerStyleProps {
  grid: {
    gridTemplateColumns: string;
  };
  input: IFormInputStyles;
  label: IFormLabelStyles;
}

export type IFormStylePropsChange = {
  [Name in keyof IFormStyleProps as string]: Partial<IFormStyleProps[Name]>;
};

export type IBorder = RequireAtLeastOne<{
  borderWidth: UnitValueType | null;
  borderRadius: UnitValueType;
  borderStyle: BorderStyle | null;
  borderColor: string | null;
}>;

export interface IBorderCss {
  borderWidth?: string;
  borderRadius?: string;
  borderStyle?: string;
  borderColor?: string;
  borderColorHover?: string;
}

export interface IBoxShadow {
  offsetX: number; // in px
  offsetY: number; // in px
  blurRadius: number; // in px
  spreadRadius: number; // in px
  color: ThemeColor;
}

export interface IFlip {
  horizontal: boolean;
  vertical: boolean;
}

export interface IImageStyleProps {
  size: ISizing & Required<Pick<ISizing, 'width' | 'height'>>;
  alignSelf: FlexAlignSelf;
  objectFit: ObjectFit;
  border: Required<IBorder>;
  boxShadow: IBoxShadow | null;
  opacity: number;
  rotate: number;
  flip: IFlip;
  zIndex: number;
}

export type IImageStylePropsChange = {
  [Name in keyof IImageStyleProps as string]: Partial<IImageStyleProps[Name]>;
};

export interface IVideoStyleProps {
  size: ISizing & Required<Pick<ISizing, 'width' | 'height'>>;
  spacing: {
    margin: ISpacing;
  };
  zIndex: number;
}

export type IVideoStylePropsChange = {
  [Name in keyof IVideoStyleProps as string]: Partial<IVideoStyleProps[Name]>;
};

export interface IInternalAlignment {
  textAlign: TextAlign;
  flexDirection: FlexDirection;
  justifyContent: FlexJustify;
  alignItems: FlexAlign;
}

export interface ISpacingProps {
  margin?: ISpacing;
  padding?: ISpacing;
}

export interface IButtonEditorProps {
  borderRadius: number;
  borderWidth: number;
  background: string;
  borderColor: string;
  borderStyle: string;
  hover?: IStyleProps;
}

export type IButtonVariant = 'contained' | 'outlined' | 'text';

export enum ButtonVariants {
  Contained = 'contained',
  Outlined = 'outlined',
  Text = 'text',
}

export interface IButtonShapes {
  [key: string]: string | number;
}

export interface IElementStyleProps {
  [key: string]: any;
}

export interface IElementProps {
  value?: string;
  variant?: string;
  json?: any;
  src?: string | ArrayBuffer;
  controls?: boolean;
  autoplay?: boolean;
  color?: 'primary' | 'secondary';
  themedImage?: string;
  themedVideo?: string;
  option?: { variant: string };
  id?: string;
}

const IBreadcrumbs = ['subcategory', 'child', 'root', 'page'] as const;
export type IBreadcrumbType = typeof IBreadcrumbs[number];

export const sizeProps = ['px', '%', 'auto'];
export const sizePropsNoAuto = ['px', '%'];
export type UnitType = typeof sizeProps[number];

export type UnitValueType = {
  value: 'auto' | number;
  unit: UnitType;
};

export interface ISpacing {
  top: UnitValueType;
  right: UnitValueType;
  bottom: UnitValueType;
  left: UnitValueType;
}

export const directions = ['top', 'right', 'bottom', 'left'] as const;
export type IDirection = typeof directions[number];

export type ISizing = RequireAtLeastOne<{
  width: UnitValueType;
  height: UnitValueType;
  maxWidth?: UnitValueType;
  minHeight?: UnitValueType;
}>;

export const flexDirection = ['row', 'column'] as const;

export const textAlign = ['left', 'center', 'right', 'justify'] as const;
export const textDecoration = [
  'bold',
  'italic',
  'line-through',
  'underline',
] as const;

export type TextDecoration = typeof textDecoration[number][];

export const flexAlign = [
  'start',
  'center',
  'end',
  'stretch',
  'baseline',
] as const;

export const flexAlignSelf = ['flex-start', 'center', 'flex-end'] as const;

export const flexJustify = [
  'start',
  'center',
  'end',
  'space-between',
  'space-around',
  'space-evenly',
] as const;

export const flexWrap = ['wrap', 'nowrap'] as const;

export type FlexDirection = typeof flexDirection[number];
export type FlexAlign = typeof flexAlign[number];
export type FlexAlignSelf = typeof flexAlignSelf[number];
export type FlexJustify = typeof flexJustify[number];
export type FlexWrap = typeof flexWrap[number];
export type TextAlign = typeof textAlign[number];

export type tabsType = {
  name: string;
  text: string;
  icon: (props: IconProps) => EmotionJSX.Element;
};

export type BreakpointType = {
  name: string;
  breakpoint: number;
  icon: (props: IconProps) => React.ReactElement;
  default?: boolean;
};

export interface IconProps {
  color?: string;
  weight?: IconWeight;
  size?: number;
}

export interface RenderTree {
  id?: EntityId;
  parentId?: EntityId;
  name?: EntityId;
  type: IBreadcrumbType;
  children: RenderTree[] | [];
}

export type ColorVariantType = 'primary' | 'secondary' | 'custom';

export const borderStyleVariants = [
  'solid',
  'dashed',
  'dotted',
  'double',
  'groove',
  'ridge',
  'inset',
  'outset',
] as const;
export type IBorderStyleVariant = typeof borderStyleVariants[number];

export enum AssetSourceType {
  LIBRARY = 'library',
  URL = 'url',
  THEME_IMAGE = 'theme image',
  THEME_VIDEO = 'theme video',
}

export enum UserRole {
  EDITOR = 'EDITOR',
  VIEWER = 'VIEWER',
  CREATOR = 'CREATOR',
  USER_ADMIN = 'USER_ADMIN',
}
