import { css } from '@emotion/react';
import { useTheme } from '@mui/material';

export const useStyles = (active?: boolean) => {
  const theme = useTheme();

  return {
    container: css`
      display: flex;
      height: 100%;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      padding: ${theme.spacing(0.5)};
      cursor: pointer;
      border-bottom: 2px solid
        ${active ? theme.palette.primary.main : 'transparent'};

      &:hover {
        border-bottom: 2px solid ${theme.palette.primary.main};
      }
    `,
    box: css`
      //flex-basis: 50%;
    `,
  };
};
