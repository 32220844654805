import { EntityId } from '@reduxjs/toolkit';
import { IconWeight } from 'phosphor-react';
import { EmotionJSX } from '@emotion/react/types/jsx-namespace';
import { IVideoProps } from '@brame/components/src/components/Video';
import { IImageProps } from '@brame/components/src/components/Image';
import { IFormProps } from '@brame/components/src/components/Form';
import { ITheme, IThemeCollection } from '@brame/theme';
import { PHASER_GAME_TYPE } from '../../components/GameEditors/GameToolbar/GameTypes';
import {
  FlexAlignSelf,
  FlexWrap,
  FontWeight,
  IBackgroundProps,
  IBorder,
  IBoxShadow,
  IFlip,
  IFontProps,
  IFormInputStyles,
  IFormLabelStyles,
  IInternalAlignment,
  ISizing,
  ISpacingProps,
  ObjectFit,
  TextAlign,
  TextDecoration,
  TextTransform,
} from '../../types';
import { ElementsState } from './builderSlice';

export enum BuilderComponent {
  ROOT_CONTAINER = 'root-container',
  CONTAINER = 'container',
  BUTTON = 'button',
  TEXT = 'text',
  IMAGE = 'image',
  PHASER_GAME = 'phasergame',
  PHASER_PUBLISH = 'phaserpublished',
  PHASER_TEXT = 'phasertext',
  PHASER_BUTTON = 'phaserbutton',
  VIDEO = 'video',
  FORM = 'form',
  TERMS_AND_CONDITIONS = 'terms-and-conditions',
  EMAIL_TEMPLATE = 'email-template',
}

export const availableElementTypes = Object.values(BuilderComponent);

export enum PageType {
  Basic = 'basic',
  Game = 'game',
  Outcome = 'outcome',
  Terms = 'terms and conditions',
  Email = 'email',
}

export enum ButtonActionType {
  CHANGE_PAGE = 'CHANGE_PAGE',
  ADDITIONAL_PAGE = 'ADDITIONAL_PAGE',
  LINK = 'LINK',
  SUBMIT_FORM = 'SUBMIT_FORM',
}

export type ButtonAction = {
  type: ButtonActionType;
  target: EntityId | 'NEXT' | 'PREVIOUS';
  openNewTab?: boolean;
};

export interface ParsedButtonAction {
  onClick?: () => void;
  href?: string;
  target?: '_blank' | '_self';
}

export const TextVariant = {
  BODY: 'body1',
  H1: 'h1',
  H2: 'h2',
  H3: 'h3',
};

export type TextVariantType = typeof TextVariant[keyof typeof TextVariant];

export const ContainerVariant = {
  SIMPLE_CONTAINER: 'simple_container',
  COLUMN: 'column',
  TWO_COLUMNS: 'two_columns',
  TWO_ROWS: 'two_rows',
  THREE_COLUMNS: 'three_columns',
  THREE_ROWS: 'three_rows',
  TWO_COLUMNS_ROW: 'two_columns_row',
  TWO_ROWS_COLUMN: 'two_rows_column',
  COLUMN_TWO_ROWS: 'col_two_rows',
  ROW_TWO_COLUMNS: 'row_two_columns',
};

export type ContainerVariantType =
  typeof ContainerVariant[keyof typeof ContainerVariant];

export type ButtonActionMapping = {
  [key in ButtonActionType]: (action: ButtonAction) => void;
};

export interface IElement {
  id: EntityId;
  parentId: EntityId;
  type: BuilderComponent;
  name?: string;
  value?: string | number;
  variant?: string;
  formId?: EntityId;
  styles?: IRawStyles;
  builderMode?: boolean;
  index?: number;
}

export interface Element {
  id: EntityId;
  type: BuilderComponent;
  parentId: EntityId;
  name?: string;
  value?: string | number;
  childrenIds?: EntityId[];
  styles?: IRawStyles;
  children?: IElement[];
}

export const ButtonVariant = {
  Contained: 'contained',
  Outlined: 'outlined',
  Text: 'text',
};

export type ButtonVariantType =
  typeof ButtonVariant[keyof typeof ButtonVariant];

export interface ButtonElement extends IElement {
  type: BuilderComponent.BUTTON;
  value: string;
  action: ButtonAction;
  color: string;
  formId?: EntityId;
  option?: { variant: ButtonVariantType };
}

export interface PhaserButtonElement extends IElement {
  type: BuilderComponent.PHASER_BUTTON;
  value: string;
  color: string;
  formId?: EntityId;
}

export interface TextElement extends IElement {
  type: BuilderComponent.TEXT;
  value: string | number;
  option?: {
    variant: TextVariantType;
  };
}

export interface ImageElement extends IElement, IImageProps {
  type: BuilderComponent.IMAGE;
}

export interface VideoElement extends IElement, IVideoProps {
  type: BuilderComponent.VIDEO;
}

export interface PhaserElement extends IElement {
  type: BuilderComponent.PHASER_GAME;
  gametype: PHASER_GAME_TYPE;
  json: any;
}

export interface PhaserPublishElement extends Omit<PhaserElement, 'type'> {
  type: BuilderComponent.PHASER_PUBLISH;
}

export interface PhaserTextElement extends IElement {
  type: BuilderComponent.PHASER_TEXT;
  value: string | number;
}

export interface FormElement extends IElement, IFormProps {
  type: BuilderComponent.FORM | BuilderComponent.TERMS_AND_CONDITIONS;
}

export interface ContainerElement extends Omit<IElement, 'value'> {
  type: BuilderComponent.CONTAINER;
  childrenIds: EntityId[];
}

export interface RootContainer extends Omit<IElement, 'parentId' | 'value'> {
  type: BuilderComponent.ROOT_CONTAINER;
  childrenIds: EntityId[];
}

export interface ElementProps extends IImageProps, IVideoProps {}

export type CanvasElement =
  | RootContainer
  | ContainerElement
  | ButtonElement
  | PhaserButtonElement
  | TextElement
  | ImageElement
  | PhaserElement
  | PhaserPublishElement
  | PhaserTextElement
  | VideoElement
  | FormElement;

export interface PageElement {
  id: EntityId;
  name?: string;
  elementRoot: EntityId;
  pageType: PageType;
}

export interface IRawStyles {
  defaults: IStyleProps;
  overrides: IStyleProps;
  responsive?: {
    [key: string]: IStyleProps;
  };
}

export type RequireAtLeastOne<T> = {
  [K in keyof T]-?: Required<Pick<T, K>> &
    Partial<Pick<T, Exclude<keyof T, K>>>;
}[keyof T];

export type IStyleProps = Partial<{
  size: ISizing;
  internalAlignment: IInternalAlignment;
  background: IBackgroundProps;
  backgroundColor: string;
  display: 'flex' | 'block' | 'inline' | 'inline-block';
  flexWrap: FlexWrap;
  position: 'relative' | 'absolute';
  overflowY: 'scroll' | 'hidden';
  overflowX: 'scroll' | 'hidden';
  spacing: ISpacingProps;
  alignSelf: FlexAlignSelf;
  objectFit: ObjectFit;
  opacity: number;
  rotate: number;
  flip: IFlip;
  decoration: TextDecoration;
  wordWrap: 'break-word';
  fontProps: IFontProps;
  font: {
    fontFamily: string;
    fontWeight: FontWeight;
    fontSize: number;
    textTransform: TextTransform;
    fontColor: string;
  };
  textAlign: TextAlign;
  border: IBorder;
  hover: { backgroundColor: string };
  color: string;
  boxShadow: IBoxShadow;
  borderRadius: number;
  zIndex: number;
  label: IFormLabelStyles;
  input: IFormInputStyles;
  grid: { gridTemplateColumns: string };
}>;

export interface IElementNameUpdate {
  id: EntityId;
  name: EntityId;
}

export interface IThemesState {
  currentTheme: ITheme;
  chosenTheme: ITheme;
  themeCollection: IThemeCollection;
}

export type IElementsLibrary = ILibrarySection[];

export interface ILibrarySection {
  title: string;
  icon: (weight: IconWeight) => EmotionJSX.Element;
  elements: ILibraryItem[];
}

export interface ILibraryItem {
  title: string;
  icon: EmotionJSX.Element;
  type: BuilderComponent;
  option?: { variant: string };
}

export interface IPageRenameModel {
  id: EntityId;
  name: string;
}

export interface ITemplateDataEntry {
  companyId: string;
  createdAt: number;
  data: IBuilderTemplate;
  templateId: string;
}

export type ITemplateData = ITemplateDataEntry[];

export interface IBuilderTemplate {
  id: string;
  name: string;
  template: ElementsState;
}

export type IBuilderTemplateData = Omit<IBuilderTemplate, 'id'>;

export interface IBuilderTemplateCollection {
  [key: string]: IBuilderTemplate;
}
