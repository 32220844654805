import React, { FC } from 'react';
import Checkbox, { CheckboxProps } from '@mui/material/Checkbox';
import { styled } from '@mui/material/styles';

const checkboxSize = 34;
const iconSize = 24;

const BpIcon = styled('span')(({ theme }) => ({
  borderRadius: `${theme.shape.borderRadius}px !important`,
  border: 'none',
  width: checkboxSize,
  height: checkboxSize,
  boxShadow: 'none',
  backgroundColor: `${theme.palette.common.white}`,
  backgroundImage:
    'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&:before': {
    display: 'block',
    position: 'relative',
    width: iconSize,
    height: iconSize,
    top: (checkboxSize - iconSize) / 2,
    left: (checkboxSize - iconSize) / 2,
    opacity: 0.54,
    backgroundImage: `url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='${iconSize}' height='${iconSize}' fill='%23000000' viewBox='0 0 256 256'%3e%3crect width='256' height='256' fill='none'%3e%3c/rect%3e%3cline x1='128' y1='40' x2='128' y2='128' fill='none' stroke='%23000000' stroke-linecap='round' stroke-linejoin='round' stroke-width='12'%3e%3c/line%3e%3cline x1='44.3' y1='100.8' x2='128' y2='128' fill='none' stroke='%23000000' stroke-linecap='round' stroke-linejoin='round' stroke-width='12'%3e%3c/line%3e%3cline x1='76.3' y1='199.2' x2='128' y2='128' fill='none' stroke='%23000000' stroke-linecap='round' stroke-linejoin='round' stroke-width='12'%3e%3c/line%3e%3cline x1='179.7' y1='199.2' x2='128' y2='128' fill='none' stroke='%23000000' stroke-linecap='round' stroke-linejoin='round' stroke-width='12'%3e%3c/line%3e%3cline x1='211.7' y1='100.8' x2='128' y2='128' fill='none' stroke='%23000000' stroke-linecap='round' stroke-linejoin='round' stroke-width='12'%3e%3c/line%3e%3c/svg%3e")`,
    content: '""',
  },
  '.Mui-focusVisible &': {
    outline: '2px auto rgba(19,124,189,.6)',
    outlineOffset: 2,
  },
  'input:hover ~ &': {
    backgroundColor: `${theme.palette.grey[600]}0a`,
  },
}));

const BpCheckedIcon = styled(BpIcon)(({ theme }) => ({
  backgroundColor: `${theme.palette.primary.main}`,
  '&:before': {
    opacity: 1,
    backgroundImage: `url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='${iconSize}' height='${iconSize}' fill='%23ffffff' viewBox='0 0 256 256'%3e%3crect width='256' height='256' fill='none'%3e%3c/rect%3e%3cline x1='128' y1='40' x2='128' y2='128' fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='24'%3e%3c/line%3e%3cline x1='44.3' y1='100.8' x2='128' y2='128' fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='24'%3e%3c/line%3e%3cline x1='76.3' y1='199.2' x2='128' y2='128' fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='24'%3e%3c/line%3e%3cline x1='179.7' y1='199.2' x2='128' y2='128' fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='24'%3e%3c/line%3e%3cline x1='211.7' y1='100.8' x2='128' y2='128' fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='24'%3e%3c/line%3e%3c/svg%3e")`,
  },
  'input:hover ~ &': {
    backgroundColor: `${theme.palette.primary.main}`,
  },
}));

const RequiredCheckbox: FC<CheckboxProps> = (props) => {
  return (
    <Checkbox
      sx={{
        '&:hover': { bgcolor: 'transparent' },
      }}
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      {...props}
    />
  );
};

export default RequiredCheckbox;
