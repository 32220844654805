import {
  AssetCategoryType,
  AssetCollectionType,
  IThemeMediaType,
} from '@brame/theme';

export const getAssetAllocation = (type: IThemeMediaType) => {
  let category: AssetCategoryType | '';
  let collection: AssetCollectionType | '';

  switch (type) {
    case 'video':
      category = AssetCategoryType.DEFAULT_CREATIVES;
      collection = AssetCollectionType.VIDEOS;
      break;

    case 'logo':
    case 'background':
    case 'image':
      category = AssetCategoryType.DEFAULT_CREATIVES;
      collection = AssetCollectionType.IMAGES;
      break;

    case 'game':
      category = AssetCategoryType.GAME_ASSETS;
      collection = AssetCollectionType.GAME;
      break;

    default:
      category = '';
      collection = '';
      break;
  }

  return { category, collection };
};
