/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import * as React from 'react';
import { FC } from 'react';
import { Typography } from '@mui/material';
import { useStyles } from './styles';

interface IProps {
  children: React.ReactNode;
  title?: string;
}

const ToolbarCategory: FC<IProps> = ({ children, title }) => {
  const styles = useStyles();

  return (
    <div css={styles.category} data-testid="toolbar-category">
      {title && (
        <Typography component="span" css={styles.title}>
          {title}
        </Typography>
      )}
      {children}
    </div>
  );
};

export default ToolbarCategory;
