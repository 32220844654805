/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import React, { FC, useCallback, useState } from 'react';
import { Eye, Info } from 'phosphor-react';
import { Dictionary, EntityId } from '@reduxjs/toolkit';
import { camelCase, startCase } from 'lodash';
import {
  Button,
  Card,
  createTheme,
  Grid,
  Popover,
  ScopedCssBaseline,
  Typography,
} from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { ITheme } from '@brame/theme';
import { renderTree } from '@brame/components/src/utils/renderComponentsTree';
import { IWizard } from '../../initialWizardState';
import { ITemplates } from '../CampaignTemplate';
import { CanvasElement } from '../../../../reducers/builder';
import { iconSize } from '../../../../constants';
import { PreviewModal } from '../../PreviewModal';
import { WizardType } from '../../CampaignType/tabsData';
import { useStyles } from './styles';

export interface ITemplateCard {
  wizardState: IWizard;
  handleProgressBar: (activeType: string) => void;
  onSelect: (stepName: string, payload: IWizard) => void;
  currentTemplate: ITemplates;
  currentTheme: ITheme;
  active: boolean;
}
const TemplateCard: FC<ITemplateCard> = ({
  wizardState,
  handleProgressBar,
  onSelect,
  currentTemplate,
  currentTheme,
  active,
}) => {
  const { id, name, template } = currentTemplate;
  const isRootBackground = Boolean(
    template.elements.entities[1].styles?.overrides?.background?.themedImage
  );
  const styles = useStyles({ active, currentTheme, isRootBackground });
  const elements: Dictionary<CanvasElement> =
    currentTemplate.template.elements.entities;
  const [visiblePreview, setVisiblePreview] = useState(false);
  const [previewModalIsOpen, setPreviewModalIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const popoverId = open ? 'simple-popover' : undefined;
  const rootElementId: EntityId = currentTemplate.template.elements.ids[0];
  const pageQuantity = currentTemplate.template.pages.ids.length - 1;

  const renderTreeStructure = useCallback(() => {
    return renderTree({
      rootElementId,
      elements,
      theme: currentTheme,
    });
  }, [elements, currentTheme]);

  const handleActiveCard = (active: boolean) => {
    onSelect(WizardType.CAMPAIGN_TEMPLATE, {
      ...wizardState,
      [camelCase(WizardType.CAMPAIGN_TEMPLATE)]: {
        templateId: active ? '' : id.toString(),
        isDisable: !active,
      },
    });
    handleProgressBar(
      active ? WizardType.CAMPAIGN_THEME : WizardType.CAMPAIGN_TEMPLATE
    );
  };

  return (
    <Grid item xs={12} sm={6} lg={4} xl={3} data-testid="template-card">
      <Card
        css={styles.card}
        onMouseOver={() => setVisiblePreview(true)}
        onMouseOut={() => setVisiblePreview(false)}
      >
        <div css={styles.titleContainer}>
          <div css={styles.titleWrapper}>
            <div css={styles.title} data-testid="card-title">
              {startCase(name)}
            </div>
            <Button
              endIcon={<Info weight="light" size={iconSize} />}
              css={styles.infoButton}
              data-testid="info-button"
              onClick={(e) => setAnchorEl(e.currentTarget)}
            />
            <Popover
              id={popoverId}
              open={open}
              anchorEl={anchorEl}
              onClose={() => setAnchorEl(null)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              <Typography
                sx={{ p: 2 }}
                data-testid="popover-title"
              >{`Avaliable ${pageQuantity} pages`}</Typography>
            </Popover>
          </div>
          {(visiblePreview || active) && (
            <div>
              <Button
                href="#"
                endIcon={<Eye weight="light" size={23} />}
                css={styles.previewButton}
                data-testid="preview-button"
                onClick={() => setPreviewModalIsOpen(true)}
              />
              <PreviewModal
                name={name}
                isOpen={previewModalIsOpen}
                onCancel={() => setPreviewModalIsOpen(false)}
              >
                <ThemeProvider theme={createTheme(currentTheme?.theme)}>
                  <ScopedCssBaseline hidden />
                  {renderTreeStructure()}
                </ThemeProvider>
              </PreviewModal>
            </div>
          )}
        </div>

        <div
          css={styles.template}
          data-textid="template"
          onClick={() => handleActiveCard(active)}
        >
          <ThemeProvider theme={createTheme(currentTheme?.theme)}>
            <ScopedCssBaseline hidden />
            {renderTreeStructure()}
          </ThemeProvider>
        </div>
      </Card>
    </Grid>
  );
};

export default TemplateCard;
