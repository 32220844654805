import { css } from '@emotion/react';
import { useTheme } from '@mui/material';

export const useStyles = () => {
  const theme = useTheme();

  return {
    inputWrapper: css`
      display: flex;
      justify-content: space-between;
      align-items: center;
    `,
    button: css`
      margin: ${theme.spacing(1)};
    `,
  };
};
