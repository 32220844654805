import { useNavigate } from 'react-router-dom';
import { useAuth } from '../auth/authHooks';

const useRouting = () => {
  const navigate = useNavigate();
  const { signOut } = useAuth();

  const goTo = (path?: string) => {
    if (path) {
      navigate(path);
    }
  };

  const logout = () => {
    signOut();
    navigate('/login');
  };

  return {
    goTo,
    logout,
  };
};

export default useRouting;
