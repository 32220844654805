import { css } from '@emotion/react';
import { useTheme } from '@mui/material';

export const useStyles = () => {
  const theme = useTheme();

  return {
    templateTile: css`
      width: 92px;
      height: 90px;
      background: #fefefe;
      color: ${theme.palette.text.primary};
      border: 1px solid #c4c4c4;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
    `,
  };
};
