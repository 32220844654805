import { useState } from 'react';
import { ResizeCallback } from 're-resizable';
import { Breakpoint } from '../../../reducers/shared/types';
import { breakpoints } from '../constants';
import { useActiveResolution } from './useActiveResolution';

export const useBreakpoints = (defaultBreakpoint: Breakpoint) => {
  const [activeBreakpoint, setActiveBreakpoint] = useState(defaultBreakpoint);
  const { activeResolution } = useActiveResolution(activeBreakpoint);

  const onSelectHandler = (id: string) =>
    setActiveBreakpoint(
      breakpoints.find((item) => item.id === id) || breakpoints[0]
    );

  const handleResize: ResizeCallback = (e, direction, ref): void => {
    const resizeBreakpoint: Breakpoint = {
      ...activeBreakpoint,
      id: activeResolution,
      breakpoint: parseInt(ref.style.width),
    };
    setActiveBreakpoint(resizeBreakpoint);
  };

  return {
    activeBreakpoint,
    setActiveBreakpoint,
    onSelectHandler,
    handleResize,
  };
};
