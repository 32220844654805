import { AxiosError } from 'axios';
import { CustomError, ILabelPosition } from '../types';

const neededVars = {
  REACT_APP_API_URL: process.env.REACT_APP_API_URL,
  REACT_APP_KEYCLOAK_FE_SERVICE_CLIENT_SECRET:
    process.env.REACT_APP_KEYCLOAK_FE_SERVICE_CLIENT_SECRET,
  REACT_APP_SERVER_BASE_URL: process.env.REACT_APP_SERVER_BASE_URL,
  REACT_APP_AUTHENTICATION_URL: process.env.REACT_APP_AUTHENTICATION_URL,
  REACT_APP_SERVER_SYNCING: process.env.REACT_APP_SERVER_SYNCING,
};

export function checkAllNeededEnvVars() {
  for (const [key, value] of Object.entries(neededVars)) {
    if (value === undefined) {
      console.log(`env variable: "${key}" missing`);
    }
  }
}

export function getCustomError(error: AxiosError): CustomError {
  const errorData = error.response?.data as any;
  return {
    message: errorData?.message ? errorData.message : error.message,
    statusCode: error.response?.status,
  } as CustomError;
}

export const isHorizontalLabelAlignment = (position: ILabelPosition) => {
  return position === 'top' || position === 'bottom';
};
