/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import React, { FC, useCallback, useMemo } from 'react';
import { IAssetCategory, IAssetChange, IThemeAssets } from '@brame/theme';
import { AssetsModal } from '../AssetsModal';
import { IAsset } from '../../types';
import { useStyles } from './styles';
import { getAssetAllocation } from './utils';
import { MediaPreview } from './MediaPreview';
import useMediaPreview from './useMediaPreview';

interface IProps {
  assets: IThemeAssets;
  onChange: (assets: IThemeAssets) => void;
  type: IAssetCategory;
}

const AssetsViewer: FC<IProps> = ({ assets, onChange }) => {
  const {
    defaultCreatives: { images, videos },
  } = assets;
  const styles = useStyles();
  const allCreatives = { ...images, ...videos };
  const allCreativesEntries = Object.entries(allCreatives);

  const renderAllCreatives = useMemo(
    () =>
      allCreativesEntries.map((media) => (
        <MediaPreview
          media={media[1]}
          key={media[0]}
          onClick={(e) => openModalForCurrentMedia(e, media)}
        />
      )),
    [allCreativesEntries]
  );

  const {
    openModalForCurrentMedia,
    assetsModalIsOpen,
    handleCancelPopup,
    currentMedia,
    getCollectionType,
  } = useMediaPreview(allCreatives);

  const handleChange = (data: IAssetChange) => {
    const { category, collection } = getAssetAllocation(data.type);

    onChange({
      ...assets,
      [category]: {
        // @ts-ignore
        ...assets[category],
        [collection]: {
          // @ts-ignore
          ...assets[category][collection],
          [data.name]: {
            name: data.name,
            url: data.asset.url,
            type: data.type,
          },
        },
      },
    });
  };

  const handleApplyAsset = useCallback(
    (asset: IAsset) => {
      if (currentMedia) {
        handleChange({
          name: currentMedia[0],
          type: currentMedia[1].type,
          asset: asset,
        });
      }
      handleCancelPopup();
    },
    [currentMedia]
  );

  return (
    <div css={styles.wrapper} data-testid="assets-viewer">
      {renderAllCreatives}
      <AssetsModal
        isOpen={assetsModalIsOpen}
        onApplyAsset={handleApplyAsset}
        onCancel={handleCancelPopup}
        elementType={getCollectionType()}
      />
    </div>
  );
};

export default AssetsViewer;
