import { css } from '@emotion/react';
import { useTheme } from '@mui/material';

export const useStyles = () => {
  const theme = useTheme();

  return {
    wrapperButton: css`
      cursor: grab;
      width: 92px;
      height: 90px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      font-size: ${theme.typography.subtitle1.fontSize};
      background-color: ${theme.palette.common.white};
      color: ${theme.palette.common.black}60;
      padding: ${theme.spacing(1)};

      & .MuiButton-startIcon {
        margin: ${theme.spacing(0)};
        padding: ${theme.spacing(0)};
      }

      &:hover {
        background-color: ${theme.palette.primary.main};
        color: ${theme.palette.common.white};
      }

      &:active {
        cursor: grabbing;
      }
    `,
    details: css`
      display: flex;
      flex-wrap: wrap;
      gap: ${theme.spacing(1)};
      padding: ${theme.spacing(1)};
    `,
    accordion: css`
      &.Mui-expanded {
        margin: ${theme.spacing(3, 0)};
      }
    `,
    library: css`
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
    `,
    disclaimer: css`
      padding: ${theme.spacing(2, 2, 6, 2)};
    `,
    hintLabel: css`
      margin: ${theme.spacing(1, 0)};
    `,
  };
};
