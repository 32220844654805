/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { FadersHorizontal } from 'phosphor-react';
import { EntityId } from '@reduxjs/toolkit';
import { ErrorBoundary } from 'react-error-boundary';
import { Drawer, Tab, Tabs } from '@mui/material';
import { Breadcrumbs } from '../Breadcrumbs';
import {
  getSelectedElement,
  resetStyles,
  updateElement,
  updateStyles,
} from '../../reducers/builder/builderSlice';
import { useAppDispatch, useAppSelector } from '../../reducers/app/hooks';
import { IStyleProps } from '../../reducers/builder';
import { IElementProps } from '../../types';
import { TabPanel, useTabs } from '../TabPanel';
import { getSelectedBreakpoint } from '../../reducers/shared';
import { ErrorFallback } from '../ErrorFallback';
import useEmailEditor from '../../hooks/useEmailEditor/useEmailEditor';
import { iconSize } from '../../constants';
import { EmailEditor } from '../EmailEditor';
import { useStyles } from './styles';
import { Editors } from './Editors';

const isElementProps = (
  props: IStyleProps | IElementProps
): props is IElementProps => {
  return (
    'valueID' in props ||
    'suffix' in props ||
    'prefix' in props ||
    'functionID' in props ||
    ('value' in props &&
      'variant' in props &&
      'src' in props &&
      'asset' in props &&
      'controls' in props &&
      'autoplay' in props &&
      'color' in props &&
      'themedImage' in props &&
      'themedVideo' in props &&
      'fields' in props)
  );
};

const removeEmptyProps = (
  elementProps: IElementProps
): { [key: string]: any } => {
  const props = elementProps as { [key: string]: any };

  Object.keys(props).forEach((key: string) => {
    if (props[key] === undefined) {
      delete props[key];
    }
  });

  return props;
};

const RightPanel: FC = () => {
  const { tabIndex, handleTabChange } = useTabs(0);
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const styles = useStyles(isOpen);
  const dispatch = useAppDispatch();
  const selectedElement = useSelector(getSelectedElement);
  const activeBreakpoint = useAppSelector(getSelectedBreakpoint);
  const { isEmailPage } = useEmailEditor();

  const handleElementChange = (data: IStyleProps | IElementProps) => {
    if (isElementProps(data)) {
      dispatch(
        updateElement({
          id: selectedElement?.id,
          changes: removeEmptyProps(data),
        })
      );
    } else {
      const updateObject: {
        id?: EntityId;
        styles: IStyleProps;
        activeBreakpoint?: string;
      } = { id: selectedElement?.id, styles: data };
      if (!activeBreakpoint.default) {
        updateObject.activeBreakpoint = activeBreakpoint.breakpoint.toString();
      }
      dispatch(updateStyles(updateObject));
    }
  };

  const handleStylesReset = () => {
    dispatch(resetStyles({ id: selectedElement?.id }));
  };

  return (
    <aside css={styles.rightPanel} data-testid="right-panel">
      <div css={styles.switch} onClick={() => setIsOpen((prev) => !prev)}>
        <FadersHorizontal size={iconSize} />
      </div>
      <Drawer
        anchor="right"
        open={isOpen}
        hideBackdrop
        variant="persistent"
        transitionDuration={0}
        PaperProps={{ sx: { border: 'none' } }}
        css={styles.drawer}
      >
        <div>
          <Tabs
            value={tabIndex}
            onChange={handleTabChange}
            aria-label="basic tabs example"
            indicatorColor="secondary"
            css={styles.tabs}
            centered
          >
            <Tab disableRipple label="Properties" css={styles.tab} />
            <Tab disableRipple label="Layers" css={styles.tab} />
          </Tabs>
        </div>
        <TabPanel value={tabIndex} index={0}>
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            {isEmailPage ? (
              <EmailEditor />
            ) : (
              <Editors
                selectedElement={selectedElement}
                onChange={handleElementChange}
                onReset={handleStylesReset}
              />
            )}
          </ErrorBoundary>
        </TabPanel>
        <TabPanel value={tabIndex} index={1}>
          <div css={styles.breadcrumbsBox}>
            <Breadcrumbs />
          </div>
        </TabPanel>
      </Drawer>
    </aside>
  );
};

export default RightPanel;
