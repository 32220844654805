/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { useFormikContext } from 'formik';
import React, { FC } from 'react';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { TextField, InputLabel, InputLabelProps } from '@mui/material';
import { InputProps as StandardInputProps } from '@mui/material/Input/Input';
import {
  IBorderCss,
  IFormInputStyles,
  IFormLabelInputStyles,
} from '@brame/builder/src/types';
import { IFormField } from '../IProps';
import { useStyles } from '../VariativeInput/styles';

interface IProps {
  name: string;
  value: string;
  field: IFormField;
  inputStyles: IFormInputStyles;
  labelStyles: IFormLabelInputStyles;
  inputProps: Partial<StandardInputProps>;
  inputLabelProps: Partial<InputLabelProps>;
  borderStyles: IBorderCss;
  boxShadow: string | null;
  width?: string;
  fontFamily?: string;
}

const DateField: FC<IProps> = ({
  name,
  value,
  field,
  inputStyles,
  labelStyles,
  inputProps,
  inputLabelProps,
  borderStyles,
  boxShadow,
  width,
  fontFamily,
}) => {
  const { setFieldValue, errors: formikErrors } = useFormikContext();
  const errors = formikErrors as any;
  const { variant, background } = inputStyles;
  const styles = useStyles({ input: labelStyles, ...borderStyles });

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <InputLabel
        error={errors[name]}
        required={field.validation.required}
        css={styles.label}
        {...inputLabelProps}
      >
        {field.label}
      </InputLabel>
      <DatePicker
        value={value || null}
        onChange={(date) => setFieldValue(name, date)}
        renderInput={(params) => (
          <TextField
            css={styles.input}
            sx={{
              width,
              background,
              boxShadow,
              fontFamily,
            }}
            variant={variant}
            name={name}
            required={field.validation.required}
            {...params}
            InputProps={{ ...params.InputProps, ...inputProps }}
            InputLabelProps={{
              ...params.InputLabelProps,
              ...inputLabelProps,
            }}
            error={!!errors[name]}
            helperText={
              errors[name] ? field.validation.errorMessage || errors[name] : ''
            }
          />
        )}
      />
    </LocalizationProvider>
  );
};

export default DateField;
