/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { FC, useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';
import { IThemeDialog, ThemeType } from '@brame/theme/src/types';
import useUserRoles from '../../../hooks/useUserRoles';
import { UserRole } from '../../../types';
import { SelectInput } from '../../SelectInput';
import { useStyles } from './styles';
import { descriptionVariants } from './constants';

export interface IProps {
  isOpen: boolean;
  themeName: string;
  onConfirm: (data: IConfirmationData) => void;
  onCancel: () => void;
  dialogType: IThemeDialog;
}

export interface IConfirmationData {
  themeName: string;
  dialogType: IThemeDialog;
  themeType: ThemeType;
}

const ThemeDialog: FC<IProps> = ({
  isOpen,
  onConfirm,
  themeName,
  onCancel,
  dialogType,
}) => {
  const title = descriptionVariants[dialogType];
  const [value, setValue] = useState(themeName || '');
  const styles = useStyles();
  const { hasRole } = useUserRoles();
  const [themeType, setThemeType] = useState<ThemeType>(ThemeType.CUSTOM);

  const handleConfirm = () => {
    onConfirm({ themeName: value, dialogType, themeType });
    setValue(themeName || '');
  };

  useEffect(() => {
    if (themeName) {
      setValue(themeName);
    }
  }, [themeName]);

  return (
    <Dialog open={isOpen} onClose={onCancel} data-testid="theme-dialog-modal">
      <div css={styles.dialog}>
        {title && (
          <DialogTitle
            variant="body1"
            css={styles.dialogTitle}
            data-testid="confirmation-modal-title"
          >
            {title}
          </DialogTitle>
        )}
        <DialogContent css={styles.dialogContent}>
          <TextField
            value={value}
            variant="standard"
            disabled={dialogType === 'update'}
            onChange={(e) => setValue(e.target.value)}
          />

          {hasRole(UserRole.CREATOR) && (
            <SelectInput
              label="Theme type"
              value={themeType}
              options={[ThemeType.DEFAULT, ThemeType.CUSTOM]}
              onChange={(type) => setThemeType(type as ThemeType)}
            />
          )}
        </DialogContent>

        <DialogActions css={styles.dialogButtons}>
          <Button variant="text" size="small" onClick={onCancel}>
            Cancel
          </Button>
          <Button
            onClick={handleConfirm}
            size="small"
            color="primary"
            variant="contained"
          >
            Save
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default ThemeDialog;
