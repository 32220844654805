/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Toolbar, Typography } from '@mui/material';
import { ReactComponent as Logo } from '../../assets/logo/logo_title.svg';
import useRouting from '../../hooks/useRouting';
import { useStyles } from './styles';

export interface IRoute {
  name: string;
  path?: string;
}

const Header: FC = () => {
  const styles = useStyles();
  const { campaignId } = useParams();
  const routes: IRoute[] = [
    {
      name: 'Home',
      path: `/${campaignId}`,
    },
    {
      name: 'Campaigns',
      path: `/campaigns`,
    },
    {
      name: 'Prize management',
    },
    {
      name: 'Account',
    },
  ];
  const { goTo, logout } = useRouting();

  return (
    <Toolbar css={styles.toolBar} data-testid="header">
      <Logo
        css={styles.logo}
        data-testid="brame-logo"
        onClick={() => {
          goTo(`/${campaignId}`);
        }}
      />
      <Box css={styles.routeContainer}>
        {routes.map((route) => (
          <Typography
            css={styles.route}
            key={route.name}
            variant="body2"
            component="div"
            onClick={() => goTo(route.path)}
          >
            {route.name}
          </Typography>
        ))}
      </Box>
      <Typography
        css={styles.route}
        onClick={logout}
        variant="body2"
        component="div"
      >
        Sign out
      </Typography>
    </Toolbar>
  );
};

export default Header;
