import { css } from '@emotion/react';
import { useTheme } from '@mui/material';

export const useStyles = () => {
  const theme = useTheme();

  return {
    container: css`
      display: grid;
      height: 100px;
      width: 100%;
      grid-template-columns: 1fr auto;
      overflow-x: hidden;
      overflow-y: hidden;
    `,
    pageContainer: css`
      display: flex;
      position: relative;
    `,
    addPageButton: css`
      margin-right: ${theme.spacing(5)};
    `,
    pageScroller: css`
      display: flex;
      overflow-x: scroll;
      overflow-y: hidden;
      padding: ${theme.spacing(0, 1)};

      &::-webkit-scrollbar {
        display: none;
      }

    ,
    `,
    pageScrollerContent: css`
      display: flex;
      width: max-content;
      margin: 0 auto;
    `,
  };
};
