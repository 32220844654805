import globalColors from '@brame/theme/src/globalColors';
import { ButtonVariants, IButtonStyleProps } from '../../types';
import { ButtonActionType, ButtonElement } from '../../reducers/builder';

export const buttonStylePropsMock: IButtonStyleProps = {
  size: {
    width: { value: 100, unit: '%' },
    height: { value: 110, unit: 'px' },
  },
  backgroundColor: globalColors.white,
  variant: ButtonVariants.Contained,
  font: {
    fontFamily: 'Lato',
    fontWeight: 400,
    fontSize: 16,
    textTransform: 'none',
    fontColor: globalColors.white,
  },
  border: {
    borderStyle: 'solid',
    borderWidth: {
      value: 1,
      unit: 'px',
    },
    borderRadius: {
      value: 5,
      unit: 'px',
    },
    borderColor: globalColors.turtleGreen,
  },
  spacing: {
    margin: {
      top: { value: 1, unit: 'px' },
      right: { value: 1, unit: 'px' },
      bottom: { value: 1, unit: 'px' },
      left: { value: 1, unit: 'px' },
    },
    padding: {
      top: { value: 1, unit: 'px' },
      right: { value: 1, unit: 'px' },
      bottom: { value: 1, unit: 'px' },
      left: { value: 1, unit: 'px' },
    },
  },
  hover: {},
  zIndex: 0,
};

export const buttonElementPropsMock: Partial<ButtonElement> = {
  value: 'Test button',
  action: {
    type: ButtonActionType.CHANGE_PAGE,
    target: 'NEXT',
  },
};

export const fallbackProps = {
  value: 'Button',
  size: {
    width: { value: 150, unit: 'px' },
    height: { value: 50, unit: 'px' },
  },
  backgroundColor: globalColors.keppel,
  color: 'primary',
  font: {
    fontFamily: 'Lato',
    fontSize: 16,
    fontWeight: 400,
    fontColor: globalColors.black,
    textTransform: 'none',
  },
  border: {
    borderWidth: { value: 0, unit: 'px' },
    borderRadius: { value: 0, unit: 'px' },
    borderColor: globalColors.keppel,
    borderStyle: 'solid',
  },
  spacing: {
    margin: {
      top: { value: 10, unit: 'px' },
      right: { value: 10, unit: 'px' },
      bottom: { value: 10, unit: 'px' },
      left: { value: 10, unit: 'px' },
    },
    padding: {
      top: { value: 6, unit: 'px' },
      right: { value: 16, unit: 'px' },
      bottom: { value: 6, unit: 'px' },
      left: { value: 16, unit: 'px' },
    },
  },
  zIndex: 0,
};
