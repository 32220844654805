import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material';

export default function Loading() {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: theme.palette.primary.main,
      }}
      style={{ minHeight: '100vh' }}
      data-testid="loading-page"
    >
      <CircularProgress color="inherit" />
    </Box>
  );
}
