import { css } from '@emotion/react';
import { useTheme } from '@mui/material';

export const useStyles = () => {
  const theme = useTheme();

  return {
    container: css`
      display: flex;
      align-items: flex-end;
      gap: ${theme.spacing(0.5)};
      margin-top: ${theme.spacing(1)};
    `,
    label: css`
      text-transform: capitalize;
      margin-bottom: ${theme.spacing(1)};
    `,
  };
};
