import { css } from '@emotion/react';
import { useTheme } from '@mui/material';
import { brameTheme } from '@brame/theme';

export const useStyles = () => {
  const theme = useTheme();

  return {
    label: css`
      color: ${theme.palette.common.white};
      font-size: ${brameTheme.typography.subtitle1.fontSize};
      border-radius: 4px 4px 0 0;
      background-color: ${brameTheme.palette.primary.main};
      padding: ${theme.spacing(1)};
      margin-left: -2px;
      display: flex;
      align-items: center;
    `,
  };
};
