import { css } from '@emotion/react';
import { Theme } from '@mui/material';
import globalColors from '@brame/theme/src/globalColors';

export const useStyles = (theme?: Theme) => {
  return {
    header: css`
      position: relative;
      background-color: ${theme?.palette.background.default};
      box-shadow: 0 5px 10px rgba(35, 71, 7, 0.15);
      color: ${theme?.palette.text.primary};
      padding: ${theme?.spacing(0, 8)};
      @media (max-width: 768px) {
        padding: ${theme?.spacing(0, 2)};
      }
      a {
        color: ${theme?.palette.text.primary};
        &:active,
        &:hover {
          background-color: transparent;
        }
      }
    `,
    container: css`
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    `,
    headerTitle: css`
      font-size: ${theme?.typography.h3.fontSize};
      @media (max-width: 479px) {
        font-size: ${theme?.typography.h4.fontSize};
      }
    `,
    button: css`
      color: ${globalColors.stormDust};
    `,
  };
};
