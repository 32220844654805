//@ts-nocheck
import { css } from '@emotion/react';
import { ITheme } from '@brame/theme';

export const useStyles = (theme: ITheme) => ({
  heading: css`
    font-family: ${theme.theme.components?.MuiTypography?.styleOverrides?.h2
      ?.fontFamily};
    font-size: ${theme.theme.components?.MuiTypography?.styleOverrides?.h2
      ?.fontSize}px;
    background: ${theme.theme.components?.MuiTypography?.styleOverrides?.h2
      ?.background};
    text-transform: ${theme.theme.components?.MuiTypography?.styleOverrides?.h2
      ?.textTransform};
    color: ${theme.theme.palette?.primary?.main};
    width: 100%;
    text-align: center;
  `,
  body: css`
    font-family: ${theme.theme.components?.MuiTypography?.styleOverrides?.root
      ?.fontFamily};
    background: ${theme.theme.components?.MuiTypography?.styleOverrides?.root
      ?.background};
    text-transform: ${theme.theme.components?.MuiTypography?.styleOverrides
      ?.root?.textTransform};
    color: ${theme.theme.palette?.text?.primary};
    font-size: 1rem;
    width: 100%;
    text-align: center;
  `,
  button: css`
    font-family: ${theme.theme.components?.MuiButton?.styleOverrides?.root
      ?.fontFamily};
    font-weight: ${theme.theme.components?.MuiButton?.styleOverrides?.root
      ?.fontWeight};
    font-size: ${theme.theme.components?.MuiButton?.styleOverrides?.root
      ?.fontSize}px;
    background: ${theme.theme.components?.MuiButton?.styleOverrides?.root
      ?.background || theme.theme.palette?.primary?.main};
    border-radius: ${theme.theme.components?.MuiButton?.styleOverrides?.root
      ?.borderRadius}px;
    border-width: ${theme.theme.components?.MuiButton?.styleOverrides?.root
      ?.borderWidth}px;
    border-style: ${theme.theme.components?.MuiButton?.styleOverrides?.root
      ?.borderStyle};
    border-color: ${theme.theme.components?.MuiButton?.styleOverrides?.root
      ?.borderColor};
    color: ${theme.theme.components?.MuiButton?.styleOverrides?.root?.color};
    text-transform: ${theme.theme.components?.MuiButton?.styleOverrides?.root
      ?.textTransform};
    pointer-events: none;
    padding: 6px 16px;
    line-height: 1.75;
  `,
});
