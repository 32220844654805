/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import React, { FC } from 'react';
import { InputAdornment, styled, TextField, Typography } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../reducers/app/hooks';
import {
  selectGameSettings,
  setGameParam,
} from '../../../reducers/game/gameSlice';
import { IGameNumberRangeEditorProps } from './IProps';
import { useStyles } from './styles';

const StyledTextField = styled(TextField)`
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    display: none;
  }
`;

const GameNumberRangeEditor: FC<IGameNumberRangeEditorProps> = (props) => {
  const dispatch = useAppDispatch();
  const gameState = useAppSelector(selectGameSettings);
  const styles = useStyles();
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let result;
    const value =
      parseFloat(event.target.value) *
      (props.denominator ? props.denominator : 1);
    if (value >= props.max) {
      result = props.max;
    } else if (value <= props.min) {
      result = props.min;
    } else {
      result = value;
    }
    dispatch(
      setGameParam({
        param: props.name,
        value: result,
        index: props.index,
        arrayCategory: props.arrayCategory,
      })
    );
  };

  let value;
  if (props.arrayCategory) {
    //@ts-ignore
    value = gameState[props.arrayCategory][props.index][props.name];
  } else {
    //@ts-ignore
    value = gameState[props.name];
  }

  value /= props.denominator ? props.denominator : 1;

  return (
    <div data-testid="game-number-range-editor" css={styles.container}>
      {props.label && <Typography css={styles.title}>{props.label}</Typography>}
      {!props.unit ? (
        <TextField
          type="number"
          //@ts-ignore
          value={value}
          onChange={handleChange}
          inputProps={{ min: props.min, max: props.max }}
          fullWidth
        />
      ) : (
        <StyledTextField
          value={value}
          type="number"
          onChange={handleChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">{props.unit}</InputAdornment>
            ),
          }}
          inputProps={{ min: props.min, max: props.max }}
          fullWidth
        />
      )}
    </div>
  );
};

export default GameNumberRangeEditor;
