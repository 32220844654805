/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import React, { FC, useState } from 'react';
import { PencilSimple, TrashSimple } from 'phosphor-react';
import {
  Box,
  Button,
  IconButton,
  ListItem,
  Popover,
  Typography,
} from '@mui/material';
import { spawnCertainCollectible } from '@brame/components/src/phaser/games/DropGame/Collectibles';

import { IGameArrayElementProps } from '../../GameArrayEditor/IProps';
import GameEnumEditor from '../../GameEnumEditor';
import { useAppSelector } from '../../../../reducers/app/hooks';
import { selectGameSettings } from '../../../../reducers/game/gameSlice';
import GameStringEditor from '../../GameStringEditor';
import GameAssetEditor from '../../GameAssetEditor';

import { iconSize } from '../../../../constants';
import { useStyles } from './styles';

const DropGameCollectibleEditor: FC<IGameArrayElementProps> = (props) => {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLImageElement | null>(null);

  const styles = useStyles();

  const gameState = useAppSelector(selectGameSettings);
  //@ts-ignore
  const texKey = gameState[props.name][props.index]['textureKey'];
  //@ts-ignore
  const collecName = gameState[props.name][props.index]['name'];
  //@ts-ignore
  const imgSrc = gameState['assets'][texKey];

  const handleOpen = (event: any) => {
    setOpen(true);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => setOpen(false);

  const handleRemove = () => {
    props.removeFunction(props.index);
  };

  const mockCollectibleDrop = () => {
    spawnCertainCollectible(
      gameState[props.name][props.index]['itemType'],
      gameState[props.name][props.index]['textureKey']
    );
  };

  return (
    <ListItem css={styles.listBox} data-testid="collectible-item">
      <Box css={styles.wrapper}>
        <Box css={styles.imageWrapper}>
          <img src={imgSrc} alt={collecName} height="100%" />
        </Box>
        <Box>
          <Typography css={styles.imageWrapper}>{collecName}</Typography>
        </Box>
        <Box>
          <IconButton onClick={handleOpen}>
            <PencilSimple size={iconSize} />
          </IconButton>
          <IconButton onClick={handleRemove}>
            <TrashSimple size={iconSize} />
          </IconButton>
        </Box>
        <Popover open={open} onClose={handleClose} anchorEl={anchorEl}>
          <div css={styles.popoverBox}>
            <GameAssetEditor
              name="textureKey"
              scalingName="size"
              index={props.index}
              arrayCategory={props.name}
              label="Asset"
            />
            <GameStringEditor
              name="name"
              index={props.index}
              arrayCategory={props.name}
              label="Name"
            />
            <GameEnumEditor
              name="itemType"
              index={props.index}
              arrayCategory={props.name}
              label="Item Type"
              enums={['Points1', 'Points2', 'Points3', 'Life', 'Bomb']}
              descs={['1 Point', '2 Points', '3 Points', 'Life', 'Obstacle']}
              defaultValue="Points1"
            />
            <Button onClick={mockCollectibleDrop}>Test me</Button>
          </div>
        </Popover>
      </Box>
    </ListItem>
  );
};

export default DropGameCollectibleEditor;
