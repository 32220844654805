/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import React, { FC } from 'react';
import {
  TextBolder,
  TextItalic,
  TextStrikethrough,
  TextUnderline,
} from 'phosphor-react';
import { EmotionJSX } from '@emotion/react/types/jsx-namespace';
import { ToggleButton, ToggleButtonGroup, useTheme } from '@mui/material';
import { IconProps, TextDecoration } from '../../types';
import { iconSize } from '../../constants';
import { useStyles } from './styles';

type TextDecorationType = {
  name: string;
  icon: (props: IconProps) => EmotionJSX.Element;
};

const decorations: TextDecorationType[] = [
  {
    name: 'bold',
    icon: (props) => <TextBolder color={props.color} size={iconSize} />,
  },
  {
    name: 'italic',
    icon: (props) => <TextItalic color={props.color} size={iconSize} />,
  },
  {
    name: 'line-through',
    icon: (props) => <TextStrikethrough color={props.color} size={iconSize} />,
  },
  {
    name: 'underline',
    icon: (props) => <TextUnderline color={props.color} size={iconSize} />,
  },
];

interface IProps {
  textDecoration: TextDecoration;
  onChange: (values: TextDecoration) => void;
}

const FontDecorationWidget: FC<IProps> = ({ textDecoration, onChange }) => {
  const styles = useStyles();
  const theme = useTheme();

  const isActive = (name: string) => {
    return textDecoration.find((item) => item === name);
  };

  const decorationHandler = (values: string[]) => {
    const multipleExist = ['line-through', 'underline'].every((value) => {
      return values.includes(value);
    });

    if (multipleExist) {
      values.indexOf('line-through') > values.indexOf('underline')
        ? (values = values.filter((e: string) => e !== 'underline'))
        : (values = values.filter((e: string) => e !== 'line-through'));
    }
    onChange(values as TextDecoration);
  };

  return (
    <div data-testid="font-decoration-widget">
      <div>
        <ToggleButtonGroup
          value={textDecoration}
          sx={{ gap: 1 }}
          onChange={(e, value) => decorationHandler(value)}
        >
          {decorations.map(({ name, icon }) => (
            <ToggleButton
              key={name}
              value={name}
              size="small"
              data-testid={`font-decoration-toggle-${name}`}
              css={styles.button}
            >
              {icon({
                color: isActive(name)
                  ? theme.palette.primary.contrastText
                  : theme.palette.text.primary,
              })}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      </div>
    </div>
  );
};

export default FontDecorationWidget;
