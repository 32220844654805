import { MouseEvent, useState } from 'react';

const useAssetModal = () => {
  const [assetsModalIsOpen, setAssetsModalIsOpen] = useState(false);

  const openAssetsModal = (e: MouseEvent) => {
    e.stopPropagation();
    setAssetsModalIsOpen(true);
  };

  const closeAssetsModal = () => {
    setAssetsModalIsOpen(false);
  };

  return {
    assetsModalIsOpen,
    openAssetsModal,
    closeAssetsModal,
  };
};

export default useAssetModal;
