import { AxiosError } from 'axios';
import { generateId } from '../reducers/builder/utils';
import axiosI from './axios.service';

class TemplateService {
  private apiUrl = `${process.env.REACT_APP_API_URL}/campaign-configurations/api/templates`;

  public getTemplatesData = async (companyId: string | undefined) => {
    if (companyId) {
      const url = `${this.apiUrl}/all/${companyId}`;

      try {
        const res = await axiosI.get(url);

        if (typeof res.data === 'undefined') {
          return undefined;
        }
        return res.data.data;
      } catch (error) {
        const err = error as AxiosError;
        return err.response;
      }
    }
  };

  public getTemplateById = async (companyId: string, templateId: string) => {
    const url = `${this.apiUrl}/${companyId}/${templateId}`;

    try {
      const res = await axiosI.get(url);

      if (typeof res.data === 'undefined') {
        return undefined;
      }
      return res.data.data;
    } catch (error) {
      const err = error as AxiosError;
      return err.response;
    }
  };

  //TODO: type
  public addTemplate = async (
    companyId: string,
    templateData: { name: string; template: any }
  ) => {
    const { name, template } = templateData;
    const id = `template-${generateId()}`;
    const reqObject = {
      companyId: companyId,
      templateId: id,
      jsonData: { id, name, template },
    };
    try {
      const res = await axiosI.post(this.apiUrl, reqObject);

      if (res.data === 'undefined') {
        return res;
      }
      return res.data;
    } catch (error) {
      const err = error as AxiosError;
      return err.response;
    }
  };

  //TODO: type
  public updateTemplate = async (
    companyId: string,
    templateData: { id: string; template: any }
  ) => {
    const reqObject = {
      companyId,
      templateId: templateData.id,
      jsonData: templateData.template,
    };

    try {
      const res = await axiosI.put(this.apiUrl, reqObject);

      if (res.data === 'undefined') {
        return res.data;
      }
      return res.data.data;
    } catch (error) {
      const err = error as AxiosError;
      return err.response;
    }
  };

  public removeTemplate = async (companyId: string, templateId: string) => {
    const url = `${this.apiUrl}/${companyId}/${templateId}`;

    try {
      const res = await axiosI.delete(url);

      if (res.data === 'undefined') {
        return res.data;
      }
      return res.data.data;
    } catch (error) {
      const err = error as AxiosError;
      return err.response;
    }
  };
}

export default new TemplateService();
