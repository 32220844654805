import { Portal } from 'react-portal';
import { FC, RefObject, useCallback, useLayoutEffect, useState } from 'react';
import { IElement } from '../../reducers/builder';
import { IElementStyleProps } from '../../types';
import Resizable from './Resizable';

interface IProps {
  isOpen: boolean;
  element: IElement;
  styleProps: IElementStyleProps;
  elementRef: RefObject<any>;
}

const ResizablePortal: FC<IProps> = ({
  isOpen,
  element,
  styleProps,
  elementRef,
}) => {
  const [size, setSize] = useState({
    width: elementRef.current.offsetWidth,
    height: elementRef.current.offsetHeight,
  });

  const [position, setPosition] = useState({
    top: elementRef.current.offsetTop,
    left: elementRef.current.offsetLeft,
  });

  const setSizeAndPosition = useCallback(() => {
    setSize({
      width: elementRef.current.offsetWidth,
      height: elementRef.current.offsetHeight,
    });
    setPosition({
      top: elementRef.current.offsetTop,
      left: elementRef.current.offsetLeft,
    });
  }, [elementRef]);

  // Prevents video from jumping off the resizable container on 'auto' height
  useLayoutEffect(() => {
    if (elementRef?.current.tagName === 'VIDEO') {
      elementRef.current.addEventListener('canplay', () => {
        setSizeAndPosition();
      });

      return elementRef?.current.removeEventListener(
        'canplay',
        setSizeAndPosition
      );
    }
  }, [elementRef]);

  const hasParent = document.getElementById(
    element.id as string
  )!.parentElement;

  return (
    <>
      {isOpen && (
        <Portal
          node={
            hasParent &&
            document.getElementById(element.id as string)!.parentElement
          }
        >
          <Resizable
            element={element}
            styleProps={styleProps}
            size={size}
            position={position}
          />
        </Portal>
      )}
    </>
  );
};

export default ResizablePortal;
