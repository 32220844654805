import { css } from '@emotion/react';
import { useTheme } from '@mui/material';

export const useStyles = () => {
  const theme = useTheme();

  return {
    label: css`
      font-size: ${theme.typography.subtitle1.fontSize};
      color: ${theme.palette.text.primary};
      text-transform: capitalize;
      font-weight: ${theme.typography.fontWeightBold};
      padding-bottom: ${theme.spacing(1)};
    `,
  };
};
