import DropGameScene from './DropGameScene';

export function initBoardSpawnPosition(scene: DropGameScene) {
  let matterDir = 0;
  if (scene.gameDirection == 'TopBot' || scene.gameDirection == 'BotTop') {
    matterDir = scene.gameHeight;
    scene.playerStartX = scene.gameWidth / 2;
    scene.gameHeight / (DropGameScene.jsonLink['game.initSpeed'] * 60);
    scene.playerOffsetX = 0;
    scene.deathBoxX = scene.gameWidth / 2;
    scene.deathBoxWidth = scene.gameWidth;
    scene.deathBoxHeight = 20;
  } else {
    matterDir = scene.gameWidth;
    scene.playerStartY = scene.gameHeight / 2;
    scene.playerOffsetY = 0;
    scene.deathBoxY = scene.gameHeight / 2;
    scene.deathBoxWidth = 20;
    scene.deathBoxHeight = scene.gameHeight;
  }
  scene.matterInitSpeed =
    matterDir / (DropGameScene.jsonLink['game.initSpeed'] * 60);
  switch (scene.gameDirection) {
    case 'TopBot':
      scene.playerStartY = scene.gameHeight;
      scene.playerOffsetY = -1;
      scene.deathBoxY = scene.gameHeight * 1.1;
      break;
    case 'BotTop':
      scene.playerStartY = 0;
      scene.playerOffsetY = 1;
      scene.deathBoxY = -scene.gameHeight * 0.1;
      break;
    case 'LeftRight':
      scene.playerStartX = scene.gameWidth;
      scene.playerOffsetX = -1;
      scene.deathBoxX = scene.gameWidth * 1.1;
      break;
    case 'RightLeft':
      scene.playerStartX = 0;
      scene.playerOffsetX = 1;
      scene.deathBoxX = -scene.gameWidth * 0.1;
      break;
  }
}

export function initLaneSpawnPositions(scene: DropGameScene) {
  let laneDir = 0;
  switch (scene.gameDirection) {
    case 'TopBot':
    case 'BotTop':
      laneDir = scene.gameWidth;
      break;
    case 'LeftRight':
    case 'RightLeft':
      laneDir = scene.gameHeight;
      break;
  }

  const totalLanesSize =
    laneDir *
    (1 -
      DropGameScene.jsonLink['game.laneOffsetTop'] / 100 -
      DropGameScene.jsonLink['game.laneOffsetBot'] / 100);

  scene.laneSize = totalLanesSize / DropGameScene.jsonLink['game.laneCount'];
  let startPos: number;
  if (
    !DropGameScene.jsonLink['game.laneOffsetBot'] &&
    !DropGameScene.jsonLink['game.laneOffsetTop']
  ) {
    startPos = scene.laneSize / 2;
  } else {
    startPos = (DropGameScene.jsonLink['game.laneOffsetTop'] / 100) * laneDir;
  }
  // Lane Settings
  for (let i = 0; i < DropGameScene.jsonLink['game.laneCount']; ++i) {
    const spawnPos = new Phaser.Math.Vector2(0, 0);
    switch (scene.gameDirection) {
      case 'TopBot':
        spawnPos.x = i * scene.laneSize + startPos;
        break;
      case 'BotTop':
        spawnPos.x = i * scene.laneSize + startPos;
        spawnPos.y = scene.gameHeight;
        break;
      case 'LeftRight':
        spawnPos.y = i * scene.laneSize + startPos;
        break;
      case 'RightLeft':
        spawnPos.x = scene.gameWidth;
        spawnPos.y = i * scene.laneSize + startPos;
        break;
    }
    scene.laneArray.push(spawnPos);
  }
}

export function drawDebugLanes(scene: DropGameScene) {
  const graphics = scene.add.graphics();
  graphics.lineStyle(1, 0xff0000, 1);
  let endpoint = 0;
  switch (scene.gameDirection) {
    case 'TopBot':
      endpoint = scene.gameHeight;
      break;
    case 'LeftRight':
      endpoint = scene.gameWidth;
      break;
  }
  for (const entry in scene.laneArray) {
    switch (scene.gameDirection) {
      case 'TopBot':
      case 'BotTop':
        graphics.lineBetween(
          scene.laneArray[entry].x + scene.laneSize / 2,
          scene.laneArray[entry].y,
          scene.laneArray[entry].x + scene.laneSize / 2,
          endpoint
        );
        graphics.lineBetween(
          scene.laneArray[entry].x - scene.laneSize / 2,
          scene.laneArray[entry].y,
          scene.laneArray[entry].x - scene.laneSize / 2,
          endpoint
        );
        break;
      case 'LeftRight':
      case 'RightLeft':
        graphics.lineBetween(
          scene.laneArray[entry].x,
          scene.laneArray[entry].y + scene.laneSize / 2,
          endpoint,
          scene.laneArray[entry].y + scene.laneSize / 2
        );
        graphics.lineBetween(
          scene.laneArray[entry].x,
          scene.laneArray[entry].y - scene.laneSize / 2,
          endpoint,
          scene.laneArray[entry].y - scene.laneSize / 2
        );
        break;
    }
  }
}
