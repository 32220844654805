import Phaser from 'phaser';
import PhaserAssetLoader from '../../PhaserAssetLoader';

export default class LoadingScene extends Phaser.Scene {
  public gameWidth!: number;
  public gameHeight!: number;
  public static assetLink: Record<string, unknown>;
  public static nextScene: string;

  constructor() {
    super('LoadingScene');
  }

  preload() {
    this.gameWidth = this.game.canvas.width;
    this.gameHeight = this.game.canvas.height;

    const progressBox = this.add.graphics();
    const progressBar = this.add.graphics();
    progressBox.fillStyle(0x222222, 0.8);
    progressBox.fillRoundedRect(
      this.gameWidth / 4,
      this.gameHeight / 2 - this.gameHeight / 60,
      this.gameWidth / 2,
      this.gameHeight / 30,
      this.gameHeight / 75
    );
    const percentText = this.make.text({
      x: this.gameWidth / 2,
      y: this.gameHeight / 2,
      text: '0%',
      style: {
        fontSize: this.gameHeight / 40 + 'px',
      },
    });
    percentText.setOrigin(0.5, 0.5);
    this.load.on('progress', (value: number) => {
      if (value) {
        percentText.setText(Math.ceil(value * 100) + '%');
        progressBar.clear();
        progressBar.fillStyle(0x2ae068, 1);
        progressBar.fillRoundedRect(
          this.gameWidth / 4,
          this.gameHeight / 2 - this.gameHeight / 60,
          (this.gameWidth / 2) * value,
          this.gameHeight / 30,
          this.gameHeight / 75
        );
      }
    });

    this.load.on('complete', () => {
      // Switch scenes
      this.scene.start(LoadingScene.nextScene);
    });
    PhaserAssetLoader(LoadingScene.assetLink, this);
  }
}
