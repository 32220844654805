/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import React, { FC, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../reducers/app/hooks';
import {
  changeAsset,
  selectGameSettings,
  setGameParam,
} from '../../../reducers/game/gameSlice';

import GameSizingEditor from '../GameSizingEditor';
import { AssetsModal, useAssetModal } from '../../AssetsModal';
import { AssetPlaceholder } from '../../AssetPlaceholder';
import { IAssetData } from '../../../types';
import { BuilderComponent } from '../../../reducers/builder';
import { IGameAssetEditorProps } from './IProps';

const GameAssetEditor: FC<IGameAssetEditorProps> = (props) => {
  const { assetsModalIsOpen, openAssetsModal, closeAssetsModal } =
    useAssetModal();
  const dispatch = useAppDispatch();
  const gameState = useAppSelector(selectGameSettings);

  const applyAsset = (asset: IAssetData) => {
    const img = new Image();
    img.src = asset.url;
    img.onload = () => {
      setIntrinsicSize({ height: img.naturalHeight, width: img.naturalWidth });
    };
    dispatch(
      setGameParam({
        param: props.name,
        value: asset.name,
        index: props.index,
        arrayCategory: props.arrayCategory,
      })
    );
    dispatch(
      changeAsset({
        texName: asset.name,
        url: asset.url,
      })
    );
    closeAssetsModal();
  };

  let value: string;
  if (props.arrayCategory) {
    //@ts-ignore
    value = gameState[props.arrayCategory][props.index][props.name];
    //@ts-ignore
    value = gameState['assets'][value];
  } else {
    //@ts-ignore
    value = gameState[props.name];
    //@ts-ignore
    value = gameState['assets'][value];
  }

  const [intrinsicSize, setIntrinsicSize] = useState({ width: 0, height: 0 });
  useEffect(() => {
    const img = new Image();
    img.src = value;
    img.onload = () => {
      setIntrinsicSize({ height: img.naturalHeight, width: img.naturalWidth });
    };
  }, []);

  return (
    <div data-testid="game-asset-editor">
      <AssetPlaceholder
        type={BuilderComponent.IMAGE}
        src={value}
        asset={null}
        onClick={openAssetsModal}
      />
      {props.scalingName && (
        <GameSizingEditor
          name={props.scalingName}
          arrayCategory={props.arrayCategory}
          index={props.index}
          label=""
          aspectRatio={true}
          actualSize={intrinsicSize}
        />
      )}
      <AssetsModal
        isOpen={assetsModalIsOpen}
        onApplyAsset={applyAsset}
        onCancel={closeAssetsModal}
        elementType={BuilderComponent.IMAGE}
      />
    </div>
  );
};

export default GameAssetEditor;
