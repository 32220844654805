/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import React, { FC } from 'react';
import { Button } from '@mui/material';
import {
  callPhaserFunction,
  usePhaserFunctionsList,
} from '@brame/components/src/phaser/games/PhaserGameHooks';
import { SelectInput } from '../../SelectInput';
import { ButtonEditor } from '../../ButtonEditor';

import { IPhaserButtonEditorProps } from './IProps';

const PhaserButtonEditor: FC<IPhaserButtonEditorProps> = ({
  styleProps,
  elementProps,
  gameType,
  onChange,
  onReset,
  props,
}) => {
  //@ts-ignore
  const { functionID } = props;
  const handleClick = () => {
    callPhaserFunction(functionID);
  };
  return (
    <div>
      <SelectInput
        value={functionID || ''}
        options={usePhaserFunctionsList(gameType)}
        onChange={(value) =>
          onChange({
            functionID: value,
          })
        }
      />
      <Button onClick={handleClick}>Test</Button>
      <ButtonEditor
        styleProps={styleProps}
        elementProps={elementProps}
        onChange={onChange}
        onReset={onReset}
        isPhaser={true}
      />
    </div>
  );
};

export default PhaserButtonEditor;
