import { useAppSelector } from '../reducers/app/hooks';
import { UserRole } from '../types';

const useUserRoles = () => {
  const roles = useAppSelector((state) => state.auth.parsedToken.role);

  const hasRole = (userRole: UserRole) => {
    return Boolean(roles?.find((role) => role === userRole));
  };

  return { hasRole };
};

export default useUserRoles;
