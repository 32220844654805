import {
  ConditionalGameOutcomeInterface,
  GameOutcomeInterface,
} from '@brame/builder/src/components/GameEditors/GameInterfaces';
import { goToPage } from '../../slices/pageSlice';
import { store } from '../../slices/store/store';

export function dispatchConditionalPageChange(
  outcomes: ConditionalGameOutcomeInterface[]
) {
  for (const outcome in outcomes) {
    const gameState = store.getState()['gameState'];
    // Sort out the default case
    if (outcome === '0') {
      continue;
    }
    // Switch on comparator
    switch (outcomes[outcome]['comparator']) {
      case '>':
        if (
          //@ts-ignore
          gameState[outcomes[outcome]['value.name']] >
          outcomes[outcome]['condition.value']
        ) {
          store.dispatch(goToPage(outcomes[outcome]['page']));
          return;
        }
        break;
      case '<':
        if (
          //@ts-ignore
          gameState[outcomes[outcome]['value.name']] <
          outcomes[outcome]['condition.value']
        ) {
          store.dispatch(goToPage(outcomes[outcome]['page']));
          return;
        }
        break;
      case '=':
        if (
          //@ts-ignore
          gameState[outcomes[outcome]['value.name']] ===
          outcomes[outcome]['condition.value']
        ) {
          store.dispatch(goToPage(outcomes[outcome]['page']));
          return;
        }
        break;
      case '>=':
        if (
          //@ts-ignore
          gameState[outcomes[outcome]['value.name']] >=
          outcomes[outcome]['condition.value']
        ) {
          store.dispatch(goToPage(outcomes[outcome]['page']));
          return;
        }
        break;
      case '<=':
        if (
          //@ts-ignore
          gameState[outcomes[outcome]['value.name']] <=
          outcomes[outcome]['condition.value']
        ) {
          store.dispatch(goToPage(outcomes[outcome]['page']));
          return;
        }
        break;
    }
  }
  // Case we didn't find a matching outcome
  store.dispatch(goToPage(outcomes['0']['page']));
}

export function dispatchPageChange(
  outcomes: GameOutcomeInterface[],
  outcomeUUID: string
) {
  for (const outcome in outcomes) {
    if (outcomes[outcome]['uuid'] === outcomeUUID) {
      store.dispatch(goToPage(outcomes[outcome]['page']));
    }
  }
}
