import { Trash, Copy } from 'phosphor-react';
import { GridColDef } from '@mui/x-data-grid';
import Link from '@mui/material/Link';
import { IconButton } from '@mui/material';

export const getColumns = ({
  onDelete,
  onCopyCampaign,
}: {
  onDelete: (campaign: { id: string; name: string }) => void;
  onCopyCampaign: (campaign: { id: string; name: string }) => void;
}): GridColDef[] => [
  {
    field: 'id',
    headerName: 'ID',
    minWidth: 300,
    flex: 1,
    disableColumnMenu: true,
    renderCell: (params) => <Link href={`${params.id}`}>{params.id}</Link>,
  },
  {
    field: 'name',
    headerName: 'Campaign',
    flex: 1,
  },
  {
    field: 'description',
    headerName: 'Description',
    flex: 2,
    disableColumnMenu: true,
  },
  {
    field: 'status',
    headerName: 'Status',
    headerAlign: 'center',
    align: 'center',
    flex: 1,
  },
  {
    field: 'actions',
    type: 'actions',
    renderCell: (params) => {
      return (
        <div>
          <IconButton
            onClick={() =>
              onDelete({ id: params.row.id, name: params.row.name })
            }
          >
            <Trash size={24} />
          </IconButton>
          <IconButton
            onClick={() =>
              onCopyCampaign({ id: params.row.id, name: params.row.name })
            }
          >
            <Copy size={24} />
          </IconButton>
        </div>
      );
    },
  },
];
