import { css } from '@emotion/react';
import { useTheme } from '@mui/material';
import { brameTheme } from '@brame/theme';

export const useStyles = () => {
  const theme = useTheme();

  return {
    widget: css`
      display: flex;
      flex-direction: column;
      gap: ${theme.spacing(1)};
    `,
    buttonGroup: css`
      gap: ${theme.spacing(1)};
      flex-wrap: wrap;
    `,
    button: css`
      border: none;
      border-radius: ${brameTheme.shape.borderRadius}px !important;
      width: 36px;
      height: 36px;
      padding: ${theme.spacing(0.5)};

      &.Mui-selected {
        background: ${theme.palette.primary.main};

        * {
          border-color: ${theme.palette.common.white};
        }

        &:hover {
          background: ${theme.palette.primary.main};

          * {
            border-color: ${theme.palette.common.white};
          }
        }
      }
    }
    `,
    preview: css`
      width: 100%;
      height: 100%;
      border-width: 2px;
      border-color: ${theme.palette.text.primary};
    `,
  };
};
