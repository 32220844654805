import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../reducers/app/hooks';
import { selectAuth } from '../../reducers/auth';
import CampaignService from '../../api-client/campaigns.service';

export const useCopyCampaign = () => {
  const navigate = useNavigate();
  const authState = useAppSelector(selectAuth);
  const companyId = authState.parsedToken.company_id;

  const createNewCampaign = async (newCampaignName: string, data: any) => {
    const res = await CampaignService.createCampaignByCompany(
      companyId,
      newCampaignName
    );
    if (res.data?.id) {
      await CampaignService.postInitialCampaignDataByCompanyAndCampaign(
        companyId,
        res.data.id,
        data
      );
    }
    return await res;
  };

  const copyCampaign = async (campaign: { id: string; name: string }) => {
    const currentCampaign =
      await CampaignService.getCampaignDataByCompanyAndCampaign(
        companyId,
        campaign.id
      );

    const currentCampaignData = currentCampaign.data.data;
    const copyCampaignData = {
      additionalPages: currentCampaignData.additionalPages,
      canvasDimensions: currentCampaignData.canvasDimensions,
      canvasResponsiveness: currentCampaignData.canvasResponsiveness,
      dimensions: currentCampaignData.dimensions,
      elements: currentCampaignData.elements,
      game: currentCampaignData.game,
      pages: currentCampaignData.pages,
      previewMode: currentCampaignData.previewMode,
      readiness: currentCampaignData.readiness,
      selectedElementId: currentCampaignData.selectedElementId,
      selectedPageId: currentCampaignData.selectedPageId,
      selectedTheme: currentCampaignData.selectedTheme,
    };

    if (campaign.name && currentCampaign) {
      const newCampaignData = await createNewCampaign(
        `${campaign.name}_COPY`,
        copyCampaignData
      );

      navigate(`/${newCampaignData?.data.id}`);
    }
  };

  return { copyCampaign };
};
