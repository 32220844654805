/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import React from 'react';
import { Form, Formik, FormikValues } from 'formik';
import * as yup from 'yup';
import { Grid } from '@mui/material';
import { getFormId } from '@brame/builder/src/reducers/builder/utils';
import Container from '../Container/Container';
import { goToNextPage } from '../../slices/pageSlice';
import { useRunnerDispatch } from '../../slices/store/store';
import { useFormSubmit } from '../../hooks/useFormSubmit';
import { IFieldsDictionary, IFormComponentProps, IFormField } from './IProps';
import { VariativeInput } from './VariativeInput';
import { useStyles } from './styles';
import { getValidationDictionary } from './validation';

const FormComponent = React.forwardRef<HTMLDivElement, IFormComponentProps>(
  ({ styles, ...props }, ref) => {
    const fields = props?.fields || [];
    const currentTheme = props?.currentTheme;
    const classes = useStyles();
    const formValues: FormikValues = {};
    const validation: any = {};
    const fieldDictionary: IFieldsDictionary<IFormField> = fields.reduce(
      (acc, item) => {
        return { ...acc, [item.name]: { ...item } };
      },
      {}
    );
    const validationDictionary = getValidationDictionary(fieldDictionary);
    const id = getFormId((props as any)?.id);

    const dispatch = useRunnerDispatch();
    const submitForm = useFormSubmit();

    fields.forEach((field): void => {
      formValues[field.name] = field.value;
      validation[field.name] = validationDictionary[field.name];
    });

    const validationSchema = yup.object().shape(validation);

    const submitValues = (values: FormikValues): void => {
      submitForm(values)
        .then(() => {
          dispatch(goToNextPage());
        })
        .catch((error) => console.error(error));
    };

    return (
      <Container
        data-testid="form-component"
        {...props}
        ref={ref}
        style={styles}
      >
        <Grid css={classes.formWrapper}>
          <Formik
            initialValues={formValues}
            onSubmit={submitValues}
            validationSchema={validationSchema}
            validateOnChange={false}
            enableReinitialize
          >
            {({ values }) => (
              <Form id={id} noValidate>
                <div
                  style={{
                    display: 'grid',
                    gridTemplateColumns:
                      props?.elementStyles?.grid?.gridTemplateColumns,
                    gap: '10px',
                    maxWidth: '100%',
                  }}
                >
                  {Object.entries(values).map(([name, value]) => (
                    <div key={name} css={classes.formItemWrapper}>
                      <VariativeInput
                        name={name}
                        value={value}
                        field={fieldDictionary[name]}
                        //@ts-ignore
                        inputStyles={props?.elementStyles?.input}
                        //@ts-ignore
                        labelStyles={props?.elementStyles?.label}
                        currentTheme={currentTheme}
                      />
                    </div>
                  ))}
                </div>
              </Form>
            )}
          </Formik>
        </Grid>
      </Container>
    );
  }
);

export default FormComponent;
