/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import React, { FC } from 'react';
import { HexColorInput } from 'react-colorful';
import { Typography } from '@mui/material';
import { ColorPicker } from '../../ColorPicker';
import { useAppDispatch, useAppSelector } from '../../../reducers/app/hooks';
import {
  selectGameSettings,
  setGameParam,
} from '../../../reducers/game/gameSlice';
import { useStyles } from './styles';
import { IGameColorEditorProps } from './IProps';

const GameColorEditor: FC<IGameColorEditorProps> = (props) => {
  const styles = useStyles();

  const dispatch = useAppDispatch();
  const gameState = useAppSelector(selectGameSettings);
  const handleChange = (newColor: string) => {
    dispatch(
      setGameParam({
        param: props.name,
        value: newColor,
        index: props.index,
        arrayCategory: props.arrayCategory,
      })
    );
  };
  let value;
  if (props.arrayCategory) {
    //@ts-ignore
    value = gameState[props.arrayCategory][props.index][props.name];
  } else {
    //@ts-ignore
    value = gameState[props.name];
  }

  return (
    <div className="color-picker-widget" data-testid="color-picker-widget">
      <Typography css={styles.title} data-testid="color-picker-title">
        {props.label}
      </Typography>
      <div css={styles.styleControl}>
        <ColorPicker
          //@ts-ignore
          value={value}
          onChange={handleChange}
          alpha={true}
        />
        <HexColorInput
          prefixed
          //@ts-ignore
          color={value}
          onChange={handleChange}
          data-testid="color-hex-input"
          css={styles.colorHexInput}
        />
      </div>
    </div>
  );
};

export default GameColorEditor;
