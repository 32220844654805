import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export enum Tabs {
  THEME_SETTINGS = 'theme-settings',
  ELEMENTS = 'elements',
  SETTINGS = 'settings',
}

const activeTabSlice = createSlice({
  name: 'activeTab',
  initialState: Tabs.ELEMENTS,
  reducers: {
    setActiveTab: (state, action: PayloadAction<Tabs>) =>
      (state = action.payload),
  },
});

export const { setActiveTab } = activeTabSlice.actions;

export default activeTabSlice.reducer;
