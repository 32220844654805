//@ts-nocheck
import { css } from '@emotion/react';
import { ITheme } from '@brame/theme';
import globalColors from '@brame/theme/src/globalColors';

interface IProps {
  theme: ITheme;
  isCurrent: boolean;
}

export const useStyles = ({ theme, isCurrent }: IProps) => ({
  previewBox: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    overflow: hidden;
    justify-content: space-between;
    padding: 15px;
    position: relative;
    border-radius: 20px;
    min-height: 130px;
    margin: 10px 0;
    cursor: pointer;
    background: ${theme.theme.palette?.background?.paper}
      url(${theme.assets?.defaultCreatives?.images?.['default background']
        ?.url})
      center no-repeat;
    background-size: cover;
    box-shadow: ${isCurrent ? `0px 10px 20px rgba(35, 71, 7, 0.2)` : undefined};
    transition: 0.05s all ease-in-out;
    transform: ${isCurrent ? `scale(1.1)` : undefined};
  `,
  button: css`
    font-family: ${theme.theme.components?.MuiButton?.styleOverrides?.root
      ?.fontFamily};
    font-weight: ${theme.theme.components?.MuiButton?.styleOverrides?.root
      ?.fontWeight};
    font-size: 18px;
    background: ${theme.theme.components?.MuiButton?.styleOverrides?.root
      ?.background || theme.theme.palette?.primary?.main};
    border-radius: ${theme.theme.components?.MuiButton?.styleOverrides?.root
      ?.borderRadius}px;
    border-width: ${theme.theme.components?.MuiButton?.styleOverrides?.root
      ?.borderWidth}px;
    border-style: ${theme.theme.components?.MuiButton?.styleOverrides?.root
      ?.borderStyle};
    border-color: ${theme.theme.components?.MuiButton?.styleOverrides?.root
      ?.borderColor};
    color: ${theme.theme.components?.MuiButton?.styleOverrides?.root?.color};
    text-transform: ${theme.theme.components?.MuiButton?.styleOverrides?.root
      ?.textTransform};
    pointer-events: none;
    padding: 6px 16px;
    line-height: 1.75;
  `,
  heading: css`
    font-family: ${theme.theme.components?.MuiTypography?.styleOverrides?.h2
      ?.fontFamily};
    font-size: 24px;
    background: ${theme.theme.components?.MuiTypography?.styleOverrides?.h2
      ?.background};
    text-transform: ${theme.theme.components?.MuiTypography?.styleOverrides?.h2
      ?.textTransform};
    color: ${theme.theme.palette?.primary?.main};
    width: 100%;
    text-align: center;
  `,
  checkMark: css`
    background: ${globalColors.white};
    border-radius: 50%;
    width: 23px;
    height: 23px;
    position: absolute;
    bottom: 10px;
    right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  deleteMark: css`
    background: ${globalColors.white};
    border-radius: 50%;
    width: 23px;
    height: 23px;
    position: absolute;
    bottom: 10px;
    left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity 300ms ease;
  `,
  body: css`
    font-family: ${theme.theme.components?.MuiTypography?.styleOverrides?.root
      ?.fontFamily};
    font-size: 18px;
    background: ${theme.theme.components?.MuiTypography?.styleOverrides?.root
      ?.background};
    text-transform: ${theme.theme.components?.MuiTypography?.styleOverrides
      ?.root?.textTransform};
    color: ${theme.theme.palette?.text?.primary};
    width: 100%;
    text-align: center;
  `,
});
