import {
  BuilderComponent,
  CanvasElement,
} from '@brame/builder/src/reducers/builder';
import { ITheme } from '@brame/theme';

const findThemedAsset = (theme: ITheme, element: CanvasElement) => {
  const getCollectionType = () => {
    if (
      element?.type === BuilderComponent.CONTAINER ||
      element?.type === BuilderComponent.ROOT_CONTAINER
    ) {
      if (element?.styles?.overrides?.background?.themedImage) {
        return 'images';
      }
      if (element?.styles?.overrides?.background?.themedVideo) {
        return 'videos';
      }
    }
    if (element?.type === BuilderComponent.IMAGE) return 'images';
    if (element?.type === BuilderComponent.VIDEO) return 'videos';
  };

  const themedMediaCollection =
    theme?.assets?.defaultCreatives[
      // @ts-ignore
      getCollectionType()
    ];

  if (themedMediaCollection) {
    if (element?.type === BuilderComponent.IMAGE) {
      if (element?.themedImage) {
        return themedMediaCollection[element?.themedImage];
      }
    }

    if (element?.type === BuilderComponent.VIDEO) {
      if (element?.themedVideo) {
        return themedMediaCollection[element?.themedVideo];
      }
    }

    if (
      element?.type === BuilderComponent.CONTAINER ||
      element?.type === BuilderComponent.ROOT_CONTAINER
    ) {
      if (element?.styles?.overrides?.background?.themedImage) {
        return themedMediaCollection[
          element.styles?.overrides?.background?.themedImage
        ];
      }

      if (element?.styles?.overrides?.background?.themedVideo) {
        return themedMediaCollection[
          element?.styles?.overrides?.background?.themedVideo
        ];
      }
    }
  }

  return;
};

export default findThemedAsset;
