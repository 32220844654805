import { css } from '@emotion/react';

export const useStyles = () => ({
  iconWrapper: css`
    position: relative;
  `,
  iconUpper: css`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -70%);
  `,
});
