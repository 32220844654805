/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import React, { FC } from 'react';
import { useAppDispatch, useAppSelector } from '../../../reducers/app/hooks';
import {
  selectGameSettings,
  setGameParam,
} from '../../../reducers/game/gameSlice';
import { ISizing } from '../../../types';
import { SizingWidget } from '../../SizingWidget';
import { DropGameSettingsKey } from '../DropGame/StateDefaults/DropGameInterface';
import { IGameSizingEditorProps } from './IProps';

const GameSizingEditor: FC<IGameSizingEditorProps> = ({
  onChange,
  index,
  name,
  arrayCategory,
  actualSize,
  styleProps,
}) => {
  const dispatch = useAppDispatch();
  const gameState = useAppSelector(selectGameSettings);
  const size =
    arrayCategory && index !== undefined
      ? gameState[arrayCategory][index][name]
      : gameState[name as DropGameSettingsKey];

  const onWidgetChange = (value: ISizing) => {
    // Update store;
    dispatch(
      setGameParam({
        param: name,
        value: value,
        index: index,
        arrayCategory: arrayCategory,
      })
    );
    if (styleProps) {
      onChange({
        ...styleProps,
        size: value,
      });
    }
  };

  return (
    <div>
      <SizingWidget
        size={size}
        actualSize={actualSize}
        showAspectRatioToggle={true}
        onChange={onWidgetChange}
        useAuto={false}
      />
    </div>
  );
};

export default GameSizingEditor;
