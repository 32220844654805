import { defaultThemes, ITheme } from '@brame/theme';
import { IThemesState } from '../builder';

const initialTheme: ITheme = defaultThemes.theme1;

export const initialThemeState: IThemesState = {
  currentTheme: initialTheme,
  chosenTheme: initialTheme,
  themeCollection: defaultThemes,
};

export const editedTheme: ITheme = defaultThemes.theme1;
