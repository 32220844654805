import Phaser from 'phaser';

export default function PhaserAssetLoader(
  assetSettings: any,
  scene: Phaser.Scene
) {
  scene.load.setCORS('anonymous');
  // Load Images
  Object.keys(assetSettings).forEach((key) => {
    scene.load.image(key, assetSettings[key] + '?' + new Date().getTime());
  });
}
