/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { FC, Fragment } from 'react';
import { Typography } from '@mui/material';
import useEmailEditor from '../../hooks/useEmailEditor/useEmailEditor';
import { EmailTemplates } from '../EmailTemplates';
import { LibrarySection } from './LibrarySection';
import { useStyles } from './styles';
import tree from './elementsTree';

const ComponentsLibrary: FC = () => {
  const styles = useStyles();
  const { isEmailPage } = useEmailEditor();

  return (
    <div data-testid="components-library" css={styles.library}>
      <div>
        {isEmailPage ? (
          <EmailTemplates />
        ) : (
          <Fragment>
            {tree.map((section) => (
              <LibrarySection section={section} key={section.title} />
            ))}
          </Fragment>
        )}
      </div>

      <div css={styles.disclaimer}>
        <Typography>DRAG AND DROP ELEMENT</Typography>
        <Typography css={styles.hintLabel}>
          When you add an item, the setting options will appear on the right in
          the
        </Typography>
        <Typography>PROPERTIES MENU</Typography>
      </div>
    </div>
  );
};

export default ComponentsLibrary;
