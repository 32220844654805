import { css } from '@emotion/react';
import { useTheme } from '@mui/material';

export const useStyles = () => {
  const theme = useTheme();

  return {
    listItem: css`
      padding: ${theme.spacing(0)};
    `,
    popoverBox: css`
      width: 250px;
      padding: ${theme.spacing(3)};
      display: flex;
      flex-direction: column;
      gap: ${theme.spacing(1)};
    `,
    wrapper: css`
      display: grid;
      align-items: center;
      grid-template-columns: auto 1fr 72px;
      height: 30px;
      width: 100%;
      gap: ${theme.spacing(2)};
    `,
    imageWrapper: css`
      height: 30px;
    `,
    itemText: css`
      text-align: left;
      display: block;
      width: 100%;
    `,
  };
};
