import { DataGrid } from '@mui/x-data-grid';
import { CampaignMetaDataIProps } from '../../reducers/campaign/types';
import { getColumns } from './constants';

const CampaignsList = ({
  campaigns,
  onDelete,
  onCopyCampaign,
}: {
  campaigns: CampaignMetaDataIProps[];
  onDelete: (campaign: { id: string; name: string }) => void;
  onCopyCampaign: (campaign: { id: string; name: string }) => void;
}) => {
  return (
    <DataGrid
      rows={campaigns}
      columns={getColumns({ onDelete, onCopyCampaign })}
      pageSize={25}
      density="compact"
      autoHeight
    />
  );
};

export default CampaignsList;
