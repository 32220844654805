export const styleOverrides = {
  zIndex: 3,
  borderRadius: 0,
  transform: 'none',
  filter: 'none',
  boxShadow: 'none',
  border: 'none',
};

export const enabled = {
  top: true,
  right: true,
  bottom: true,
  left: true,
  topRight: true,
  bottomRight: true,
  bottomLeft: true,
  topLeft: true,
};

export const disabled = {
  top: false,
  right: false,
  bottom: false,
  left: false,
  topRight: false,
  bottomRight: false,
  bottomLeft: false,
  topLeft: false,
};

const disabledCorners = {
  topRight: false,
  bottomRight: false,
  bottomLeft: false,
  topLeft: false,
};

export const disabledWidth = {
  right: false,
  left: false,
  ...disabledCorners,
};

export const disabledHeight = {
  top: false,
  bottom: false,
  ...disabledCorners,
};

export const getResizingPermissions = (
  prohibitWidth: boolean,
  prohibitHeight: boolean
) => {
  let permissions = enabled;

  if (prohibitWidth) {
    permissions = { ...permissions, ...disabledWidth };
  }
  if (prohibitHeight) {
    permissions = { ...permissions, ...disabledHeight };
  }

  return permissions;
};
