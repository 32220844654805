/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import React, { FC } from 'react';
import {
  TextAlignJustify,
  TextAlignLeft,
  TextAlignRight,
} from 'phosphor-react';
import { ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { FlexAlignSelf } from '../../types';
import { iconSize } from '../../constants';
import { useStyles } from './styles';

type TextAlignType = {
  name: string;
  icon: React.ReactElement;
};

const alignVariants: TextAlignType[] = [
  { name: 'flex-start', icon: <TextAlignLeft size={iconSize} /> },
  { name: 'center', icon: <TextAlignJustify size={iconSize} /> },
  { name: 'flex-end', icon: <TextAlignRight size={iconSize} /> },
];

interface IProps {
  align: FlexAlignSelf;
  onChange: (align: FlexAlignSelf) => void;
}

const AlignSelfWidget: FC<IProps> = ({ align, onChange }) => {
  const styles = useStyles();
  return (
    <div data-testid="align-self-widget">
      <div>
        <Typography css={styles.title}>Positioning</Typography>
        <ToggleButtonGroup
          value={align}
          exclusive
          onChange={(e, value) => !!value && onChange(value)}
        >
          {alignVariants.map(({ name, icon }) => (
            <ToggleButton
              key={name}
              value={name}
              size="small"
              data-testid={`align-self-toggle-${name}`}
            >
              {icon}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      </div>
    </div>
  );
};

export default AlignSelfWidget;
