/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { useMemo } from 'react';
import { BuilderComponent } from '@brame/builder/src/reducers/builder';
import { buildDefaultStylesFromTheme } from '@brame/builder/src/components/RightPanel/Editors/utils';
import parseStyleProps from '@brame/builder/src/components/Canvas/utils';
import findThemedAsset from '../themedAssetsUtils';
import { getAction, getElementProps, isContainer } from './utils';
import { getComponent, haveNoValueElements } from './constants';
import { CanvasComponentProps, CanvasWrappedComponentProps } from './types';
import { useMediaQueries } from './hooks';

const CanvasComponent = ({
  elements,
  element,
  index,
  theme,
  breakpoints,
  activeBreakpoint,
  builderMode = false,
  actions,
  wrapper,
  gameData,
}: CanvasComponentProps | CanvasWrappedComponentProps) => {
  if (!builderMode && element.type === BuilderComponent.PHASER_GAME) {
    //@ts-ignore
    element.type = BuilderComponent.PHASER_PUBLISH;
    gameData['game.preview'] = false;
  }
  const Component = builderMode
    ? // @ts-ignore
      wrapper(getComponent(element.type))
    : getComponent(element.type);

  const themeStyleProps = useMemo(
    () => theme && buildDefaultStylesFromTheme(element, theme),
    [theme, element]
  );

  const { mediaQueryStyles, mediaQueries } = useMediaQueries({
    activeBreakpoint,
    breakpoints,
    styles: element.styles?.responsive,
  });

  const styleProps = useMemo(
    () => ({
      // @ts-ignore
      ...themeStyleProps,
      ...element.styles?.defaults,
      ...element.styles?.overrides,
      ...mediaQueryStyles,
    }),
    [themeStyleProps, element, mediaQueryStyles]
  );

  const props = useMemo(() => getElementProps(element), [element]);

  const cssStyling =
    !!element.styles &&
    css([
      !wrapper && mediaQueries,
      parseStyleProps({
        ...element.styles.defaults,
        ...element.styles.overrides,
        ...mediaQueryStyles,
      }),
    ]);

  const elementProp = useMemo(
    () => ({
      ...element,
      builderMode,
      // @ts-ignore
      themedMedia: findThemedAsset(theme, element) || null,
      //@ts-ignore
      index,
    }),
    [element, index, theme]
  );

  return (
    // @ts-ignore
    <Component
      key={element.id}
      css={cssStyling}
      data-testid={`test-${element.type}`}
      element={elementProp}
      elementStyles={styleProps}
      currentTheme={theme}
      builderMode={builderMode}
      {...props}
      {...(element.type === BuilderComponent.PHASER_PUBLISH && !wrapper
        ? //@ts-ignore
          { json: gameData, gametype: gameData['gameType'] }
        : {})}
      {...([
        BuilderComponent.PHASER_TEXT,
        BuilderComponent.PHASER_BUTTON,
      ].includes(element.type) && !wrapper
        ? //@ts-ignore
          { gametype: gameData['gameType'] }
        : {})}
      {...((element.type === BuilderComponent.BUTTON ||
        element.type === BuilderComponent.FORM) &&
      !wrapper
        ? { ...getAction(element, actions) }
        : {})}
    >
      {haveNoValueElements.includes(element.type)
        ? null
        : isContainer(element)
        ? element.childrenIds.map((elementId, index) => {
            const element = elements[elementId];
            if (!element) return null;

            return (
              <CanvasComponent
                key={elementId}
                elements={elements}
                element={element}
                index={index}
                breakpoints={breakpoints}
                activeBreakpoint={activeBreakpoint}
                theme={theme}
                builderMode={builderMode}
                actions={actions}
                wrapper={wrapper}
                gameData={gameData}
              />
            );
          })
        : element.value}
    </Component>
  );
};

export default CanvasComponent;
