import { css } from '@emotion/react';
import { useTheme } from '@mui/material';

export const useStyles = () => {
  const theme = useTheme();

  return {
    wrapper: css`
      display: flex;
      width: 100%;
      flex-direction: column;
    `,
    header: css`
      display: flex;
      justify-content: space-between;
      align-items: center;
    `,
    checkbox: css`
      margin-right: ${theme.spacing(0)};
    `,
  };
};
