import { useCallback, useState } from 'react';
import { useAppSelector } from '../../reducers/app/hooks';
import CampaignService from '../../api-client/campaigns.service';
import { selectAuth } from '../../reducers/auth';
import useAlert from '../../components/Alert/useAlert';

export const useDeleteCampaign = () => {
  const authState = useAppSelector(selectAuth);
  const companyId = authState.parsedToken.company_id;
  const [campaign, setCampaign] = useState<{ id: string; name: string } | null>(
    null
  );
  const { toast, openAlert } = useAlert();
  const [open, setOpen] = useState(false);

  const openConfirmationModal = useCallback(
    (campaign: { id: string; name: string }) => {
      setCampaign(campaign);
      setOpen(true);
    },
    []
  );

  const onConfirm = () => {
    if (companyId && campaign?.id) {
      const deleteCampaign = async () => {
        const response = await CampaignService.deleteCampaignByCompany(
          companyId,
          campaign.id
        );
        openAlert(
          response?.message,
          response?.status === 200 ? 'success' : 'error'
        );
      };
      deleteCampaign();
    }
    onCancel();
  };

  const onCancel = () => {
    setOpen(false);
    setCampaign(null);
  };

  return { openConfirmationModal, onConfirm, onCancel, open, toast };
};
