/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { FC } from 'react';
import { MenuItem, Select, Typography } from '@mui/material';
import { ColorPicker } from '../ColorPicker';
import { IFontProps } from '../../types';
import { useStyles } from './styles';

interface IProps {
  title?: string;
  props: IFontProps;
  onChange: (fontProps: IFontProps) => void;
}

const FontSelectorWidget: FC<IProps> = ({ title, props, onChange }) => {
  const { fontList, currentFont } = props.font;
  const { sizeList, currentSize } = props.size;
  const styles = useStyles();

  return (
    <div className="font-selector-widget" data-testid="font-selector-widget">
      {title && <Typography css={styles.title}>{title}</Typography>}
      <div css={styles.styleControl}>
        {!!fontList.length && (
          <Select
            css={styles.fontSelect}
            data-testid="font-select"
            inputProps={{ 'data-testid': 'font-select-input' }}
            value={currentFont}
            onChange={(e) =>
              onChange({
                ...props,
                font: { ...props.font, currentFont: e.target.value },
              })
            }
          >
            {fontList.map((font) => (
              <MenuItem value={font} key={font} data-testid="font-select-item">
                {font}
              </MenuItem>
            ))}
          </Select>
        )}

        {!!sizeList.length && (
          <Select
            css={styles.sizeSelect}
            data-testid="size-select"
            inputProps={{ 'data-testid': 'size-select-input' }}
            value={currentSize}
            onChange={(e) =>
              onChange({
                ...props,
                size: {
                  ...props.size,
                  currentSize: e.target.value as number,
                },
              })
            }
          >
            {sizeList.map((size) => (
              <MenuItem value={size} key={size} data-testid="size-select-item">
                {size}
              </MenuItem>
            ))}
          </Select>
        )}

        {!!props.color && (
          <ColorPicker
            value={props.color}
            onChange={(value) => onChange({ ...props, color: value })}
          />
        )}
      </div>
    </div>
  );
};

export default FontSelectorWidget;
