/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { FC } from 'react';
import { IEmailTemplate } from '../../../reducers/email/types';
import { useStyles } from './styles';

interface IProps {
  template: IEmailTemplate;
  onClick: (template: IEmailTemplate) => void;
}

const EmailTemplateTile: FC<IProps> = ({ template, onClick }) => {
  const styles = useStyles();
  return (
    <div
      data-testid={`email-template-tile-${template.id}`}
      onClick={() => onClick(template)}
      css={styles.templateTile}
    >
      {template?.name}
    </div>
  );
};

export default EmailTemplateTile;
