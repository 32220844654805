/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import React, { FC } from 'react';
import { EntityId } from '@reduxjs/toolkit';
import {
  Envelope,
  Files,
  FileText,
  GameController,
  Trophy,
} from 'phosphor-react';
import { IconButton, Tooltip, Typography, useTheme } from '@mui/material';
import { PageElement, PageType } from '../../../../reducers/builder';
import { CustomIcon } from '../CustomIcon';
import { useStyles } from './styles';

export interface IProperties {
  page: PageElement;
  index: number;
  hidden?: boolean;
  selected: boolean;
  onSelectPage: (id: EntityId) => void;
}

const Page: FC<IProperties> = ({
  page,
  index,
  selected,
  onSelectPage,
  hidden,
}) => {
  const theme = useTheme();
  const styles = useStyles(selected);

  const pageTypeIcons = {
    [PageType.Basic]: (
      <CustomIcon
        Icon={FileText}
        data-testid="page-basic-icon"
        color={
          selected ? theme.palette.common.white : theme.palette.text.primary
        }
      />
    ),
    [PageType.Game]: (
      <CustomIcon
        Icon={GameController}
        data-testid="page-game-icon"
        color={
          selected ? theme.palette.common.white : theme.palette.text.primary
        }
      />
    ),
    [PageType.Outcome]: (
      <CustomIcon
        Icon={Trophy}
        data-testid="page-result-icon"
        color={
          selected ? theme.palette.common.white : theme.palette.text.primary
        }
      />
    ),
    [PageType.Terms]: (
      <CustomIcon
        Icon={Files}
        data-testid="page-terms-icon"
        color={
          selected ? theme.palette.common.white : theme.palette.text.primary
        }
      />
    ),
    [PageType.Email]: (
      <CustomIcon
        Icon={Envelope}
        data-testid="page-email-icon"
        color={
          selected ? theme.palette.common.white : theme.palette.text.primary
        }
      />
    ),
  };

  return (
    <div
      css={styles.pageContainer}
      onClick={() => onSelectPage(page.id)}
      data-testid="page-container"
      style={{
        display: hidden ? 'none' : 'flex',
      }}
    >
      <IconButton key={page.id} size="small" css={styles.page}>
        {/*@ts-ignore*/}
        {pageTypeIcons[page.pageType]}
        {page.name && (
          <Tooltip
            title={`${index + 1} ${page.name}`}
            data-testid="page-tooltip"
            disableInteractive
          >
            <Typography variant="h4" css={styles.title}>
              {index + 1} {page.name}
            </Typography>
          </Tooltip>
        )}
      </IconButton>
    </div>
  );
};

export default Page;
