import { css } from '@emotion/react';
import { useTheme } from '@mui/material';

export const useStyles = () => {
  const theme = useTheme();

  return {
    error: css`
      padding: ${theme.spacing(1)};
      color: ${theme.palette.common.white};
      background: ${theme.palette.error.light};
      text-align: center;
      border-radius: 10px;
      max-height: 40px;
      margin: ${theme.spacing(3, 1)};
    `,
  };
};
