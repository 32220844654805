import { css } from '@emotion/react';
import { useTheme } from '@mui/material';

export const useStyles = () => {
  const theme = useTheme();

  return {
    container: css`
      margin-bottom: ${theme.spacing(1)};
      display: flex;
      flex-direction: column;
      gap: ${theme.spacing(1)};
    `,
    menuItem: css`
      text-transform: capitalize;
    `,
    title: css`
      display: block;
      text-transform: capitalize;
    `,
    select: css`
      text-transform: capitalize;
    `,
  };
};
