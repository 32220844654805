/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import React, { FC } from 'react';
import { HexColorInput } from 'react-colorful';
import { Typography } from '@mui/material';
import { ColorPicker } from '../ColorPicker';
import { useStyles } from './styles';

interface IProps {
  alpha?: boolean;
  title?: string;
  value: string;
  onChange: (value: string) => void;
}

const ColorPickerWidget: FC<IProps> = ({ alpha, title, value, onChange }) => {
  const styles = useStyles();

  return (
    <div
      css={styles.widget}
      className="color-picker-widget"
      data-testid="color-picker-widget"
    >
      <Typography css={styles.title} data-testid="title">
        {title}
      </Typography>
      <div css={styles.styleControl}>
        <HexColorInput
          alpha={alpha}
          prefixed
          color={value}
          onChange={onChange}
          data-testid="color-hex-input"
          css={styles.colorHexInput}
        />
        <ColorPicker value={value} onChange={onChange} alpha={alpha} />
      </div>
    </div>
  );
};

export default ColorPickerWidget;
