/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import React, { FC } from 'react';
import { FormControlLabel, Switch } from '@mui/material';
import { brameTheme } from '@brame/theme';
import { SizeInput } from '../SizeInput';
import { ColorPickerWidget } from '../ColorPickerWidget';
import { SelectInput } from '../SelectInput';
import { borderStyles } from '../../types';
import { useStyles } from './styles';

interface IProps {
  widgetTitle?: string;
  border: any;
  onChange: (border: any) => void;
}

const defaultBorder = {
  borderWidth: { value: 0, unit: 'px' },
  borderColor: brameTheme.palette.common.white,
  borderStyle: 'solid',
};

const BorderWidget: FC<IProps> = ({ widgetTitle, border, onChange }) => {
  const styles = useStyles();
  const [checked, setChecked] = React.useState(
    !!(border.borderWidth && border.borderColor && border.borderStyle)
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
    if (event.target.checked) {
      onChange({ ...border, ...defaultBorder });
    } else {
      onChange({
        ...border,
        borderWidth: null,
        borderStyle: null,
        borderColor: null,
      });
    }
  };

  return (
    <div data-testid="border-widget">
      <FormControlLabel
        control={
          <Switch checked={checked} onChange={handleChange} name="border" />
        }
        label={widgetTitle || 'Border'}
      />

      {checked && border && (
        <div>
          <SizeInput
            title="Border width"
            sizeValue={border?.borderWidth}
            fullWidth
            css={styles.input}
            onChange={(values) => onChange({ ...border, borderWidth: values })}
          />
          <SelectInput
            label="Border style"
            value={border?.borderStyle}
            options={borderStyles}
            onChange={(value) => onChange({ ...border, borderStyle: value })}
          />
          <ColorPickerWidget
            title="Border color"
            alpha
            value={border?.borderColor}
            onChange={(value) => onChange({ ...border, borderColor: value })}
          />
        </div>
      )}
    </div>
  );
};

export default BorderWidget;
