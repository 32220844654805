/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import React, { FC } from 'react';
import { Box, Typography } from '@mui/material';
import { useAppSelector } from '../../../reducers/app/hooks';
import { PageElement } from '../../../reducers/builder';
import { selectPages } from '../../../reducers/builder/builderSlice';
import GameEnumEditor from '../GameEnumEditor';
import { IGameDefaultOutcomeEditorProps } from './IProps';

const GameDefaultOutcomeEditor: FC<IGameDefaultOutcomeEditorProps> = (
  props
) => {
  const pages = useAppSelector(selectPages);
  const pageNameArray: (string | undefined)[] = [];
  const pageIDArray: (string | undefined)[] = [];

  pages.ids.map((pageId) => {
    const page = pages.entities[pageId] as PageElement;
    pageNameArray.push(page.name);
    pageIDArray.push(page.id as string);
  });

  return (
    <Box sx={{ padding: 2 }}>
      <Typography>Default Outcome</Typography>
      <GameEnumEditor
        name="page"
        index={props.index}
        arrayCategory={props.name}
        label=""
        enums={pageIDArray}
        descs={pageNameArray}
      />
    </Box>
  );
};
export default GameDefaultOutcomeEditor;
