import { css } from '@emotion/react';
import { useTheme } from '@mui/material';
import { ITheme } from '@brame/theme';
import globalColors from '@brame/theme/src/globalColors';

interface IProps {
  currentTheme?: ITheme;
  active: boolean;
  isRootBackground: boolean;
}

export const useStyles = ({
  currentTheme,
  active,
  isRootBackground,
}: IProps) => {
  const theme = useTheme();

  return {
    card: css`
      border: ${
        active
          ? `1px solid ${globalColors.opacity99Black}`
          : `1px solid ${globalColors.transparent}`
      };
      box-shadow: ${
        active
          ? `7px 7px 10px rgba(0 0 0 / 25%);`
          : `7px 7px 10px rgba(255, 255, 255, 1);`
      }
      padding: ${theme.spacing(0.5, 2, 2)};

      & div {
        border-radius: 5px;
      }
    `,
    titleContainer: css`
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: ${theme.spacing(0.5, 0, 1)};
      transform: ${active ? `scale(1.05)` : `scale(1)`};
      transform-origin: top center;
      transition: all 0.3s ease;
    `,
    titleWrapper: css`
      display: flex;
      justify-content: start;
      align-items: center;
    `,
    title: css`
      color: ${globalColors.opacity99Black};
      padding: ${theme.spacing(0.5, 0)};
      max-width: 160px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    `,
    infoButton: css`
      min-width: 20px;
      color: ${globalColors.opacity99Black};
      padding: ${theme.spacing(0)};
      margin-left: ${theme.spacing(1)};

      &:hover {
        background-color: transparent;
      }

      span {
        margin: ${theme.spacing(0)};
      }
    `,
    previewButton: css`
      color: ${globalColors.opacity99Black};
      padding: ${theme.spacing(0)};
      min-width: 35px;

      &:hover {
        background-color: transparent;
      }

      &:active {
      }

      span {
        margin: ${theme.spacing(0)};
      }

      a {
        padding: ${theme.spacing(0)};
      }
    `,
    template: css`
      display: flex;
      height: 460px;
      zoom: 0.3;
      box-shadow: ${
        active
          ? `20px 20px 25px rgba(255, 255, 255, 1);`
          : `20px 20px 25px rgba(0 0 0 / 25%);`
      }
      transform: ${active ? `scale(1.05)` : `scale(1)`};
      transform-origin: top center;
      transition: all 0.3s ease;
      cursor: pointer;
      background: ${
        currentTheme
          ? currentTheme.theme.palette?.background?.paper
          : globalColors.white
      } url(${
      isRootBackground &&
      currentTheme?.assets?.defaultCreatives?.images?.['default background']
        ? currentTheme.assets?.defaultCreatives?.images?.['default background']
            ?.url
        : currentTheme?.assets?.defaultCreatives?.images?.[
            'default background 1'
          ]?.url
    }) center no-repeat;
      background-size: cover;
    `,
  };
};
