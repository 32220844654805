/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { useFormikContext } from 'formik';
import React, { FC } from 'react';
import {
  Checkbox,
  ListItemText,
  MenuItem,
  TextField,
  InputLabel,
  alpha,
} from '@mui/material';
import { IFormInputStyles, IFormLabelStyles } from '@brame/builder/src/types';
import {
  parseBorder,
  parseBoxShadow,
  parseSize,
} from '@brame/builder/src/components/Canvas/utils';
import { ITheme } from '@brame/theme';
import { FormFieldType, IFormField } from '../IProps';
import { MaskedInput } from '../MaskedInput';
import { DateField } from '../DateField';
import { CheckboxField } from '../CheckboxField';
import { useStyles } from './styles';

interface IProps {
  name: string;
  value: string | boolean;
  field: IFormField;
  inputStyles: IFormInputStyles;
  labelStyles: IFormLabelStyles;
  currentTheme?: ITheme;
}

const VariativeInput: FC<IProps> = ({
  name,
  value,
  field,
  inputStyles,
  labelStyles,
  currentTheme,
}) => {
  const { handleChange, errors: formikErrors } = useFormikContext();
  const errors = formikErrors as any;
  const { variant, size, border, background, boxShadow: bsh } = inputStyles;

  const width = size?.width ? parseSize(size?.width) : undefined;
  const height = size?.height ? parseSize(size?.height) : undefined;
  const boxShadow = bsh ? parseBoxShadow(bsh) : null;

  const isMultiselect = field.type === FormFieldType.MultiSelect;
  const isSingleSelect = field.type === FormFieldType.SingleSelect;
  const isNotMaskedNumber =
    field.type === FormFieldType.Number && !field?.validation?.mask;

  const borderStyles = parseBorder(border);
  if (borderStyles.borderColor) {
    borderStyles.borderColorHover = alpha(borderStyles.borderColor, 0.75);
  }

  const styles = useStyles({
    input: labelStyles.input,
    ...borderStyles,
  });

  const typographyStyles =
    currentTheme?.theme?.components?.MuiTypography?.styleOverrides;
  // @ts-ignore
  const fontFamily = typographyStyles?.root?.fontFamily;
  // @ts-ignore
  const fontSize = typographyStyles?.root?.fontSize;
  // @ts-ignore
  const color = typographyStyles?.root?.color;

  const inputStyle = {
    background,
    height,
    fontFamily,
  };
  const inputProps = {
    style: inputStyle,
  };
  const inputLabelProps = {
    shrink: true,
    sx: { fontFamily, fontSize, color },
  };

  return (
    <div data-testid="variative-input" css={styles.container}>
      {field.type === FormFieldType.Date && (
        <DateField
          name={name}
          value={value as string}
          field={field}
          inputStyles={inputStyles}
          labelStyles={labelStyles.input}
          inputProps={inputProps}
          inputLabelProps={inputLabelProps}
          borderStyles={borderStyles}
          boxShadow={boxShadow}
          width={width}
          fontFamily={fontFamily}
        />
      )}
      {field.type === FormFieldType.Checkbox && (
        <CheckboxField
          name={name}
          value={value as boolean}
          field={field}
          inputStyles={inputStyles}
          labelPosition={labelStyles.checkbox}
          borderStyles={borderStyles}
          boxShadow={boxShadow}
          width={width}
          height={height}
          fontFamily={fontFamily}
          color={color}
        />
      )}
      {field.type !== FormFieldType.Date &&
        field.type !== FormFieldType.Checkbox &&
        !isNotMaskedNumber && (
          <React.Fragment>
            <InputLabel
              error={errors[name]}
              required={field.validation.required}
              css={styles.label}
              {...inputLabelProps}
            >
              {field.label}
            </InputLabel>
            <TextField
              value={value}
              variant={variant}
              onChange={handleChange}
              name={name}
              error={!!errors[name]}
              multiline={field.type === FormFieldType.Textarea}
              rows={field.validation.rows || '4'}
              select={isSingleSelect || isMultiselect}
              required={field.validation.required}
              helperText={
                errors[name]
                  ? field.validation.errorMessage || errors[name]
                  : ''
              }
              css={styles.input}
              sx={{
                width,
                boxShadow,
                fontFamily,
              }}
              SelectProps={{
                multiple: isMultiselect,
                renderValue: (selected: any) => {
                  return isMultiselect ? selected?.join(', ') : selected;
                },
              }}
              InputProps={
                field?.validation?.mask
                  ? {
                      inputComponent: MaskedInput as any,
                      inputProps: {
                        mask: field.validation.mask,
                        style: inputStyle,
                      },
                    }
                  : inputProps
              }
            >
              {isSingleSelect &&
                field?.validation?.options?.map((option, index) => (
                  <MenuItem value={option} key={index}>
                    {option}
                  </MenuItem>
                ))}
              {isMultiselect &&
                field?.validation?.options?.map((option, index) => (
                  <MenuItem key={index} value={option}>
                    <Checkbox checked={(value as string).includes(option)} />
                    <ListItemText primary={option} />
                  </MenuItem>
                ))}
            </TextField>
          </React.Fragment>
        )}
      {isNotMaskedNumber && (
        <React.Fragment>
          <InputLabel
            error={errors[name]}
            required={field.validation.required}
            css={styles.label}
            {...inputLabelProps}
          >
            {field.label}
          </InputLabel>
          <TextField
            value={value}
            variant={variant}
            onChange={handleChange}
            name={name}
            error={!!errors[name]}
            required={field.validation.required}
            helperText={
              errors[name] ? field.validation.errorMessage || errors[name] : ''
            }
            css={styles.input}
            sx={{
              width,
              boxShadow,
              fontFamily,
            }}
            type="number"
            InputProps={inputProps}
            inputProps={{
              min: field?.validation?.min,
              max: field?.validation?.max,
            }}
          />
        </React.Fragment>
      )}
    </div>
  );
};

export default VariativeInput;
