import { css } from '@emotion/react';
import {
  IBorderCss,
  IFormLabelInputStyles,
  IHorizontalLabelAlignment,
  ILabelPosition,
  IVerticalLabelAlignment,
} from '@brame/builder/src/types';
import { isHorizontalLabelAlignment } from '@brame/builder/src/utils/utils';

export interface IStylesProps extends IBorderCss {
  textAlign?: string;
  input: IFormLabelInputStyles;
}

const getFlexAlignment = (alignment: IVerticalLabelAlignment) => {
  switch (alignment) {
    case 'top':
      return 'flex-start';
    case 'bottom':
      return 'flex-end';
    case 'center':
    default:
      return 'center';
  }
};

const getTextAlign = (
  position: ILabelPosition,
  alignment: IVerticalLabelAlignment | IHorizontalLabelAlignment
) => {
  if (isHorizontalLabelAlignment(position)) {
    return alignment;
  } else if (position === 'left') {
    return 'right';
  }
  return 'left';
};

export const useStyles = (props: IStylesProps) => ({
  container: css`
    display: flex;
    flex-direction: ${isHorizontalLabelAlignment(props.input.position)
      ? 'column'
      : 'row'};
    align-items: ${isHorizontalLabelAlignment(props.input.position)
      ? 'center'
      : getFlexAlignment(props.input.alignment as IVerticalLabelAlignment)};
  `,
  label: css`
    transform: none;
    width: 100%;
    text-align: ${getTextAlign(props.input.position, props.input.alignment)};
    order: ${props.input.position === 'right' ||
    props.input.position === 'bottom'
      ? 1
      : 0};
    padding-left: ${props.input.position === 'right' ? '8px' : 0};
    padding-right: ${props.input.position === 'left' ? '8px' : 0};
  `,
  input: css`
    & .MuiOutlinedInput-root {
      & > fieldset {
        border-color: ${props.borderColor};
        border-style: ${props.borderStyle};
        border-radius: ${props.borderRadius};
        border-width: ${props.borderWidth};
      }

      &:hover:not(.Mui-focused) > fieldset {
        border-color: ${props.borderColorHover};
      }

      &.Mui-focused > fieldset {
        border-width: ${props.borderWidth};
      }
    }

    & .MuiFilledInput-underline,
    & .MuiInput-underline {
      &:before {
        border-bottom-color: ${props.borderColor};
        border-bottom-style: ${props.borderStyle};
        border-bottom-width: ${props.borderWidth};
        transition: border-bottom-color;
      }

      &:hover:not(.Mui-disabled):before {
        border-bottom-color: ${props.borderColorHover};
        border-bottom-style: ${props.borderStyle};
        border-bottom-width: ${props.borderWidth};
      }

      &.Mui-focused:not(.Mui-disabled):after {
        border-bottom-style: ${props.borderStyle};
        border-bottom-width: ${props.borderWidth};
        transition: color;
      }
    }
  `,
});
