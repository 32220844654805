/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import React, { FC, Fragment, useEffect } from 'react';
import { Plus } from 'phosphor-react';
import { IconButton, Typography } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../reducers/app/hooks';
import {
  addGameArrayEntry,
  removeGameArrayEntry,
  selectGameSettings,
  setGameParam,
} from '../../../reducers/game/gameSlice';
import { iconSize } from '../../../constants';
import { IGameArrayEditorProps } from './IProps';
import { useStyles } from './styles';

const GameArrayEditor: FC<IGameArrayEditorProps> = (props) => {
  const dispatch = useAppDispatch();
  const styles = useStyles();
  const gameState = useAppSelector(selectGameSettings);

  // CR functions for the array
  const addElement = (): void => {
    dispatch(
      addGameArrayEntry({ arrayCategory: props.name, uuid: props.uuid })
    );
  };

  const removeElement = (index: number) => {
    dispatch(removeGameArrayEntry({ arrayCategory: props.name, index: index }));
  };

  useEffect(() => {
    // Initialize the Array
    if (!gameState[props.name]) {
      dispatch(
        setGameParam({
          param: props.name,
          value: [],
        })
      );
    }
  }, []);

  return (
    <Fragment>
      {gameState[props.name] && (
        <div data-testid="game-array-editor" css={styles.itemsWrapper}>
          <div css={styles.titleBox}>
            {props.label && (
              <Typography css={styles.toolbarTitle}>{props.label}</Typography>
            )}
            <IconButton onClick={addElement}>
              <Plus data-testid="AddIcon" size={iconSize} />
            </IconButton>
          </div>
          {gameState[props.name].map((element: any, index: number) => (
            <props.ComponentType
              key={props.name + index}
              name={props.name}
              label={props.label}
              index={index}
              removeFunction={removeElement}
            />
          ))}
        </div>
      )}
    </Fragment>
  );
};

export default GameArrayEditor;
