import emptyTemplate from './emptyTemplate';
import threePageTemplate from './threePageTemplate';

export const defaultTemplates = {
  '1': {
    id: '1',
    name: 'empty template',
    template: emptyTemplate,
  },
  '2': {
    id: '2',
    name: 'three pages templates',
    template: threePageTemplate,
  },
};
