import { ITheme } from '@brame/theme';
import useThemeData from '@brame/components/src/hooks/useThemeData';
import { IWizard } from '../initialWizardState';

const useCurrentTheme = (
  customThemes: ITheme[],
  wizardState: IWizard
): ITheme | undefined => {
  const { themeCollection } = useThemeData();
  const allThemes: ITheme[] =
    Object.values(themeCollection).concat(customThemes);
  return allThemes.find(
    (theme) => theme.id === wizardState.campaignTheme.themeId
  );
};

export default useCurrentTheme;
