/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import React, { FC, RefObject, Fragment, useRef } from 'react';
import { EntityId } from '@reduxjs/toolkit';
import Typography from '@mui/material/Typography';
import useEditable from '../../hooks/useEditable';
import { Editable } from '../Editable';
import { ITextProps } from './IProps';

const Text: FC<ITextProps> = React.forwardRef(
  (
    { children, styles, currentTheme, elementStyles, variant, ...props },
    ref
  ) => {
    const spanRef = useRef<HTMLSpanElement | null>(null);

    const { startEditing, isEditable, handleTextChange, handleSubmitValue } =
      useEditable(spanRef as RefObject<HTMLElement>, props.id as EntityId);

    return (
      <Typography
        ref={ref}
        variant={variant}
        {...props}
        css={styles}
        {...(props.element?.builderMode && {
          onDoubleClick: startEditing,
        })}
      >
        {props.element?.builderMode ? (
          <Editable
            ref={spanRef}
            value={children as string}
            editable={isEditable}
            onKeyDown={handleTextChange}
            onBlur={handleSubmitValue}
          />
        ) : (
          <Fragment>{children}</Fragment>
        )}
      </Typography>
    );
  }
);

export default Text;
