import React, { FC } from 'react';
import { InputAdornment, TextField } from '@mui/material';
import { TextFieldProps } from '@mui/material/TextField/TextField';

const InputWithSuffix: FC<
  {
    title?: string;
    suffix: string;
    value: number;
    max?: number;
    min?: number;
    onChange: (value: number) => void;
  } & Omit<TextFieldProps, 'onChange'>
> = ({ onChange, value, min, max, title, suffix, ...props }) => {
  return (
    <TextField
      type="number"
      size="small"
      value={value}
      label={title}
      fullWidth
      data-testid="input-with-suffix"
      InputProps={{
        inputProps: { min: min, max: max },
        sx: { paddingRight: 1 },
        endAdornment: <InputAdornment position="end">{suffix}</InputAdornment>,
      }}
      onChange={(e) => onChange(+e.target.value)}
      {...props}
    />
  );
};

export default InputWithSuffix;
