import { css } from '@emotion/react';
import { useTheme } from '@mui/material';
import { brameTheme } from '@brame/theme';

export const useStyles = () => {
  const theme = useTheme();

  return {
    widget: css`
      display: flex;
      flex-direction: column;
      gap: ${theme.spacing(1)};
    `,
    itemWrapper: css`
      display: flex;
      gap: ${theme.spacing(1)};
      align-items: flex-start;
    `,
    gap10: css`
      gap: ${theme.spacing(1)};
    `,
    shapeSelect: css`
      width: 170px;
      text-transform: capitalize;
    `,
    menuItem: css`
      text-transform: capitalize;
    `,
    title: css`
      display: block;
      margin-bottom: ${theme.spacing(1)};
      text-transform: capitalize;
    `,
    styleControl: css`
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    `,
    widthSelect: css`
      width: 80px;
    `,
    widgetItem: css`
      display: flex;
      flex-direction: column;
      gap: ${theme.spacing(1)};
    `,
    button: css`
      border: none;
      border-radius: ${brameTheme.shape.borderRadius}px !important;
      width: 36px;
      height: 36px;
      padding: ${theme.spacing(0)};

      &.Mui-selected {
        background: ${theme.palette.primary.main};
        * {
          border-color: ${theme.palette.common.white};
        }

        &:hover {
          background: ${theme.palette.primary.main};
          * {
            border-color: ${theme.palette.common.white};
          }
        }
      }
    `,
    buttonShape: css`
      width: 25px;
      height: 15px;
      border-width: 2px;
      border-style: solid;
    `,
  };
};
