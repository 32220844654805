import { css } from '@emotion/react';
import { useTheme } from '@mui/material';

export const useStyles = () => {
  const theme = useTheme();

  return {
    container: css`
      display: flex;
      flex-direction: column;
      width: 100%;
    `,
    removeButton: css`
      margin: ${theme.spacing(1, 0)};
    `,
  };
};
