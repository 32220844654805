/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import React, { FC } from 'react';
import { ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { FlexJustify, flexJustify } from '../../types';
import { useStyles } from './styles';

interface IProps {
  justify: FlexJustify;
  onChange: (justify: FlexJustify) => void;
}

const JustifyContentSwitcher: FC<IProps> = ({ justify, onChange }) => {
  const styles = useStyles();
  return (
    <div data-testid="justify-content-switcher">
      <div>
        <Typography css={styles.title}>Justify content</Typography>

        <ToggleButtonGroup
          value={justify}
          exclusive
          onChange={(e, value) => !!value && onChange(value)}
        >
          {flexJustify.map((item) => (
            <ToggleButton
              key={item}
              value={item}
              size="small"
              data-testid={`justify-content-toggle-${item}`}
              css={styles.button}
            >
              {item}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      </div>
    </div>
  );
};

export default JustifyContentSwitcher;
