/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css, CacheProvider } from '@emotion/react';
import React, {
  useCallback,
  useReducer,
  useEffect,
  useMemo,
  useRef,
  Fragment,
} from 'react';
import createCache from '@emotion/cache';
import { Portal } from 'react-portal';

const Framed = ({
  children,
  document,
}: {
  children: any;
  document: Document;
}) => {
  const getWindow = useCallback(() => document.defaultView, [document]);

  const cache = useMemo(
    () =>
      createCache({
        key: `iframe-canvas`,
        prepend: true,
        container: document.head,
      }),
    [document]
  );

  return (
    <CacheProvider value={cache}>
      {React.cloneElement(children, {
        window: getWindow,
      })}
    </CacheProvider>
  );
};

interface IFrameProps extends React.HTMLAttributes<HTMLIFrameElement> {
  children: React.ReactNode;
  name: string;
}

const iframeStyle = { width: '100%', height: '100%' };

export const IFrame: React.FC<IFrameProps> = ({ children, name, id }) => {
  const frameRef = useRef<HTMLIFrameElement>(null);
  const [iframeLoaded, onLoad] = useReducer(() => true, false);

  useEffect(() => {
    if (!frameRef?.current) return;

    const document = frameRef.current.contentDocument;
    if (
      document != null &&
      document.readyState === 'complete' &&
      !iframeLoaded
    ) {
      onLoad();
    }
  }, [iframeLoaded, frameRef.current]);

  const document = frameRef.current?.contentDocument;

  return (
    <Fragment>
      <iframe
        onLoad={onLoad}
        css={css`
          *,
          *::before,
          *::after {
            box-sizing: border-box;
          }
        `}
        ref={frameRef}
        style={iframeStyle}
        title={name}
        frameBorder="0"
        id={id}
      />

      {iframeLoaded && document ? (
        <Portal node={document.body}>
          <Framed document={document}>{children}</Framed>
        </Portal>
      ) : null}
    </Fragment>
  );
};
