import { useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import { ITheme } from '@brame/theme';
import ThemeService from '../../../../api-client/themes.service';

export interface IProps {
  companyId: string;
  createdAt: number;
  data: ITheme;
  themeId: string;
}

export const useCustomThemesData = (
  handleCustomThemes: (customThemes: ITheme[]) => void
) => {
  const COMPANY_ID = process.env.REACT_APP_TEST_USER_COMPANY_ID;
  const [customThemesData, setCustomThemesData] = useState<
    ITheme[] | undefined
  >();

  useEffect(() => {
    const getThemesData = async (companyId: string | undefined) => {
      try {
        const responseData = await ThemeService.fetchThemesData(companyId);
        setCustomThemesData(responseData.map((theme: IProps) => theme.data));
      } catch (error) {
        const err = error as AxiosError;
        return err.response;
      }
    };

    getThemesData(COMPANY_ID);
  }, [COMPANY_ID]);

  useEffect(() => {
    if (customThemesData) {
      handleCustomThemes(customThemesData);
    }
  }, [handleCustomThemes, customThemesData]);

  return { customThemesData };
};
