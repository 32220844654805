/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import React, { FC, useState } from 'react';
import { PencilSimple, TrashSimple } from 'phosphor-react';
import {
  Collapse,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Menu,
  MenuItem,
} from '@mui/material';
import { IFormField } from '@brame/components/src/components/Form';
import { EditField } from '../EditField';
import { useFormEditorStyles } from '../styles';
import { iconSize } from '../../../constants';

interface IProps {
  fields: IFormField[];
  onChange: (props: IFormField[]) => void;
}

const FieldList: FC<IProps> = ({ fields, onChange }) => {
  const [openedFieldIndex, setOpenFieldIndex] = useState<number | null>(null);
  const [fieldToReorderIndex, setFieldToReorderIndex] = useState<number | null>(
    null
  );
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const styles = useFormEditorStyles();

  const onEditField = (index: number): void => {
    if (openedFieldIndex === index) {
      setOpenFieldIndex(null);
    } else {
      setOpenFieldIndex(index);
    }
  };

  const onRemoveField = (index: number): void => {
    const newFields = [...fields];
    newFields.splice(index, 1);
    onChange(newFields);
  };

  const onChangeField = (field: IFormField, index: number): void => {
    const newFields = [...fields];
    newFields.splice(index, 1, field);
    onChange(newFields);
  };

  const handleClick = (
    event: React.MouseEvent<HTMLDivElement>,
    index: number
  ) => {
    setAnchorEl(event.currentTarget);
    setFieldToReorderIndex(index);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setFieldToReorderIndex(null);
  };

  const onChangeFieldOrder = (newIndex: number): void => {
    if (typeof fieldToReorderIndex === 'number') {
      const newFields = [...fields];
      const field = newFields.splice(fieldToReorderIndex, 1)[0];
      newFields.splice(newIndex, 0, field);
      onChange(newFields);
      handleClose();
    }
  };

  return (
    <div data-testid="field-list">
      <List dense={false}>
        {fields?.length > 0 &&
          fields.map((field, index) => (
            <React.Fragment key={index}>
              <ListItem
                disablePadding
                key={index}
                secondaryAction={
                  <React.Fragment>
                    <IconButton
                      edge="end"
                      aria-label="edit"
                      style={{ marginRight: 0 }}
                      onClick={() => onEditField(index)}
                    >
                      <PencilSimple size={iconSize} />
                    </IconButton>
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => onRemoveField(index)}
                    >
                      <TrashSimple size={iconSize} />
                    </IconButton>
                  </React.Fragment>
                }
              >
                <ListItemButton
                  id={'basic-button' + index}
                  aria-controls={open ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  onClick={(e) => handleClick(e, index)}
                  css={styles.orderButton}
                >
                  {index + 1}
                </ListItemButton>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button' + index,
                  }}
                >
                  {fields.map((f, i) => (
                    <MenuItem
                      value={i}
                      key={i}
                      selected={i === fieldToReorderIndex}
                      disabled={i === fieldToReorderIndex}
                      onClick={() => onChangeFieldOrder(i)}
                    >
                      {i + 1}
                    </MenuItem>
                  ))}
                </Menu>
                <ListItemText
                  primary={field?.label}
                  secondary={null}
                  sx={{ marginRight: 8 }}
                />
              </ListItem>
              <Collapse
                sx={{ paddingTop: 1, paddingBottom: 1 }}
                in={openedFieldIndex === index}
                timeout="auto"
                unmountOnExit
              >
                <EditField
                  field={field}
                  onChange={(field) => onChangeField(field, index)}
                  onClose={() => onEditField(index)}
                />
              </Collapse>
            </React.Fragment>
          ))}
      </List>
    </div>
  );
};

export default FieldList;
