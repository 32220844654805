import React, { useState } from 'react';
import {
  IAssetChange,
  IThemeMedia,
  IThemeMediaCollection,
  IThemeMediaType,
} from '@brame/theme';
import { useAppSelector } from '../../reducers/app/hooks';
import { selectedAssetSelector } from '../../reducers/assets/assetSlice';
import { useAssetModal } from '../AssetsModal';
import { IAssetData } from '../../types';
import { BuilderComponent } from '../../reducers/builder';
import {
  findFreeIndexInCollection,
  normalizeAssetType,
} from './MediaPreview/utils';

type Media = [string, IThemeMedia];

const useMediaPreview = (mediaCollection: IThemeMediaCollection) => {
  const [currentMedia, setCurrentMedia] = useState<Media | null>(null);
  const selectedAsset = useAppSelector(selectedAssetSelector);

  const { assetsModalIsOpen, openAssetsModal, closeAssetsModal } =
    useAssetModal();

  const collection = Object.entries(mediaCollection);

  const getCollectionType = () => {
    if (currentMedia) {
      return currentMedia[1].type === 'video'
        ? BuilderComponent.VIDEO
        : BuilderComponent.IMAGE;
    }
    return BuilderComponent.IMAGE;
  };

  const openModalForCurrentMedia = (e: React.MouseEvent, media: Media) => {
    setCurrentMedia(media);
    openAssetsModal(e);
  };

  const handleCancelPopup = () => {
    setCurrentMedia(null);
    closeAssetsModal();
  };

  const createNewAsset = (asset: IAssetData): IAssetChange => {
    const keys = Object.keys(mediaCollection);
    const index = findFreeIndexInCollection(
      keys.length + 1,
      normalizeAssetType(asset.type),
      mediaCollection
    );

    return {
      name: `default ${normalizeAssetType(asset.type)} ${index}`,
      type: asset.type as IThemeMediaType,
      asset,
    };
  };

  return {
    collection,
    currentMedia,
    selectedAsset,
    openModalForCurrentMedia,
    assetsModalIsOpen,
    handleCancelPopup,
    createNewAsset,
    openAssetsModal,
    getCollectionType,
  };
};

export default useMediaPreview;
