/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import React, { FC, useState } from 'react';
import { Eye } from 'phosphor-react';
import { camelCase } from 'lodash';
import { Button, Card, Grid } from '@mui/material';
import { ITheme } from '@brame/theme';
import { IWizard } from '../../initialWizardState';
import { PreviewModal } from '../../PreviewModal';
import { WizardType } from '../../CampaignType/tabsData';
import { useStyles } from './styles';
import { ThemeBasicStructure } from './ThemeBasicStructure';

interface IThemeCard {
  wizardState: IWizard;
  handleProgressBar: (activeType: string) => void;
  theme: ITheme;
  onSelect: (stepName: string, payload: IWizard) => void;
  active: boolean;
}

const ThemeCard: FC<IThemeCard> = ({
  wizardState,
  handleProgressBar,
  theme,
  onSelect,
  active,
}) => {
  const { name, id } = theme;
  const styles = useStyles({ theme, active });
  const [visiblePreview, setVisiblePreview] = useState(false);
  const [previewModalIsOpen, setPreviewModalIsOpen] = useState(false);

  const handleActiveCard = (active: boolean) => {
    onSelect(WizardType.CAMPAIGN_THEME, {
      ...wizardState,
      [camelCase(WizardType.CAMPAIGN_THEME)]: {
        themeId: active ? '' : id.toString(),
        isDisable: !active,
      },
    });
    handleProgressBar(
      active ? WizardType.CAMPAIGN_DETAILS : WizardType.CAMPAIGN_THEME
    );
  };

  return (
    <Grid item xs={12} sm={6} lg={4} xl={3} data-testid="theme-card">
      <Card
        css={styles.card}
        onMouseOver={() => setVisiblePreview(true)}
        onMouseOut={() => setVisiblePreview(false)}
      >
        <div css={styles.titleContainer}>
          <div css={styles.title} data-testid="card-title">
            {theme.name}
          </div>
          {(visiblePreview || active) && (
            <div>
              <Button
                href="#"
                endIcon={<Eye weight="light" size={23} />}
                css={styles.previewButton}
                data-testid="preview-button"
                onClick={() => setPreviewModalIsOpen(true)}
              />
              <PreviewModal
                name={theme.name}
                isOpen={previewModalIsOpen}
                onCancel={() => setPreviewModalIsOpen(false)}
              >
                <div css={styles.wrapper}>
                  <ThemeBasicStructure name={theme.name} theme={theme} />
                </div>
              </PreviewModal>
            </div>
          )}
        </div>
        <div css={styles.themePreview} onClick={() => handleActiveCard(active)}>
          <ThemeBasicStructure name={name} theme={theme} />
        </div>
      </Card>
    </Grid>
  );
};

export default ThemeCard;
