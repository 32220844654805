import { css } from '@emotion/react';
import { useTheme } from '@mui/material';

export const useStyles = () => {
  const theme = useTheme();

  return {
    toolbar: css`
      height: 60px;
      display: flex;
      justify-content: space-between;
      align-content: center;
      background: ${theme.palette.common.white};
      box-shadow: 0 10px 20px ${theme.palette.grey[700]}30;
      padding: ${theme.spacing(0, 2)};
    `,
    centered: css`
      display: flex;
      align-content: center;
    `,
    buttonsBlock: css`
      gap: ${theme.spacing(3)};
    `,
    toolbarButton: css`
      color: ${theme.palette.text.primary};
      text-transform: capitalize;
    `,
    publishButton: css`
      min-width: 130px;
    `,
    historyButtons: css`
      display: flex;
      align-items: center;
    `,
  };
};
