/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import React, { FC, useMemo } from 'react';
import { Button } from '@mui/material';
import { ToolbarCategory } from '../../ToolbarCategory';
import {
  checkboxLabelPositions,
  defaultHorizontalLabelAlignment,
  defaultVerticalLabelAlignment,
  FieldShape,
  horizontalLabelAlignment,
  ICheckboxLabelPosition,
  IFormStyleProps,
  IHorizontalLabelAlignment,
  ILabelPosition,
  IVerticalLabelAlignment,
  labelPositions,
  verticalLabelAlignment,
} from '../../../types';
import { ColorPickerWidget } from '../../ColorPickerWidget';
import { SelectInput, IOption } from '../../SelectInput';
import { SizingWidget } from '../../SizingWidget';
import { SizeInput } from '../../SizeInput';
import BorderWidget from '../../BorderWidget/BorderWidget';
import BoxShadowWidget from '../../BoxShadowWidget/BoxShadowWidget';
import { fallbackProps } from '../../ContainerEditor/mock';
import { SpacingWidget } from '../../SpacingWidget';
import { BackgroundWidget } from '../../BackgroundWidget';
import { TextAlignSwitcher } from '../../TextAlignSwitcher';
import { ZIndexWidget } from '../../ZIndexWidget';
import { isHorizontalLabelAlignment } from '../../../utils/utils';

interface IProps {
  props: IFormStyleProps;
  onChange: (props: IFormStyleProps) => void;
  onReset: () => void;
}

const columns: IOption[] = [
  {
    value: '1fr',
    label: '1 Column',
  },
  {
    value: '1fr 1fr',
    label: '2 Columns',
  },
  {
    value: '1fr 1fr 1fr',
    label: '3 Columns',
  },
];

const inputShapes = [
  {
    value: FieldShape.Outlined,
    label: 'Classic',
  },
  {
    value: FieldShape.Filled,
    label: 'Just Line',
  },
  {
    value: FieldShape.Standard,
    label: 'Text',
  },
];

const FormStylesEditor: FC<IProps> = ({ props, onChange, onReset }) => {
  const {
    internalAlignment,
    internalAlignment: { textAlign },
    size,
    background,
    input,
    input: {
      background: inputBackground,
      size: inputSize,
      variant,
      border,
      border: { borderRadius },
      boxShadow,
    },
    label,
    label: {
      input: { position: labelPosition, alignment: labelAlignment },
      checkbox,
    },
    grid,
    grid: { gridTemplateColumns },
    zIndex,
  } = props;

  const isHorizontalAlignment = useMemo(
    () => isHorizontalLabelAlignment(labelPosition),
    [labelPosition]
  );

  return (
    <div data-testid="form-styles-editor">
      <ToolbarCategory title="Internal Alignment">
        <TextAlignSwitcher
          align={textAlign}
          onChange={(value) =>
            onChange({
              ...props,
              internalAlignment: { ...internalAlignment, textAlign: value },
            })
          }
        />
      </ToolbarCategory>
      <ToolbarCategory title="Column Layout">
        <SelectInput
          value={gridTemplateColumns}
          options={columns}
          onChange={(value) =>
            onChange({
              ...props,
              grid: { ...grid, gridTemplateColumns: value },
            })
          }
        />
      </ToolbarCategory>
      {size && (
        <ToolbarCategory title="Container Size">
          <SizingWidget
            size={size || fallbackProps.size}
            //@ts-ignore
            onChange={(values) => onChange({ ...props, size: values })}
          />
        </ToolbarCategory>
      )}

      <ToolbarCategory title="Plane Index">
        <ZIndexWidget
          value={zIndex || fallbackProps.zIndex}
          onChange={(value) => onChange({ ...props, zIndex: value })}
        />
      </ToolbarCategory>

      {props.spacing?.margin && (
        <ToolbarCategory title="Margin">
          <SpacingWidget
            spacing={props.spacing?.margin || fallbackProps.spacing?.margin}
            onChange={(values) =>
              onChange({
                ...props,
                spacing: { ...props.spacing, margin: values },
              })
            }
          />
        </ToolbarCategory>
      )}
      {props.spacing?.padding && (
        <ToolbarCategory title="Padding">
          <SpacingWidget
            spacing={props.spacing?.padding || fallbackProps.spacing?.padding}
            onChange={(values) =>
              onChange({
                ...props,
                spacing: { ...props.spacing, padding: values },
              })
            }
          />
        </ToolbarCategory>
      )}
      <ToolbarCategory title="Background">
        <BackgroundWidget
          props={background || fallbackProps.background}
          onChange={(values) => onChange({ ...props, background: values })}
        />
      </ToolbarCategory>
      <ToolbarCategory title="Size of Each Field">
        <SizingWidget
          //@ts-ignore
          size={inputSize}
          onChange={(values) =>
            onChange({ ...props, input: { ...input, size: values } })
          }
        />
      </ToolbarCategory>
      <ToolbarCategory title="Fields Shape">
        <SelectInput
          value={variant}
          options={inputShapes}
          onChange={(value) =>
            onChange({
              ...props,
              input: { ...input, variant: value as FieldShape },
            })
          }
        />
        <ColorPickerWidget
          title="Fields background"
          alpha
          value={inputBackground}
          onChange={(value) =>
            onChange({
              ...props,
              input: { ...input, background: value },
            })
          }
        />
        <SizeInput
          title="Roundness"
          //@ts-ignore
          sizeValue={borderRadius}
          fullWidth
          onChange={(values) =>
            onChange({
              ...props,
              input: {
                ...input,
                border: { ...border, borderRadius: values },
              },
            })
          }
        />
      </ToolbarCategory>
      <ToolbarCategory>
        <BorderWidget
          widgetTitle="Fields Border"
          border={border}
          onChange={(value) =>
            onChange({
              ...props,
              input: {
                ...input,
                border: value,
              },
            })
          }
        />
        <BoxShadowWidget
          widgetTitle="Fields Shadow"
          boxShadow={boxShadow}
          onChange={(value) =>
            onChange({
              ...props,
              input: {
                ...input,
                boxShadow: value,
              },
            })
          }
        />
      </ToolbarCategory>
      <ToolbarCategory title="Labels">
        <SelectInput
          value={labelPosition}
          label="Label position"
          options={[...labelPositions] as string[]}
          onChange={(value) =>
            onChange({
              ...props,
              label: {
                ...label,
                input: {
                  position: value as ILabelPosition,
                  alignment: isHorizontalLabelAlignment(value as ILabelPosition)
                    ? defaultHorizontalLabelAlignment
                    : defaultVerticalLabelAlignment,
                },
              },
            })
          }
        />
        <SelectInput
          value={labelAlignment}
          label="Label alignment"
          options={
            [
              ...(isHorizontalAlignment
                ? horizontalLabelAlignment
                : verticalLabelAlignment),
            ] as string[]
          }
          onChange={(value) =>
            onChange({
              ...props,
              label: {
                ...label,
                input: {
                  ...label.input,
                  alignment: isHorizontalAlignment
                    ? (value as IHorizontalLabelAlignment)
                    : (value as IVerticalLabelAlignment),
                },
              },
            })
          }
        />
        <SelectInput
          value={checkbox}
          label="Checkbox label position"
          options={[...checkboxLabelPositions] as string[]}
          onChange={(value) =>
            onChange({
              ...props,
              label: { ...label, checkbox: value as ICheckboxLabelPosition },
            })
          }
        />
      </ToolbarCategory>

      <ToolbarCategory>
        <Button
          data-testid="reset-styles-button"
          onClick={onReset}
          size="small"
        >
          Reset
        </Button>
      </ToolbarCategory>
    </div>
  );
};

export default FormStylesEditor;
