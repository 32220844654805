/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import React from 'react';
import { IVideoComponentProps } from './IProps';

const Video = React.forwardRef<HTMLVideoElement, IVideoComponentProps>(
  (
    {
      styles,
      src,
      asset,
      controls,
      autoplay,
      themedVideo,
      currentTheme,
      elementStyles,
      ...props
    },
    ref
  ) => {
    // @ts-ignore
    const themedMedia = props?.element?.themedMedia;

    return (
      <video
        data-testid="video-component"
        {...props}
        ref={ref}
        //@ts-ignore
        css={css([styles, { zIndex: 1, position: 'relative' }])}
        autoPlay={autoplay || false}
        controls={controls || false}
        src={src || themedMedia?.url || asset?.url}
      >
        Your browser does not support this element
      </video>
    );
  }
);

export default Video;
