import {
  FormFieldType,
  IFormField,
} from '@brame/components/src/components/Form';

export const createNumberMask = (field: IFormField): string | undefined => {
  const maxCharacters = field.validation.maxCharacters;
  const minCharacters = field.validation.minCharacters;

  if (maxCharacters !== undefined && minCharacters !== undefined) {
    const requiredDigits = new Array(minCharacters).fill('0');
    const optionalDigits = new Array(maxCharacters - minCharacters).fill('0');
    return `${requiredDigits.join('')}[${optionalDigits.join('')}]`;
  }
  return undefined;
};

export const getMask = (field: IFormField): string | undefined => {
  switch (field.type) {
    case FormFieldType.PhoneNumber:
      return '+000000000000';
    case FormFieldType.Number:
      return createNumberMask(field);
    default:
      return undefined;
  }
};
