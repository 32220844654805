import axios, { AxiosError } from 'axios';
import { FormikValues } from 'formik';

class LeadsService {
  baseUrl = `${process.env.REACT_APP_API_URL}/leads/api/lead`;

  async getSessionId(
    campaignId: string | undefined,
    visitorId = 'fingerprint'
  ) {
    const data = {
      visitorId: visitorId,
      campaignId: campaignId,
    };
    try {
      const res = await axios.post(`${this.baseUrl}/session`, data);
      if (res.data === 'undefined') {
        return res;
      }
      return res.data?.sessionId;
    } catch (error) {
      const err = error as AxiosError;
      return Promise.reject(err.response?.data);
    }
  }

  async submitForm(
    campaignId: string | undefined,
    sessionId: string | null,
    formData: FormikValues
  ) {
    const data = {
      ...this.parseFormData(formData),
      visitorId: 'fingerprint',
      campaignId: campaignId,
      sessionId: sessionId,
    };
    try {
      const res = await axios.post(this.baseUrl, data);
      if (res.data === 'undefined') {
        return res;
      }
      return res.data;
    } catch (error) {
      const err = error as AxiosError;
      return Promise.reject(err.response?.data);
    }
  }

  parseFormData(values: FormikValues) {
    let data: { [key: string]: string } = {
      firstName: values.firstName || null,
      lastName: values.lastName || null,
      email: values.email || null,
      title: values.title || null,
      address: values.address || null,
      phone: values.phoneNumber || null,
      postalCode: values.postalCode || null,
      city: values.city || null,
      jobTitle: values.jobTitle || null,
      company: values.company || null,
      newsletter: values.newsletter || null,
      birthDate: values.dateOfBirth?.format('MM/DD/YYYY') || null,
    };

    const customData = Object.entries(values).filter(([key]) =>
      key.includes('custom')
    );

    customData.forEach(([, value], index) => {
      data = {
        ...data,
        [`customField${index + 1}`]: value.toString(),
      };
    });

    return data;
  }
}

export default new LeadsService();
