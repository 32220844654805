import { useEffect, useState } from 'react';
import { useAppSelector } from '../../reducers/app/hooks';
import { getSelectedElement } from '../../reducers/builder/builderSlice';
import { getSelectedBreakpoint } from '../../reducers/shared/sharedSlice';

const useResize = () => {
  const currentElement = useAppSelector(getSelectedElement);
  const breakpoint = useAppSelector(getSelectedBreakpoint);
  const [currentParentRef, setCurrentParentRef] = useState(
    document.getElementById(currentElement?.id as string)?.parentElement
  );
  const [elementRect, setElementRect] = useState<DOMRect | undefined>(
    document
      .getElementById(currentElement?.id as string)
      ?.getBoundingClientRect()
  );

  const [parentRect, setParentRect] = useState<null | DOMRect>(null);
  const maxWidth = breakpoint.breakpoint;

  useEffect(() => {
    if (currentElement) {
      setCurrentParentRef(
        document.getElementById(currentElement.id as string)?.parentElement
      );
      setElementRect(
        document
          .getElementById(currentElement.id as string)
          ?.getBoundingClientRect()
      );
    }
  }, [breakpoint, currentElement]);

  useEffect(() => {
    if (currentParentRef) {
      setParentRect(currentParentRef.getBoundingClientRect());
    }
  }, [currentParentRef]);

  const toPercent = (root: number, size: number): number => {
    return Math.round((size / root) * 100);
  };
  const toPixels = (root: number, size: number): number => {
    return Math.round((root / 100) * size);
  };

  return { toPercent, toPixels, parentRect, elementRect, maxWidth };
};

export default useResize;
