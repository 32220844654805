import { createSlice, EntityId, PayloadAction } from '@reduxjs/toolkit';

const initialState: {
  pages: EntityId[];
  additionalPages: EntityId[];
  currentPage: EntityId | null;
  currentPageIsAdditional: boolean;
} = {
  pages: [],
  additionalPages: [],
  currentPage: null,
  currentPageIsAdditional: false,
};

const pagesSlice = createSlice({
  name: 'pages',
  initialState,
  reducers: {
    setPages: (
      state,
      action: PayloadAction<{ pages: EntityId[]; additionalPages: EntityId[] }>
    ) => {
      const { pages, additionalPages } = action.payload;
      state.pages = pages;
      state.additionalPages = additionalPages;
      state.currentPage = pages[0];
      state.currentPageIsAdditional = false;
    },
    goToNextPage: (state) => {
      const currentPageIndex = state.pages.findIndex(
        (i) => i === state.currentPage
      );

      //Last page
      if (currentPageIndex === state.pages.length - 1) {
        state.currentPage = state.pages[0];
        return state;
      }

      state.currentPage = state.pages[currentPageIndex + 1];
    },
    goToPreviousPage: (state) => {
      const currentPageIndex = state.pages.findIndex(
        (i) => i === state.currentPage
      );

      //First page
      if (currentPageIndex === 0) {
        return state;
      }

      state.currentPage = state.pages[currentPageIndex - 1];
    },
    goToPage: (state, action: PayloadAction<EntityId>) => {
      const pageId = action.payload;
      const newPageIndex = state.pages.findIndex((id) => id === pageId);
      const newAdditionalPageIndex = state.additionalPages.findIndex(
        (id) => id === pageId
      );

      if (newPageIndex === -1 && newAdditionalPageIndex === -1) {
        return state;
      }

      state.currentPage =
        newPageIndex > -1
          ? state.pages[newPageIndex]
          : state.additionalPages[newAdditionalPageIndex];
      state.currentPageIsAdditional = newAdditionalPageIndex > -1;
    },
  },
});

export const { setPages, goToNextPage, goToPage, goToPreviousPage } =
  pagesSlice.actions;

export default pagesSlice.reducer;
