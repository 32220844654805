import { css } from '@emotion/react';
import { useTheme } from '@mui/material';
import { brameTheme } from '@brame/theme';

export const useStyles = () => {
  const theme = useTheme();

  return {
    previewFooter: css`
      display: flex;
      justify-content: center;
      background-color: ${theme.palette.grey['100']};
    `,
    tabsContainer: css`
      width: 100%;
      height: 100px;
      background: ${theme.palette.common.white};
      border-top: 3px solid ${theme.palette.primary.main};
      box-shadow: 0px -10px 20px rgba(174, 174, 174, 0.3);
      gap: ${theme.spacing(2)};
      outline: none;
      position: relative;
    `,
    tabs: css`
      & .MuiTabs-flexContainer {
        min-height: 31px;
        height: 31px;
        gap: ${theme.spacing(0.5)};
      }

      & .MuiTabs-indicator {
        height: 0;
      }

      position: absolute;
      top: -34px;
      left: 20px;
    `,
    tab: css`
      &.MuiTab-root {
        height: 31px;
        min-height: 31px;
      }

      font-size: ${theme.typography.body1.fontSize};
      text-transform: none;
      border: none;
      background: ${theme.palette.common.white};
      color: ${theme.palette.text.primary};
      border-radius: ${brameTheme.shape.borderRadius}px
        ${brameTheme.shape.borderRadius}px 0 0;
      padding: ${theme.spacing(0, 1)};
      cursor: pointer;

      &.Mui-selected {
        background: ${theme.palette.primary.main};
        color: ${theme.palette.primary.contrastText};
      }
    `,
  };
};
