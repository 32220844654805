import * as React from 'react';
import { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { EntityId } from '@reduxjs/toolkit';
import { CaretDown, CaretRight } from 'phosphor-react';
import TreeView from '@mui/lab/TreeView';
import { RenderTree } from '../../types';
import { useAppDispatch, useAppSelector } from '../../reducers/app/hooks';
import { getSelectedElement } from '../../reducers/builder/builderSlice';
import { BuilderComponent } from '../../reducers/builder';
import { iconSize } from '../../constants';
import { selectElement } from '../../reducers/shared';
import useElementTree from './useElementTree';
import './styles.css';
import TreeItem from './TreeItem';

export const Breadcrumbs = () => {
  const { elementsTree } = useElementTree();
  const dispatch = useAppDispatch();

  const selected = useAppSelector(getSelectedElement);
  const [expanded, setExpanded] = useState<string[]>(['page-1']);

  useEffect(() => {
    if (!selected) return;
    setExpanded((state) => {
      return [
        ...state,
        (selected.type === BuilderComponent.ROOT_CONTAINER
          ? selected.id
          : selected.parentId) as string,
      ];
    });
  }, [selected]);

  const renderTree = (tree: RenderTree) => {
    return (
      // @ts-ignore
      <TreeItem tree={tree} key={tree.id}>
        {Array.isArray(tree.children) && tree.children.map(renderTree)}
      </TreeItem>
    );
  };

  const onNodeToggle = useCallback(
    (e: SyntheticEvent, ids: string[]): void => {
      setExpanded(ids);
    },
    [dispatch, setExpanded]
  );

  const onNodeSelect = useCallback(
    (e: SyntheticEvent, id: string[] | string): void => {
      if (typeof id === 'string') {
        dispatch(selectElement(id as EntityId));
      }
    },
    [dispatch]
  );

  return (
    <div data-testid="breadcrumbs">
      <TreeView
        defaultCollapseIcon={<CaretDown size={iconSize} />}
        defaultExpandIcon={<CaretRight size={iconSize} />}
        expanded={expanded}
        selected={[selected?.id as string]}
        onNodeToggle={onNodeToggle}
        onNodeSelect={onNodeSelect}
      >
        {elementsTree.map((tree: RenderTree) => renderTree(tree))}
      </TreeView>
    </div>
  );
};

export default Breadcrumbs;
