/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import React, { FC } from 'react';
import { Typography } from '@mui/material';
import { IThemeMedia } from '@brame/theme';
import { images } from '@brame/components/src/assets/images';
import { useStyles } from './styles';

interface IProps {
  asset: IThemeMedia;
  name: string;
  video?: boolean;
}

const ThemeMediaPreviewBox: FC<IProps> = ({ asset, name, video }) => {
  const styles = useStyles();

  return asset ? (
    <div
      css={styles.container}
      data-testid={`theme-image-select-preview-${asset.name}`}
    >
      <div css={styles.box}>
        <Typography>{name}</Typography>

        {video ? (
          <div css={styles.videoBox}>
            <video
              src={asset.url}
              width={45}
              poster={asset.url ? undefined : images.fallback_clear}
              autoPlay={false}
              muted
            />
          </div>
        ) : (
          <img width={45} src={asset.url} alt={asset.name} />
        )}
      </div>
    </div>
  ) : null;
};

export default ThemeMediaPreviewBox;
