/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import React, {
  ChangeEvent,
  FC,
  Fragment,
  SyntheticEvent,
  useState,
} from 'react';
import { Checkbox, Tab, Typography, useTheme } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import {
  GameColorEditor,
  GameBooleanEditor,
  GameNumberEditor,
  GameNumberRangeEditor,
  GameEnumEditor,
  GameBooleanWrapper,
  GameSizingEditor,
  DropGameOutcomeEditor,
} from '../..';
import DropGameCollectibleEditor from '../CollectibleEditor';
import GameArrayEditor from '../../GameArrayEditor/GameArrayEditor';
import ParallaxEditor from '../ParallaxEditor';
import GameAssetEditor from '../../GameAssetEditor';
import ToolbarCategory from '../../../ToolbarCategory/ToolbarCategory';
import { dropGame } from '../utils';
import { ZIndexWidget } from '../../../ZIndexWidget';
import { GameToolbarTab, IDropGameToolbarProps } from './IProps';
import { useStyles } from './styles';


const DropGameToolbar: FC<IDropGameToolbarProps> = ({
  styleProps,
  onChange,
}) => {
  const theme = useTheme();
  const styles = useStyles();
  const [value, setValue] = useState<GameToolbarTab>('visuals');
  const [advancedCheck, setAdvancedCheck] = useState<boolean>(false);

  const handleTabChange = (event: SyntheticEvent, newValue: string) => {
    setValue(newValue as GameToolbarTab);
  };

  const handleAdvancedCheckChange = (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    setAdvancedCheck(checked);
  };

  const actualSize = {
    width: styleProps.size?.width?.value,
    height: styleProps.size?.height?.value,
  };

  return (
    <div data-testid="game-editor">
      <TabContext value={value}>
        <div>
          <TabList
            onChange={handleTabChange}
            centered
            css={styles.tabs}
            aria-label="Drop game tabs"
            indicatorColor="secondary"
          >
            <Tab
              label="Visuals"
              value="visuals"
              css={styles.tab}
              disableRipple
            />
            <Tab
              label="Settings"
              value="settings"
              css={styles.tab}
              disableRipple
            />
            <Tab
              label="Outcomes"
              value="outcomes"
              css={styles.tab}
              disableRipple
            />
          </TabList>
        </div>
        <TabPanel value="visuals" css={styles.tabPanel}>
          <ToolbarCategory title="Game screen size">
            <GameSizingEditor
              name="game.size"
              aspectRatio={true}
              updateAspectRatio={false}
              styleProps={styleProps}
              onChange={onChange}
              //@ts-ignore
              actualSize={actualSize}
            />
          </ToolbarCategory>

          <ToolbarCategory title="Plane Index">
            <ZIndexWidget
              value={styleProps.zIndex || 0}
              onChange={(value) => onChange({ zIndex: value })}
            />
          </ToolbarCategory>

          <ToolbarCategory title="Player">
            <GameAssetEditor
              name="visual.playerTexture"
              scalingName="visual.playerSize"
            />
          </ToolbarCategory>

          <ToolbarCategory title="Background">
            <GameBooleanWrapper
              name="visual.parallax"
              label="Background image"
              trueChildren={
                <GameArrayEditor
                  name="visual.parallaxSettings"
                  label="Images"
                  ComponentType={ParallaxEditor}
                />
              }
              falseChildren={
                <GameColorEditor
                  name="game.backgroundColor"
                  label="Game Background Color"
                  defaultValue={theme.palette.common.white}
                />
              }
            />
          </ToolbarCategory>

          <ToolbarCategory>
            <GameArrayEditor
              name="game.collectibles"
              label="Game Assets"
              ComponentType={DropGameCollectibleEditor}
            />
          </ToolbarCategory>
        </TabPanel>

        <TabPanel value="settings" css={styles.tabPanel}>
          <ToolbarCategory title="Basic Settings">
            <div css={[styles.settingsBlock, styles.column]}>
              <GameEnumEditor
                name="game.direction"
                label="Drop Direction"
                enums={dropGame.directions}
                descs={dropGame.directionDescriptions}
              />
              <GameEnumEditor
                name="game.movementMode"
                label="Movement Mode"
                enums={dropGame.movementModes}
                descs={dropGame.movementModeDescriptions}
              />
              <GameNumberRangeEditor
                name="game.lifeCount"
                label="Player Life Count"
                min={0}
                max={10}
                defaultValue={5}
                unit="lives"
              />

              <GameBooleanWrapper
                name="game.maxTime"
                trueDefault={0}
                headerChildren={
                  <Typography css={styles.toolbarTitle}>
                    Endless Time
                  </Typography>
                }
                falseChildren={
                  <div>
                    <Typography>Limited to</Typography>
                    <GameNumberRangeEditor
                      name="game.maxTime"
                      min={10}
                      max={6000}
                      unit="sec."
                    />
                  </div>
                }
              />
            </div>
          </ToolbarCategory>

          <ToolbarCategory title="Amount of lanes">
            <GameNumberRangeEditor
              name="game.laneCount"
              min={2}
              max={10}
              unit="lanes"
            />
          </ToolbarCategory>

          <ToolbarCategory title="Difficulty">
            <GameEnumEditor
              name="game.difficulty"
              enums={dropGame.difficulties}
            />
          </ToolbarCategory>

          <ToolbarCategory>
            <div css={styles.checkableTitle}>
              <Typography css={styles.toolbarTitle}>
                Advanced Settings
              </Typography>
              <Checkbox
                checked={advancedCheck}
                onChange={handleAdvancedCheckChange}
              />
            </div>
          </ToolbarCategory>

          {advancedCheck && (
            <Fragment>
              <ToolbarCategory title="Lanes offset">
                <div css={styles.settingsBlock}>
                  <div>
                    <Typography>From Left/Top</Typography>
                    <GameNumberEditor name="game.laneOffsetTop" unit="%" />
                  </div>

                  <div>
                    <Typography>From Right/Bottom</Typography>
                    <GameNumberEditor name="game.laneOffsetBot" unit="%" />
                  </div>
                </div>
              </ToolbarCategory>

              <ToolbarCategory title="Initial time it takes to spawn objects">
                <div css={styles.settingsBlock}>
                  <div>
                    <Typography>Collectibles</Typography>
                    <GameNumberRangeEditor
                      name="game.initSpawnTime"
                      max={10000}
                      min={100}
                      denominator={1000}
                      unit="sec."
                    />
                  </div>
                  <div>
                    <Typography>Obstacles</Typography>
                    <GameNumberRangeEditor
                      name="game.bombSpawnTime"
                      max={10000}
                      min={100}
                      denominator={1000}
                      unit="sec."
                    />
                  </div>
                </div>
              </ToolbarCategory>

              <GameBooleanEditor
                name="game.debugSprites"
                label="Debug Draw sprites"
              />
            </Fragment>
          )}
        </TabPanel>
        <TabPanel value="outcomes" css={styles.tabPanel}>
          <ToolbarCategory>
            <GameBooleanWrapper
              name="game.outcomes"
              label="Use Game outcomes?"
              trueChildren={
                <GameArrayEditor
                  name="outcomes"
                  label="Game Outcomes"
                  ComponentType={DropGameOutcomeEditor}
                />
              }
            />
          </ToolbarCategory>
        </TabPanel>
      </TabContext>
    </div>
  );
};

export default DropGameToolbar;
