import { css } from '@emotion/react';
import { useTheme } from '@mui/material';
import { brameTheme } from '@brame/theme';

export const useStyles = () => {
  const theme = useTheme();

  return {
    popper: css`
      top: 30px !important;

      & > div {
        border-radius: ${brameTheme.shape.borderRadius}px;
        box-shadow: 0 0 20px rgba(174, 174, 174, 0.3);
        background: ${theme.palette.common.white};
        padding: ${theme.spacing(3)};
        display: flex;
        gap: ${theme.spacing(2)};
        flex-direction: column;
      }
    `,
    popperBox: css`
      display: flex;
      gap: ${theme.spacing(1)};
    `,
    flexEnd: css`
      justify-content: flex-end;
    `,
    popperInput: css`
      width: 100px;
    `,
    formatsContainer: css`
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      gap: ${theme.spacing(3)};
    `,
    formatsBox: css`
      display: flex;
      gap: ${theme.spacing(1)};
    `,
  };
};
