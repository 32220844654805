import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import { Provider } from 'react-redux';
import { AppBar } from '@mui/material';
import { runnerStore } from '@brame/components/src/slices/store';
import Main from './pages/main/main-page';
import ProtectedRoute from './ProtectedRoute';
import { Header } from './components/Header';
import { useAuth } from './auth/authHooks';
import ErrorCampaignNotFound from './components/ErrorCampaignNotFound';
import { Preview } from './pages/preview';
import { checkAllNeededEnvVars } from './utils/utils';
import { Wizard } from './pages/wizard';
import { Toolbar as ControlBar } from './components/Toolbar';
import { CampaignsPage } from './pages/campaigns';
import { baseName } from './api-client/axios.service';

checkAllNeededEnvVars();

const App: React.FC = () => {
  const { authState } = useAuth();
  return (
    <BrowserRouter basename={`/${baseName}`}>
      <Routes>
        <Route
          path="/:campaignId"
          element={
            <ProtectedRoute
              authState={authState}
              authenticationPath={process.env.REACT_APP_AUTHENTICATION_URL}
              outlet={
                <>
                  <AppBar
                    position="static"
                    elevation={0}
                    sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
                  >
                    <Header />
                    <ControlBar />
                  </AppBar>
                  <Main />
                </>
              }
            />
          }
        />
        <Route
          path="/:campaignId/:companyId/preview"
          element={
            <ProtectedRoute
              authState={authState}
              authenticationPath={process.env.REACT_APP_AUTHENTICATION_URL}
              outlet={
                <Provider store={runnerStore}>
                  <Preview />
                </Provider>
              }
            />
          }
        />
        <Route
          path="/campaigns"
          element={
            <ProtectedRoute
              authState={authState}
              authenticationPath={process.env.REACT_APP_AUTHENTICATION_URL}
              outlet={
                <>
                  <AppBar
                    position="static"
                    elevation={0}
                    sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
                  >
                    <Header />
                  </AppBar>
                  <CampaignsPage />
                </>
              }
            />
          }
        />
        <Route
          path="*"
          element={
            <ProtectedRoute
              authState={authState}
              authenticationPath={process.env.REACT_APP_AUTHENTICATION_URL}
              outlet={<Wizard />}
            />
          }
        />
        <Route
          path="/error-page"
          element={
            <ProtectedRoute
              authState={authState}
              authenticationPath={process.env.REACT_APP_AUTHENTICATION_URL}
              outlet={<ErrorCampaignNotFound />}
            />
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default React.memo(App);
