import { css } from '@emotion/react';
import { useTheme } from '@mui/material';

export const useStyles = () => {
  const theme = useTheme();

  return {
    dialog: css`
      width: 460px;
      max-width: 100%;
    `,
    dialogTitle: css`
      padding: ${theme.spacing(2, 2, 1)};
    `,
    dialogContent: css`
      padding: ${theme.spacing(0, 2, 1)};
    `,
    dialogButtons: css`
      padding: ${theme.spacing(0, 2, 2)};

      & > :not(:first-of-type) {
        margin-left: ${theme.spacing(1)};
      }
    `,
  };
};
