import { AxiosError } from 'axios';
import { CampaignMetaDataIProps } from '../reducers/campaign/types';
import axiosI from './axios.service';
import { mockCampaignConfig } from './fixtures';

type GetAllCampaignsResponse = {
  data: CampaignMetaDataIProps[];
  message: string;
};
class CampaignService {
  campaignEndpointUrl = `${process.env.REACT_APP_API_URL}/campaign-configurations/api/campaigns`;
  campaignMetadataUrl = `${process.env.REACT_APP_SERVER_BASE_URL}/campaign`;

  async getCampaignDataByCompanyAndCampaign(
    companyId?: string,
    campaignId?: string
  ) {
    const url = `${this.campaignEndpointUrl}/${companyId}/${campaignId}`;
    try {
      const res = await axiosI.get(url);

      if (res.data === 'undefined') {
        return res;
      }
      return res.data;
    } catch (error) {
      const err = error as AxiosError;
      return err.response;
    }
  }

  async putCampaignDataByCompanyAndCampaign(
    companyId: string | undefined,
    campaignId: string | undefined,
    // eslint-disable-next-line
    data: any
  ) {
    const dataString = JSON.stringify({
      jsonData: data,
      companyId: companyId,
      campaignId: campaignId,
    });
    const url = `${this.campaignEndpointUrl}`;
    try {
      const res = await axiosI.put(url, dataString);
      if (res.data === 'undefined') {
        return res;
      }
      return res.data;
    } catch (error) {
      const err = error as AxiosError;
      return err.response;
    }
  }

  async putCampaignMetaData(data: any) {
    const url = `${this.campaignMetadataUrl}/`;
    try {
      const res = await axiosI.put(url, data);
      if (res.data === 'undefined') {
        return res;
      }
      return res.data;
    } catch (error) {
      const err = error as AxiosError;
      return err.response;
    }
  }

  async postInitialCampaignDataByCompanyAndCampaign(
    companyId: string | undefined,
    campaignId: string | undefined,
    // eslint-disable-next-line
    data: any
  ) {
    const dataString = JSON.stringify({
      jsonData: data,
      companyId: companyId,
      campaignId: campaignId,
    });
    const url = `${this.campaignEndpointUrl}`;
    try {
      const res = await axiosI.post(url, dataString);
      if (res.data === 'undefined') {
        return res;
      }
      return res.data;
    } catch (error) {
      const err = error as AxiosError;
      return err.response;
    }
  }

  /**
   *
   * @param campaignId campaignId for which the data should be fetched
   *
   * The authentication is sent in the auth header
   */
  async getCampaignMetaData(campaignId: string | undefined) {
    const config = {
      headers: {},
    };
    const url = `${this.campaignMetadataUrl}/${campaignId}`;
    try {
      const res = await axiosI.get(url, config);
      if (res.data === 'undefined') {
        return res;
      }
      return res.data;
    } catch (error) {
      const err = error as AxiosError;
      return err.response;
    }
  }

  async getAllCampaignsByCompany(companyId: string | undefined) {
    const url = `${this.campaignMetadataUrl}/company-id/${companyId}`;
    try {
      const {
        data: { data, message },
      } = await axiosI.get<GetAllCampaignsResponse>(url);

      return { data, error: null, message };
    } catch (error) {
      console.log(error);
    }
    return { data: [], message: '' };
  }

  async createCampaignByCompany(
    companyId: string | undefined,
    name: string,
    description?: string
  ) {
    const data = { ...mockCampaignConfig, name: name, companyId, description };
    const url = `${this.campaignMetadataUrl}/`;
    try {
      const res = await axiosI.post(url, data);
      if (res.data === 'undefined') {
        return res;
      }
      return res.data;
    } catch (error) {
      const err = error as AxiosError;
      return err.response;
    }
  }

  async deleteCampaignByCompany(companyId: string, campaignId: string) {
    const url = `${this.campaignEndpointUrl}/${companyId}/${campaignId}`;
    try {
      const res = await axiosI.delete(url);
      return res.data;
    } catch (error) {
      const err = error as AxiosError;
      return err.response?.data;
    }
  }
}

export default new CampaignService();
