import React, { useState } from 'react';
import { UnitValueType } from '../../types';
import { ISizingWidgetProps } from './SizingWidget';
import useResize from './useResize';

const useSizingWidget = (props: Omit<ISizingWidgetProps, 'title'>) => {
  const { showAspectRatioToggle, size, actualSize, onChange } = props;
  const { parentRect, toPercent, toPixels, elementRect, maxWidth } =
    useResize();
  const [preservedAspectRatio, setPreservedAspectRatio] = useState<
    string | null
  >(null);

  const onChangeAspectRatioToggle = (
    event: React.MouseEvent<HTMLElement>,
    newPreservedAspectRatio: string
  ): void => {
    setPreservedAspectRatio(newPreservedAspectRatio);
  };

  const onChangeWidth = (value: UnitValueType) => {
    if (value.unit === '%' && value.value > 100) {
      onChange({
        ...size,
        width: {
          value: 100,
          unit: value.unit,
        },
      });
      return;
    }

    if (value.unit === 'px' && value.value > maxWidth) {
      onChange({
        ...size,
        width: {
          value: maxWidth,
          unit: value.unit,
        },
      });
      return;
    }

    if (elementRect && parentRect) {
      if (size.width.unit === 'auto' && value.unit === 'px') {
        onChange({
          ...size,
          width: {
            value: elementRect.width,
            unit: 'px',
          },
        });
        return;
      }
      if (size.width.unit === 'auto' && value.unit === '%') {
        onChange({
          ...size,
          width: {
            value: toPercent(parentRect.width, elementRect.width),
            unit: '%',
          },
        });
        return;
      }

      if (
        typeof size.width.value === 'number' &&
        typeof value.value === 'number'
      ) {
        if (size.width.unit === 'px' && value.unit === '%') {
          onChange({
            ...size,
            width: {
              value: toPercent(parentRect.width, value.value),
              unit: '%',
            },
          });
          return;
        }
        if (size.width.unit === '%' && value.unit === 'px') {
          onChange({
            ...size,
            width: {
              value: toPixels(parentRect.width, value.value),
              unit: 'px',
            },
          });
          return;
        }
      }
    }

    if (showAspectRatioToggle && preservedAspectRatio && actualSize) {
      const newHeight =
        value.value === 'auto'
          ? 'auto'
          : (value.value * actualSize.height) / actualSize.width;

      onChange({
        width: value,
        height: { value: newHeight, unit: value.unit } as UnitValueType,
      });
    } else {
      onChange({ ...size, width: value });
    }
  };

  const onChangeHeight = (value: UnitValueType) => {
    if (elementRect && parentRect) {
      if (size.height.unit === 'auto' && value.unit === 'px') {
        onChange({
          ...size,
          height: {
            value: elementRect.height,
            unit: 'px',
          },
        });
        return;
      }
      if (size.height.unit === 'auto' && value.unit === '%') {
        onChange({
          ...size,
          height: {
            value: toPercent(parentRect.height, elementRect.height),
            unit: '%',
          },
        });
        return;
      }

      if (
        typeof size.height.value === 'number' &&
        typeof value.value === 'number'
      ) {
        if (size.height.unit === 'px' && value.unit === '%') {
          onChange({
            ...size,
            height: {
              value: toPercent(parentRect?.height as number, value.value),
              unit: '%',
            },
          });
          return;
        }
        if (size.height.unit === '%' && value.unit === 'px') {
          onChange({
            ...size,
            height: {
              value: toPixels(parentRect?.height as number, value.value),
              unit: 'px',
            },
          });
          return;
        }
      }
    }

    if (showAspectRatioToggle && preservedAspectRatio && actualSize) {
      const newWidth =
        value.value === 'auto'
          ? 'auto'
          : (value.value * actualSize?.width) / actualSize?.height;

      onChange({
        height: value,
        width: { value: newWidth, unit: value.unit } as UnitValueType,
      });
    } else {
      onChange({ ...size, height: value });
    }
  };

  return {
    preservedAspectRatio,
    onChangeAspectRatioToggle,
    onChangeWidth,
    onChangeHeight,
  };
};

export default useSizingWidget;
