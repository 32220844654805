import { css } from '@emotion/react';
import { useTheme } from '@mui/material';

export const useStyles = () => {
  const theme = useTheme();

  return {
    pageNavigator: css`
      display: flex;
      justify-content: center;
    `,
    pageScroller: css`
      display: flex;
      overflow-x: scroll;
      overflow-y: hidden;
      padding: ${theme.spacing(0, 1)};

      &::-webkit-scrollbar {
        display: none;
      }

    ,
    `,
    pageScrollerContent: css`
      display: flex;
      width: max-content;
      margin: 0 auto;
    `,
  };
};
