/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import React, { FC } from 'react';
import capitalize from 'lodash/capitalize';
import startCase from 'lodash/startCase';
import {
  FormControl,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { useFormEditorStyles } from '../../styles';
import { ButtonActionType, PageType } from '../../../../reducers/builder';
import { useAppSelector } from '../../../../reducers/app/hooks';
import { selectAdditionalPages } from '../../../../reducers/builder/builderSlice';

export interface IProps {
  props: any;
}

const buttonActions = Object.values(ButtonActionType)
  .filter((action) => action !== ButtonActionType.SUBMIT_FORM)
  .map((value) => ({
    value,
    name: capitalize(startCase(value)),
  }));

const EditTerms: FC<IProps> = ({ props }) => {
  const styles = useFormEditorStyles();
  const additionalPages = useAppSelector(selectAdditionalPages).filter(
    (page) => page?.pageType !== PageType.Email
  );

  return (
    <div>
      <FormControl fullWidth css={styles.container}>
        <Typography css={styles.label}>Go To</Typography>
        <Select
          id="actionType"
          name="actionType"
          css={styles.select}
          value={props.values.actionType}
          onChange={(event) => {
            props.handleChange(event);
            props.setFieldValue('actionTarget', '');
          }}
        >
          {buttonActions.map(({ name, value }) => (
            <MenuItem value={value} key={value} css={styles.menuItem}>
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {props.values.actionType === ButtonActionType.LINK && (
        <FormControl fullWidth css={styles.container}>
          <Typography css={styles.label}>Link</Typography>
          <TextField
            InputLabelProps={{ shrink: true }}
            id="actionTarget"
            name="actionTarget"
            value={props.values.actionTarget}
            onChange={props.handleChange}
          />
        </FormControl>
      )}
      {props.values.actionType === ButtonActionType.CHANGE_PAGE && (
        <FormControl fullWidth css={styles.container}>
          <Typography css={styles.label}>Additional Page</Typography>
          <Select
            id="actionTarget"
            name="actionTarget"
            css={styles.select}
            value={props.values.actionTarget}
            onChange={props.handleChange}
          >
            {additionalPages.map((page) => (
              <MenuItem value={page?.id} key={page?.id} css={styles.menuItem}>
                {page?.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </div>
  );
};

export default EditTerms;
