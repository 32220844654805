import { css } from '@emotion/react';
import { useTheme } from '@mui/material';

export const useStyles = () => {
  const theme = useTheme();

  return {
    container: css`
      display: flex;
      align-items: flex-end;
      gap: ${theme.spacing(0.5)};
    `,
    title: css`
      text-transform: capitalize;
      margin-bottom: ${theme.spacing(1)};
    `,
    toggleButton: css`
      height: 33px;
    `,
    icon: css`
      transform: rotate(90deg);
    `,
  };
};
