/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import React, { FC } from 'react';
import { EntityId } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import TabUnstyled from '@mui/base/TabUnstyled';
import TabsListUnstyled from '@mui/base/TabUnstyled';
import TabPanelUnstyled from '@mui/base/TabPanelUnstyled';
import TabsUnstyled from '@mui/base/TabsUnstyled';
import { PageNavigator } from '../PageNavigator';
import {
  selectAdditionalPages,
  selectedPageId,
  selectRegularPages,
} from '../../reducers/builder/builderSlice';
import { PageElement } from '../../reducers/builder';
import { useFooter } from './useFooter.hook';
import { useStyles } from './styles';

const Footer: FC = () => {
  const regularPages: (PageElement | undefined)[] =
    useSelector(selectRegularPages);
  const additionalPages: (PageElement | undefined)[] = useSelector(
    selectAdditionalPages
  );
  const currentPageId: EntityId = useSelector(selectedPageId);

  const {
    handleRemovePage,
    handleAddPage,
    handleSelectPage,
    handleRenamePage,
  } = useFooter();

  const styles = useStyles();

  return (
    <footer css={styles.footer} data-testid="footer">
      <TabsUnstyled css={styles.tabsContainer} defaultValue={0}>
        <TabsListUnstyled css={styles.tabs} component="div">
          <TabUnstyled css={styles.tab} value={0}>
            Regular pages
          </TabUnstyled>
          <TabUnstyled css={styles.tab} value={1}>
            Additional pages
          </TabUnstyled>
        </TabsListUnstyled>

        <TabPanelUnstyled value={0}>
          <PageNavigator
            // @ts-ignore
            pages={regularPages}
            currentPageId={currentPageId}
            onRemovePage={handleRemovePage}
            onSelectPage={handleSelectPage}
            onRenamePage={handleRenamePage}
            onAddPage={handleAddPage}
          />
        </TabPanelUnstyled>
        <TabPanelUnstyled value={1}>
          <PageNavigator
            // @ts-ignore
            pages={additionalPages}
            currentPageId={currentPageId}
            onRemovePage={handleRemovePage}
            onSelectPage={handleSelectPage}
            onRenamePage={handleRenamePage}
            onAddPage={handleAddPage}
            isAdditional
          />
        </TabPanelUnstyled>
      </TabsUnstyled>
    </footer>
  );
};

export default Footer;
