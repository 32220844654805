export function parseJwt(token: string) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
}

export function isTokenExpired(token: string) {
  const exp = parseJwt(token).exp;
  return Date.now() >= exp * 1000;
}

export function isTokenExpiredWithTimeframe(token: string, timeframe: number) {
  const exp = parseJwt(token).exp;
  return Date.now() + timeframe * 1000 >= exp * 1000;
}

export function isLocalHost(url: string) {
  return url.indexOf('localhost') !== -1 || url.indexOf('127.0.0.1') !== -1;
}
