import Phaser from 'phaser';
import DropGameScene from './DropGameScene';
import { grantLife, takeDamage } from './GameStateManager';

enum CollectibleType {
  Points1 = 'Points1',
  Points2 = 'Points2',
  Points3 = 'Points3',
  Bomb = 'Bomb',
  Life = 'Life',
}

export class Collectible extends Phaser.Physics.Matter.Sprite {
  constructor(scene: Phaser.Scene, x: number, y: number, key: string) {
    super(scene.matter.world, x, y, key);
  }
}

export class CollectiblePool extends Phaser.GameObjects.Group {
  constructor(
    scene: Phaser.Scene,
    config: Phaser.Types.GameObjects.Group.GroupConfig = {}
  ) {
    const defaults: Phaser.Types.GameObjects.Group.GroupConfig = {
      classType: Collectible,
      maxSize: -1,
      active: false,
    };

    super(scene, Object.assign(defaults, config));
  }

  spawn(x = 0, y = 0, collisionGroup: number): Collectible {
    const spawnExisting = this.countActive(false) > 0;
    const collectible = super.get(x, y);
    if (spawnExisting) {
      collectible.setActive(true);
      collectible.setVisible(true);
      collectible.setCollisionGroup(collisionGroup);
      collectible.world.add(collectible.body);
    }
    return collectible;
  }

  despawn(collectible: Collectible) {
    collectible.setActive(false);
    collectible.setVisible(false);
    collectible.world.remove(collectible.body);
  }
}

export function initProbabilityArray(scene: DropGameScene) {
  for (const entry in DropGameScene.jsonLink['game.collectibles']) {
    switch (DropGameScene.jsonLink['game.collectibles'][entry].itemType) {
      case CollectibleType.Points1:
        scene.points1Keys.push(
          DropGameScene.jsonLink['game.collectibles'][entry].textureKey
        );
        break;
      case CollectibleType.Points2:
        scene.points2Keys.push(
          DropGameScene.jsonLink['game.collectibles'][entry].textureKey
        );
        break;
      case CollectibleType.Points3:
        scene.points3Keys.push(
          DropGameScene.jsonLink['game.collectibles'][entry].textureKey
        );
        break;
      case CollectibleType.Bomb:
        scene.bombKeys.push(
          DropGameScene.jsonLink['game.collectibles'][entry].textureKey
        );
        break;
      case CollectibleType.Life:
        scene.lifeKeys.push(
          DropGameScene.jsonLink['game.collectibles'][entry].textureKey
        );
        break;
    }
  }
  // Set spawn bombs / lives
  if (scene.lifeKeys.length) {
    scene.lifeSpawning = true;
  }
  if (scene.bombKeys.length) {
    scene.bombSpawning = true;
  }
  const prob1 = 0.6;
  const prob2 = 0.3;
  const prob3 = 0.1;
  if (scene.points1Keys.length) {
    scene.spawnChances['Points1'] = prob1;
    if (scene.points2Keys.length) {
      scene.spawnChances['Points2'] = prob2;
    } else {
      scene.spawnChances['Points1'] += prob2;
    }

    if (scene.points3Keys.length) {
      scene.spawnChances['Points3'] = prob3;
    } else {
      scene.spawnChances['Points1'] += prob3;
    }
  }
}

export function initCollectiblePools(scene: DropGameScene) {
  const typeArray = ['Points1', 'Points2', 'Points3', 'Bomb', 'Life'];
  for (const entry in typeArray) {
    const name = typeArray[entry];
    //@ts-ignore
    scene.collectiblePool[name] = scene.add.collectiblePool();
    scene.collectiblePool[name].name = name;
  }
}

export function collectCollectible(
  collectible: Phaser.Physics.Matter.Sprite,
  scene: DropGameScene
) {
  if (scene.gameEnd) return;
  // Clear previous emitter
  if (scene.playerParticlesEmitter) scene.playerParticlesEmitter.remove();
  // Swap texture
  scene.playerParticlesManager.setTexture(collectible.texture.key);
  // Calculate scale
  const scaleVec = scene.getScalingValues(collectible.texture.key);
  scaleVec.x *= 0.35;
  scaleVec.y *= 0.35;
  scene.playerParticlesEmitter = scene.playerParticlesManager
    .createEmitter({
      speed: { min: 100, max: 300 },
      angle: { min: 180, max: 360 },
      scale: { min: scaleVec.x, max: scaleVec.y },
      alpha: { start: 1, end: 0 },
      lifespan: { min: 600, max: 800 },
      blendMode: 'NORMAL',
    })
    .startFollow(scene.player);
  scene.playerParticlesEmitter.explode(20, scene.player.x, scene.player.y);
  // Update Game State
  switch (collectible.name) {
    case 'Points1':
      scene.updateValue('score', scene.getScore() + 1);
      break;
    case 'Points2':
      scene.updateValue('score', scene.getScore() + 2);
      break;
    case 'Points3':
      scene.updateValue('score', scene.getScore() + 3);
      break;
    case 'Bomb':
      takeDamage(scene);
      break;
    case 'Life':
      grantLife(scene);
      break;
  }
  const collectiblePool = scene.collectiblePool[
    collectible.name
  ] as CollectiblePool;
  collectiblePool.despawn(collectible);
}

export function missCollectible(
  collectible: Phaser.Physics.Matter.Sprite,
  scene: DropGameScene
) {
  const collectiblePool = scene.collectiblePool[
    collectible.name
  ] as CollectiblePool;
  collectiblePool.despawn(collectible);
}

export function spawnBomb(scene: DropGameScene) {
  if (scene.bombSpawning) {
    const bomb = spawnCollectible('Bomb', scene);
    bomb?.setDepth(1);
  }
}

export function spawnLife(scene: DropGameScene) {
  if (scene.getLives() < scene.maxLives && scene.lifeSpawning) {
    spawnCollectible('Life', scene);
  }
}

export function spawnPoints(scene: DropGameScene) {
  const randomGen = Math.random();
  let chanceSum = 0;
  let spawnType = '';
  for (const entry in scene.spawnChances) {
    if (randomGen < scene.spawnChances[entry] + chanceSum) {
      spawnType = entry;
      break;
    }
    chanceSum += scene.spawnChances[entry];
  }
  spawnCollectible(spawnType, scene);
}

export function spawnCollectible(spawnType: string, scene: DropGameScene) {
  let targetArray = [] as string[];
  switch (spawnType) {
    case 'Points1':
      targetArray = scene.points1Keys;
      break;
    case 'Points2':
      targetArray = scene.points2Keys;
      break;
    case 'Points3':
      targetArray = scene.points3Keys;
      break;
    case 'Bomb':
      targetArray = scene.bombKeys;
      break;
    case 'Life':
      targetArray = scene.lifeKeys;
      break;
  }
  let keyIndex = Math.random();
  keyIndex *= targetArray.length;
  keyIndex = Math.floor(keyIndex);

  return spawnCertainCollectible(spawnType, targetArray[keyIndex]);
}

export function spawnCertainCollectible(spawnType: string, texKey: string) {
  const scene = DropGameScene.getInstance();
  const laneIndex = Math.floor(Math.random() * scene.laneArray.length);
  const collectiblePool = scene.collectiblePool[spawnType] as CollectiblePool;
  if (collectiblePool) {
    const collectible = collectiblePool.spawn(
      scene.laneArray[laneIndex].x,
      scene.laneArray[laneIndex].y,
      scene.collectibleGroup
    );
    collectible.setTexture(texKey);
    const scales = scene.getScalingValues(texKey);
    collectible.setScale(scales.x, scales.y);
    collectible.setBody({
      type: 'rectangle',
      width: collectible.displayWidth,
      height: collectible.displayHeight,
    });
    collectible.setFriction(0, 0, 0);
    collectible.setSensor(true);
    collectible.name = collectiblePool.name;
    switch (scene.gameDirection) {
      case 'TopBot':
        collectible.setVelocityY(scene.currentSpeed);
        break;
      case 'BotTop':
        collectible.setVelocityY(-scene.currentSpeed);
        break;
      case 'LeftRight':
        collectible.setVelocityX(scene.currentSpeed);
        break;
      case 'RightLeft':
        collectible.setVelocityX(-scene.currentSpeed);
        break;
    }
    return collectible;
  }
}
