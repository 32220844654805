import { css } from '@emotion/react';
export const useStyles = () => ({
  container: css`
    width: 100%;
  `,
  box: css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  `,
  videoBox: css`
    display: flex;
    height: 30px;

    video {
      object-fit: cover;
    }
  `,
});
