import { css } from '@emotion/react';
import { useTheme } from '@mui/material';

export const useStyles = () => {
  const theme = useTheme();

  return {
    button: css`
      border: none;
      border-radius: ${theme.shape.borderRadius}px !important;
      width: 36px;
      height: 36px;
      padding: ${theme.spacing(0)};

      &.Mui-selected {
        background: ${theme.palette.primary.main};

        &:hover {
          background: ${theme.palette.primary.main};
        }
      }
    `,
    wrapper: css`
      display: flex;
      flex-direction: column;
      gap: ${theme.spacing(1)};
    `,
  };
};
