/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import * as React from 'react';
import { FC, ReactNode } from 'react';
import { X } from 'phosphor-react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { IconButton } from '@mui/material';
import { iconSize } from '../../../constants';
import { useStyles } from './styles';

export interface IProps {
  children: ReactNode;
  name: string;
  isOpen: boolean;
  onCancel: () => void;
}

const PreviewModal: FC<IProps> = ({ children, name, isOpen, onCancel }) => {
  const styles = useStyles();
  return (
    <Dialog
      open={isOpen}
      onClose={onCancel}
      data-testid="preview-modal"
      PaperProps={{
        sx: {
          maxHeight: 'calc(100% - 323px)',
          height: '100%',
          width: '600px',
          maxWidth: '100%',
        },
      }}
    >
      <DialogTitle css={styles.dialogTitle} data-testid="preview-modal-title">
        <span>{`${name} preview`}</span>
        <IconButton
          aria-label="close"
          onClick={onCancel}
          css={styles.dialogTitleIcon}
        >
          <X size={iconSize} />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <div css={styles.content}>{children}</div>
      </DialogContent>
    </Dialog>
  );
};

export default PreviewModal;
