import { useAppDispatch, useAppSelector } from '../../reducers/app/hooks';
import {
  getCanvasDimensions,
  getCanvasResponsiveness,
  getDimensions,
  setCanvasDimensions,
  setCanvasResponsiveness,
} from '../../reducers/shared/sharedSlice';
import {
  getSelectedPage,
  resetElementDimensions,
  updateStyles,
} from '../../reducers/builder/builderSlice';
import { ICanvasDimensions } from '../../reducers/shared/types';

const useCanvasResize = () => {
  const dispatch = useAppDispatch();
  const canvasDimensions = useAppSelector(getCanvasDimensions);
  const canvasResponsiveness = useAppSelector(getCanvasResponsiveness);
  const currentRootElement = useAppSelector(getSelectedPage)?.elementRoot;
  const canvasFormats = useAppSelector(getDimensions);

  const changeCanvasMode = (checked: boolean) => {
    if (canvasResponsiveness !== checked) {
      dispatch(setCanvasResponsiveness(checked));
    }

    if (!checked) {
      changeCanvasDimensions(canvasDimensions);
    }

    if (checked) {
      dispatch(resetElementDimensions(currentRootElement));
    }
  };

  const changeCanvasDimensions = (dimensions: ICanvasDimensions) => {
    const styles = {
      size: {
        width: { value: dimensions.width, unit: 'px' },
        height: { value: dimensions.height, unit: 'px' },
      },
    };

    dispatch(setCanvasDimensions(dimensions));
    dispatch(updateStyles({ id: currentRootElement, styles }));
  };

  return {
    canvasFormats,
    canvasDimensions,
    changeCanvasMode,
    canvasResponsiveness,
    changeCanvasDimensions,
  };
};

export default useCanvasResize;
