import { css } from '@emotion/react';
import { useTheme } from '@mui/material';

export const useStyles = () => {
  const theme = useTheme();

  return {
    title: css`
      text-transform: capitalize;
      margin-bottom: ${theme.spacing(1)};
    `,
    styleControl: css`
      display: inline-flex;
      align-items: center;
    `,
    colorHexInput: css`
      width: 90px;
      text-transform: uppercase;
      padding: ${theme.spacing(0.5, 1)};
      font-size: ${theme.typography.subtitle1.fontSize};
      border-radius: 5px;
      border: 1px solid ${theme.palette.grey[600]};
      color: ${theme.palette.grey[600]};

      &:focus {
        outline-color: ${theme.palette.primary.main};
      }

      &:hover {
        border-color: ${theme.palette.grey[600]};
      }
    `,
    widget: css`
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin: ${theme.spacing(0.5, 0)};
      width: 100%;
    `,
  };
};
