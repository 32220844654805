import { IEmailTemplateCollection } from './types';

export const defaultEmailTemplates: IEmailTemplateCollection = {
  template_1: {
    id: 'template_1',
    name: 'Template 1',
    subject: 'Your subject',
    sender: 'sender@bender.com',
    html: '<div><h1>Simple textual template</h1><h2>Your fascinating subtitle goes here...</h2> <p>Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p><br/><br/><p><b>See you soon!</b></p></div>',
  },
  template_2: {
    id: 'template_2',
    name: 'Template 2',
    subject: 'Your subject',
    sender: 'sender@bender.com',
    html: '<div><h1>Simple block template</h1><h3>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</h3><p><i>Quote of the great one...</i></p> <div><img width="100%" src="https://brame-global-assets.s3.eu-central-1.amazonaws.com/templateDefaults/youthful.jpg" alt="kids" /></div><p>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. </p><p><br/><br/><a href="#">Footer link 1</a> | <a href="#">Footer link 2</a> | <a href="#">Footer link 3</a></p> </div>',
  },
  template_3: {
    id: 'template_3',
    name: 'Template 3',
    subject: 'Your subject',
    sender: 'sender@bender.com',
    html: '<div><h1>Simple block template</h1><h3>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</h3><div><img width="100%" height="70" style="object-fit: cover" src="https://brame-global-assets.s3.eu-central-1.amazonaws.com/templateDefaults/youthful.jpg" alt="kids" /></div><p>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. </p> <div><img width="100%" height="90" style="object-fit: cover" src="https://brame-global-assets.s3.eu-central-1.amazonaws.com/templateDefaults/youthful.jpg" alt="kids" /><h3>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</h3><p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. </p></div>  <p><br/><br/><a href="#">Footer link 1</a> | <a href="#">Footer link 2</a> | <a href="#">Footer link 3</a></p></div>',
  },
};
