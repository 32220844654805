import { css } from '@emotion/react';
import { useTheme } from '@mui/material';

export const useStyles = () => {
  const theme = useTheme();

  return {
    block: css`
      position: relative;
    `,
    container: css`
      display: flex;
      justify-content: space-between;
      align-items: center;
    `,
    iconsContainer: css`
      display: flex;
      gap: ${theme.spacing(1)};
      position: absolute;
      top: 3px;
      right: 3px;
    `,
  };
};
