import { css } from '@emotion/react';
import { useTheme } from '@mui/material';

export const useStyles = () => {
  const theme = useTheme();

  return {
    flexDiv: css`
      display: flex;
      gap: ${theme.spacing(4)};
    `,
    column: css`
      flex-direction: column;
    `,
    startDivTitle: css`
      margin-bottom: ${theme.spacing(2)};
      margin-top: ${theme.spacing(2)};
      margin-left: -${theme.spacing(0.5)};
      font-style: normal;
      font-weight: ${theme.typography.fontWeightRegular};
      font-size: ${theme.typography.h4.fontSize};
      line-height: ${theme.typography.h4.lineHeight};
      text-transform: capitalize;
      color: rgba(0, 0, 0, 0.8);
    `,
    divTitle: css`
      margin-bottom: ${theme.spacing(1)};
      font-style: normal;
      font-weight: ${theme.typography.fontWeightRegular};
      font-size: ${theme.typography.body1.fontSize};
      line-height: ${theme.typography.body1.lineHeight};
      text-transform: capitalize;
      color: rgba(0, 0, 0, 0.7);
    `,
  };
};
