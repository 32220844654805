import { css } from '@emotion/react';
import { useTheme } from '@mui/material';

export const useStyles = (selected?: boolean) => {
  const theme = useTheme();

  return {
    container: css`
      display: flex;
      align-items: center;
      flex-direction: column;
      padding: ${theme.spacing(1, 0)};
      background-color: ${selected
        ? theme.palette.primary.main
        : 'transparent'};
    `,
    page: css`
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      height: 40px;
      width: 80px;
      cursor: pointer;
      margin: ${theme.spacing(1)};
      border-radius: 4px;
    `,
    removeIcon: css`
      position: absolute;
      top: -14px;
      right: -8px;
      cursor: pointer;
      color: ${selected ? theme.palette.grey[200] : theme.palette.error.main};
    `,
    title: css`
      font-size: ${theme.typography.body1.fontSize};
      max-width: 140px;
      padding: ${theme.spacing(0, 0.5)};
      font-weight: ${selected
        ? theme.typography.fontWeightBold
        : theme.typography.fontWeightRegular};
      color: ${selected
        ? theme.palette.primary.contrastText
        : theme.palette.text.primary};
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      cursor: pointer;
    `,
    inputWrapper: css`
      width: 102px;
      height: 19px;
      padding: ${theme.spacing(0, 0.5)};
    `,
    iconWrapper: css`
      position: relative;
    `,
    iconUpper: css`
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -70%);
    `,
    groupedPagesLabel: css`
      height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      inset: auto auto 1px 0 !important;
    `,
    tabButton: css`
      transform: rotate(90deg) scaleY(0.66) scaleX(0.8);
      fill: ${!selected && theme.palette.common.white};
    `,
    groupedPagesLabelText: css`
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 3;
      color: ${selected
        ? theme.palette.common.white
        : theme.palette.text.primary};
    `,
    groupedPagesPageOuter: css`
      position: relative;
      min-width: 100px;
      height: 100%;
      display: flex;
      justify-content: center;
    `,
    groupedPagesPageInner: css`
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    `,
  };
};
