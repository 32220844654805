import { createSlice } from '@reduxjs/toolkit';
import { PayloadAction } from '@reduxjs/toolkit/src/createAction';
import { RootState } from '../store';
import {
  AssetScope,
  AssetType,
  IAsset,
  IAssetData,
  IAssetUpdate,
} from '../../types';
import {
  IAssetsState,
  IAssetStateBaseDictionary,
  IAssetStateDictionary,
} from './types';
import {
  fetchBrameAssets,
  fetchGlobalAssets,
  fetchPersonalAssets,
  searchGlobalAssets,
} from './asyncThunks';

export const initialAssetsState: IAssetsState = {
  global: {
    images: [],
    videos: [],
  },
  brame: {
    images: [],
    videos: [],
    icons: [],
    games: [],
  },
  personal: {
    images: [],
    videos: [],
  },
  selectedAsset: null,
};

const getAssetTypeKey = (assetType: AssetType): keyof IAssetStateDictionary => {
  switch (assetType) {
    case AssetType.VIDEO:
      return 'videos';
    case AssetType.LOGO:
      return 'icons';
    case AssetType.GAME:
      return 'games';
    case AssetType.IMAGE:
    default:
      return 'images';
  }
};

const getAssetBaseTypeKey = (
  assetType: AssetType
): keyof IAssetStateBaseDictionary => {
  switch (assetType) {
    case AssetType.VIDEO:
      return 'videos';
    case AssetType.IMAGE:
    default:
      return 'images';
  }
};

const assetSlice = createSlice({
  name: 'assets',
  initialState: initialAssetsState,
  reducers: {
    selectAsset: (state, action) => {
      state.selectedAsset = action.payload;
    },
    removePersonalAsset: (
      state,
      action: PayloadAction<{
        id: string | number;
        assetType: AssetType;
      }>
    ) => {
      const { id, assetType } = action.payload;
      const assetTypeKey = getAssetBaseTypeKey(assetType);
      return {
        ...state,
        personal: {
          ...state.personal,
          [assetTypeKey]: state.personal[assetTypeKey].filter(
            (asset) => asset.assetId !== id
          ),
        },
      };
    },
    removeBrameAsset: (
      state,
      action: PayloadAction<{
        id: string | number;
        assetType: AssetType;
      }>
    ) => {
      const { id, assetType } = action.payload;
      const assetTypeKey = getAssetTypeKey(assetType);
      return {
        ...state,
        brame: {
          ...state.brame,
          [assetTypeKey]: state.brame[assetTypeKey].filter(
            (asset) => asset.assetId !== id
          ),
        },
      };
    },
    updateAsset: (
      state,
      action: PayloadAction<{
        scope: AssetScope;
        asset: IAssetUpdate;
        assetType: AssetType;
      }>
    ) => {
      const { asset, assetType, scope } = action.payload;
      const assets =
        scope === AssetScope.BRAME
          ? state.brame[getAssetTypeKey(assetType)]
          : state.personal[getAssetBaseTypeKey(assetType)];

      if (!assets) {
        return state;
      }

      return {
        ...state,
        [scope]: {
          ...state[scope],
          [getAssetTypeKey(assetType)]: assets.map((a) =>
            a.assetId === asset.assetId ? { ...a, ...asset } : a
          ),
        },
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchGlobalAssets.fulfilled, (state, action) => {
      if (!action.payload) return state;
      state.global[getAssetBaseTypeKey(action.meta.arg)] =
        action.payload as IAsset[];
    });
    builder.addCase(fetchBrameAssets.fulfilled, (state, action) => {
      if (!action.payload) return state;
      return {
        ...state,
        brame: {
          ...state.brame,
          [getAssetTypeKey(action.meta.arg)]: action.payload as IAsset[],
        },
      };
    });
    builder.addCase(fetchPersonalAssets.fulfilled, (state, action) => {
      if (!action.payload) return state;
      state.personal[getAssetBaseTypeKey(action.meta.arg.assetType)] =
        action.payload as IAsset[];
    });
    builder.addCase(fetchPersonalAssets.rejected, (state, action) => {
      state.personal[getAssetBaseTypeKey(action.meta.arg.assetType)] = [];
    });
    builder.addCase(searchGlobalAssets.fulfilled, (state, action) => {
      if (!action.payload) return state;
      state.global[getAssetBaseTypeKey(action.meta.arg.assetType)] =
        action.payload as IAsset[];
    });
  },
});

export const {
  selectAsset,
  removePersonalAsset,
  removeBrameAsset,
  updateAsset,
} = assetSlice.actions;

export const globalImagesSelector = (state: RootState): IAsset[] =>
  state.assets.global.images;

export const globalVideosSelector = (state: RootState): IAsset[] =>
  state.assets.global.videos;

export const brameImagesSelector = (state: RootState): IAsset[] =>
  state.assets.brame.images;

export const brameVideosSelector = (state: RootState): IAsset[] =>
  state.assets.brame.videos;

export const brameIconsSelector = (state: RootState): IAsset[] =>
  state.assets.brame.icons;

export const brameGamesSelector = (state: RootState): IAsset[] =>
  state.assets.brame.games;

export const personalImagesSelector = (state: RootState): IAsset[] =>
  state.assets.personal.images;

export const personalVideosSelector = (state: RootState): IAsset[] =>
  state.assets.personal.videos;

export const selectedAssetSelector = (state: RootState): IAssetData | null =>
  state.assets.selectedAsset;

export default assetSlice.reducer;
