/* eslint-disable  @typescript-eslint/no-non-null-assertion */
type keycloakFrontendServiceConfigType = {
  realm: string;
  client_id: string;
  client_secret: string;
  grant_type: string;
  token_uri: string;
  user_management_uri: string;
  check_token_uri: string;
};

const fixedKeycloakParams = {
  realm: 'brame',
  client_id: 'fe-client',
};

const keycloakFrontendServiceConfig: keycloakFrontendServiceConfigType = {
  realm: fixedKeycloakParams.realm,
  client_id: fixedKeycloakParams.client_id,
  client_secret: process.env.REACT_APP_KEYCLOAK_FE_SERVICE_CLIENT_SECRET!,
  grant_type: 'password',
  token_uri:
    process.env.REACT_APP_SERVER_BASE_URL +
    `/keycloak/auth/realms/${fixedKeycloakParams.realm}/protocol/openid-connect/token`,
  user_management_uri:
    process.env.REACT_APP_SERVER_BASE_URL + `/user-management`,
  check_token_uri: `${process.env.REACT_APP_SERVER_BASE_URL}/keycloak/auth/realms/${fixedKeycloakParams.realm}/protocol/openid-connect/token/introspect`,
};

type cookieConfigType = {
  brame_at: string;
  refresh_brame_at: string;
};

const cookieConfig: cookieConfigType = {
  brame_at: 'brame_at_f2',
  refresh_brame_at: 'refresh_brame_at_f2',
};

type testTokenType = {
  token: string;
  refreshToken: string;
};

const testToken: testTokenType = {
  token: 'testToken',
  refreshToken: 'testRefreshToken',
};

type testUserType = {
  email: string;
  password: string;
};

const testUser: testUserType = {
  email: process.env.REACT_APP_KEYCLOAK_TEST_USER!,
  password: process.env.REACT_APP_KEYCLOAK_TEST_USER_PASSWORD!,
};

export { keycloakFrontendServiceConfig, cookieConfig, testToken, testUser };
