/* eslint-disable  @typescript-eslint/no-non-null-assertion */
import axios from 'axios';
import qs from 'qs';
import { keycloakFrontendServiceConfig } from './authConfig';
import TokenService from './token.service';

class AuthService {
  getToken(): string | undefined {
    return TokenService.getCookieAccessToken();
  }

  getRefreshToken(): string | undefined {
    return TokenService.getCookieRefreshToken();
  }

  removeTokens() {
    TokenService.deleteCookieAccessToken();
    TokenService.deleteCookieRefreshToken();
  }

  signInWithEmailAndPassword = async (email: string, password: string) => {
    const data = {
      username: email,
      password: password,
      clientId: keycloakFrontendServiceConfig.client_id,
      clientSecret: keycloakFrontendServiceConfig.client_secret,
      grantType: keycloakFrontendServiceConfig.grant_type,
      tokenUri: keycloakFrontendServiceConfig.token_uri,
    };
    try {
      const response = await axios.post(
        keycloakFrontendServiceConfig.user_management_uri + `/users/log-in`,
        data
      );
      TokenService.updateCookieAccessToken(response.data.access_token);
      TokenService.updateCookieRefreshToken(response.data.refresh_token);
      return {
        ...response.data,
        status: '200',
      };
    } catch (err) {
      return {
        status: '500',
        message: 'request error',
      };
    }
  };

  async refreshToken(refresh_token: string | undefined) {
    const data = qs.stringify({
      client_id: keycloakFrontendServiceConfig.client_id,
      client_secret: keycloakFrontendServiceConfig.client_secret,
      grant_type: 'refresh_token',
      refresh_token: refresh_token,
    });
    const config = {
      method: 'post',
      url: keycloakFrontendServiceConfig.token_uri,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      data: data,
    };
    try {
      const response = await axios(config);
      TokenService.updateCookieAccessToken(response.data.access_token);
      TokenService.updateCookieRefreshToken(response.data.refresh_token);
      return {
        status: '200',
        ...response.data,
      };
    } catch (err) {
      if (axios.isAxiosError(err)) {
        return { status: '500', message: 'axios error' };
      } else {
        return { status: '500', message: 'client error' };
      }
    }
  }

  async verifyUserToken(token: string) {
    const data = qs.stringify({
      client_id: keycloakFrontendServiceConfig.client_id,
      client_secret: keycloakFrontendServiceConfig.client_secret,
      token: token,
    });
    const config = {
      method: 'post',
      url: keycloakFrontendServiceConfig.check_token_uri,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      data: data,
    };
    try {
      return await axios(config);
    } catch (err) {
      if (axios.isAxiosError(err)) {
        return { status: '500', message: 'axios error' };
      } else {
        return { status: '500', message: 'client error' };
      }
    }
  }
}

export default new AuthService();
