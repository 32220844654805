/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { DotsThreeVertical } from 'phosphor-react';
import React, { FC } from 'react';
import { IconButton, Menu, MenuItem } from '@mui/material';
import { iconSize } from '../../../constants';
import { useStyles } from './styles';
import { MenuAction } from './types';

interface IProps {
  options: MenuAction[];
}

const MenuButton: FC<IProps> = ({ options }) => {
  const styles = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(null);
  };

  const handleMenuActionClick = (
    event: React.MouseEvent<HTMLElement>,
    option: MenuAction
  ) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(null);
    option.action();
  };

  return (
    <React.Fragment>
      <IconButton
        css={styles.menuIcon}
        size="medium"
        aria-label="asset menu"
        data-testid="asset-menu-button"
        onClick={handleMenuOpen}
      >
        <DotsThreeVertical size={iconSize} weight="bold" />
      </IconButton>
      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        MenuListProps={{
          'aria-labelledby': 'lock-button',
          role: 'listbox',
        }}
      >
        {options.map((option) => (
          <MenuItem
            css={styles.menuItem}
            key={option.name}
            onClick={(e) => handleMenuActionClick(e, option)}
          >
            {option.name}
          </MenuItem>
        ))}
      </Menu>
    </React.Fragment>
  );
};

export default MenuButton;
