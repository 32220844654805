/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { Resizable as ReResizable } from 're-resizable';
import useResizableElement from '@brame/components/src/hooks/useResizableElement';
import { disabled, getResizingPermissions } from './utils';
import { useStyles } from './styles';

const Resizable = ({ element, styleProps, children, position, size }: any) => {
  const { isSelected, prohibitWidth, prohibitHeight, handleResize } =
    useResizableElement(element, styleProps);

  const styles = useStyles(isSelected, styleProps, position);

  return (
    <ReResizable
      data-testid="re-resizable"
      size={size}
      onResizeStop={handleResize}
      onResizeStart={(e) => {
        e.stopPropagation();
        e.preventDefault();
      }}
      css={styles.resizable}
      enable={
        element.type !== 'root-container' && isSelected
          ? getResizingPermissions(prohibitWidth, prohibitHeight)
          : disabled
      }
      handleWrapperStyle={{ pointerEvents: 'all' }}
    >
      {children}
    </ReResizable>
  );
};

export default Resizable;
