import React, { FC, ForwardedRef } from 'react';

interface IProps {
  ref: ForwardedRef<HTMLElement>;
  value: string;
  editable: boolean;
  onKeyDown: (e: React.KeyboardEvent<HTMLElement>) => void;
  onBlur: () => void;
}

const Editable: FC<IProps> = React.forwardRef(
  ({ value, editable, onKeyDown, onBlur }, ref) => {
    return (
      <span
        style={{ outline: 'none' }}
        ref={ref}
        contentEditable={editable}
        suppressContentEditableWarning
        onKeyDown={onKeyDown}
        onBlur={onBlur}
      >
        {value}
      </span>
    );
  }
);

export default Editable;
