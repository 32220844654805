/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import React, { FC, Fragment, useRef } from 'react';
import { EntityId } from '@reduxjs/toolkit';
import MuiButton from '@mui/material/Button';
import useEditable from '../../hooks/useEditable';
import { Editable } from '../Editable';
import { IButtonProps } from './IProps';

const Button: FC<IButtonProps> = React.forwardRef(
  ({ styles, value, ...rest }, ref) => {
    const { action, formId, currentTheme, elementStyles, element, ...props } =
      rest;
    const spanRef = useRef<HTMLSpanElement | null>(null);

    const { startEditing, isEditable, handleTextChange, handleSubmitValue } =
      useEditable(spanRef, element?.id as EntityId);

    return (
      <MuiButton
        ref={ref}
        {...props}
        css={styles}
        type={formId ? 'submit' : 'button'}
        form={formId}
        disableRipple
        {...(element?.builderMode && {
          onDoubleClick: startEditing,
        })}
      >
        {element?.builderMode ? (
          <Editable
            value={value as string}
            editable={isEditable}
            onKeyDown={handleTextChange}
            onBlur={handleSubmitValue}
            ref={spanRef}
          />
        ) : (
          <Fragment>{value}</Fragment>
        )}
      </MuiButton>
    );
  }
);

export default Button;
