import { css } from '@emotion/react';
import { useTheme } from '@mui/material';

export const useStyles = () => {
  const theme = useTheme();

  return {
    dialogTitle: css`
      height: 40px;
      background: ${theme.palette.primary.main};
      font-weight: ${theme.typography.fontWeightBold};
      color: ${theme.palette.primary.contrastText};
      padding: ${theme.spacing(1, 3)};
      text-transform: uppercase;
    `,
    dialogTitleIcon: css`
      color: ${theme.palette.primary.contrastText};
      position: absolute;
      right: ${theme.spacing(1)};
      top: ${theme.spacing(0)};
    `,
    dialogContent: css`
      font-size: ${theme.typography.body1.fontSize};
    `,
    dialogButtons: css`
      padding: ${theme.spacing(0, 3, 3)};

      & > :not(:first-of-type) {
        margin-left: ${theme.spacing(2)};
      }
    `,
  };
};
