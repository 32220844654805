/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import React, { FC } from 'react';
import { colord } from 'colord';
import { Button } from '@mui/material';
import useThemeData from '@brame/components/src/hooks/useThemeData';
import { fontSizes } from '@brame/components/src/assets/fonts';
import {
  borderStyles,
  fontWeights,
  IButtonStyleProps,
  textTransformVariants,
  FontWeight,
  IButtonStylePropsChange,
} from '../../types';
import { ToolbarCategory } from '../ToolbarCategory';
import { SpacingWidget } from '../SpacingWidget';
import { SizeInput } from '../SizeInput';
import { SelectInput } from '../SelectInput';
import { ColorPickerWidget } from '../ColorPickerWidget';
import { FontWeightInput } from '../FontWeightInput';
import { ButtonColorPicker } from '../ButtonColorPicker';
import { ButtonElement, PageElement } from '../../reducers/builder';
import ButtonNavigationWidget from '../ButtonNavigationWidget/ButtonNavigationWidget';
import {
  selectAdditionalPages,
  selectRegularPages,
} from '../../reducers/builder/builderSlice';
import { useAppSelector } from '../../reducers/app/hooks';
import SizingWidget from '../SizingWidget/SizingWidget';
import { ZIndexWidget } from '../ZIndexWidget';
import { useStyles } from './styles';
import { fallbackProps } from './mock';
import ButtonValueInput from './ButtonValueInput';

interface IProps<T> {
  styleProps: T;
  elementProps: ButtonElement;
  onChange: (props: IButtonStylePropsChange | ButtonElement) => void;
  onReset: () => void;
  isPhaser?: boolean;
}

const ButtonEditor = <T extends IButtonStyleProps>({
  styleProps,
  elementProps,
  onChange,
  onReset,
  isPhaser,
}: IProps<T>) => {
  const {
    size,
    backgroundColor,
    font: { fontFamily, fontSize, fontWeight, fontColor, textTransform },
    border: { borderWidth, borderRadius, borderColor, borderStyle },
    spacing: { margin, padding },
    zIndex,
  } = styleProps;
  const { value, color, action, variant } = elementProps;
  const styles = useStyles();
  const { fontFamilies, getThemedStylingValues } = useThemeData();
  const pages = useAppSelector(selectRegularPages) as PageElement[];
  const additionalPages = useAppSelector(
    selectAdditionalPages
  ) as PageElement[];

  const handleColorChange = (values: any) => {
    if (values.elementProps) {
      onChange({ ...elementProps, color: values.elementProps.color });
      onChange({
        backgroundColor: undefined,
        hover: undefined,
      });
    } else {
      onChange({
        backgroundColor: values,
        hover: { backgroundColor: colord(values).darken(0.1).toHex() },
      });
    }
  };

  return (
    <div data-testid="button-editor">
      <ToolbarCategory title="General">
        <ButtonValueInput
          value={value}
          onChange={(value) => onChange({ ...elementProps, value })}
        />
        {!isPhaser && (
          <ButtonNavigationWidget
            action={action}
            pages={pages}
            additionalPages={additionalPages}
            onChange={(action) => onChange({ ...elementProps, action })}
          />
        )}
      </ToolbarCategory>

      <ToolbarCategory title="Size">
        <SizingWidget
          useAuto
          size={size || fallbackProps.size}
          onChange={(size) => onChange({ size: size })}
        />
      </ToolbarCategory>

      <ToolbarCategory title="Button color">
        <ButtonColorPicker
          color={color || fallbackProps.color}
          hex={backgroundColor}
          onChange={handleColorChange}
        />
      </ToolbarCategory>

      <ToolbarCategory title="Font">
        <SelectInput
          label="Font family"
          value={
            fontFamily ||
            getThemedStylingValues('MuiButton', variant).fontFamily ||
            fallbackProps.font.fontFamily
          }
          options={fontFamilies}
          onChange={(value) =>
            onChange({
              font: {
                fontFamily: value,
              },
            })
          }
        />
        <SelectInput
          label="Text variant"
          value={
            textTransform ||
            // @ts-ignore
            getThemedStylingValues('MuiButton', variant).textTransform ||
            fallbackProps.font.textTransform
          }
          options={textTransformVariants}
          onChange={(value) =>
            onChange({
              font: {
                textTransform: value,
              },
            })
          }
        />
        <SelectInput
          label="Font size"
          value={
            fontSize?.toString() ||
            getThemedStylingValues('MuiButton', variant).fontSize ||
            fallbackProps.font.fontSize.toString()
          }
          options={fontSizes}
          onChange={(value) =>
            onChange({
              font: {
                fontSize: +value,
              },
            })
          }
        />

        <FontWeightInput
          label="Font weight"
          value={
            fontWeight ||
            getThemedStylingValues('MuiButton', variant).fontWeight ||
            fallbackProps.font.fontWeight
          }
          options={fontWeights}
          onChange={(value) =>
            onChange({
              font: {
                fontWeight: value as FontWeight,
              },
            })
          }
        />

        <ColorPickerWidget
          title="Text color"
          alpha
          value={
            fontColor ||
            getThemedStylingValues('MuiButton', variant).color ||
            fallbackProps.font.fontColor
          }
          onChange={(value) =>
            onChange({
              font: {
                fontColor: value,
              },
            })
          }
        />
      </ToolbarCategory>

      <ToolbarCategory title="Border">
        <SizeInput
          title="Border width"
          sizeValue={
            borderWidth ||
            getThemedStylingValues('MuiButton', variant).borderWidth ||
            fallbackProps.border.borderWidth
          }
          fullWidth
          css={styles.input}
          onChange={(values) =>
            onChange({
              border: { borderWidth: values },
            })
          }
        />
        <SizeInput
          title="Border radius"
          sizeValue={
            borderRadius ||
            getThemedStylingValues('MuiButton', variant).borderRadius.value ||
            fallbackProps.border.borderRadius
          }
          fullWidth
          css={styles.input}
          onChange={(values) =>
            onChange({
              border: { borderRadius: values },
            })
          }
        />
        <ColorPickerWidget
          title="Border color"
          alpha
          value={
            borderColor ||
            getThemedStylingValues('MuiButton', variant).borderColor ||
            fallbackProps.border.borderColor
          }
          onChange={(value) =>
            onChange({
              border: { borderColor: value },
            })
          }
        />
        <SelectInput
          label="Border style"
          value={
            borderStyle ||
            getThemedStylingValues('MuiButton', variant).borderStyle ||
            fallbackProps.border.borderStyle
          }
          options={borderStyles}
          onChange={(value) =>
            onChange({
              border: { borderStyle: value },
            })
          }
        />
      </ToolbarCategory>

      <ToolbarCategory title="Plane Index">
        <ZIndexWidget
          value={zIndex || fallbackProps.zIndex}
          onChange={(value) => onChange({ zIndex: value })}
        />
      </ToolbarCategory>

      <ToolbarCategory title="Margin">
        <SpacingWidget
          spacing={margin || fallbackProps.spacing.margin}
          onChange={(values) =>
            onChange({
              spacing: { margin: values },
            })
          }
        />
      </ToolbarCategory>

      <ToolbarCategory title="Padding">
        <SpacingWidget
          spacing={padding || fallbackProps.spacing.padding}
          onChange={(values) =>
            onChange({
              spacing: { padding: values },
            })
          }
        />
      </ToolbarCategory>

      <ToolbarCategory>
        <Button
          data-testid="reset-styles-button"
          onClick={onReset}
          size="small"
        >
          Reset
        </Button>
      </ToolbarCategory>
    </div>
  );
};

export default ButtonEditor;
