import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import CampaignService from '../../../api-client/campaigns.service';

export const useCampaignData = () => {
  const { campaignId, companyId } = useParams();
  const [data, setData] = useState(null);

  useEffect(() => {
    const getCampaignData = async () => {
      const campaignData =
        await CampaignService.getCampaignDataByCompanyAndCampaign(
          companyId,
          campaignId
        );
      setData(campaignData);
    };

    getCampaignData();
  }, [companyId, campaignId]);

  return {
    // @ts-ignore
    pages: data?.data.data.pages,
    // @ts-ignore
    additionalPages: data?.data.data.additionalPages,
    // @ts-ignore
    elements: data?.data.data.elements.entities,
    // @ts-ignore
    gameData: data?.data.data.game,
  };
};
