/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import React, { FC } from 'react';
import { Grid, Typography } from '@mui/material';
import { ITheme } from '@brame/theme';
import { IWizard } from '../initialWizardState';
import { useStyles } from './styles';
import { TemplateCard } from './TemplateCard';
import { ITemplateState } from './templateTypes';
import useCurrentTheme from './useCurrentTheme';

export interface ITemplates {
  template: ITemplateState;
  id: string;
  name: string;
}

export interface ICampaignTemplate {
  wizardState: IWizard;
  handleWizardState: (stepName: string, payload: IWizard) => void;
  handleProgressBar: (activeType: string) => void;
  customThemes: ITheme[];
  templates?: ITemplates;
}

const CampaignTemplate: FC<ICampaignTemplate> = ({
  wizardState,
  handleWizardState,
  handleProgressBar,
  customThemes,
  templates,
}) => {
  const currentTemplateId = wizardState.campaignTemplate.templateId;
  const styles = useStyles(!wizardState.campaignTemplate.isDisable);

  const currentTheme = useCurrentTheme(customThemes, wizardState);

  if (!currentTheme) return null;

  return (
    <div data-testid="campaign-template">
      <Typography css={styles.successText}>
        Everything is set up and ready to create
      </Typography>
      <div
        css={styles.templateContainer}
        data-testid="campaign-template-content"
      >
        <Grid css={styles.gridContainer} container spacing={2}>
          {templates &&
            Object.values(templates).map((template) => (
              <TemplateCard
                key={template.id}
                wizardState={wizardState}
                handleProgressBar={handleProgressBar}
                onSelect={handleWizardState}
                currentTemplate={template}
                currentTheme={currentTheme}
                active={currentTemplateId === template.id}
              />
            ))}
        </Grid>
      </div>
    </div>
  );
};

export default CampaignTemplate;
