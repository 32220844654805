/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import React, { FC } from 'react';
import { Button } from '@mui/material';
import { InternalAlignmentWidget } from '../InternalAlignmentWidget';
import { ToolbarCategory } from '../ToolbarCategory';
import { SizingWidget } from '../SizingWidget';
import { SpacingWidget } from '../SpacingWidget';
import { BackgroundWidget } from '../BackgroundWidget';
import { IContainerStyleProps, IContainerStylePropsChange } from '../../types';
import { ZIndexWidget } from '../ZIndexWidget';
import { fallbackProps } from './mock';

interface IProps {
  props: IContainerStyleProps;
  onChange: (props: IContainerStylePropsChange) => void;
  root?: boolean;
  onReset: () => void;
}

const ContainerEditor: FC<IProps> = ({ props, onChange, onReset, root }) => {
  const { internalAlignment, size, background, zIndex } = props;

  return (
    <div data-testid="container-editor">
      {!!Object.keys(props).length && (
        <div>
          <ToolbarCategory title="Internal Alignment">
            <InternalAlignmentWidget
              props={internalAlignment || fallbackProps.internalAlignment}
              onChange={(values) => onChange({ internalAlignment: values })}
            />
          </ToolbarCategory>

          {!root && (
            <div>
              {size && (
                <ToolbarCategory title="Size">
                  <SizingWidget
                    size={size || fallbackProps.size}
                    onChange={(values) => onChange({ size: values })}
                    useAuto
                  />
                </ToolbarCategory>
              )}

              {props.spacing?.margin && (
                <ToolbarCategory title="Margin">
                  <SpacingWidget
                    spacing={
                      props.spacing?.margin || fallbackProps.spacing?.margin
                    }
                    onChange={(values) =>
                      onChange({
                        spacing: { margin: values },
                      })
                    }
                  />
                </ToolbarCategory>
              )}

              <ToolbarCategory title="Plane Index">
                <ZIndexWidget
                  value={zIndex || fallbackProps.zIndex}
                  onChange={(value) => onChange({ zIndex: value })}
                />
              </ToolbarCategory>
            </div>
          )}

          {props.spacing?.padding && (
            <ToolbarCategory title="Padding">
              <SpacingWidget
                spacing={
                  props.spacing?.padding || fallbackProps.spacing?.padding
                }
                onChange={(values) =>
                  onChange({
                    spacing: { padding: values },
                  })
                }
              />
            </ToolbarCategory>
          )}

          <ToolbarCategory title="background">
            <BackgroundWidget
              props={background || fallbackProps.background}
              onChange={(values) => onChange({ background: values })}
            />
          </ToolbarCategory>

          <ToolbarCategory>
            <Button
              data-testid="reset-styles-button"
              onClick={onReset}
              size="small"
            >
              Reset
            </Button>
          </ToolbarCategory>
        </div>
      )}
    </div>
  );
};

export default ContainerEditor;
