/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { FC, Fragment } from 'react';
import parse, { HTMLReactParserOptions } from 'html-react-parser';
import { useAppSelector } from '../../reducers/app/hooks';
import { getSelectedBreakpoint } from '../../reducers/shared/sharedSlice';
import { IFrame } from '../../pages/preview/IFrame';
import { getCurrentTemplate } from '../../reducers/email/emailSlice';
import { useStyles } from './styles';

const EmailCanvas: FC = () => {
  const activeBreakpoint = useAppSelector(getSelectedBreakpoint);
  const styles = useStyles(activeBreakpoint.breakpoint);
  const template = useAppSelector(getCurrentTemplate);

  // TODO: add options
  const options: HTMLReactParserOptions = {
    replace: ({ attribs, children }: any) => {
      if (!attribs) {
        return;
      }
    },
  };

  const parsedHtml = parse(template ? template.html : '', options);

  return (
    <div css={styles.wrapper} data-testid="email-canvas">
      <IFrame name="email-canvas">
        <Fragment>{parsedHtml}</Fragment>
      </IFrame>
    </div>
  );
};

export default EmailCanvas;
