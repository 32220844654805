import { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../reducers/app/hooks';
import { selectAuth } from '../../../reducers/auth/authSlice';
import AssetsService from '../../../api-client/assets.service';
import { AssetScope, AssetType } from '../../../types';
import {
  fetchBrameAssets,
  fetchPersonalAssets,
} from '../../../reducers/assets/asyncThunks';

const useFileUploader = (
  scope: AssetScope.BRAME | AssetScope.PERSONAL,
  assetType: AssetType
) => {
  const dispatch = useAppDispatch();
  const companyId = useAppSelector(selectAuth).parsedToken.company_id;
  const [fileIsLoading, setFileIsLoading] = useState<boolean>(false);

  const uploadFile = async (
    file: File,
    base64: string | ArrayBuffer | null,
    labels: string[]
  ) => {
    if (file && companyId) {
      setFileIsLoading(true);
      await AssetsService.addAsset(
        scope,
        file,
        base64,
        assetType,
        companyId,
        labels
      ).finally(() => {
        setFileIsLoading(false);
      });
      if (scope === AssetScope.PERSONAL) {
        dispatch(fetchPersonalAssets({ companyId, assetType }));
      } else {
        dispatch(fetchBrameAssets(assetType));
      }
    }
  };

  return { uploadFile, fileIsLoading };
};

export default useFileUploader;
