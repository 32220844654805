import { KeyboardEvent } from 'react';
import { FormikProps } from 'formik';
import { AlertColor } from '@mui/material/Alert';
import { IFormProps } from './UpdateAssetModal';

const useLabels = (openAlert: (text: string, type: AlertColor) => void) => {
  const addLabel = (
    e: KeyboardEvent<HTMLDivElement>,
    props: FormikProps<IFormProps>
  ): void => {
    if (e.key === 'Enter') {
      e.preventDefault();
      e.stopPropagation();
      const newLabel = props.values.newLabel.trim();

      if (!newLabel) {
        return;
      }
      if (props.values.labels.includes(newLabel)) {
        openAlert('Such label already exists', 'warning');
        return;
      }
      props.setFieldValue('labels', [...props.values.labels, newLabel]);
      props.setFieldValue('newLabel', '');
    }
  };

  const deleteLabel = (props: FormikProps<IFormProps>, label: string): void => {
    props.setFieldValue(
      'labels',
      props.values.labels.filter((l) => l !== label)
    );
  };

  return {
    addLabel,
    deleteLabel,
  };
};

export default useLabels;
