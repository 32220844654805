import { css } from '@emotion/react';
import { useTheme } from '@mui/material';

export const useStyles = () => {
  const theme = useTheme();

  return {
    title: css`
      text-transform: capitalize;
      font-size: ${theme.typography.body1.fontSize};
      font-weight: ${theme.typography.fontWeightBold};
      color: ${theme.palette.text.primary};
    `,
    fontBlock: css`
      display: flex;
      flex-direction: column;
      gap: ${theme.spacing(3)};
      border-bottom: 1px solid gray;
      padding: ${theme.spacing(2, 0)};

      &:first-of-type {
        padding-top: ${theme.spacing(0)};
      }

      &:last-of-type {
        border: none;
        padding-bottom: ${theme.spacing(0)};
      }
    `,
    accordionSummary: css`
      padding-left: ${theme.spacing(0)};
    `,
    buttonBlock: css`
      margin-top: ${theme.spacing(4)};
    `,
    button: css`
      &:not(:first-of-type) {
        margin-left: ${theme.spacing(2)};
      }
    `,
    accordion: css`
      &.Mui-expanded {
        margin: ${theme.spacing(3, 0)};
      }
    `,
    titleBlock: css`
      display: flex;
      align-items: center;
      gap: ${theme.spacing(1)};
      width: 100%;
    `,
    fontProps: css`
      display: flex;
      gap: ${theme.spacing(2)};
    `,
  };
};
