/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import React, { FC } from 'react';
import { HexColorPicker, RgbaStringColorPicker } from 'react-colorful';
import { colord } from 'colord';
import { debounce, Popover, Typography } from '@mui/material';
import { useStyles } from './styles';

interface IProps {
  value: string;
  onChange: (value: string) => void;
  label?: string;
  alpha?: boolean;
}

const VariativeColorPicker: FC<IProps> = ({ alpha, value, onChange }) => {
  const handleChange = (value: string) => {
    onChange(colord(value).toHex());
  };

  if (alpha) {
    const transformedValue = colord(value).toRgbString();
    return (
      <RgbaStringColorPicker
        color={transformedValue}
        onChange={handleChange}
        data-testid="color-picker-element-alpha"
      />
    );
  }

  return (
    <HexColorPicker
      color={value}
      onChange={onChange}
      data-testid="color-picker-element"
    />
  );
};

const ColorPicker: FC<IProps> = ({ alpha, value, onChange, label }) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const styles = useStyles({ value });
  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const handleChange = debounce(onChange, 200);

  return (
    <div data-testid="color-picker" css={styles.widget}>
      {label && <Typography>{label}</Typography>}
      <div
        css={styles.swatch}
        data-testid="color-picker-swatch"
        onClick={handleClick}
      />

      <Popover
        id={id}
        data-testid="color-picker-popover"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        PaperProps={{ style: { overflow: 'visible' } }}
      >
        <VariativeColorPicker
          value={value}
          onChange={handleChange}
          alpha={alpha}
        />
      </Popover>
    </div>
  );
};

export default ColorPicker;
